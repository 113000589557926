import React, { useEffect, useState } from "react";
// import "./userDetails.scss";
import "./userField.scss";
import { useHistory, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import placeholder from "../../../../assets/images/placeholder.svg";
import IcDownload from "../../../../assets/images/IcDownload.svg";
import { getTranslatedText as t } from "../../../../translater/index";
import { FaBook, FaUsers, FaLevelUpAlt } from "react-icons/fa";
import API from "../../../../api";
import { Link } from "react-router-dom";
import { getDetailsUsers } from "../../../../services/adminService";
import Loader from "../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import NoData from "../../../../components/NoData/NoData";
import Footer from "../../../../components/footer/Footer";
import { formatDate } from "../../../../services/constant";

function UserInfo() {
  const params = useParams();
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();

  const usertype = state.UserDetail.user_type;
  let lan = state?.language;
  const [list, setList] = useState([]);
  const [basicInfo, setBasicInfo] = useState({});
  const [allInfo, setAllInfo] = useState({});
  const [numCourses, setNumCourses] = useState([]);
  const [userType, setUserType] = useState("");
  const [supplierInfo, setSupplierInfo] = useState();
  const [learners, setLearners] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [coursesOffered, setCourseOffered] = useState([]);
  const getUserDeatilsCall = () => {
    setIsLoader(true);
    getDetailsUsers(params?.id).then((res) => {
      setIsLoader(false);
      if (res?.status === "success") {
        if (res?.supplier_profile) {
          setList(res.individual_list);
          setBasicInfo(res?.supplier_profile[0]);

          setAllInfo(res?.organization_profile);
          setCourseOffered(
            res.organization_profile?.course_category.split(",")
          );
          setSupplierInfo(res?.supplier_profile[1]);
          setUserType("Supplier");
          setLearners(res?.learners);
          setNumCourses(res?.total_course);
        } else {
          setList(res?.course_list);
          setBasicInfo(res?.data[1]);
          setAllInfo(res?.data[0]);
          setNumCourses(res?.course_list?.length);
          setUserType("User");
        }
      }
    });
  };

  useEffect(() => {
    getUserDeatilsCall();
  }, [params.id]);

  return (
    <div className="userDetail">
      <div className="userDetailHeader">
        <p className="midText">
          {" "}
          <span
            className="cursor-pointer"
            onClick={() => history.push("/supplier/dashboard")}
          >
            {t("Dashboard", lan)} |{" "}
          </span>
          <span
            className="cursor-pointer"
            onClick={() => history.push("/supplier/manage-users")}
          >
            {usertype === "Supplier" ? "" : `${t("ManageUsers", lan)} |`}
          </span>
          <span className="textPrimaryColor">
            {" "}
            {` ${t("User", lan)} ${t("Detail", lan)}`}
          </span>
        </p>
        <div className="userDetailHeaderRight">
          <span
            className="headerTab smallText cursorPointer"
            onClick={() => {
              window.history.back();
            }}
          >
            <IoIosArrowBack /> &nbsp;{t("Back", lan)}
          </span>
        </div>
      </div>
      <div className="userDetailsMain">
        <div className="userDetailsMainDetailView">
          <div className="userDetailsMainDetailViewLeft">
            <span className="summaryDetail">
              <div className="imgBlock">
                <img
                  src={
                    userType === "Supplier"
                      ? basicInfo?.supplier_image
                        ? `${basicInfo?.supplier_image}`
                        : placeholder
                      : allInfo?.profile_image
                      ? `${allInfo?.profile_image}`
                      : placeholder
                  }
                  className="userDetailImage"
                />
              </div>
              <span className="ms-lg-2">
                {/* <p className="midText boldText mb-2">
                  {selectedAdsDetails?.data?.course_name || "-"}{" "}
                  <FaStar className="starIcon" />{" "}
                  <span className="superSmallText"> 4.5 </span>
                </p> */}
                <p className="midText">
                  {userType === "Supplier"
                    ? `${supplierInfo?.first_name ?? ""} ${
                        supplierInfo?.last_name ?? ""
                      }`
                    : `${allInfo?.first_name ?? ""} ${
                        allInfo?.last_name ?? ""
                      }` || "-"}
                </p>
                <p className="superSmallText">
                  {t("UserType", lan)} :{" "}
                  <span className="textPrimaryColor">
                    {userType === "Supplier"
                      ? "Supplier"
                      : basicInfo?.user_type?.user_type || "-"}
                  </span>
                </p>

                <p className="superSmallText">
                  {` ${t("DateCreatedOn", lan)}`} :{" "}
                  <span className="textPrimaryColor">
                    {formatDate(basicInfo?.created_date_time)}
                  </span>
                </p>

                <p className="pWithMargin f-18  whitespaceNoWrap">
                  {"Status"} :
                  <span
                    className={
                      (userType === "Supplier"
                        ? supplierInfo?.status?.value
                        : basicInfo.status?.value) == "Active"
                        ? "StatusActive ms-4"
                        : "StatusInActive ms-4"
                    }
                    style={{ backgroundImage: "unset" }}
                  >
                    {userType === "Supplier"
                      ? t(supplierInfo?.status?.value, lan)
                      : t(basicInfo?.status?.value, lan)}
                  </span>
                  {/* <select value={selectedAdsDetails?.data?.status?.value}
                        className={
                            selectedAdsDetails?.data?.status?.value == "Active" ?
                             "StatusActive ms-4" : "StatusInActive ms-4"
                        } 
                        // onChange={(e)=>{setStatus(e?.target?.value); onStatusChange(e)}}
                      
                    >
                        <option
                        value="Active"
                        // selected={
                        //   CoursesOfferedData?.status
                        //     ?.value == "Active"
                        // }
                        >
                        Active
                        </option>
                        <option
                        value="Inactive"
                        // selected={
                        //   CoursesOfferedData?.status
                        //     ?.value == "Inactive"
                        // }
                        >
                        In-active
                        </option>
                    </select> */}
                </p>
              </span>
            </span>
            {userType === "Supplier" ? (
              <>
                <p className="boldText pWithMargin p-title">
                  {` ${t("Company", lan)} ${t("Information", lan)}`}
                </p>
                <div className="personalInfo mb-5">
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("Organization", lan)} ${t("Name", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.organizational_name || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("Organization", lan)} ${t("Email", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.organization_email || "-"}
                    </p>
                  </span>

                  {supplierInfo.is_corporate ? (
                    <span className="singleInfothree">
                      <p className="smallText boldText mb-2">
                        {` ${t("CorporateCode", lan)} `}
                      </p>
                      <p className="superSmallText">
                        {allInfo?.corporate_code || "-"}
                      </p>
                    </span>
                  ) : (
                    <span className="singleInfothree">
                      <p className="smallText boldText mb-2">
                        {` ${t("Organization", lan)} ${t("Website", lan)}`}
                      </p>
                      <p className="superSmallText">
                        {allInfo?.organization_website || "-"}
                      </p>
                    </span>
                  )}
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("Organization", lan)} ${t("Address", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.organization_address || "-"}
                    </p>
                  </span>

                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("Phone", lan)} ${t("Number", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.organization_phone_number || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {t("Country", lan)}
                    </p>
                    <p className="superSmallText">{allInfo?.country || "-"}</p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">{t("City", lan)}</p>
                    <p className="superSmallText">{allInfo?.city || "-"}</p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("Contact", lan)} ${t("Person", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.contact_person || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("About", lan)} ${t("Organization", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.brif_information || "-"}
                    </p>
                  </span>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-12">
                    <p className="boldText pWithMargin p-title">
                      {` ${t("Courses", lan)} ${t("Offer", lan)}`}
                    </p>
                    <div className="personalInfo courses-list mb-5">
                      {coursesOffered.map((cou) => (
                        <ul>
                          <li>{cou}</li>
                        </ul>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <p className="boldText pWithMargin p-title">
                      {` ${t("Organization", lan)} ${t("Logo", lan)}`}
                    </p>
                    <div className="personalInfo mb-5 org-logo">
                      {" "}
                      <img
                        alt="LOGO"
                        src={
                          allInfo?.organization_logo
                            ? `${allInfo?.organization_logo}`
                            : placeholder
                        }
                        className="userDetailImage"
                      />
                      {/* <span className="icon-download">
                        <img src={IcDownload} />
                      </span> */}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                <p className="boldText pWithMargin p-title">
                  {` ${t("Personal", lan)} ${t("Information", lan)}`}
                </p>
                <div className="personalInfo mb-5">
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {t("Gender", lan)}
                    </p>
                    <p className="superSmallText">{allInfo?.gender}</p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {t("DateofBirth", lan)}
                    </p>
                    <p className="superSmallText">{formatDate(allInfo?.dob)}</p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("phonnumber", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.phone_number || "-"}
                    </p>
                  </span>

                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("EmailAddress", lan)}`}
                    </p>
                    <p className="superSmallText">{allInfo?.email_id}</p>
                  </span>
                </div>
                <p className="boldText pWithMargin p-title">
                  {` ${t("Educational", lan)}`}
                </p>
                <div className="personalInfo mb-5">
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("Highestlvl", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.highest_education}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("University", lan)} ${t("Name", lan)}`}
                    </p>
                    <p className="superSmallText">{allInfo.university_name}</p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("HighestDegree", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.highest_degree || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("EducationArea", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.educational_area || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("OtherRelevantEducation", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.other_education || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("DiplomaCertificates", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.diplomas_certificates}
                    </p>
                  </span>
                </div>
                <p className="boldText pWithMargin p-title">
                  {` ${t("ProfessionalInfo", lan)}`}
                </p>
                <div className="personalInfo mb-5">
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("CurrentProfessionalRole", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.current_professional_role || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("AdditionalRole", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo.additional_role || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("ExtraCurricular", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.extra_curricular || "-"}
                    </p>
                  </span>
                  {/* <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("Extra", lan)} ${t("Curricular", lan)}  ${t(
                        "Competence",
                        lan
                      )} ${t("You", lan)} ${t("Have", lan)}`}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.extra_curricular_competence || "-"}
                    </p>
                  </span> */}
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {t("CoreResponsibilities", lan)}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.core_responsibilities || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {t("LevelOfRole", lan)}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.level_of_role || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {t("FutureProfessionalRole", lan)}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.future_professional_role || "-"}
                    </p>
                  </span>
                </div>
                <p className="boldText pWithMargin p-title">
                  {" "}
                  {t("AreaOfInterest", lan)}
                </p>
                <div className="personalInfo mb-5">
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {t("CourseCategory", lan)}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.course_category?.split(", ") || "-"}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {t("AreaOfInterest", lan)}{" "}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.area_of_interest || "-"}
                    </p>
                  </span>
                </div>
              </>
            )}
          </div>

          <div className="userDetailsMainDetailViewRight">
            <span className="userDetailRightContent">
              {userType === "Supplier" ? (
                <FaUsers className="textPrimaryColor midText" />
              ) : (
                <FaBook className="textPrimaryColor midText" />
              )}
              &nbsp; &nbsp;
              {userType === "Supplier" ? learners : numCourses || 0}{" "}
              {userType === "Supplier"
                ? t("Learners", lan)
                : t("OngoingCourses", lan)}
            </span>

            <span className="userDetailRightContent">
              {userType === "Supplier" ? (
                <FaLevelUpAlt className="textPrimaryColor midText" />
              ) : (
                ""
              )}

              {userType === "Supplier" ? `${numCourses} Courses Added` : ""}
            </span>
            {/* <span className='userDetailRightContent'><FaClock className='textPrimaryColor midText'/> &nbsp; &nbsp;3-4 Weeks</span>
                    <span className='userDetailRightContent'><FaBookmark className='textPrimaryColor midText'/> &nbsp; &nbsp;$80</span> */}
          </div>
        </div>
        <div className="userDetailsMainListView">
          <p>
            {userType === "Supplier"
              ? ` ${t("Individual", lan)} ${t("List", lan)} ( ${t(
                  "who",
                  lan
                )} ${t("had", lan)} ${t("applied", lan)} ${t("for", lan)} ${t(
                  "course",
                  lan
                )})`
              : ` ${t("CourseList", lan)}`}
          </p>
          <div className="userDetailsMainListTable">
            <table className="courseListTable">
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>
                          <img
                            src={item?.profile_image || placeholder}
                            alt="Avtar"
                            className="courseAvtars"
                          />
                        </td>

                        <td>
                          {item?.course_name || ""}
                          <br />{" "}
                          <span className="superSmallText textGrayColor">
                            {item?.course_category || "-"}
                          </span>
                        </td>
                        <td>
                          {item?.supplier_name || "-"}
                          <br />{" "}
                          <span className="superSmallText textGrayColor">
                            {item?.supplier_email || "-"}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color:
                                item?.course_status == "Completed"
                                  ? "#3E8181"
                                  : item?.course_status == "Ongoing"
                                  ? "#DBBA0D"
                                  : "#5C59E7",
                            }}
                          >
                            {t(item?.course_status, lan) || t("Pending", lan)}
                          </span>
                          <br />{" "}
                          <span className="superSmallText textPrimaryColor">
                            <Link
                              to={`/supplier/course/details/${item?.course_uuid}`}
                              className="cursorPointer textPrimaryColor linkDecoreRemove"
                            >
                              {t("VIEW", lan)}
                            </Link>
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <NoData />
              )}
            </table>
          </div>
        </div>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default UserInfo;
