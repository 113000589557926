import React, { useState, useEffect, Component, useMemo } from "react";
import Chart from "react-apexcharts";
import "./DonutChart.scss";
import { connect } from "react-redux";
import { getTranslatedText as t } from "../../translater/index";
import { useSelector } from "react-redux";
const DonutChart = (props) => {
  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;

  const [someData, setSomeData] = useState(props?.state);
  const [offered, setOffered] = useState(Number(someData?.Course_Offered));
  const [purchased, setPurchased] = useState(Number(someData?.Purchased));
  const [unPurchased, setUnpurchased] = useState(
    Number(someData?.Not_Purchased)
  );
  const [data, setData] = useState({
    series: [offered, purchased, unPurchased],
    options: {
      chart: {
        type: "donut",
        width: 50,
        height: "50px",
      },
      dataLabels: {
        formatter: function (val, opts) {
          // return opts.w.config.series[opts.seriesIndex]
        },
      },
      labels: [
        `${offered} ${t("CoursesOfferedChart", lan)}`,
        `${purchased} ${t("Purchased", lan)}`,
        `${unPurchased} ${t("NotPurchased", lan)}`,
      ],
      responsive: [
        {
          breakpoint: 576,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  useMemo(() => {
    setSomeData(props?.state);
    setOffered(Number(props?.state?.Course_Offered));
    setPurchased(Number(props?.state?.Purchased));
    setUnpurchased(Number(props?.state?.Not_Purchased));
  }, [props]);

  useMemo(() => {
    setData({
      series: [offered, purchased, unPurchased],
      options: {
        chart: {
          type: "donut",
          width: 50,
          height: "50px",
        },
        dataLabels: {
          formatter: function (val, opts) {
            // return opts.w.config.series[opts.seriesIndex]
          },
        },
        labels: [
          `${offered} ${t("CoursesOfferedChart", lan)}`,
          `${purchased} ${t("Purchased", lan)}`,
          `${unPurchased} ${t("NotPurchased", lan)}`,
        ],
        responsive: [
          {
            breakpoint: 576,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });
  }, [props]);

  return (
    <div id="chart">
      <Chart
        options={data.options}
        height="330px"
        series={data.series}
        type="donut"
      />
    </div>
  );
};

export default DonutChart;
