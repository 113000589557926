import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./inputText.scss";



function inputText(props) {


  return (
    <div className="input-main">
      <div className="form-group position-relative">
        <label>{props.labelName}</label>
        <input
          type={props.type}
          className="form-control"
          placeholder={props.placeholder}
          ref={props.inputref}
          onChange={props.onChange}
          value={props?.value}
          defaultValue={props?.defaultValue}
          name={props.name}
          
        />
      </div>
    </div>
  );
}

export default inputText;
