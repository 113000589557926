
import "./ForgotPassword.scss";
import "../../../common/styles/globalStyles.scss";
import LOGO from "../../../assets/logo/Logo.svg";
import { forgotPassApi, loginApi } from "../../../services/authService";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Popup from "../../../components/popup/popup";
import { getTranslatedText as t } from "../../../translater/index";
import { encrypt } from "../../../utils/encrypt";
const ForgotPass = () => {
  const history = useHistory();
  const emailref = useRef();
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [loginDissabled, setLoginDissabled] = useState(true);
  const [inputchange, setInputChange] = useState("");
  const [loginError, setLoginError] = useState("");
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);

  useEffect(() => {
    let emailVal = emailref.current.value;
    if (emailVal.trim() === "") {
      setLoginDissabled(true);
    } else {
      if (!emailRegex.test(emailVal)) {
        setLoginDissabled(true);
      } else {
        setLoginDissabled(false);
      }
    }
  }, [inputchange]);

  const loginApiCall = () => {
    let emailVal = emailref.current.value;

setLoderBtn(true)
    var bodyFormData = new FormData();
    bodyFormData.append("email_id", encrypt(emailVal));

    forgotPassApi(bodyFormData)
      .then((res) => {
        setLoderBtn(false)
        if(res.status==="success"){
          setPopupStatus(t("Success", lan));
        }else{
          setPopupStatus(t("Error", lan));
        }
        setPopupMessage(lan == "en" ? res?.data : res?.data_sv)
        
    
      })
      .catch((err) => {
        setLoderBtn(false)
        console.log(err.data);
      });
  };
  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    history.push("/");
  };

  return (
    <div className="login">
      <div className="loginCard">
        <img src={LOGO} alt="Eddi Logo" className="eddiLogo" />
        <p className="midText pWithMargin boldText">{t("ForgotPasswordHeader",lan)}</p>
        <p className="textBlackColor smallText ">
          {t("EnterYourEmailBelow",lan)}
        </p>
        <div className="inputDiv">
          <label htmlFor="email">
            <p className="smallText inputText boldText"> {t("EnterYourEmail",lan)} </p>
          </label>
          <input
            type="email"
            id="email"
            placeholder={t("EmailAddress",lan)}
            className="authInputs"
            ref={emailref}
            onChange={(e) => {
              setInputChange(e.target.value);
            }}
          />

        </div>

        <button
          className={
            loginDissabled ? "authPrimaryButtonDissabled" : "authPrimaryButton"
          }
          disabled={loginDissabled || loderBtn ? true : false}
          onClick={() => {
            loginApiCall();
          }}
        >
          {
            loderBtn ?
            <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                :
                t("submit",lan)
          }
          
        </button>


        <p>&nbsp;</p>

        {loginError !== "" && (
          <>
            <p className="errorText">{loginError}</p>
            <p>&nbsp;</p>
          </>
        )}

      </div>
      
      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupMessage}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default ForgotPass;