import React, { useEffect, useState } from "react";
import "./managePayments.scss";
import { getTranslatedText as t } from "../../../../translater/index";
import { useSelector } from "react-redux";
import NoData from "../../../../components/NoData/NoData";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../../assets/images/IcFilter.svg";
import IcFilter2 from "../../../../assets/images/IcFilter2.svg";
import FilterSelectMenu from "../../../../components/FilterSelectMenu/FilterSelectMenu";
import { Link, useHistory } from "react-router-dom";
import IcSearch from "../../../../assets/images/icSearch.svg";
import downloadArrow from "../../../../assets/images/downloadIcon.svg";
import Popup from "../../../../components/popup/popup";
import Loader from "../../../../components/Loader/Loader";
import { editPayments, getPayments } from "../../../../services/adminService";
import InputText from "../../../../components/inputText/inputText";
import Footer from "../../../../components/footer/Footer";
import Boxarrow from "../../../../assets/images/boxarrow.svg";
import TopCounterBox from "../../../../components/TopCounterBox/TopCounterBox";
import { toast } from "react-toastify";
import { sendWitdrawalRequest } from "../../../../services/supplierService";
import api from "../../../../api";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function ManagePayments() {
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const history = useHistory();
  const type = state.UserDetail?.user_type;
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [userData, setUserData] = useState([]);
  const [filteredUser, setFilteredUser] = useState();
  const [paginationUser, setPaginationUser] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [byStatusFilter, setByStatusFilter] = useState("All");
  const [selectedMethodFilter, setSelectedMethodFilter] =
    useState("PaymentMethod");
  const [isLoader, setIsLoader] = useState(false);
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("Course");
  const [searchData, setSearchData] = useState([]);
  const [events, setEvents] = useState([]);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [loderBtn, setLoaderBtn] = useState(false);
  const [ammountEntered, setAmmountEntered] = useState();
  const [supplierAccData, setSupplierAccData] = useState();
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );

  const managePaymentsApiCall = async () => {
    setIsLoader(true);
    getPayments()
      .then((res) => {
        setIsLoader(false);
        if (res?.status == "success") {
          setSearchData(res.data);
          setUserData(res?.data);
          setEvents(res?.event);
          setSupplierAccData(res?.supplier_account_data);
        }else{
          toast.error(lan ==='en' ? res.data : res.data_sv)
        }
      })
      .catch((e) => {
        setIsLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    setSearchData(selectedRoleFilter === "Course" ? userData : events);
  }, [selectedRoleFilter]);

  useEffect(async () => {
    await managePaymentsApiCall();
  }, []);

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredUser?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationUser(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [
    paginationStartIndex,
    filteredUser,
    selectedRoleFilter,
    byStatusFilter,
  ]);

  function userComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.course?.course_starting_date);
    const date2 = new Date(b?.course?.course_starting_date);

    return date2 - date1;
  }
  function userComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.course?.course_starting_date);
    const date2 = new Date(b?.course?.course_starting_date);

    return date1 - date2;
  }

  const filterByRole = () => {
    let filteredbyTime = [];
    let filterByMethod = [];
    if (byStatusFilter === "Pending") {
      filteredbyTime = searchData.filter((pay) =>pay.is_approved?.value === "Pending");
    } else if(byStatusFilter === "Rejected")  {
      filteredbyTime = searchData.filter((pay) =>pay.is_approved?.value === "Rejected");
    }else if(byStatusFilter === "Approved")  {
      filteredbyTime = searchData.filter((pay) =>pay.is_approved?.value === "Approved");
    }else{
      filteredbyTime=searchData
    }

    if (selectedMethodFilter != "PaymentMethod") {
      filteredbyTime.map((item, i) => {
        if (
          item?.payment_mode == "PayByInvoice" &&
          selectedMethodFilter == "PayByInvoice"
        ) {
          filterByMethod.push(item);
        } else if (
          item?.payment_mode == "Eddi Platform" &&
          selectedMethodFilter == "Eddi"
        ) {
          filterByMethod.push(item);
        } else if (
          item?.payment_mode == "External" &&
          selectedMethodFilter == "External"
        ) {
          filterByMethod.push(item);
        }
      });
    } else {
      filterByMethod = filteredbyTime;
    }
    // console.log(filteredbyTime);

    setFilteredUser(filterByMethod);
  };

  const validate = () => {
    if (!ammountEntered || ammountEntered?.trim() === "") {
      toast.error("Please Enter ammount");
      return false;
    } else if (ammountEntered < 20) {
      toast.error("Minimum 20 SEK required");
      return false;
    } else if (ammountEntered > supplierAccData?.total_amount_due) {
      toast.error("You can not withdraw more than due ammount ");
      return false;
    } else {
      return true;
    }
  };

  function download(url) {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const sendPaymentRequest = async () => {
    let validation = await validate();
    if (validation) {
      let formData = new FormData();
      formData.append("amount", ammountEntered);
      setLoaderBtn(true);
      sendWitdrawalRequest(formData)
        .then((res) => {
          setLoaderBtn(false);
          if (res.status == "success") {
            handleClosePopupStatus();
            toast.success(lan == "en" ? res?.data : res?.data_sv);
          } else {
            toast.error(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((e) => {
          setLoaderBtn(false);
          console.log(e);
        });
    }
  };

  useEffect(() => {
    filterByRole();
    pagination();

    setPaginationStartIndex(1);
  }, [
    byStatusFilter,
    selectedRoleFilter,
    selectedMethodFilter,
    searchData,
  ]);

  const onApproveReject = (e, id) => {
    let formData = new FormData();

    formData.append("approval_status", e?.target?.value);
    setIsLoader(true);
    editPayments(id, formData)
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          managePaymentsApiCall();
          // setPopupStatus(t("success",lan));
          // setPopupMessage(result?.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };

  const startScroll = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
  };

  const preventScroll = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  };

  const handleClosePopupStatus = () => {
    startScroll();
    setPopupStatus("");
    setAmmountEntered();
    setWithdrawPopup(false);
  };

  const handleSearchData = (e) => {
    let searchData = [];

    if (e?.target?.value != "") {
      if (type === "Supplier") {
        userData?.map((user) => {
          if (
            user?.user_name
              ?.toLowerCase()
              .includes(e.target.value?.toLowerCase())
          ) {
            searchData.push(user);
          }
        });
      } else {
        if (selectedRoleFilter === "Course") {
          userData?.map((user) => {
            if (
              user?.course.supplier?.first_name
                ?.toLowerCase()
                .includes(e.target.value?.toLowerCase()) ||
              user?.user_name
                ?.toLowerCase()
                .includes(e.target.value?.toLowerCase())
            ) {
              searchData.push(user);
            }
          });
        } else {
          events.map((user) => {
            if (
              user?.user_name
                ?.toLowerCase()
                .includes(e.target.value?.toLowerCase())
            ) {
              searchData.push(user);
            }
          });
        }
      }
    } else {
      searchData = selectedRoleFilter === "Course" ? userData : events;
    }

    setSearchData(searchData);
  };

  return (
    <div className="manageUsers">
      <div className="subCategoryHeader my-4 pb-3 pt-3">
        <p className="midText mb-0">
          {" "}
          <span
            className="cursor-pointer"
            onClick={() => history.push("/supplier/dashboard")}
          >
            {t("Dashboard", lan)} |{" "}
          </span>
          <span className="textPrimaryColor"> {t("ManagePayments", lan)}</span>
        </p>
        <div className="fliter-block">
          <div className="pageSearch">
            <span className="iconSearch">
              <img src={IcSearch} />
            </span>
            <InputText
              placeholder={t("searchbyName", lan)}
              onChange={handleSearchData}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            {type !== "Supplier" && (
              <div className="headerTab smallText position-relative">
                <span className="iconsSelect">
                  <img src={IcFilter} />
                </span>
                <FilterSelectMenu
                  placeholder=""
                  value={["Course", "Events"]}
                  onChange={(e) => {
                    setSelectedRoleFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div>
            )}

            <div className="headerTab smallText position-relative">
              <span className="iconsSelect">
                <img src={IcFilter2} />
              </span>
              <FilterSelectMenu
                placeholder=""
                value={[
                  t("PaymentMethod", lan),
                  "PayByInvoice",
                  "Eddi",
                  "External",
                ]}
                selected={selectedMethodFilter}
                onChange={(e) => {
                  setSelectedMethodFilter(e?.target?.value);
                }}
                isWithicon
              />
            </div>

            <div className="headerTab smallText position-relative">
              <span className="iconsSelect">
                <img src={IcFilter2} />
              </span>
              <FilterSelectMenu
                placeholder=""
                value={["All", "Pending","Approved","Rejected"]}
                selected={byStatusFilter}
                onChange={(e) => {
                  setByStatusFilter(e.target.value);
                }}
                isWithicon
              />
            </div>
          </div>
        </div>
        {!isAdmin && (
          <button
            className="primaryButton"
            onClick={() => {
              setWithdrawPopup(true);
              preventScroll();
            }}
          >
            {t("Withdraw", lan)}
          </button>
        )}
      </div>

      {!isAdmin && (
        <div className="boxes">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <TopCounterBox
                bgColor="#876C6F"
                boxTitle={t("TotalvalueSoldCourse", lan)}
                number={supplierAccData?.total_earnings || "0"}
                Boxarrow={Boxarrow}
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <TopCounterBox
                bgColor="#616A46"
                boxTitle={t("AmmountDue", lan)}
                number={supplierAccData?.total_amount_due || "0"}
                Boxarrow={Boxarrow}
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <TopCounterBox
                bgColor="#481A20"
                boxTitle={t("AmmountWithdrow", lan)}
                number={supplierAccData?.total_amount_withdraw || "0"}
              />
            </div>
          </div>
        </div>
      )}
      <div className="subCategoryListDiv">
        <table className="subCategoryListTable managpaymet">
          <tr className="whitespaceNoWrap">
            {type !== "Supplier" &&
              (selectedRoleFilter === "Course" ? (
                <th> {t("SupplierName", lan)}</th>
              ) : (
                ""
              ))}
            <th>
              {type !== "Supplier"
                ? ` ${t("IndividualName", lan)}`
                : t("UserName", lan)}
            </th>
            {/* <th> {` ${t("PaymentAmt", lan)} `}</th> */}
            <th> {` ${t("TotalPaymentAmt", lan)} `}</th>
            <th>
              {" "}
              {selectedRoleFilter === "Course"
                ? t("CourseName", lan)
                : t("EventName", lan)}
            </th>

            <th> {` ${t("PaymentMode", lan)}`}</th>
            <th> {` ${t("PaymentStatus", lan)}  `}</th>
            <th> {` ${t("Action", lan)}`}</th>
          </tr>

          {paginationUser?.length > 0 &&
            paginationUser?.map((payMents, index) => {
              return (
                <tr key={index} className="subCategoryTableInfo">
                  {type !== "Supplier" && selectedRoleFilter === "Course" && (
                    <td>
                      <div className="d-flex align-items-center">
                        <div>
                          <p className="mb-0">
                            {selectedRoleFilter === "Course"
                              ? `${payMents.course?.supplier?.first_name||''} ${payMents.course?.supplier?.last_name||''}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </td>
                  )}
                  <td>{payMents.user_name || "-"}</td>
                  <td>{payMents?.amount?`SEK ${payMents?.amount}` : "-"}</td>
                  <td>
                    {selectedRoleFilter !== "Course"
                      ? payMents?.event_name
                      : payMents.course?.course_name}
                  </td>
                  <td>
                    {payMents.payment_mode
                      ? t(payMents.payment_mode, lan)
                      : "-"}
                  </td>{" "}
                  <td>
                    {type ? (
                      <select
                        value={payMents?.is_approved?.value}
                        onChange={(e) => onApproveReject(e, payMents?.uuid)}
                        className={
                          payMents?.is_approved?.value === "Pending"
                            ? "StatusActive"
                            : payMents?.is_approved?.value === "Rejected"
                            ? "StatusInActive"
                            : "StatusApproved"
                        }
                      >
                        <option value={"Pending"}>{` ${t(
                          "Pending",
                          lan
                        )} `}</option>
                        <option value={"Approved"}>{` ${t(
                          "Approved",
                          lan
                        )} `}</option>
                        <option value={"Rejected"}>{` ${t(
                          "Rejected",
                          lan
                        )} `}</option>
                      </select>
                    ) : (
                      <></>
                      // <span
                      //   style={{ backgroundImage: "none" }}
                      //   className={
                      //     payMents?.is_approved?.value === "Pending"
                      //       ? "StatusActive"
                      //       : payMents?.is_approved?.value === "Rejected"
                      //       ? "StatusInActive"
                      //       : "StatusApproved"
                      //   }
                      // >
                      //   {payMents?.is_approved?.value}
                      // </span>
                    )}
                  </td>
                  <td>
                    {payMents?.invoice?.invoice_pdf && (
                      <div className="d-flex align-items-center">
                        <span className="me-1">{t("invoice", lan)}</span>
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            download(
                              `${payMents?.invoice?.invoice_pdf}`
                            );
                          }}
                          src={downloadArrow}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </table>
        {paginationUser?.length <= 0 && (
          <div>
            <NoData />
          </div>
        )}
      </div>
      <div className="mt-3 text-end">
        <div className="col-12">
          {filteredUser?.length > 12 && (
            <CustomPagination
              startIndex={paginationStartIndex}
              endIndex={
                paginationStartIndex + 11 > filteredUser?.length
                  ? filteredUser?.length
                  : paginationStartIndex + 11
              }
              totalData={filteredUser?.length}
              onPrev={paginationPrev}
              onNext={paginationNext}
            />
          )}
        </div>
      </div>

      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupMessage}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>

      <div className="popup-start">
        <Popup
          show={withdrawPopup}
          header={t("Withdrawal", lan)}
          handleClose={handleClosePopupStatus}
        >
          <div className="userRatingList">
            <div className="sidepopupContents">
              <label htmlFor="ammount">
                <p className="smallText inputText boldText pWithMargin">
                  {` ${t("Amount", lan)} (minimum 20 SEK)`}
                  <span className="text-danger">*</span>
                </p>
              </label>
              <input
                type="number"
                id="ammount"
                placeholder={`${t("EnterAmmount", lan)}`}
                className="formInputs"
                onChange={(e) => {
                  setAmmountEntered(e?.target?.value);
                }}
              />
            </div>

            <button
              className="primaryButton"
              disabled={loderBtn}
              onClick={() => {
                sendPaymentRequest();
              }}
            >
              {loderBtn ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                t("SendPaymentRequest", lan)
              )}
            </button>
          </div>
        </Popup>
      </div>
      {isLoader ? <Loader /> : ""}

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ManagePayments;
