import React, { Fragment, useEffect, useState } from "react";
import "./paymentList.scss";
import { getTranslatedText as t } from "../../../../translater/index";
import API from "../../../../api";

import { useSelector } from "react-redux";
import NoData from "../../../../components/NoData/NoData";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../../assets/images/IcFilter.svg";
import IcSearch from "../../../../assets/images/icSearch.svg";
import FilterSelectMenu from "../../../../components/FilterSelectMenu/FilterSelectMenu";
import InputText from "../../../../components/inputText/inputText";

import { Link, useHistory } from "react-router-dom";
import {

  getBatch,

} from "../../../../services/supplierService";
import Loader from "../../../../components/Loader/Loader";
import Footer from "../../../../components/footer/Footer";
import { useDispatch } from "react-redux";
import { getPaymentList } from "../../../../services/adminService";
import { formatDate } from "../../../../services/constant";
import { BsInfoCircle } from "react-icons/bs";

function PaymentList() {
  const initialAdsDetails = {
    batch_name: null,
    course_name: null,
    stu_emails: null,
  };

  let state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState();
  const [paymentList, setPaymentList] = useState([]);
  const [paginationPayments, setPaginationPayments] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );

  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [isLoader, setIsLoader] = useState(false);

  const getAllBatches = () => {
    setIsLoader(true);
    getPaymentList()
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          setPaymentList(result?.data);
          setSearchData(result?.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };

  useEffect(async () => {
    getAllBatches();
  }, []);


  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredPayments?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationPayments(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredPayments]);

  useEffect(() => {
    setPaginationStartIndex(1);
  }, [filteredPayments, searchData]);


  const generateColor = () => {
    return Math.random().toString(16).substr(-6);
  };

  function adsComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function adsComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date1 - date2;
  }
  const handleSearchData = (e) => {
    let searchData = [];
    if (e?.target?.value !== "") {
      paymentList?.map((item) => {
        if (
          item?.supplier?.first_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase() ||
            item?.supplier?.first_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()
            ))
        ) {
          searchData.push(item);
        }
      });
    } else {
      searchData = paymentList;
    }

    setSearchData(searchData);
  };

  const filterAdsFun = () => {
    let filteredbyTime = [];

    if (selectedTimeFilter === "Newest") {
      filteredbyTime = searchData.sort(adsComparisonbyNewestDate);
    } else {
      filteredbyTime = searchData?.sort(adsComparisonbyOldestDate);
    }

    setFilteredPayments(filteredbyTime);
  };

  useEffect(() => {
    filterAdsFun();
    pagination();
    setPaginationStartIndex(1);
  }, [selectedTimeFilter, searchData]);

  return (
    <div className="batchDetail">
      <div className="fix_height">
        <div className="subCategoryHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span className="cursor-pointer" onClick={() => history.push("/supplier/dashboard")}>
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">{t("SupplierPaymentList", lan)}</span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchbyName", lan)}
                onChange={handleSearchData}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="headerTab smallText position-relative">
                <span className="iconsSelect">
                  <img src={IcFilter} />
                </span>
                <FilterSelectMenu
                  placeholder=""
                  value={["Newest", "Oldest"]}
                  selected={selectedTimeFilter}
                  onChange={(e) => {
                    setSelectedTimeFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div>

              {/* <div className="headerTab smallText position-relative">
                        <span className="iconsSelect">
                            <img src={IcFilter2} />
                        </span>
                        <FilterSelectMenu
                            placeholder="Filter By"
                            value={["Paid", "Free"]}
                            onChange={(e) => {
                            // setSelectedPriceFilter(e.target.value);
                            }}
                            isWithicon
                        />
                    </div> */}
            </div>
          </div>
        </div>
        {paginationPayments?.length > 0 ? (
          <Fragment>
            <div className="subCategoryListDiv">
              <table className="subCategoryListTable rc-paymentList">
                <tr className="whitespaceNoWrap">
                  <th>{`${t("Id", lan)}`} </th>
                  <th> {` ${t("UserName", lan)}`}</th>
                  <th> {` ${t("TotalEarning", lan)}`} 
                  <span
          className="d-inline-block cursor-pointer ms-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("IncludingVat",lan)}
        >
<BsInfoCircle className="f-14" color="#1a4840"/>
        </span>
                  </th>
                  <th> {` ${t("Commission", lan)}`}</th>
                  <th> {` ${t("AdminFee", lan)}`}</th>
                  <th> {` ${t("TotalPaid", lan)} `}</th>
                  <th> {` ${t("TotalDue", lan)} `}</th>
                  {/* <th> {` ${t("DateCreated", lan)} `}</th> */}
                  <th>{` ${t("Action", lan)} `}</th>
                </tr>

                {paginationPayments?.length > 0 &&
                  paginationPayments?.map((paymentList, index) => {
                    return (
                      <tr key={index} className="subCategoryTableInfo">
                        <td>{paymentList?.id}</td>
                        <td>{`${paymentList.supplier?.first_name||''} ${paymentList.supplier?.last_name||''}`}</td>
                        <td>{paymentList.total_earnings || 0}</td>
                        <td>{paymentList?.commission ? paymentList?.commission+'%' : '-'}</td>
                        <td>{paymentList?.admin_fee ? paymentList?.admin_fee+'%' : '-'}</td>
                        <td>{paymentList?.total_amount_withdraw || 0}</td>
                        <td>{paymentList?.total_amount_due || 0}</td>
                        {/* <td>
                          {formatDate(
                            paymentList?.created_date_time
                          )}
                        </td> */}
                        <td className="whitespaceNoWrap">
                              <Link
                              to={`/supplier/payment-list/${paymentList?.uuid}`}
                               className="linkDecoreRemove textPrimaryColor">{t('View',lan)}
                              </Link>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            <div className="mt-3 text-end">
              <div className="row">
                {filteredPayments?.length > 12 && (
                  <CustomPagination
                    startIndex={paginationStartIndex}
                    endIndex={
                      paginationStartIndex + 11 > filteredPayments?.length
                        ? filteredPayments?.length
                        : paginationStartIndex + 11
                    }
                    totalData={filteredPayments?.length}
                    onPrev={paginationPrev}
                    onNext={paginationNext}
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <div>
            <NoData />
          </div>
        )}
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default PaymentList;
