import React, { useEffect, useState } from "react";
import "./RecuritmentAdsDetails.scss";
import { useHistory, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import IcReadmore from "../../../../assets/images/ic_readmore.svg";
import IcReadless from "../../../../assets/images/ic_readless.svg";
import placeholder from "../../../../assets/images/placeholder.svg";
import {
  FaStar,
  FaUsers,
  FaCalendar,
  FaLevelUpAlt,
  FaClock,
  FaBookmark,
  FaUpload,
} from "react-icons/fa";
import { getRecuritmentAdsDetailApi } from "../../../../services/supplierService";
import API from "../../../../api";
import Dummy from "../../../../assets/images/dummy.png";
import { Link } from "react-router-dom";
import { getTranslatedText as t } from "../../../../translater";
import { useSelector } from "react-redux";
import Footer from "../../../../components/footer/Footer";
import { formatDate } from "../../../../services/constant";

function RecuritmentAdsDetails() {
  const params = useParams();
  const history =useHistory()
  const [selectedAdsDetails, setSelectedAdsDetails] = useState();
  const [enrolledUser, setEnrolledUser] = useState();
  const [isReadMore, setIsReadMore] = useState(true);
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const recuritmentAdsDetailApi = () => {
    getRecuritmentAdsDetailApi(params?.id).then((res) => {
      if (res?.status == "success") {
        console.log("res", res.user_profile);
        setEnrolledUser(res?.data?.user_profile);

        setSelectedAdsDetails(res);
      }
    });
  };

  useEffect(() => {
    recuritmentAdsDetailApi();
  }, []);

  return (
    <div className="recuritmentDetail">
      <div className="fix_height">
      <div className="courseDetailHeader">
        <p className="midText">
          {" "}
          <span className="cursor-pointer" onClick={()=>history.push('/supplier/dashboard')}>{t("Dashboard", lan)} |{" "} </span>
          <span onClick={()=>history.push('/supplier/recuritmentAds')}>{t("Recruitment", lan)} {t("Ads", lan) }</span>
          <span className="textPrimaryColor">   {`| ${t("Recuritment", lan)} ${t("Ad", lan)}  ${t("Detail", lan)}`}</span>
        </p>
        <div className="courseDetailHeaderRight">
          <span
            className="headerTab smallText cursorPointer"
            onClick={() => {
              window.history.back();
            }}
          >
            <IoIosArrowBack /> &nbsp;{t("Back", lan)}
          </span>
        </div>
      </div>
      <div className="courseDetailsMain">
        <div className="courseDetailsMainDetailView">
          <div className="courseDetailsMainDetailViewLeft">
            <span className="summaryDetail">
              <div className="imgBlock">
                <img
                  src={
                    selectedAdsDetails?.data?.recruitmentAd_File
                      ? `${selectedAdsDetails?.data?.recruitmentAd_File}`
                      : Dummy
                  }
                  className="courseDetailImage"
                />
              </div>
              <span className="ms-lg-2">
                {/* <p className="midText boldText mb-2">
                  {selectedAdsDetails?.data?.course_name || "-"}{" "}
                  <FaStar className="starIcon" />{" "}
                  <span className="superSmallText"> 4.5 </span>
                </p> */}
                <p className="midText f-18 mb-2">
                  {selectedAdsDetails?.data?.recruitmentAd_title || "-"}
                </p>
                <p className="superSmallText mb-3">
                {t("PostedOn", lan)}:{" "}
                  <span className="textPrimaryColor">
                    {selectedAdsDetails?.data?.supplier_profile
                      ?.supplier_name || "-"}
                  </span>
                  , on{" "}
                  {formatDate(
                    selectedAdsDetails?.data?.created_date_time
                  )}
                </p>
                {/* <p className="superSmallText mb-1">
                  {selectedAdsDetails?.data?.course_subcategory?.subcategory_name || "-"}
                </p>
                <p className="superSmallText mb-1">
                  {selectedAdsDetails?.data?.sub_area}
                </p>
                <p className="superSmallText mb-2">
                  Course Type - {selectedAdsDetails?.data?.course_type?.type_name || "-"}
                </p> */}
                <p className="pWithMargin midText f-18 whitespaceNoWrap">
                  {"Status"}
                  <span
                    className={
                      selectedAdsDetails?.data?.status?.value == "Active"
                        ? "StatusActive ms-4"
                        : "StatusInActive ms-4"
                    }
                    style={{ backgroundImage: "unset" }}
                  >
                    {selectedAdsDetails?.data?.status?.value}
                  </span>
                  {/* <select value={selectedAdsDetails?.data?.status?.value}
                        className={
                            selectedAdsDetails?.data?.status?.value == "Active" ?
                             "StatusActive ms-4" : "StatusInActive ms-4"
                        } 
                        // onChange={(e)=>{setStatus(e?.target?.value); onStatusChange(e)}}
                      
                    >
                        <option
                        value="Active"
                        // selected={
                        //   CoursesOfferedData?.status
                        //     ?.value == "Active"
                        // }
                        >
                        Active
                        </option>
                        <option
                        value="Inactive"
                        // selected={
                        //   CoursesOfferedData?.status
                        //     ?.value == "Inactive"
                        // }
                        >
                        In-active
                        </option>
                    </select> */}
                </p>
              </span>
            </span>
            {/* <p className="pWithMargin">
              Posted By:{" "}
              <span className="textPrimaryColor">
                {selectedAdsDetails?.data?.supplier?.first_name
                  ? selectedAdsDetails?.data?.supplier?.first_name
                  : "-"}
              </span>
              , on{" "}
              {new Date(
                selectedAdsDetails?.data?.created_date_time
              ).toLocaleDateString()}
            </p> */}
            <p className="boldText pWithMargin">  {` ${t("Course", lan)} ${t("Description", lan)} `}</p>
            <p className="text pWithMargin smallText ">
              {isReadMore
                ? selectedAdsDetails?.data?.recruitmentAd_description?.slice(
                    0,
                    150
                  )
                : selectedAdsDetails?.data?.recruitmentAd_description}

              <div
                hidden={
                  selectedAdsDetails?.data?.recruitmentAd_description?.length >
                  150
                    ? false
                    : true
                }
              >
                <span
                  onClick={() => setIsReadMore(!isReadMore)}
                  className="cursor-pointer mt-3"
                >
                  <img
                    hidden={
                      selectedAdsDetails?.data?.recruitmentAd_description
                        ?.length > 150
                        ? false
                        : true
                    }
                    height={18}
                    style={{ marginTop: "-4px" }}
                    src={isReadMore ? IcReadmore : IcReadless}
                    className="me-2"
                  />
                  {isReadMore
                    ? `${t("readmore", lan)}`
                    : `${t("readless", lan)}`}
                  {/* {isReadMore ? "currently" : "not"} */}
                </span>
              </div>
            </p>

            {/* <p className="boldText pWithMargin">What you will learn</p>
            <p className="pWithMargin smallText">
              <ul>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
                <li>Senectus posuere elemen tum nulla tristique.</li>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
              </ul>
            </p> */}
          </div>
          <div className="courseDetailsMainDetailViewRight">
            <span className="courseDetailRightContent">
              <FaUsers className="textPrimaryColor midText" /> &nbsp; &nbsp;
              {selectedAdsDetails?.data?.subscriber_count || 0} {t("Viewers", lan)}
            </span>
            <span className="courseDetailRightContent">
              <FaCalendar className="textPrimaryColor midText" /> &nbsp;
              &nbsp;{" "}{t("PostedOn", lan)}
              {formatDate(
                selectedAdsDetails?.data?.created_date_time
              ) || "-"}
            </span>
            {/* <span className="courseDetailRightContent">
              <FaLevelUpAlt className="textPrimaryColor midText" /> &nbsp;
              &nbsp;{selectedAdsDetails?.data?.course_level?.level_name}
            </span> */}
            <span className="courseDetailRightContent">
              <FaClock className="textPrimaryColor midText" /> &nbsp; &nbsp;
              {t("ExpiresOn", lan)}
              {formatDate(
                selectedAdsDetails?.data?.recruitmentAd_Expiry
              ) || "-"}
            </span>
            {/* <span className="courseDetailRightContent">
              <FaBookmark className="textPrimaryColor midText" /> &nbsp; &nbsp;$
              {selectedAdsDetails?.data?.course_price}
            </span> */}
          </div>
        </div>
        <div className="courseDetailsMainListView">
          <p>  {` ${t("Subscribers", lan)} ${t("List", lan)}`}</p>
          <div className="courseDetailsMainListTable">
            <table className="courseListTable">
              {enrolledUser
                ? enrolledUser?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <img
                              src={
                                item?.profile_image
                                  ? `${item?.profile_image}`
                                  : placeholder
                              }
                              alt="Avtar"
                              className="courseAvtars"
                            />
                          </td>
                          <td>
                            {item?.first_name} {item?.last_name} <br />{" "}
                            <span className="superSmallText textGrayColor">
                              {item?.email_id ? item?.email_id : ""}
                            </span>
                          </td>
                          <td>
                             {` ${t("Contact", lan)} ${t("Information", lan)}`} <br />{" "}
                            <span className="superSmallText textGrayColor">
                              {item?.phone_number ? item?.phone_number : ""}
                            </span>
                          </td>
                          <td>
                            {t("Action", lan)} <br />{" "}
                            <Link
                          
                          to={`/supplier/manage-users/manage-users-detail/${item?.usersignup?.uuid}`}
                              className="linkDecoreRemove textPrimaryColor"
                            >
                              {t("View", lan)}
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })
                : t("NoData",lan)}
            </table>
          </div>
        </div>
      </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default RecuritmentAdsDetails;
