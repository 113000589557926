import React, { useEffect, useState } from "react";
import "./CorporateCourse.scss";
import "../../../../common/styles/globalStyles.scss";
import SidePopup from "../../../../components/sidePopup/sidePopup";
import UploadImage from "../../../../assets/images/uploadImage.png";
import IcFilter from "../../../../assets/images/IcFilter.svg";
import IcFilter2 from "../../../../assets/images/IcFilter2.svg";
import IcSearch from "../../../../assets/images/icSearch.svg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  courseCategories,
  courses,
  courseSubCategories,
  viewCoursesDetails,
} from "../../../../redux/actions";
import {
  activeInactiveCorporateCourseApi,
  deleteCorporateCourseApi,
  deleteCourseApi,
  editCourseApi,
  getCategoryApi,
  getCorporateCourseApi,
  getCoursesApi,
  getSubCategoryApi,
  postCourse,
} from "../../../../services/supplierService";
import API from "../../../../api";
import Popup from "../../../../components/popup/popup";
import { getTranslatedText as t } from "../../../../translater";
import placeholder from "../../../../assets/images/placeholder.svg";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import NoData from "../../../../components/NoData/NoData";
import InputText from "../../../../components/inputText/inputText";
import FilterSelectMenu from "../../../../components/FilterSelectMenu/FilterSelectMenu";
import Loader from "../../../../components/Loader/Loader";
import Footer from "../../../../components/footer/Footer";

function CorporateCourse() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();
  let lan = state?.language;
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [approvedSubCategory, setApprovedSubCategory] = useState([]);
  const [coursesData, setCoursesData] = useState();

  const [isLoader, setIsLoader] = useState(false);
  const [activeInactivePopup, setActiveInactivePopup] = useState({
    value: false,
    data: "",
  });
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupDelete, setPopupDelete] = useState("");
  const [deleteCourseId, setDeleteCourseId] = useState();
  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [selectedPriceFilter, setSelectedPriceFilter] = useState("");

  const [filteredCourse, setFilteredCourse] = useState(coursesData);
  const [searchFilteredCourse, setSearchFilteredCourse] = useState(
    state?.Courses
  );
  const [paginationCourses, setPaginationCourses] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const role = state?.UserDetail?.user_type;
  const [isAdmin, setIsAdmin] = useState(role == "Admin" ? true : false);
  const [searchText, setSearchtext] = useState();
  useEffect(() => {
    // let adminSubCategory = state?.approvedSubCategory?.filter((data)=>data?.created_by == "admin")
    setAllSubCategories(state?.CourseSubCategories);
    setApprovedSubCategory(state?.approvedSubCategory);
    setAllCategories(state.CourseCategories);
    setPaginationStartIndex(1);
  }, [state]);

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredCourse?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationCourses(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredCourse]);

  const coursesCall = () => {
    setIsLoader(true);
    getCorporateCourseApi()
      .then((res) => {
        if (res.status == "success") {
          setIsLoader(false);
          dispatch(courses(res.data));
          setCoursesData(res?.data);

          setSearchFilteredCourse(res?.data);
          // setFilteredCourse(res?.data)
        } else {
          setIsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      });
  };



  useEffect(async () => {
    await coursesCall();
  }, []);

  // console.log("proodo", process?.env);
  const deleteCourseCall = () => {
    deleteCorporateCourseApi(deleteCourseId)
      .then((res) => {
        setPopupDelete("");
        if (res.status == "success") {
          let result = coursesData?.filter(
            (fil) => fil?.uuid != deleteCourseId
          );
          setCoursesData(result);
          setSearchFilteredCourse(result);
          // coursesCall();
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onApproveReject = (e, id, type) => {
    const course_Status = e?.target?.value;
    let formData = new FormData();
      formData.append("status_s", e?.target?.value);
    setIsLoader(true);
    activeInactiveCorporateCourseApi(id, formData)
      .then((result) => {
        setIsLoader(false);
        if (result?.status === "success") {
          let courseUpdated = [];

          filteredCourse.map((cou) => {
     
              if (cou?.uuid == id) {
                cou.status.value = course_Status;
                courseUpdated.push(cou);
              } else {
                courseUpdated.push(cou);
              }
            
          });
          setCoursesData(courseUpdated);
          setSearchFilteredCourse(courseUpdated);
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setActiveInactivePopup({
            value: true,
            data: lan == "en" ? result?.data : result?.data_sv,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };
  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    setPopupDelete("");
    setActiveInactivePopup({ value: false, data: "" });
  };

  function courseComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function courseComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);
    return date1 - date2;
  }

  const filterCourses = () => {
    let filteredbyTime = [];
    let filteredbyPrice = [];

    if (selectedPriceFilter != "") {
      searchFilteredCourse?.map((course) => {
        if (selectedPriceFilter == course?.fee_type?.fee_type_name) {
          filteredbyPrice.push(course);
        }
      });
    } else {
      filteredbyPrice = searchFilteredCourse;
    }

    if (selectedTimeFilter == "Newest") {
      filteredbyTime = filteredbyPrice?.sort(courseComparisonbyNewestDate);
    } else {
      filteredbyTime = filteredbyPrice?.sort(courseComparisonbyOldestDate);
    }
    setFilteredCourse(filteredbyTime);
  };

  const handleSearchData = (e) => {
    setSearchtext(e?.target?.value);
    let searchData = [];
    if (e?.target?.value != "") {
      coursesData?.map((cour) => {
        if (
          cour?.course_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(cour);
        }
      });
    } else {
      searchData = coursesData;
    }

    setSearchFilteredCourse(searchData);
  };

  useEffect(() => {
    filterCourses();
    setPaginationStartIndex(1);
  }, [searchFilteredCourse, selectedPriceFilter]);
  useEffect(() => {
    filterCourses();
    pagination();
    setPaginationStartIndex(1);
  }, [selectedTimeFilter]);

  return (
    <div className="course">
      <div className="fix_height">
        <div className="courseHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">{t("ManageCourses", lan)}</span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchbyName", lan)}
                onChange={handleSearchData}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div className="headerTab smallText position-relative">
                {/* <FaSortAmountUp className="textPrimaryColor" /> &nbsp;Sort */}
                <span className="iconsSelect">
                  <img src={IcFilter} />
                </span>
                <FilterSelectMenu
                  placeholder=""
                  value={["Newest", "Oldest"]}
                  selected={selectedTimeFilter}
                  onChange={(e) => {
                    setSelectedTimeFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div>
{/* 
              <div className="headerTab smallText position-relative">
                <span className="iconsSelect">
                  <img src={IcFilter2} />
                </span>
                <FilterSelectMenu
                  placeholder={t("FilterBy", lan)}
                  value={["Paid", "Free"]}
                  onChange={(e) => {
                    setSelectedPriceFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div> */}

    
              <Link
                to={"/supplier/corporate/course/create-course"}
                className="primaryButton mt-0"
              >
                {t("AddCourseTitle", lan)}
              </Link>
            </div>
          </div>
        </div>
        <div className="courseListDiv">
          <table
            className={`courseListTable table-${
              isAdmin ? "admin" : "supplier"
            }`}
          >
            {paginationCourses?.length > 0 && (
              <tr className="whitespaceNoWrap">
                { <th>{`${t("CourseID", lan)}`}</th>}

                <th>{`${t("CourseName", lan)}`}</th>
                <th> {t("CourseCategoryName", lan)} </th>
                <th>{t("Status", lan)}</th>
                <th>{t("Action", lan)}</th>
              </tr>
            )}
            {paginationCourses?.length > 0 &&
              paginationCourses?.map((cou, index) => {
                return (
                  <tr key={index} className="courseTableInfo">
                   <td>{cou?.id}</td>
                    <td className="text-capital" title={cou?.course_name}>
                      {cou?.course_name?.length > 50
                        ? cou?.course_name.slice(0, 60).concat("...")
                        : cou?.course_name}
                    </td>

                    <td> {cou.course_category[`category_${lan}`]}</td>
                   
        
                    <td>
                       
                        <select
                          value={cou?.status?.value}
                          onChange={(e) =>
                            onApproveReject(e, cou?.uuid, "Activation")
                          }
                          className={
                            cou?.status?.value == "Active"
                              ? "StatusActive"
                              : "StatusInActive"
                          }
                        >
                          <option value={"Active"}>{t("Active", lan)}</option>
                          <option value={"Inactive"}>
                            {t("inActive", lan)}
                          </option>
                        </select>
                     
                    </td>
                    <td className="whitespaceNoWrap">
                      <Link
                        to={`/supplier/corporate/course/details/${cou.uuid}`}
                        onClick={() => {
                          dispatch(viewCoursesDetails(cou));
                        }}
                        className="linkDecoreRemove textPrimaryColor"
                      >
                        {t("View", lan)}{" "}
                      </Link>{" "}
                      |{" "}
                      <Link
                        className="cursorPointer linkDecoreRemove textPrimaryColor"
                        to={`/supplier/corporate/course/edit-course/${cou.uuid}`}
                        onClick={() => {
                          dispatch(viewCoursesDetails(cou));
                        }}
                      >
                        {t("Edit", lan)}
                      </Link>{" "}
                      |{" "}
                      <span
                        className="cursorPointer textPrimaryColor"
                        // onClick={()=>{deleteCourseCall(cou.uuid)}}
                        onClick={() => {
                          setPopupDelete("delete");
                          setDeleteCourseId(cou?.uuid);
                        }}
                      >
                        {t("Delete", lan)}
                      </span>
                    </td>
                   
                  </tr>
                );
              })}
          </table>
          {paginationCourses?.length <= 0 && (
            <div>
              <NoData />
            </div>
          )}
        </div>
        <div className="mt-3 text-end">
          <div className="row">
            {filteredCourse?.length > 12 && (
              <CustomPagination
                startIndex={paginationStartIndex}
                endIndex={
                  paginationStartIndex + 11 > filteredCourse?.length
                    ? filteredCourse?.length
                    : paginationStartIndex + 11
                }
                totalData={filteredCourse?.length}
                onPrev={paginationPrev}
                onNext={paginationNext}
              />
            )}
          </div>
        </div>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>

        <Popup
          show={popupDelete !== "" ? true : false}
          header={t("DeletePopUp", lan)}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">{/* <p>{popupStatus}</p> */}</div>
          <div className="row justify-content-around w-100">
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase  mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("CancleText", lan)}
            </button>

            <button
              onClick={deleteCourseCall}
              className="btn btn-danger text-uppercase  mt-2 col-md-5 col-sm-5 col-xs-12 "
            >
              {t("DeleteText", lan)}
            </button>
          </div>
        </Popup>

        <Popup
          show={activeInactivePopup?.value}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
          className="MB-3"
        >
          <div className="popupinfo">
            <p>{activeInactivePopup?.data}</p>

            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
        {isLoader ? <Loader /> : ""}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CorporateCourse;
