import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./../CountryCodeSelect/CountryCodeSelect.scss";
import { getTranslatedText as t } from "../../translater/index";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";

function CountryCodeSelect(props) {
  const state = useSelector((state)=>state?.Eddi)
  let lan = state?.language;
  
  const [value, setValue] = useState()
  useEffect(() => {
  setValue(props.inputref.current.value)
  console.log(props)
  }, [])
  
  return (
    <div className="selectbox-main">
      <label>{props.labelName}</label>
      <PhoneInput
        placeholder={t("phonnumber", lan)}
        value={value}
        defaultCountry="SE"
        onChange={setValue}
        flags={false}
        ref={props.inputref}
      />
    </div>
  );
}

export default CountryCodeSelect;
