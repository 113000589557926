import React, { useEffect, useState } from "react";
import "./supplier.scss";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import Course from "../subPages/course/course";
import Dashboard from "../subPages/dashboard/dashboard";
import LOGO2 from "../../assets/logo/logo2.png";
import {
  FaChartPie,
  FaSuitcase,
  FaBookOpen,
  FaAd,
  FaUserTie,
  FaCog,
  FaAward,
  FaBell,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { Redirect } from "react-router-dom";
import CourseDetails from "../subPages/course/courseDetails/courseDetails";
import SubCategory from "../subPages/subCategory/subCategory";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import EddiProfile from "../subPages/EddiProfile/EddiProfile";
import EditProfile from "../subPages/EditProfile/EditProfile";
import ProfileMain from "../createProfile/ProfileMain";
import EventList from "../subPages/Events/eventList";
import EventDetails from "../subPages/Events/eventDetails/eventDetails";
import RecuritmentAds from "../subPages/RecuritmentAds/RecuritmentAds";
import RecuritmentAdsDetails from "../subPages/RecuritmentAds/RecuritmentAdsDetails/RecuritmentAdsDetails";
import Category from "../subPages/Category/Category";
import ManageUsers from "../subPages/ManageUsers/ManageUsers";

import UserInfo from "../subPages/ManageUsers/UserDetails/UserDetails";
import SupplierProfile from "../subPages/SupplierProfile/SupplierProflie";
import OrganizationProfileDetail from "../subPages/SupplierProfile/oragnizationProfile/OrganizationDetails";
import Footer from "../../components/footer/Footer";
import ManageCourse from "../subPages/course/manageCourse/manageCourse";
import Batch from "../subPages/course/batch/batch";
import Session from "../subPages/course/session/session";
import Page404 from "../../components/Page404/Page404";
import PaymentList from "../subPages/payments/paymentList/paymentList";
import ManagePayments from "../subPages/payments/managePayment/ManagePayments";
import PaymentDetail from "../subPages/payments/paymentList/paymentDetail/paymentDetail";
import MyPayments from "../subPages/payments/myPayments/myPayments";
import WithdrawalList from "../subPages/payments/withdrawalList/withdrawalList";
import CorporateCategory from "../subPages/CorporateSupplier/corporateCategory/CorporateCategory";
import ManageCorporateCourse from "../subPages/CorporateSupplier/corporateCourse/manageCourse/ManageCorporateCourse";
import CorporateBatch from "../subPages/CorporateSupplier/corporateCourse/batch/CorporateBatch";
import CorporateCourse from "../subPages/CorporateSupplier/corporateCourse/CorporateCourse";
import CorporateCourseDetails from "../subPages/CorporateSupplier/corporateCourse/courseDetails/CorporateCourseDetails";
import CorporateSession from "../subPages/CorporateSupplier/corporateCourse/session/CorporateSession";
import CorporateEventList from "../subPages/CorporateSupplier/corporateEvents/CorporateEventList";
import CorporateEventDetails from "../subPages/CorporateSupplier/corporateEvents/eventDetails/CorporateEventDetails";
import CorporateDashboard from "../subPages/CorporateSupplier/corporateDashboard/CorporateDashboard";
import ManageCorporateUsers from "../subPages/CorporateSupplier/ManageCorporateUsers/ManageCorporateUsers";
import CorporateUserDetail from "../subPages/CorporateSupplier/ManageCorporateUsers/CorporateUserDetails/CorporateUserDetail";
import CorporateModuleList from "../subPages/CorporateSupplier/corporateCourse/corporateModuleList/CorporateModuleList";
import ManageCorporateMaterial from "../subPages/CorporateSupplier/manageCorporateMaterial/ManageCorporateMaterial";
import ModuleList from "../subPages/course/moduleList/ModuleList";
import ManageMaterial from "../subPages/course/manageMaterial/ManageMaterial";

function Supplier() {
  const history = useHistory();
  const state = useState((state) => state?.Eddi);
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  let { path, url } = useRouteMatch();
  const [scrWidth, setScrWidth] = useState(window.screen.width);
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  const handleResize = (e) => {
    setScrWidth(window.screen.width);
  };

  useEffect(() => {
    if (localStorage.getItem("logedInUser") != "true") {
      history.push("/");
    }
  }, []);
  useEffect(() => {
    if (hamburgerMenuOpen) {
      let body = document.getElementsByTagName("body");
      body[0]?.classList.add("overflow-hidden");
      console.log("oppennn");
    } else {
      let body = document.getElementsByTagName("body");
      body[0]?.classList.remove("overflow-hidden");
    }
  }, [hamburgerMenuOpen]);

  return (
    <div className="supplier">
      <div
        className={
          hamburgerMenuOpen
            ? "supplierSideBar mobileSidebar"
            : "supplierSideBar"
        }
      >
        <Sidebar
          onClick={() => hamburgerMenuOpen && setHamburgerMenuOpen(false)}
        />
      </div>
      {hamburgerMenuOpen && <div className="mobilesidebarBackground"></div>}

      <div className="supplierMaindiv">
        <div className="supplierHeaderAndMiddle">
          <div className="supplierHeader">
            <div className="mobileHamburger">
              {hamburgerMenuOpen ? (
                <FaTimes
                  onClick={() => {
                    setHamburgerMenuOpen(false);
                  }}
                />
              ) : (
                <FaBars
                  onClick={() => {
                    setHamburgerMenuOpen(true);
                  }}
                />
              )}
            </div>

            <Header />
          </div>

          <div className="supplierMiddle">
            <Switch>
              <Route
                exact
                path={`${path}/create-profile`}
                component={ProfileMain}
              />
              <Route exact path={`${path}`}>
                <Redirect to={`${path}/dashboard`} />
              </Route>
              <Route exact path={`${path}/dashboard`} component={Dashboard} />
              <Route exact path={`${path}/course`} component={Course} />
              <Route
                exact
                path={`${path}/course/create-course`}
                component={ManageCourse}
              />
              <Route exact path={`${path}/course/batch`} component={Batch} />
              <Route
                exact
                path={`${path}/course/session`}
                component={Session}
              />
              <Route
                path={`${path}/course/module/:id`}
                component={ModuleList}
              />
              <Route
                path={`${path}/course/manage-material/:course_id/:module_id`}
                component={ManageMaterial}
              />

              <Route
                exact
                path={`${path}/course/edit-course/:id`}
                component={ManageCourse}
              />
              <Route
                path={`${path}/course/details/:id`}
                component={CourseDetails}
              />

              <Route exact path={`${path}/category`} component={Category} />
              <Route
                exact
                path={`${path}/subCategory`}
                component={SubCategory}
              />
              <Route
                exact
                path={`${path}/eddi-profile`}
                component={EddiProfile}
              />
              <Route
                exact
                path={`${path}/manage-users`}
                component={ManageUsers}
              />

              <Route
                exact
                path={`${path}/manage-users/manage-users-detail/:id`}
                component={UserInfo}
              />

              <Route
                exact
                path={`${path}/eddi-profile/edit-profile`}
                component={EditProfile}
              />
              <Route exact path={`${path}/event`} component={EventList} />
              <Route
                exact
                path={`${path}/event/event-detail/:id`}
                component={EventDetails}
              />
              <Route
                exact
                path={`${path}/recuritmentAds`}
                component={RecuritmentAds}
              />
              <Route
                path={`${path}/recuritmentAdsDetails/details/:id`}
                component={RecuritmentAdsDetails}
              />
              <Route
                exact
                path={`${path}/manage-supplier-profile`}
                component={SupplierProfile}
              />
              <Route
                exact
                path={`${path}/manage-supplier-profile/organizational-profile`}
                component={OrganizationProfileDetail}
              />
              <Route
                exact
                path={`${path}/manage-payments`}
                component={ManagePayments}
              />
              <Route
                exact
                path={`${path}/withdrawal-list`}
                component={WithdrawalList}
              />
              <Route
                exact
                path={`${path}/payment-list`}
                component={PaymentList}
              />
              <Route
                exact
                path={`${path}/my-payments`}
                component={MyPayments}
              />
              <Route
                exact
                path={`${path}/payment-list/:id`}
                component={PaymentDetail}
              />
              <Route
                exact
                path={`${path}/organization/categories`}
                component={CorporateCategory}
              />

              <Route
                exact
                path={`${path}/corporate/course`}
                component={CorporateCourse}
              />
              <Route
                exact
                path={`${path}/corporate/course/create-course`}
                component={ManageCorporateCourse}
              />
              <Route
                exact
                path={`${path}/corporate/batch`}
                component={CorporateBatch}
              />
              <Route
                exact
                path={`${path}/corporate/session`}
                component={CorporateSession}
              />
              <Route
                exact
                path={`${path}/corporate/course/edit-course/:id`}
                component={ManageCorporateCourse}
              />
              <Route
                path={`${path}/corporate/course/details/:id`}
                component={CorporateCourseDetails}
              />
              <Route
                path={`${path}/corporate/course/module/:id`}
                component={CorporateModuleList}
              />
              <Route
                path={`${path}/corporate/course/manage-material/:course_id/:module_id`}
                component={ManageCorporateMaterial}
              />
              <Route
                exact
                path={`${path}/corporate/event`}
                component={CorporateEventList}
              />
              <Route
                exact
                path={`${path}/corporate/event/event-detail/:id`}
                component={CorporateEventDetails}
              />
              <Route
                exact
                path={`${path}/corporate/dashboard`}
                component={CorporateDashboard}
              />
              <Route
                exact
                path={`${path}/manage-corporate-users`}
                component={ManageCorporateUsers}
              />

              <Route
                exact
                path={`${path}/manage-corporate-users/users-detail/:id`}
                component={CorporateUserDetail}
              />
              <Route path="*" component={Page404} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supplier;
