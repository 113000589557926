import React, { useEffect, useState } from "react";
// import "./userDetails.scss";
import "./organizationDetails.scss";

import { IoIosArrowBack } from "react-icons/io";
import placeholder from "../../../../assets/images/placeholder.svg";
import IcDownload from "../../../../assets/images/IcDownload.svg";
import { getTranslatedText as t } from "../../../../translater/index";

import API from "../../../../api";
import { Link } from "react-router-dom";
import { organizationalDetails } from "../../../../services/adminService";
import Loader from "../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../../../../components/footer/Footer";
import { formatDate } from "../../../../services/constant";

function OrganizationProfileDetail() {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");

  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;

  const [allInfo, setAllInfo] = useState({});
  const [supplierInfo, setSupplierInfo] = useState();
  const [courseOffered, setCourseOffered] = useState([]);

  const [isLoader, setIsLoader] = useState(false);

  const organizationDetailApi = () => {
    setIsLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append("supplier_email", email);

    organizationalDetails(bodyFormData).then((res) => {
      setIsLoader(false);
      if (res?.status == "success") {
        setAllInfo(res.data);
        setSupplierInfo(res.supplier_info);
        setCourseOffered(res.data?.course_category.split(","));
      }
    });
  };

  useEffect(() => {
    organizationDetailApi();
  }, [email]);

  return (
    <div className="userDetail">
      <div className="fix_height">
        <div className="userDetailHeader">
          <p className="midText">
            {" "}
            {t("Dashboard", lan)} |{" "}
            <span className="textPrimaryColor">
              {" "}
              {t("Manage", lan)} {t("SupplierProfile", lan).toLowerCase()}{" "}
            </span>
            <span className="textPrimaryColor">
              {" "}
              | {` ${t("Organization", lan)} ${t("Detail", lan)}`}
            </span>
          </p>
          <div className="userDetailHeaderRight">
            <span
              className="headerTab smallText cursorPointer"
              onClick={() => {
                window.history.back();
              }}
            >
              <IoIosArrowBack /> &nbsp;{t("Back", lan)}
            </span>
          </div>
        </div>
        <div className="userDetailsMain">
          <div className="userDetailsMainDetailView">
            <div className="userDetailsMainDetailViewLeft">
              <span className="summaryDetail">
                <div className="imgBlock">
                  <img
                    src={
                      allInfo?.organization_logo
                        ? `${allInfo?.organization_logo}`
                        : placeholder
                    }
                    className="userDetailImage"
                  />
                </div>
                <span className="ms-lg-2">
                  {/* <p className="midText boldText mb-2">
                  {selectedAdsDetails?.data?.course_name || "N/A"}{" "}
                  <FaStar className="starIcon" />{" "}
                  <span className="superSmallText"> 4.5 </span>
                </p> */}
                  <p className="midText">
                    {allInfo?.organizational_name || "-"}
                  </p>

                  <p className="superSmallText">
                    {` ${t("Date", lan)} ${t("Created", lan)} ${t("On", lan)}`}{" "}
                    :{" "}
                    <span className="textPrimaryColor">
                      {formatDate(allInfo?.created_date_time)}
                    </span>
                  </p>

                  {/* <p className="pWithMargin f-18  whitespaceNoWrap">
                  {"Status"}
                  <span
                    className={

                      allInfo.status?.value
                        ? allInfo.status?.value === "Active"
                          ? "StatusActive ms-4"
                          : "StatusInActive ms-4" : ''}

                    style={{ backgroundImage: "unset" }}
                  >

                    {allInfo.status?.value ==="Inactive"?"In-Active":"Active"}
                  </span>
                </p> */}
                </span>
              </span>

              <p className="boldText pWithMargin p-title">
                {` ${t("CompanyInformation", lan)}`}
              </p>
              <div className="personalInfo mb-5">
                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">
                    {" "}
                    {` ${t("Organization", lan)} ${t("Name", lan)}`}
                  </p>
                  <p className="superSmallText">
                    {allInfo?.organizational_name || "-"}
                  </p>
                </span>
                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">
                    {` ${t("OrganizationEmail", lan)} `}
                  </p>
                  <p className="superSmallText">
                    {allInfo?.organization_email || "-"}
                  </p>
                </span>

                {supplierInfo?.is_corporate ? (
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("CorporateCode", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.corporate_code || "-"}
                    </p>
                  </span>
                ) : (
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {` ${t("OrganizationWebsite", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.organization_website || "-"}
                    </p>
                  </span>
                )}
                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">
                    {` ${t("OrganizationLocation_Address", lan)} `}
                  </p>
                  <p className="superSmallText">
                    {allInfo?.organization_address || "-"}
                  </p>
                </span>

                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">
                    {" "}
                    {` ${t("phonnumber", lan)} `}
                  </p>
                  {allInfo?.organization_phone_number && (
                    <p className="superSmallText">
                      {allInfo?.organization_phone_number || "-"}
                    </p>
                  )}
                </span>
                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">{t("Country", lan)}</p>
                  <p className="superSmallText">{allInfo?.country || "-"}</p>
                </span>
                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">{t("City", lan)}</p>
                  <p className="superSmallText">{allInfo?.city || "-"}</p>
                </span>
                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">
                    {" "}
                    {` ${t("ContactPersonName", lan)} `}
                  </p>
                  <p className="superSmallText">
                    {allInfo?.contact_person || "-"}
                  </p>
                </span>
                <span className="singleInfothree">
                  <p className="smallText boldText mb-2">
                    {` ${t("AboutOrganization", lan)} `}
                  </p>
                  <p className="superSmallText">
                    {allInfo?.brif_information || "-"}
                  </p>
                </span>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <p className="boldText pWithMargin p-title">
                    {` ${t("CoursesOfferOrg", lan)} `}
                  </p>
                  <div className="personalInfo courses-list mb-5">
                    {courseOffered.map((cou) => (
                      <ul>
                        <li>{cou}</li>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default OrganizationProfileDetail;
