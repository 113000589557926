import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Page404 from "./components/Page404/Page404";
import ForgotPass from "./pages/authentication/forgotPassword/ForgotPassword";
import Login from "./pages/authentication/login/login";
import Redirection from "./pages/authentication/redirect/redirect";
import ResetPassword from "./pages/authentication/resetPassword/resetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Dashboard from "./pages/subPages/dashboard/dashboard";
import Supplier from "./pages/supplier/supplier";
import TermsAndCondition from "./pages/TermsAndCondition/TermsAndCondition";
// import Header from "./components/Header/Header";

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

const Routing = () => {
  const state = useSelector((state) => state?.Eddi);
  const [authed, setAuthed] = useState(
    state?.UserDetail?.Authorization ? true : false
  );

  useEffect(() => {
    setAuthed(state?.UserDetail?.Authorization ? true : false);
  }, [state?.UserDetail?.Authorization]);

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/resetPassword" component={ResetPassword} />
        <Route exact path="/forgot_password" component={ForgotPass} />
        <Route path="/supplier" component={Supplier} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-condition" component={TermsAndCondition} />
        <Route path="/redirect/*" component={Redirection} />
        <Route path="*" component={Page404} />
        {/* <PrivateRoute authed={authed} exact path="/supplier" component={Supplier} />
        <PrivateRoute authed={authed} exact path="/dashboard" component={Dashboard} /> */}
      </Switch>
    </HashRouter>
  );
};

export default Routing;
