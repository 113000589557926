import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./ManageCorporateCourse.scss";
import { getTranslatedText as t } from "../../../../../translater";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadImage from "../../../../../assets/images/uploadImage.png";
import IcEddiProfile from "../../../../../assets/images/times-circle.svg";
import IcPlay from "../../../../../assets/images/IcPlay.svg";
import API from "../../../../../api";
import Popup from "../../../../../components/popup/popup";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { viewCoursesDetails } from "../../../../../redux/actions";
import {
  addCorporateCourseApi,
  editCorporateCourseApi,
  editCourseApi,
  getCorporateCategoryApi,
  getCorporateCourseByID,
  getCourseDetailApi,
  postCourse,
} from "../../../../../services/supplierService";
import Loader from "../../../../../components/Loader/Loader";
import { IoIosArrowBack } from "react-icons/io";
import EddiDropzone from "../../../../../components/dropzone/dropzone";
import { checkUrlExtension } from "../../../../../services/constant";

const ManageCorporateCourse = () => {
  const initialAddCourseDetails = {
    course_image: null,
    course_name: null,
    course_level_id: null,
    course_length: null,
    choose_course_length: "",
    instructor_email: "",
    instructor_name: "",
    course_category_id: null,
    course_type_id: null,
    additional_information: null,
    organization_location: null,
    sub_area: null,
    course_checkout_link: null,
    course_preview_video: null,
    course_language: "en",
    course_for_organization: false,
    course_organization: null,
    course_starting_date: null,
    meeting_link: null,
    meeting_password: null,
    target_users: [],
    offer_price: "",
    author_name: "",
    about_author: "",
    // offer_price: null,
  };
  const dispatch = useDispatch();
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();
  const params = useParams();
  const search = useLocation().search;
  const republish = Boolean(new URLSearchParams(search).get("republish"));
  let lan = state?.language;
  const [allCategories, setAllCategories] = useState([]);
  const [choosedVideos, setChoosedVideos] = useState([]);
  const [editCource, setEditCource] = useState();
  const [editCourceImage, setEditCourceImage] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [addCourseDetals, setAddCourseDetails] = useState(
    initialAddCourseDetails
  );
  const [isLoader, setIsLoader] = useState(false);

  const [chooseCourseLength, setChooseCourseLength] = useState("");
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [overviewError, setOverviewError] = useState("");
  const [lengthError, setLengthError] = useState("");
  const [levelError, setLevelError] = useState("");
  const [overviewUrlType, setOverviewUrlType] = useState("");
  const [dateError, setDateError] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);
  const [categoryError, setCategoryError] = useState("");
  const [videoPreviewError, setVideoPreviewError] = useState("");
  const [courseTypeError, setCourseError] = useState("");
  const [imageError, setImageError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [courseForOrgError, setCourseForOrgError] = useState("");
  const [dayLengthError, setDayLengthError] = useState("");
  let [isAddCourse, setIsAddCourse] = useState(
    window.location.href.includes("create-course")
  );
  const role = state?.UserDetail?.user_type;
  const [offerPriceErr, setOfferPriceErr] = useState("");

  useEffect(() => {
    if (!isAddCourse) {
      setIsLoader(true);
      getCorporateCourseByID(params?.id)
        .then((result) => {
          if (result?.status == "success") {
            setEditCource(result?.data);
            dispatch(viewCoursesDetails(result?.data));

            const courseDataEdit = result.data;
            const initialEditCourseDetails = {
              course_image: null,
              course_name: courseDataEdit.course_name,
              course_level_id: courseDataEdit.course_level?.level_name,
              course_length: courseDataEdit.course_length,
              choose_course_length: courseDataEdit?.course_length_type,
              course_category_id:
                courseDataEdit.course_category[`category_${lan}`] || "",
              course_preview_video: courseDataEdit.course_preview_video,
              // subcategory_name_id: courseDataEdit.course_subcategory?.subcategory_name,
              instructor_email: courseDataEdit?.instructor_email,
              instructor_name: courseDataEdit?.instructor_name,
              course_type_id: republish
                ? null
                : courseDataEdit.course_type?.type_name,
              meeting_link: courseDataEdit?.meeting_link,
              meeting_password: courseDataEdit?.meeting_passcode,
              organization_location:
                courseDataEdit?.organization_location || "",
              sub_area: courseDataEdit?.sub_area,
              course_checkout_link: courseDataEdit?.course_checkout_link || "",
              course_language: courseDataEdit?.course_language,
              course_starting_date: republish
                ? null
                : courseDataEdit?.course_starting_date,
              offer_price: courseDataEdit?.offer_price || "",
              author_name: courseDataEdit?.author_name || "",
            };
            initialEditCourseDetails.about_author = courseDataEdit?.author_bio;
            initialEditCourseDetails.additional_information =
              courseDataEdit?.additional_information;

            setAddCourseDetails(initialEditCourseDetails);
            setEditCourceImage(courseDataEdit.course_image);
          }
        })
        .finally(() => setIsLoader(false));
    }
  }, []);

  useMemo(() => {
    console.log("?????", addCourseDetals);
  }, [addCourseDetals]);

  const categoryCall = () => {
    setIsLoader(true);
    getCorporateCategoryApi()
      .then((res) => {
        if (res.status == "success") {
          setAllCategories(res?.data);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  useEffect(() => {
    categoryCall();
  }, []);

  async function loadVideoOrImageFile(evv) {
    const file = evv[evv.length - 1];
    await setAddCourseDetails({
      ...addCourseDetals,
      course_preview_video: file,
    });
    setVideoPreviewError("");
    let output = document.getElementById("bgImg");
    let iconImg = document.getElementById("iconImg");
    if (file.type.includes("image") && output) {
      iconImg.hidden = true;
      output.hidden = false;
      output.src = URL.createObjectURL(file);
      return (output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      });
    }
    iconImg["hidden"] = false;
    output["hidden"] = true;
  }

  function loadFile(e) {
    const src = URL.createObjectURL(e.target.files[0]);
    setAddCourseDetails({
      ...addCourseDetals,
      course_image: e.target.files[0],
      upload_image: src,
    });
  }

  useEffect(() => {
    if (
      addCourseDetals?.course_image !== null &&
      addCourseDetals?.course_image !== undefined
    ) {
      setEditCourceImage(null);
    }
  }, [addCourseDetals.course_image]);

  useEffect(() => {
    if (addCourseDetals.course_type_id === "Online") {
      setAddCourseDetails({ ...addCourseDetals, organization_location: "" });
    }
  }, [addCourseDetals.course_type_id]);

  useMemo(() => {
    if (!editCource) return;
    console.log(">>>>>>????");
    const updatedData = {
      course_image: null,
      course_name: editCource.course_name,
      course_level_id: editCource.course_level?.level_name,
      course_length: editCource.course_length,
      choose_course_length: editCource?.course_length_type,
      course_category_id: editCource.course_category[`category_${lan}`] || "",
      course_preview_video: editCource.course_preview_video,
      // subcategory_name_id: editCource.course_subcategory?.subcategory_name,
      instructor_email: editCource?.instructor_email,
      instructor_name: editCource?.instructor_name,
      course_type_id: republish ? null : editCource.course_type?.type_name,
      meeting_link: editCource?.meeting_link,
      meeting_password: editCource?.meeting_passcode,
      organization_location: editCource?.organization_location || "",
      sub_area: editCource?.sub_area,
      course_checkout_link: editCource?.course_checkout_link || "",
      course_language: editCource?.course_language,
      course_starting_date: republish ? null : editCource?.course_starting_date,
      offer_price: editCource?.offer_price || "",
      author_name: editCource?.author_name || "",
    };
    updatedData.about_author = editCource?.author_bio;
    updatedData.additional_information = editCource?.additional_information;

    setAddCourseDetails(updatedData);
  }, [editCource]);

  useEffect(() => {
    if (
      editCource &&
      window.location.href.includes("edit-course") &&
      addCourseDetals?.course_preview_video
    ) {
      let output = document.getElementById("bgImg");
      let iconImg = document.getElementById("iconImg");
      const fileType = checkUrlExtension(editCource.course_preview_video);
      if (fileType === "image") {
        iconImg.hidden = true;
        output.src = editCource.course_preview_video;
        output.hidden = false;
      } else {
        iconImg.hidden = false;
        output.hidden = true;
      }
    }
  }, [editCourceImage]);

  const validate = () => {
    console.log("???????", addCourseDetals);
    if (
      !addCourseDetals?.course_name &&
      !addCourseDetals.course_level_id &&
      !addCourseDetals.course_length &&
      !addCourseDetals.course_category_id &&
      !addCourseDetals?.course_image &&
      !addCourseDetals.course_type_id &&
      !addCourseDetals?.additional_information
    ) {
      setNameError("*Name is Required");
      setOverviewError("*Overview is Required");
      setLengthError("*Length is Required");
      setLevelError("*Level is Required");
      setDateError("*Date is Required");
      setCategoryError("*Category is Required");
      setCourseError("*Course Type is Required");
      setImageError("*Image is Required");

      return false;
    } else if (!addCourseDetals.course_image && !editCourceImage) {
      setImageError("*Image is Required");
      return false;
    } else if (
      !addCourseDetals?.course_name ||
      addCourseDetals?.course_name?.trim() == ""
    ) {
      setNameError("*Name is Required");
      return false;
    } else if (
      !addCourseDetals?.course_level_id ||
      addCourseDetals?.course_level_id == "Choose"
    ) {
      setLevelError("*Level is Required");
      return false;
    } else if (
      !addCourseDetals?.additional_information ||
      addCourseDetals?.additional_information?.trim() == ""
    ) {
      setOverviewError("*Overview is Required");
      return false;
    } else if (
      !addCourseDetals?.course_length ||
      addCourseDetals?.course_length == ""
    ) {
      setLengthError("*Length is Required");
      return false;
    } else if (
      !addCourseDetals.choose_course_length ||
      addCourseDetals.choose_course_length == ""
    ) {
      setDayLengthError("*Length is Required in (Day/Hours)");
      return false;
    } else if (
      !addCourseDetals?.course_category_id ||
      addCourseDetals?.course_category_id == "Choose"
    ) {
      setCategoryError("*Category is Required");
      return false;
    } else if (!addCourseDetals.course_type_id) {
      setCourseError("*Course Type is Required");
      return false;
    } else if (!addCourseDetals.course_starting_date) {
      setDateError("*Date is Required");
      return false;
    } else if (!addCourseDetals.course_preview_video) {
      setVideoPreviewError("*Video/Image is Required");
      return false;
    } else {
      return true;
    }
  };
  const addCourse = () => {
    let stat = validate();
    if (stat === true) {
      const categoryId = allCategories?.find(
        (ff) => ff[`category_${lan}`] === addCourseDetals.course_category_id
      );
      var data = new FormData();
      data.append("course_image", addCourseDetals.course_image);
      data.append("course_name", addCourseDetals.course_name || "");
      data.append("course_level_id", addCourseDetals.course_level_id || "");
      data.append("course_length", Number(addCourseDetals.course_length));
      data.append("course_length_type", addCourseDetals.choose_course_length);
      data.append("course_category_id", categoryId.id);

      data.append("course_type_id", addCourseDetals.course_type_id || "");
      data.append("fee_type_id", "Free");
      data.append("meeting_link", addCourseDetals?.meeting_link || "");
      data.append("meeting_passcode", addCourseDetals?.meeting_password || "");
      data.append(
        "course_starting_date",
        addCourseDetals.course_starting_date || ""
      );
      data.append(
        "additional_information",
        addCourseDetals.additional_information || ""
      );
      data.append(
        "organization_location",
        addCourseDetals.organization_location || ""
      );
      data.append("sub_area", addCourseDetals.sub_area || "");
      data.append(
        "course_checkout_link",
        addCourseDetals?.course_checkout_link || ""
      );
      data.append("course_language", addCourseDetals.course_language);
      data.append(
        "course_for_organization",
        addCourseDetals.course_for_organization
      );
      data.append("offer_price", addCourseDetals?.offer_price || "");
      data.append("author_bio", addCourseDetals?.about_author || "");
      data.append("author_name", addCourseDetals?.author_name || "");
      data.append("is_post", true);
      data.append("course_preview_video", addCourseDetals.course_preview_video);
      data.append("instructor_email", addCourseDetals.instructor_email || "");
      data.append("instructor_name", addCourseDetals.instructor_name || "");
      setLoderBtn(true);
      addCorporateCourseApi(data, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        setUploadPercentage(percentage);
        return percentage; // Because you were returning the percentage before.
      })
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            toast.success(lan == "en" ? res.data : res?.data_sv);
            setTimeout(() => {
              history.push("/supplier/corporate/course");
            }, 3000);
            // setPopupStatus(t("Success",lan));
            // setPopupMessage(lan =='en'? res.data : res?.data_sv);
          } else {
            setPopupStatus(t("Failed", lan));
            setPopupMessage(lan == "en" ? res.data : res?.data_sv);
            // toast.error(lan =='en'? res.data : res?.data_sv)
          }
        })
        .finally(() => {
          setLoderBtn(false);
          setUploadPercentage(0);
        });
    }
  };

  const editCourseCall = () => {
    let validation = validate();
    if (validation === true) {
      const categoryId = allCategories?.find(
        (ff) => ff[`category_${lan}`] === addCourseDetals.course_category_id
      );
      var data = new FormData();

      data.append(
        "course_image",
        addCourseDetals.course_image || editCourceImage
      );
      data.append("email_id", localStorage.getItem("logedInEmail"));
      data.append("course_name", addCourseDetals.course_name || "");
      data.append("course_level_id", addCourseDetals.course_level_id);
      data.append("course_length", Number(addCourseDetals.course_length));
      data.append("course_length_type", addCourseDetals.choose_course_length);

      data.append("course_category_id", categoryId?.id);
      data.append("fee_type_id", "Free");
      data.append("course_type_id", addCourseDetals.course_type_id || "");
      data.append("meeting_link", addCourseDetals?.meeting_link || "");
      data.append("meeting_passcode", addCourseDetals?.meeting_password || "");
      data.append(
        "course_starting_date",
        addCourseDetals.course_starting_date || ""
      );
      data.append("offer_price", addCourseDetals?.offer_price || "");
      data.append(
        "additional_information",
        addCourseDetals.additional_information || ""
      );
      data.append(
        "organization_location",
        addCourseDetals.organization_location || ""
      );
      data.append("sub_area", addCourseDetals.sub_area || "");
      data.append(
        "course_checkout_link",
        addCourseDetals.course_checkout_link || ""
      );
      data.append("course_language", addCourseDetals.course_language);
      data.append("author_bio", addCourseDetals?.about_author || "");
      data.append("author_name", addCourseDetals?.author_name || "");
      data.append("course_preview_video", addCourseDetals.course_preview_video);
      data.append("instructor_email", addCourseDetals.instructor_email || "");
      data.append("instructor_name", addCourseDetals.instructor_name || "");

      setLoderBtn(true);
      editCorporateCourseApi(data, editCource.uuid, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        setUploadPercentage(percentage);
        return percentage; // Because you were returning the percentage before.
      })
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            toast.success(lan == "en" ? res?.data : res?.data_sv);
            handleClosePopup();
            setTimeout(() => {
              history.push("/supplier/corporate/course");
            }, 3000);
          } else {
            setPopupStatus(t("Failed", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoderBtn(false);
          setUploadPercentage(0);
        });
    }
  };

  const maxLengthCheck = (e) => {
    if (e?.target?.value?.length > e?.target?.maxLength) {
      e.target.value = e?.target.value.slice(0, e?.target.maxLength);
    }
  };

  const handleClosePopup = () => {
    setAddCourseDetails(initialAddCourseDetails);
    setChooseCourseLength("");
    setEditCource(null);
    setEditCourceImage(null);
    setNameError("");
    setOverviewError("");
    setLengthError("");
    setLevelError("");
    setDateError("");
    setCategoryError("");
    setDayLengthError("");
    setCourseError("");
    setCourseError("");
    setImageError("");
    setLinkError("");
    setCourseForOrgError("");
    setOfferPriceErr("");
  };
  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
  };
  return (
    <Fragment>
      <div className="course">
        <div className="courseHeader my-2 pb-3 pt-3">
          <div className="midText course-list-title mb-0">
            {" "}
            <div>
              <span
                className="cursor-pointer"
                onClick={() => history.push("/supplier/dashboard")}
              >
                {t("Dashboard", lan)} |{" "}
              </span>
              <span
                className="cursor-pointer"
                onClick={() => history.push("/supplier/course")}
              >
                {t("CourseList", lan)} |{" "}
              </span>
              <span className="textPrimaryColor">
                {t(isAddCourse ? "AddCourse" : "UpdateCourse", lan)}
              </span>
            </div>
            <div className="courseDetailHeaderRight">
              <span
                className="headerTab smallText cursorPointer"
                onClick={() => {
                  window.history.back();
                }}
              >
                <IoIosArrowBack /> &nbsp;{t("Back", lan)}
              </span>
            </div>
            {/* <div>
              <Link
                onClick={() => {
                  history.goBack();
                }}
                className="back-link"
              >
                {t("Back", lan)}
              </Link>
            </div> */}
          </div>
        </div>
        {/* add-edit course  */}
        <div className="m-3">
          <div className="sidepopupContents main-manage">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="col-12">
                  <label>
                    <span className="customeFileUpload cursor-pointer">
                      <img
                        src={
                          addCourseDetals?.upload_image
                            ? addCourseDetals?.upload_image
                            : editCourceImage !== null
                            ? `${editCourceImage}`
                            : addCourseDetals?.course_image !== null &&
                              addCourseDetals?.course_image !== undefined
                            ? addCourseDetals?.course_image?.name
                            : UploadImage
                        }
                        alt="Upload Image"
                        className="uploadImage"
                        id="output"
                      />
                      {addCourseDetals?.course_image !== null &&
                      addCourseDetals?.course_image !== undefined ? (
                        <p className="smallText">
                          {addCourseDetals?.course_image?.name}
                        </p>
                      ) : editCourceImage !== null ? (
                        <p className="smallText imageMaxText">
                          {editCourceImage?.split("/").slice(-1)}
                        </p>
                      ) : (
                        <p className="smallText">
                          {`${t("UploadCourseImages", lan)} `}
                          <span className="text-danger">*</span>
                        </p>
                      )}

                      <input
                        type="file"
                        accept=".jpeg,image/*,.png"
                        className="inputTypeFile"
                        multiple={false}
                        onChange={(e) => {
                          loadFile(e);
                          setImageError("");
                        }}
                      />
                    </span>
                    {imageError && (
                      <p className="errorText mb-0">{imageError}</p>
                    )}
                  </label>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseName">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseNameTitle", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <input
                      type="text"
                      id="courseName"
                      readOnly={republish}
                      placeholder={` ${t("Course", lan)} ${t("name", lan)}`}
                      className="formInputs"
                      value={addCourseDetals.course_name}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_name: e.target.value,
                        });
                        setNameError("");
                      }}
                    />
                    {nameError && <p className="errorText mb-0">{nameError}</p>}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseCategory">
                      <p className="smallText inputText boldText pWithMargin">
                        {` ${t("CourseCategory", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="courseCategory"
                      value={addCourseDetals.course_category_id}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_category_id: e.target.value,
                        });
                        setCategoryError("");
                      }}
                    >
                      <option disabled selected>
                        {`${t("ChooseCourseCategory", lan)}`}
                      </option>
                      {allCategories?.map((res) => {
                        return (
                          <option value={res[`category_${lan}`]}>
                            {res[`category_${lan}`]}
                          </option>
                        );
                      })}
                    </select>
                    {categoryError && (
                      <p className="errorText mb-0">{categoryError}</p>
                    )}

                    {/* <input type="text" id="courseLevel" placeholder="Choose Course Level " className="formInputs"/> */}
                  </span>
                </div>

                {/* subcategory input */}
                {/* <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseSubCategory ">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("ChooseSubCategory", lan)}`}
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="courseSubCategory"
                      value={addCourseDetals?.subcategory_name_id}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          subcategory_name_id: e.target.value,
                        });
                        setSubCategoryError("");
                      }}
                    >
                      <option disabled selected>
                        {`${t("ChooseSubCategory", lan)}  `}
                      </option>

                      {allSubCategories
                        ?.filter(
                          (fil) =>
                            fil?.is_approved?.value == "Approved" &&
                            fil?.status?.value == "Active" &&
                            fil?.category_name?.category_name?.toLowerCase() ==
                              addCourseDetals?.course_category_id?.toLowerCase()
                        )
                        ?.map((res) => {
                          return (
                            <option value={res.subcategory_name}>
                              {res.subcategory_name}
                            </option>
                          );
                        })}
                    </select>
                    {subCategoryError && (
                      <p className="errorText mb-0">{subCategoryError}</p>
                    )}
                  </span>
                </div> */}

                <div className="col-md-12 col-12">
                  {" "}
                  <span>
                    <label htmlFor="courseOverview">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseOverview", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <div className="richtext">
                      <CKEditor
                        data={
                          editCource?.additional_information
                            ? editCource?.additional_information
                            : ""
                        }
                        editor={ClassicEditor}
                        placeholder={t("CourseOverview", lan)}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log("data1", data);
                          setAddCourseDetails({
                            ...addCourseDetals,
                            additional_information: data,
                          });
                          setOverviewError("");
                        }}
                      />
                    </div>
                    {/* <textarea
              rows={3}
              id="courseOverview"
              placeholder={`${t("CourseOverview", lan)}}`}
              className="formInputs textArea"
              value={addCourseDetals.additional_information}
              onChange={(e) => {
                setAddCourseDetails({
                  ...addCourseDetals,
                  additional_information: e.target.value,
                });
                setOverviewError("");
              }}
            /> */}
                    {overviewError && (
                      <p className="errorText mb-0">{overviewError}</p>
                    )}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseLength">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseLength", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <input
                      type="number"
                      id="courseLength"
                      placeholder={`${t("EnterLength", lan)}`}
                      className="formInputs"
                      maxLength={3}
                      onInput={maxLengthCheck}
                      value={addCourseDetals.course_length}
                      disabled={chooseCourseLength !== "" ? true : false}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_length: e.target.value,
                        });
                        setChooseCourseLength("");
                        setLengthError("");
                      }}
                    />
                    {lengthError && (
                      <p className="errorText mb-0">{lengthError}</p>
                    )}
                  </span>
                </div>

                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="chooseCourseLength ">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("ChooseCourseLength", lan)}  `}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="chooseCourseLength"
                      value={addCourseDetals.choose_course_length}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          choose_course_length: e.target.value,
                        });
                        setDayLengthError("");
                      }}
                    >
                      <option selected value="">
                        {`${t("EnterCourseLength", lan)}`}
                      </option>
                      <option value={"Hour"}>{t("Hour", lan)}</option>
                      <option value={"Day"}>{t("Day", lan)}</option>
                    </select>
                    {/* <input type="text"  placeholder="Choose Course Length" /> */}
                    {dayLengthError && (
                      <p className="errorText mb-0">{dayLengthError}</p>
                    )}
                  </span>
                </div>

                <div className="col-md-12 col-12">
                  <span className="date-box">
                    <label htmlFor="chooseCourseDate ">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("ChooseCourseLevelCorporate", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <input
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_starting_date: e.target.value,
                        });
                        setDateError("");
                      }}
                      min={new Date().toISOString().split("T")[0]}
                      type="Date"
                      value={addCourseDetals?.course_starting_date}
                      id="chooseCourseDate"
                      placeholder="MM/DD/YY"
                      className="formInputs"
                    />
                    {dateError && <p className="errorText mb-0">{dateError}</p>}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseLevel">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseLevel", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="courseLevel"
                      value={addCourseDetals.course_level_id}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_level_id: e.target.value,
                        });
                        setLevelError("");
                      }}
                    >
                      <option disabled selected>
                        {`${t("ChooseCourseLevel", lan)} `}
                      </option>
                      <option value="Beginner">{t("Beginner", lan)}</option>
                      <option value="Intermediate">
                        {t("Intermediate", lan)}
                      </option>
                      <option value="Advanced">{t("Advanced", lan)}</option>
                    </select>
                    {levelError && (
                      <p className="errorText mb-0">{levelError}</p>
                    )}

                    {/* <input type="text" id="courseLevel" placeholder="Choose Course Level " className="formInputs"/> */}
                  </span>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="col-md-12 col-12">
                  <span>
                    <span>
                      <label htmlFor="courseType">
                        <p className="smallText inputText boldText pWithMargin">
                          {`${t("CourseType", lan)} `}
                          <span className="text-danger">*</span>
                        </p>
                      </label>
                      <div className="radioDiv smallText">
                        <div className="radiobuttons">
                          <div className="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              name="radio"
                              value="Physical"
                              id="Physical"
                              checked={
                                addCourseDetals.course_type_id === "Physical"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    course_type_id: e.target.value,
                                  });
                                setCourseError("");
                              }}
                            />{" "}
                            <label for="Physical">{t("Physical", lan)}</label>
                          </div>

                          <div className="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              name="radio"
                              value="Online"
                              id="Online"
                              checked={
                                addCourseDetals.course_type_id === "Online"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    course_type_id: e.target.value,
                                  });
                                setCourseError("");
                              }}
                            />{" "}
                            <label for="Online">{t("Online", lan)}</label>
                          </div>

                          <div className="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              name="radio"
                              value="Hybrid"
                              id="Hybrid"
                              checked={
                                addCourseDetals.course_type_id === "Hybrid"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    course_type_id: e.target.value,
                                  });
                                setCourseError("");
                              }}
                            />{" "}
                            <label for="Hybrid">{t("Hybrid", lan)}</label>
                          </div>
                        </div>
                      </div>

                      {/* course type inputs starts  */}
                      {addCourseDetals?.course_type_id && (
                        <div>
                          {addCourseDetals?.course_type_id !== "Online" ? (
                            <>
                              <label htmlFor="courseLoc">
                                <p className="smallText inputText boldText pWithMargin">
                                  {t("CourseLocation", lan)}{" "}
                                </p>
                              </label>
                              <input
                                type="text"
                                id="courseType"
                                placeholder={t("EnterLocation", lan)}
                                className="formInputs"
                                value={
                                  addCourseDetals.organization_location === null
                                    ? ""
                                    : addCourseDetals.organization_location
                                }
                                onChange={(e) => {
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    organization_location: e.target.value,
                                  });
                                  setLinkError("");
                                  setCourseError("");
                                }}
                              />
                            </>
                          ) : (
                            <Fragment>
                              {/* currently we don't want meeting link in manage course  */}
                              {/* <label htmlFor="courseType">
                                <p className="smallText inputText boldText pWithMargin">
                                  {`${t("EnterMeetingLink", lan)} `}
                                  <span className="text-danger">*</span>
                                </p>
                              </label>
                              <input
                                type="text"
                                id="courseType"
                                placeholder={`${t("EnterMeetingLink", lan)}  `}
                                className="formInputs"
                                value={
                                  addCourseDetals.meeting_link === null
                                    ? ""
                                    : addCourseDetals.meeting_link
                                }
                                onChange={(e) => {
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    meeting_link: e.target.value,
                                  });
                                  setLinkError("");
                                }}
                              /> */}
                            </Fragment>
                          )}
                        </div>
                      )}
                      {courseTypeError && (
                        <p className="errorText mb-0">{courseTypeError}</p>
                      )}
                      {linkError && (
                        <p className="errorText mb-0">{linkError}</p>
                      )}
                    </span>

                    {/* password meeeting */}
                    <div
                      hidden={
                        addCourseDetals?.course_type_id !== "Online"
                          ? true
                          : false
                      }
                    >
                      <Fragment></Fragment>
                    </div>
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="subArea">
                      <p className="smallText inputText boldText pWithMargin">
                        {t("CourseInstructorname", lan)}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="subArea"
                      placeholder={t("EnterCourseInstructorname", lan)}
                      className="formInputs"
                      value={addCourseDetals?.author_name}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          author_name: e.target.value,
                        });
                      }}
                    />
                  </span>
                </div>

                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="subArea">
                      <p className="smallText inputText boldText pWithMargin">
                        {t("CourseInstructoremail", lan)}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="subArea"
                      placeholder={t("EnterCourseInstructoremail", lan)}
                      className="formInputs"
                      value={addCourseDetals?.instructor_email}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          instructor_email: e.target.value,
                        });
                      }}
                    />
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="checkoutLink">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CheckoutLink", lan)}`}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="checkoutLink"
                      placeholder={`${t("EnterCheckoutLink", lan)}`}
                      className="formInputs"
                      value={addCourseDetals?.course_checkout_link}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_checkout_link: e.target.value,
                        });
                      }}
                    />
                  </span>
                </div>

                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="writeauthor">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("WriteAuthor", lan)}`}
                      </p>
                    </label>
                    <div className="richtext">
                      <CKEditor
                        data={
                          addCourseDetals?.about_author
                            ? addCourseDetals?.about_author
                            : ""
                        }
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log("data2", data);

                          setAddCourseDetails({
                            ...addCourseDetals,
                            about_author: data,
                          });
                        }}
                      />
                    </div>
                  </span>
                </div>

                <div className="col-12">
                  <label>
                    <p className="smallText inputText boldText pWithMargin">
                      {t("BackgroundFile", lan)}
                      <span className="text-danger">*</span>
                    </p>
                  </label>
                  <EddiDropzone
                    dragText={t("DragDrop", lan)}
                    accept="video,image"
                    selectedFiles={choosedVideos}
                    choosedFile={(evv) => loadVideoOrImageFile(evv)}
                  />
                  {videoPreviewError && (
                    <p className="errorText mb-0">{videoPreviewError}</p>
                  )}
                  {addCourseDetals.course_preview_video && (
                    <div className="mt-3 list-video">
                      <div id="iconImg" className="img-v center">
                        <img src={IcPlay} />
                      </div>
                      <img id="bgImg" alt="bgvideoImg" hidden={true} />
                      <span className="mx-2 text-break align-self-center">
                        {addCourseDetals.course_preview_video?.name?.slice(
                          0,
                          60
                        ) ||
                          addCourseDetals.course_preview_video
                            ?.split("course_preview_video/")[1]
                            ?.slice(0, 60) ||
                          ""}
                      </span>
                      <div className="align-self-center ">
                        <img
                          className="cursor-pointer"
                          onClick={() =>
                            setAddCourseDetails({
                              ...addCourseDetals,
                              course_preview_video: null,
                            })
                          }
                          src={IcEddiProfile}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {loderBtn && (
              <div className="progress mt-4">
                <div
                  className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow={uploadPercentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${uploadPercentage}%` }}
                ></div>
              </div>
            )}

            <div className="d-flex justify-content-end">
              <button
                className="primaryButton"
                onClick={() => {
                  isAddCourse ? addCourse() : editCourseCall();
                }}
                disabled={loderBtn}
              >
                {loderBtn ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  `${t(
                    isAddCourse ? "AddCourseTitle" : "UpdateCourseBtn",
                    lan
                  )} `
                )}
              </button>
              <Link
                className="primaryButton mx-4"
                to={`/supplier/corporate/course`}
              >
                {t("CancleText", lan)}
              </Link>
            </div>
          </div>
        </div>
        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
      </div>
      {isLoader ? <Loader /> : ""}
    </Fragment>
  );
};

export default ManageCorporateCourse;
