import React, { useEffect, useState } from "react";
import "./eventList.scss";
import "../../../common/styles/globalStyles.scss";
import { FaFilter, FaSortAmountUp } from "react-icons/fa";
import SidePopup from "../../../components/sidePopup/sidePopup";
import UploadImage from "../../../assets/images/uploadImage.png";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../api";
import Popup from "../../../components/popup/popup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getTranslatedText as t } from "../../../translater";
import {
  deleteEventById,
  editEventById,
  getAllEvent,
  postEventDetails,
} from "../../../services/adminService";
import {
  allEventDetails,
  courseCategories,
  searchCourseText,
  selectedEventDetails,
} from "../../../redux/actions";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import NoData from "../../../components/NoData/NoData";
import InputText from "../../../components/inputText/inputText";
import FilterSelectMenu from "../../../components/FilterSelectMenu/FilterSelectMenu";
import IcSearch from "../../../assets/images/icSearch.svg";
import IcFilter from "../../../assets/images/IcFilter.svg";
import IcFilter2 from "../../../assets/images/IcFilter2.svg";
import Loader from "../../../components/Loader/Loader";
import {
  deleteNews,
  editNews,
  getAllSubCategory,
  getCategoryApi,
  getNews,
  postNews,
} from "../../../services/supplierService";
import Footer from "../../../components/footer/Footer";
import { encrypt } from "../../../utils/encrypt";
import { checkTrimOrUndefined, formatDate } from "../../../services/constant";

function EventList() {
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();
  const dispatch = useDispatch();
  let lan = state?.language;
  const initialAddCourseDetails = {
    ads_image: null,
    ads_name: null,
    Ads_type: null,
    Ads_publish_on: null,
    Ads_catagory: null,
    Ads_desc: null,
    Ads_small_desc: null,
    Ads_banner_link: null,
    Ads_checkout_link: null,
    start_date: "",
    end_date: "",
    start_time: "",
    fees_type: null,
    event_type: null,
    event_price: null,
    event_location: null,
    is_featured: false,
    organizer: null,
    zoom_link: null,
    zoom_password: null,
    offer_price: null,
  };

  const [addAds, setAddAds] = useState(false);
  const [addAdsDetails, setAddAdsDetails] = useState(initialAddCourseDetails);
  const [editAds, setEditAds] = useState(false);
  const [getEditAdsData, setEditAdsData] = useState();
  const [getAdsDetails, setGetAdsDetails] = useState();
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupDelete, setPopupDelete] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [eventTypeStatus, setEventTypeStatus] = useState("Advertisement");
  const [deleteEventId, setDeleteEventId] = useState();
  const [activeInactivePopup, setActiveInactivePopup] = useState({
    value: false,
    data: "",
  });
  const [eventShowPageError, setEventShowPageError] = useState("");
  const [nameError, setNameError] = useState("");
  const [descError, setDescError] = useState("");
  const [imageError, setImageError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [startTimeError, setTimeError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [onlineOfflineError, setOnlineOfflineError] = useState("");
  const [paginationCourses, setPaginationCourses] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [selectedPriceFilter, setSelectedPriceFilter] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);
  const [searchFilteredCourse, setSearchFilteredCourse] = useState(
    state?.AllEvent
  );
  const [isLoader, setIsLoader] = useState(false);
  const [oriPriceError, setOriPriceError] = useState("");
  const [newsData, setNewsData] = useState([]);
  const [filteredNewsData, setFilteredNewsData] = useState([]);
  const [paginationNewsData, setPaginationNewsData] = useState([]);
  const [addNewsData, setAddNewsData] = useState({
    type: "Add",
    value: false,
    news_image: "",
    news_title_en: "",
    news_short_description_en: "",
    news_detailed_description_en: "",
    news_detailed_title_en: "",
  });
  const [newsError, setNewsError] = useState({
    news_image: "",
    news_title_en: "",
    news_short_description_en: "",
    news_detailed_description_en: "",
    news_detailed_title_en: "",
  });

  const datePlaceholder = "DD-MM-YYYY";
  useEffect(() => {
    getEvents();
    subCategoryApi();
    getAllNews();
  }, []);

  const getEvents = () => {
    setIsLoader(true);
    getAllEvent().then((result) => {
      setIsLoader(false);
      if (result?.status == "success") {
        setGetAdsDetails(result?.data);
        setSearchFilteredCourse(result?.data);
        dispatch(allEventDetails(result?.data));
      }
    });
  };
  const subCategoryApi = () => {
    getCategoryApi()
      .then((result) => {
        if (result?.status == "success") {
          dispatch(courseCategories(result?.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (getEditAdsData !== null) {
      const initialAddCourseDetails = {
        ads_image: getEditAdsData?.event_image || null,
        ads_name: getEditAdsData?.event_name || null,
        Ads_type: getEditAdsData?.event_choose_type || null,
        Ads_publish_on: getEditAdsData?.event_publish_on || null,
        Ads_desc: getEditAdsData?.event_description || null,
        Ads_small_desc: getEditAdsData?.event_small_description || null,
        Ads_banner_link: getEditAdsData?.banner_video_link || null,
        Ads_checkout_link: getEditAdsData?.checkout_link || null,
        start_date: getEditAdsData?.start_date || null,
        end_date: getEditAdsData?.end_date || null,
        start_time: getEditAdsData?.start_time || "",
        fees_type: getEditAdsData?.fees_type || null,
        event_type: getEditAdsData?.event_type || null,
        event_price: getEditAdsData?.event_price || null,
        event_location: getEditAdsData?.event_location || null,
        is_featured: getEditAdsData?.is_featured || null,
        organizer: getEditAdsData?.event_organizer || null,
        Ads_catagory: getEditAdsData?.event_category || null,
        zoom_link: getEditAdsData?.meeting_link || null,
        zoom_password: getEditAdsData?.meeting_passcode || null,
        offer_price: getEditAdsData?.original_price || null,
      };
      setAddAdsDetails(initialAddCourseDetails);
    }
  }, [getEditAdsData]);

  function loadFile(event, id = "output") {
    let output = document.getElementById(id);
    const blob = URL.createObjectURL(event.target.files[0]);
    handleChangeNews("blob_img", blob);
    output.src = blob;
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };
  }

  const onStatusChange = (e, data) => {
    let formData = new FormData();
    formData.append("status", e?.target?.value);
    editEventById(data?.uuid, formData)
      .then((result) => {
        if (result?.status == "success") {
          getEvents();
        } else {
          setActiveInactivePopup({
            value: true,
            data: lan == "en" ? result?.data : result?.data_sv,
          });
        }
      })
      .catch((e) => console.log("something went wrong!"));
  };
  const onAddAdsClick = (type, id) => {
    if (addAdsDetails) {
      if (
        (!addAdsDetails?.ads_name &&
          !addAdsDetails?.Ads_publish_on &&
          !addAdsDetails?.start_date &&
          !addAdsDetails?.ads_image &&
          !addAdsDetails?.start_time &&
          !addAdsDetails?.event_type) ||
        addAdsDetails?.event_type === "Choose"
      ) {
        setNameError("*Name is Required");
        setEventShowPageError("*Publish page is Required");
        setDescError("*Description is Required");
        setStartDateError("*Date is Required");
        setTimeError("*Time is Required");
        setOnlineOfflineError("*Type is Required");
        setCategoryError("*category is Required");
        setImageError("*Image is Required");
      } else if (
        addAdsDetails?.ads_name == null ||
        addAdsDetails?.ads_name.trim() == ""
      ) {
        return setNameError("*Name is Required");
      } else if (
        !addAdsDetails?.Ads_publish_on &&
        addAdsDetails?.Ads_publish_on == "Choose"
      ) {
        return setEventShowPageError("*Publish page is Required");
      } else if (
        !addAdsDetails?.Ads_desc ||
        addAdsDetails?.Ads_desc.trim() == ""
      ) {
        return setDescError("*Description is Required");
      } else if (!addAdsDetails?.start_date && eventTypeStatus == "Event") {
        return setStartDateError("*Date is Required");
      }
      // else if (new Date(addAdsDetails?.start_date).toLocaleDateString() < new Date().toLocaleDateString() && eventTypeStatus == "Event") {
      //   return setStartDateError("*Enter valid Date");
      // }
      else if (!addAdsDetails?.ads_image) {
        return setImageError("*Image is Required");
      } else if (
        addAdsDetails?.fees_type === "Paid" &&
        eventTypeStatus === "Event" &&
        addAdsDetails?.event_price <= 0
      ) {
        return setOriPriceError("*Please Enter Valid Price");
      } else if (!addAdsDetails?.fees_type && eventTypeStatus === "Event") {
        return setOriPriceError("*Fees type is Required");
      } else if (
        !addAdsDetails?.Ads_catagory &&
        addAdsDetails?.Ads_catagory === "Choose" &&
        eventTypeStatus == "Event"
      ) {
        return setCategoryError("*category is Required");
      } else if (!addAdsDetails?.event_type && eventTypeStatus === "Event") {
        return setOnlineOfflineError("*Event Type is Required");
      } else if (!addAdsDetails?.start_time && eventTypeStatus === "Event") {
        return setTimeError("*Time is Required");
      } else if (
        addAdsDetails?.fees_type === "Paid" &&
        eventTypeStatus === "Event" &&
        addAdsDetails?.event_price <= 0
      ) {
        return setOriPriceError("*Enter price");
      } else {
        let formData = new FormData();
        formData.append("event_name", addAdsDetails?.ads_name || "");
        formData.append("event_choose_type", eventTypeStatus || "");
        formData.append(
          "banner_video_link",
          addAdsDetails?.Ads_banner_link || ""
        );
        formData.append("fees_type", addAdsDetails?.fees_type || "");
        formData.append("event_type", addAdsDetails?.event_type || "");
        if (addAdsDetails?.fees_type == "Free") {
          formData.append("event_price", 0);
        } else {
          formData.append(
            "event_price",
            Number(addAdsDetails?.event_price || 0)
          );
        }

        formData.append("start_date", addAdsDetails?.start_date || "");
        formData.append("end_date", addAdsDetails?.end_date || "");
        formData.append("start_time", addAdsDetails?.start_time || "");
        formData.append(
          "checkout_link",
          addAdsDetails?.Ads_checkout_link || ""
        );
        formData.append("is_featured", addAdsDetails?.is_featured || "");
        formData.append("event_image", addAdsDetails?.ads_image || "");
        formData.append(
          "event_publish_on",
          addAdsDetails?.Ads_publish_on || ""
        );
        formData.append(
          "event_small_description",
          addAdsDetails?.Ads_small_desc || ""
        );
        formData.append("event_description", addAdsDetails?.Ads_desc || "");
        formData.append("event_location", addAdsDetails?.event_location || "");
        formData.append(
          "event_organizer",
          state?.UserDetail?.data?.FIRST_NAME || ""
        );
        formData.append("event_category", addAdsDetails?.Ads_catagory || "");
        formData.append("meeting_link", addAdsDetails?.zoom_link || "");
        formData.append("meeting_passcode", addAdsDetails?.zoom_password || "");
        if (addAdsDetails?.fees_type == "Free") {
          formData.append("original_price", 0);
        } else {
          formData.append(
            "original_price",
            Number(
              addAdsDetails?.offer_price || addAdsDetails?.event_price || 0
            )
          );
        }

        setLoderBtn(true);

        if (type === "add") {
          postEventDetails(formData).then((result) => {
            setLoderBtn(false);
            if (result.status == "success") {
              setPopupStatus(t("Success", lan) + "!");
              setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
              setAddAds(false);
              setAddAdsDetails(initialAddCourseDetails);
              getEvents();
            } else {
              setPopupStatus(t("Failed", lan) + "!");
              setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
            }
          });
        } else {
          editEventById(id, formData).then((result) => {
            setLoderBtn(false);
            if (result?.status == "success") {
              setPopupStatus(t("Success", lan) + "!");
              setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
              setAddAds(false);
              setAddAdsDetails(initialAddCourseDetails);
              getEvents();
            } else {
              setPopupStatus(t("Failed", lan) + "!");
              setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
            }
          });
        }
      }
    }
  };
  const onDeleteCourse = () => {
    deleteEventById(deleteEventId).then((result) => {
      setPopupDelete("");
      if (result) {
        setPopupStatus(t("success", lan));
        setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
        getEvents();
      } else {
        setPopupStatus("Failed!");
        setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
      }
    });
  };
  const onEditClick = (data) => {
    setEditAds(true);
    setEditAdsData(data);
  };

  const handleClosePopup = () => {
    setAddAds(false);
    setAddAdsDetails(initialAddCourseDetails);
    setEditAds(false);
    setEditAdsData();
    setDescError("");
    setNameError("");
    setEventShowPageError("");
    setTimeError("");
    setStartDateError("");
    setOnlineOfflineError("");
    setCategoryError("");
    setPriceError("");
    setImageError("");
    setActiveInactivePopup({ value: false, data: "" });
    setOriPriceError("");
  };

  const handleClosePopupStatus = () => {
    if (popupMessage !== "error") {
      setAddAds(false);
      setAddAdsDetails(initialAddCourseDetails);
      setEditAds(false);
    }
    setPopupStatus("");
    setPopupDelete("");
    setActiveInactivePopup({ value: false, data: "" });
    setDescError("");
    setNameError("");
    setPriceError("");
    setEventShowPageError("");
    setTimeError("");
    setStartDateError("");
    setOnlineOfflineError("");
    setCategoryError("");
    setImageError("");
    setOriPriceError("");
  };

  function courseComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function courseComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);
    return date1 - date2;
  }

  const filterEvents = async () => {
    let filteredbyTime = [];
    let filteredbyPrice = [];
    let selectedEvent = [];
    if (eventTypeStatus !== "") {
      await searchFilteredCourse?.map((data, index) => {
        if (eventTypeStatus === data?.event_choose_type) {
          selectedEvent.push(data);
        }
      });
    } else {
      selectedEvent = searchFilteredCourse;
    }

    if (selectedPriceFilter !== "") {
      await selectedEvent?.map((course) => {
        if (selectedPriceFilter === course?.fees_type) {
          filteredbyPrice.push(course);
        }
      });
    } else {
      filteredbyPrice = selectedEvent;
    }

    if (selectedTimeFilter == "Newest") {
      filteredbyTime = filteredbyPrice?.sort(courseComparisonbyNewestDate);
    } else {
      filteredbyTime = filteredbyPrice?.sort(courseComparisonbyOldestDate);
    }

    setFilteredData(filteredbyTime);
  };

  const handleSearchData = (e) => {
    const inputValue = e.target.value?.trim().toLowerCase();

    let searchData = [];

    if (inputValue) {
      if (eventTypeStatus === "News") {
        searchData = newsData.filter((ff) =>
          ff[`news_title_en`]?.toLowerCase().includes(inputValue)
        );
      } else {
        searchData = getAdsDetails.filter((cour) =>
          cour?.event_name?.toLowerCase().includes(inputValue)
        );
      }
    } else {
      searchData = eventTypeStatus === "News" ? newsData : getAdsDetails;
    }

    if (eventTypeStatus === "News") {
      setFilteredNewsData(searchData);
    } else {
      setSearchFilteredCourse(searchData);
    }
  };

  useEffect(() => {
    if (eventTypeStatus === "Advertisement") {
      setSelectedPriceFilter("");
    }
    filterEvents();
    pagination();
  }, [
    eventTypeStatus,
    state?.AllEvent,
    searchFilteredCourse,
    selectedTimeFilter,
    selectedPriceFilter,
  ]);

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    if (eventTypeStatus !== "News") {
      filteredData?.map((data, i) => {
        if (i >= actualIndex && i <= actualIndex + 12) {
          paginationData.push(data);
        }
      });
      setPaginationCourses(paginationData);
    } else {
      filteredNewsData?.map((data, i) => {
        if (i >= actualIndex && i <= actualIndex + 12) {
          paginationData.push(data);
        }
      });
      setPaginationNewsData(paginationData);
    }
  };

  useEffect(() => {
    pagination();
  }, [
    paginationStartIndex,
    filteredData,
    selectedTimeFilter,
    filteredNewsData,
  ]);

  useEffect(() => {
    setPaginationStartIndex(1);
  }, [filteredData, filteredNewsData]);

  // ======================= news start =================

  const onNewsEditClick = (data) => {
    setAddNewsData({
      type: "Update",
      news_detailed_description_en: data?.news_detailed_description_en || "",
      news_detailed_title_en: data?.news_detailed_title_en || "",
      news_image: data?.news_image || "",
      news_short_description_en: data?.news_short_description_en || "",
      news_title_en: data?.news_title_en || "",
      value: true,
      uuid: data?.uuid,
    });
  };

  const onDeleteNews = () => {
    deleteNews(deleteEventId).then((result) => {
      setPopupDelete("");
      if (result) {
        setPopupStatus(t("success", lan));
        setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
        getAllNews();
      } else {
        setPopupStatus("Failed!");
        setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
      }
    });
  };

  const onNewsStatusChange = (e, data) => {
    let formData = new FormData();
    formData.append("news_status", e?.target?.value);
    setIsLoader(true);
    editNews(data?.uuid, formData)
      .then((result) => {
        if (result?.status == "success") {
          getAllNews();
        } else {
          setActiveInactivePopup({
            value: true,
            data: lan == "en" ? result?.data : result?.data_sv,
          });
        }
      })
      .catch((e) => console.log("something went wrong!"))
      .finally(() => setIsLoader(false));
  };

  const getAllNews = () => {
    setIsLoader(true);
    getNews(false)
      .then((result) => {
        if (result.status === "success") {
          setNewsData(result?.data);
          handleFilterNews(selectedTimeFilter, result.data);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoader(false));
  };

  const validateNews = () => {
    if (!addNewsData.news_image || addNewsData.news_image.length === 0) {
      setNewsError({ ...newsError, news_image: "*Image is Required" });
      return false;
    } else if (checkTrimOrUndefined(addNewsData.news_title_en)) {
      setNewsError({ ...newsError, news_title_en: "*Title is Required" });
      return false;
    } else if (addNewsData.news_short_description_en?.length > 500) {
      setNewsError({
        ...newsError,
        news_short_description_en: "*Max 500 character allowed",
      });
      return false;
    } else if (checkTrimOrUndefined(addNewsData.news_detailed_title_en)) {
      setNewsError({
        ...newsError,
        news_detailed_title_en: "*Title is Required",
      });
      return false;
    } else if (checkTrimOrUndefined(addNewsData.news_detailed_description_en)) {
      setNewsError({
        ...newsError,
        news_detailed_description_en: "*description is Required",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleNewsAdd = () => {
    if (!validateNews()) return;
    const formData = new FormData();
    formData.append("news_title_en", addNewsData.news_title_en);
    formData.append(
      "news_detailed_title_en",
      addNewsData.news_detailed_title_en
    );
    formData.append(
      "news_short_description_en",
      addNewsData.news_short_description_en
    );
    formData.append(
      "news_detailed_description_en",
      addNewsData.news_detailed_description_en
    );
    formData.append("news_image", addNewsData.news_image);
    formData.append("news_title_sw", addNewsData.news_title_en);
    formData.append(
      "news_short_description_sw",
      addNewsData.news_short_description_en
    );
    formData.append(
      "news_detailed_description_sw",
      addNewsData.news_detailed_description_en
    );
    formData.append(
      "news_detailed_title_sw",
      addNewsData.news_detailed_title_en
    );

    setLoderBtn(true);
    if (addNewsData.type === "Add") {
      postNews(formData).then((result) => {
        setLoderBtn(false);
        if (result.status == "success") {
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
          handleCloseNewsPopup();
          getAllNews();
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
        }
      });
    } else {
      editNews(addNewsData?.uuid, formData).then((result) => {
        setLoderBtn(false);
        if (result.status == "success") {
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
          handleCloseNewsPopup();
          getAllNews();
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
        }
      });
    }
  };

  const handleChangeNews = (type, value) => {
    setAddNewsData((item) => {
      return { ...item, [type]: value };
    });
    setNewsError((item) => {
      return { ...item, [type]: "" };
    });
  };

  const handleFilterNews = (val = selectedTimeFilter, data) => {
    let filtered = data ?? newsData;
    if (filtered.length === 0) return;
    if (val == "Newest") {
      filtered = filtered?.sort(courseComparisonbyNewestDate);
    } else {
      filtered = filtered?.sort(courseComparisonbyOldestDate);
    }
    setFilteredNewsData(filtered);
  };

  const handleCloseNewsPopup = () => {
    setAddNewsData({
      type: "Add",
      value: false,
      news_detailed_description_en: "",
      news_image: "",
      news_short_description_en: "",
      news_title_en: "",
      news_detailed_title_en: "",
    });
    setNewsError({
      news_detailed_description_en: "",
      news_image: "",
      news_short_description_en: "",
      news_title_en: "",
      news_detailed_title_en: "",
    });
  };

  // ==================news end =======================

  return (
    <div className="main-event">
      <div className="course">
        <div className="courseHeader my-4 pb-3 pt-3">
          <p className="midText">
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">
              {" "}
              {`${t("Manage", lan)} ${t(eventTypeStatus?.toLowerCase(), lan)}`}
            </span>
          </p>

          <div className="fliter-block w-100 justify-content-between">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={` ${t("searchbyName", lan)} `}
                onChange={handleSearchData}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <div className="d-flex align-items-center ">
                <div className="headerTab smallText position-relative">
                  <span className="iconsSelect">
                    <img src={IcFilter2} />
                  </span>
                  <FilterSelectMenu
                    placeholder=""
                    value={["Advertisement", "Event", "News"]}
                    selected={eventTypeStatus}
                    onChange={(e) => {
                      setEventTypeStatus(e.target.value);
                    }}
                    isWithicon
                  />
                </div>
                <div className="headerTab smallText position-relative">
                  <span className="iconsSelect">
                    <img src={IcFilter} />
                  </span>
                  <FilterSelectMenu
                    placeholder=""
                    value={["Newest", "Oldest"]}
                    selected={selectedTimeFilter}
                    onChange={(e) => {
                      setSelectedTimeFilter(e.target.value);
                      handleFilterNews(e.target.value);
                    }}
                    isWithicon
                  />
                </div>
                {eventTypeStatus == "Event" && (
                  <div className="headerTab smallText position-relative">
                    <span className="iconsSelect">
                      <img src={IcFilter2} />
                    </span>
                    <FilterSelectMenu
                      placeholder="Filter By"
                      value={["Paid", "Free"]}
                      onChange={(e) => {
                        setSelectedPriceFilter(e.target.value);
                        // setSelectedPriceFilter("");
                      }}
                      isWithicon
                    />
                  </div>
                )}
              </div>
              {eventTypeStatus === "News" ? (
                <button
                  className="btn-green"
                  onClick={() => handleChangeNews("value", true)}
                >
                  {`${t(addNewsData.type, lan)} ${t("news", lan)}`}
                </button>
              ) : (
                <button
                  className="btn-green"
                  onClick={() => {
                    setAddAds(true);
                  }}
                >
                  {eventTypeStatus == "Advertisement"
                    ? ` ${t("Add", lan)} ${t("ads", lan)}  `
                    : ` ${t("Add", lan)} ${t("event", lan)}`}
                </button>
              )}
            </div>
          </div>
        </div>
        {eventTypeStatus === "News" ? (
          <div className="courseListDiv">
            {paginationNewsData?.length > 0 ? (
              <table className="courseListTable event-tabl">
                <tr className="whitespaceNoWrap">
                  <th>
                    {t(eventTypeStatus, lan)} {t("title", lan)}{" "}
                  </th>
                  <th> {` ${t("DateCreated", lan)} `}</th>
                  <th>{t("Status", lan)}</th>
                  <th>{t("Action", lan)}</th>
                </tr>
                {paginationNewsData?.map((data, index) => {
                  return (
                    <>
                      <tr key={index} className="courseTableInfo">
                        <td className="eventName">
                          {data[`news_title_${lan}`] || "-"}
                        </td>
                        <td className="eventDate">
                          {formatDate(data?.created_date_time)}
                        </td>
                        <td>
                          <select
                            value={data?.news_status?.value}
                            onChange={(e) => onNewsStatusChange(e, data)}
                            className={
                              data?.news_status?.value == "Active"
                                ? "StatusActive"
                                : "StatusInActive"
                            }
                          >
                            <option value={"Active"}>{t("Active", lan)}</option>
                            <option value={"Inactive"}>
                              {t("Inactive", lan)}
                            </option>
                          </select>
                        </td>
                        <td className="whitespaceNoWrap">
                          {/* <Link
                            to={`/supplier/event/event-detail/${data?.uuid}`}
                            className="cursorPointer textPrimaryColor text-decoration-none"
                          >
                            {t("View", lan)}
                          </Link>{" "} */}
                          <span
                            className="cursorPointer textPrimaryColor"
                            onClick={() => {
                              onNewsEditClick(data);
                            }}
                          >
                            {t("Edit", lan)}
                          </span>{" "}
                          |{" "}
                          <span
                            className="cursorPointer textPrimaryColor"
                            onClick={() => {
                              setDeleteEventId(data?.uuid);
                              setPopupDelete("delete");
                            }}
                          >
                            {t("Delete", lan)}
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            ) : (
              <div>
                <NoData />
              </div>
            )}
          </div>
        ) : (
          <div className="courseListDiv">
            {paginationCourses?.length > 0 ? (
              <table className="courseListTable event-tabl">
                <tr className="whitespaceNoWrap">
                  <th>
                    {t(eventTypeStatus, lan)} {t("name", lan)}{" "}
                  </th>
                  <th> {` ${t("DateCreated", lan)} `}</th>
                  <th>{t("Status", lan)}</th>
                  <th>{t("Action", lan)}</th>
                </tr>
                {paginationCourses?.map((data, index) => {
                  return (
                    <>
                      <tr key={index} className="courseTableInfo">
                        <td className="eventName">{data?.event_name || "-"}</td>
                        <td className="eventDate">
                          {formatDate(data?.created_date_time)}
                        </td>
                        <td>
                          <select
                            value={data?.status?.value}
                            onChange={(e) => onStatusChange(e, data)}
                            className={
                              data?.status?.value == "Active"
                                ? "StatusActive"
                                : "StatusInActive"
                            }
                          >
                            <option value={"Active"}>{t("Active", lan)}</option>
                            <option value={"Inactive"}>
                              {t("Inactive", lan)}
                            </option>
                          </select>
                        </td>
                        <td className="whitespaceNoWrap">
                          <Link
                            to={`/supplier/event/event-detail/${data?.uuid}`}
                            className="cursorPointer textPrimaryColor text-decoration-none"
                            onClick={() => {
                              dispatch(selectedEventDetails(data));
                            }}
                          >
                            {t("View", lan)}
                          </Link>{" "}
                          |{" "}
                          <span
                            className="cursorPointer textPrimaryColor"
                            onClick={() => {
                              onEditClick(data);
                            }}
                          >
                            {t("Edit", lan)}
                          </span>{" "}
                          |{" "}
                          <span
                            className="cursorPointer textPrimaryColor"
                            onClick={() => {
                              setDeleteEventId(data?.uuid);
                              setPopupDelete("delete");
                            }}
                          >
                            {t("Delete", lan)}
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            ) : (
              <div>
                <NoData />
              </div>
            )}
          </div>
        )}

        <div className="mt-3 text-end">
          <div className="row">
            {filteredData?.length > 12 && (
              <CustomPagination
                startIndex={paginationStartIndex}
                endIndex={
                  paginationStartIndex + 11 > filteredData?.length
                    ? filteredData?.length
                    : paginationStartIndex + 11
                }
                totalData={filteredData?.length}
                onPrev={paginationPrev}
                onNext={paginationNext}
              />
            )}
          </div>
        </div>

        <SidePopup
          header={`${t("Add", lan)} ${t(eventTypeStatus.toLowerCase(), lan)}`}
          show={addAds}
          handleClose={handleClosePopup}
        >
          <div className="sidepopupContents">
            <div className="wrapper">
              <div>
                <input
                  accept=".jpeg,image/*,.png"
                  multiple={false}
                  onChange={(e) => {
                    loadFile(e);
                    setAddAdsDetails({
                      ...addAdsDetails,
                      ads_image: e.target.files[0],
                    });
                  }}
                  type="file"
                  id="file"
                />
                <label htmlFor="file">
                  <img src={UploadImage} id="output" alt="Upload Icon" />
                </label>
              </div>
              <div>
                <p className="smallText">
                  {` ${t("UploadBannerImages", lan)} `}{" "}
                  <span className="text-danger">*</span>
                </p>
              </div>
            </div>
            {imageError && <p className="errorText mb-0">{imageError}</p>}

            <div className="halfInputsDiv justify-content-between">
              <span>
                <label htmlFor="chooseType">
                  <p className="smallText inputText boldText pWithMargin">
                    {` ${t("ChooseType", lan)} `}
                  </p>
                </label>
                <input
                  disabled
                  className="formInputs"
                  id="chooseType"
                  value={t(eventTypeStatus, lan)}
                  onChange={(e) => {
                    setAddAdsDetails({
                      ...addAdsDetails,
                      Ads_type: e.target.value,
                    });
                  }}
                />
              </span>
              <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>
              <span>
                <label htmlFor="whichPage ">
                  <p className="smallText inputText boldText pWithMargin">
                    {" "}
                    {t("PublishOn", lan)}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <select
                  className="formInputs"
                  id="chooseCourseLength"
                  onChange={(e) => {
                    setAddAdsDetails({
                      ...addAdsDetails,
                      Ads_publish_on: e.target.value,
                    });
                    setEventShowPageError("");
                  }}
                >
                  <option selected value="Choose">
                    {" "}
                    {t("Choose", lan)}
                  </option>
                  <option value={"Landing page"}>
                    {t("LandingPage", lan)}
                  </option>
                  <option value={"User Dashboard"}>
                    {t("UserDashboard", lan)}
                  </option>
                  <option value={"Both"}>{t("Both", lan)}</option>
                </select>
                {eventShowPageError && (
                  <p className="errorText mb-0">{eventShowPageError}</p>
                )}
              </span>
            </div>

            <div hidden={eventTypeStatus === "Event" ? false : true}>
              <label htmlFor="courseName">
                <p className="smallText inputText boldText pWithMargin">
                  {` ${t("Choose", lan)} ${t("Category", lan)}  `}
                  <span className="text-danger">*</span>
                </p>
              </label>
              <select
                className="formInputs"
                id="chooseCourseLength"
                onChange={(e) => {
                  setAddAdsDetails({
                    ...addAdsDetails,
                    Ads_catagory: e.target.value,
                  });
                  setCategoryError("");
                }}
              >
                <option value={"Choose"}>{t("Choose", lan)}</option>

                {state?.CourseCategories?.map((res) => {
                  return (
                    <option value={res.category_name}>
                      {t(res.category_name, lan)}
                    </option>
                  );
                })}
              </select>
              {categoryError && (
                <p className="errorText mb-0">{categoryError}</p>
              )}
            </div>

            <label htmlFor="courseName">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t(eventTypeStatus, lan)} ${t("title", lan)} `}
                <span className="text-danger">*</span>
              </p>
            </label>
            <input
              required
              type="text"
              id="courseName"
              placeholder={` ${t("Enter", lan)} ${t(
                eventTypeStatus.toLowerCase(),
                lan
              )}  ${t("Name", lan)}`}
              className="formInputs"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  ads_name: e.target.value,
                });
                setNameError("");
              }}
            />
            {nameError && <p className="errorText mb-0">{nameError}</p>}

            <label htmlFor="courseOverview">
              <p className="smallText inputText boldText pWithMargin">
                {" "}
                {t("Description", lan)}
                <span className="text-danger">*</span>
              </p>
            </label>
            <textarea
              required
              rows={3}
              id="courseOverview"
              placeholder={t("EnterDescription", lan)}
              className="formInputs textArea"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  Ads_desc: e.target.value,
                });
                setDescError("");
              }}
            />
            {descError && <p className="errorText mb-0">{descError}</p>}

            <div hidden={eventTypeStatus === "Advertisement" ? true : false}>
              <label htmlFor="courseOverview">
                <p className="smallText inputText boldText pWithMargin">
                  {` ${t("Small", lan)} ${t("description", lan)}  `}
                </p>
              </label>
              <textarea
                required
                rows={3}
                id="courseOverview"
                placeholder={t("EnterDescription", lan)}
                className="formInputs textArea"
                onChange={(e) => {
                  setAddAdsDetails({
                    ...addAdsDetails,
                    Ads_small_desc: e.target.value,
                  });
                }}
              />
            </div>

            <label htmlFor="bannerLink">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("EnterVideolink", lan)} `}
              </p>
            </label>
            <input
              type="text"
              id="bannerLink"
              placeholder={`${t("VLink", lan)}`}
              className="formInputs"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  Ads_banner_link: e.target.value,
                });
              }}
            />
            <label htmlFor="bannerLinkOther">
              <p className="smallText inputText boldText pWithMargin">
                {`${eventTypeStatus == "Event" ? "Checkout" : eventTypeStatus}`}{" "}
                {`${t("Link", lan)} (${t("optional", lan)})`}
              </p>
            </label>
            <input
              type="text"
              id="bannerLinkOther"
              placeholder={`${t("EnterLink", lan)}`}
              className="formInputs"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  Ads_checkout_link: e.target.value,
                });
              }}
            />
            <div hidden={eventTypeStatus == "Event" ? false : true}>
              <div className="halfInputsDiv justify-content-between">
                <span className="date-box">
                  <label htmlFor="chooseCourseDate ">
                    <p className="smallText inputText boldText pWithMargin">
                      {" "}
                      {t("EventStartDate", lan)}
                      <span className="text-danger">*</span>
                    </p>
                  </label>
                  <input
                    type="Date"
                    name="input_date"
                    min={new Date().toISOString().split("T")[0]}
                    id="chooseCourseDate"
                    placeholder={datePlaceholder.toUpperCase()}
                    className="formInputs"
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        start_date: e.target.value,
                      });
                      setStartDateError("");
                    }}
                  />
                  {startDateError && (
                    <p className="errorText mb-0">{startDateError}</p>
                  )}
                </span>
                <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>

                <span className="date-box">
                  <label htmlFor="chooseCourseEndDate ">
                    <p className="smallText inputText boldText pWithMargin">
                      {" "}
                      {t("EventEndDate", lan)}
                    </p>
                  </label>
                  <input
                    type="Date"
                    name="input_date"
                    // min={new Date(addAdsDetails?.start_date || '').toISOString().split("T")[0]}
                    id="chooseCourseEndDate"
                    placeholder={datePlaceholder?.toUpperCase()}
                    className="formInputs"
                    min={
                      addAdsDetails?.start_date
                        ? new Date(addAdsDetails?.start_date)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        end_date: e.target.value,
                      });
                      setStartDateError("");
                    }}
                  />
                </span>
              </div>
              <span>
                <label htmlFor="startTime">
                  <p className="smallText inputText boldText pWithMargin">
                    {t("EventStartTime", lan)}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <input
                  type="time"
                  step="1"
                  value={addAdsDetails?.start_time}
                  id="startTime"
                  placeholder={t("EventStartTime", lan)}
                  className="formInputs"
                  onChange={(e) => {
                    setAddAdsDetails({
                      ...addAdsDetails,
                      start_time: e.target.value,
                    });
                    setTimeError("");
                  }}
                />
                {startTimeError && (
                  <p className="errorText mb-0">{startTimeError}</p>
                )}
              </span>

              <div className="halfInputsDiv mb-3">
                <span>
                  <label htmlFor="chooseFeeType ">
                    <p className="smallText inputText boldText pWithMargin">
                      {t("ChooseEventFeeType", lan)}
                      <span className="text-danger">*</span>
                    </p>
                  </label>
                  <div className="radioDiv smallText mb-3">
                    <input
                      type="radio"
                      name="chooseFeeType"
                      value="Free"
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            fees_type: e.target.value,
                          });
                      }}
                    />
                    <span className="radioCustom"></span> {t("Free", lan)}
                    <input
                      type="radio"
                      name="chooseFeeType"
                      value="Paid"
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            fees_type: e.target.value,
                          });
                      }}
                    />
                    <span className="radioCustom"></span> {t("Paid", lan)}
                  </div>

                  <input
                    hidden={
                      !addAdsDetails.fees_type ||
                      addAdsDetails.fees_type == "Free"
                        ? true
                        : false
                    }
                    type="number"
                    id=""
                    placeholder={`${t("EnterOriginalPrice", lan)} `}
                    className="formInputs"
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        event_price: e.target.value,
                      });
                      setOriPriceError("");
                    }}
                  />
                  {oriPriceError && (
                    <p className="errorText mb-0">{oriPriceError}</p>
                  )}
                  {/* <input
                    hidden={
                      !addAdsDetails.fees_type ||
                      addAdsDetails.fees_type == "Free"
                        ? true
                        : false
                    }
                    type="number"
                    id="chooseEventType"
                    placeholder={` ${t("EnterOfferPrice", lan)} `}
                    className="formInputs w-50 mt-3"
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        offer_price: e.target.value,
                      });
                      setPriceError("");
                    }}
                  />

                   */}
                  {priceError && <p className="errorText mb-0">{priceError}</p>}
                </span>
                <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>
                <span>
                  <label htmlFor="chooseEventType ">
                    <p className="smallText inputText boldText pWithMargin">
                      {t("ChooseEventType", lan)}
                      <span className="text-danger">*</span>
                    </p>
                  </label>
                  <div className="radioDiv smallText mb-3">
                    <input
                      type="radio"
                      name="chooseEventType"
                      value="Online"
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            event_type: e.target.value,
                          });
                        setOnlineOfflineError("");
                      }}
                    />
                    <span className="radioCustom "></span>
                    {t("Online", lan)}
                    <input
                      type="radio"
                      name="chooseEventType"
                      value="Offline"
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            event_type: e.target.value,
                          });
                        setOnlineOfflineError("");
                      }}
                    />
                    <span className="radioCustom "></span>
                    {t("Physical", lan)}
                  </div>
                  <input
                    type="text"
                    id="chooseEventType"
                    placeholder={t("EnterEventLocation", lan)}
                    className="formInputs "
                    hidden={
                      addAdsDetails.event_type !== null
                        ? addAdsDetails.event_type !== "Online"
                          ? false
                          : true
                        : false
                    }
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        event_location: e.target.value,
                      });
                    }}
                  />

                  {/* // if online selected share zoom link  */}
                  <input
                    type="text"
                    placeholder={t("EnterMeetingLink", lan)}
                    className="formInputs "
                    hidden={
                      addAdsDetails.event_type !== null
                        ? addAdsDetails.event_type == "Online"
                          ? false
                          : true
                        : true
                    }
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        zoom_link: e.target.value,
                      });
                    }}
                  />

                  <input
                    type="text"
                    placeholder={t("Passcode_optional", lan)}
                    className="formInputs  mt-3"
                    hidden={
                      addAdsDetails.event_type !== null
                        ? addAdsDetails.event_type == "Online"
                          ? false
                          : true
                        : true
                    }
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        zoom_password: e.target.value,
                      });
                    }}
                  />

                  {onlineOfflineError && (
                    <p className="errorText mb-0">{onlineOfflineError}</p>
                  )}
                </span>
              </div>
              {/*pricing */}

              <div className="halfInputsDiv">
                <div className="form-check mt-3 w-50">
                  <input
                    className="form-check-input"
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        is_featured: e.target.checked,
                      });
                    }}
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {` ${t("BannerPublistion", lan)}`} ?
                  </label>
                </div>
                <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>
              </div>
            </div>

            <button
              className="primaryButton"
              disabled={loderBtn}
              onClick={() => {
                onAddAdsClick("add");
              }}
            >
              {loderBtn ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                `${t("Add", lan)}  ${
                  eventTypeStatus == "Advertisement"
                    ? `${t("advertisement", lan)}`
                    : `${t("event", lan)}`
                }`
              )}
            </button>
          </div>
        </SidePopup>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>

        {/* EdditPopup Starts From here */}
        <SidePopup
          header={
            lan == "sw" && eventTypeStatus == "Event"
              ? `${t(eventTypeStatus, lan)}`
              : `${t("Update", lan)} ${t(eventTypeStatus, lan)}`
          }
          show={editAds}
          handleClose={handleClosePopup}
        >
          <div className="sidepopupContents">
            <label>
              <span className="customeFileUpload">
                <img
                  src={
                    getEditAdsData?.event_image !== null
                      ? `${getEditAdsData?.event_image}`
                      : getEditAdsData.event_image !== null
                      ? getEditAdsData.event_image
                      : UploadImage
                  }
                  className="uploadImage"
                  id="output"
                />

                <p className="smallText">
                  {" "}
                  {` ${t("UploadBannerImages", lan)}`}{" "}
                </p>

                <input
                  type="file"
                  accept=".jpeg,image/*,.png"
                  className="inputTypeFile"
                  multiple={false}
                  onChange={(e) => {
                    loadFile(e);
                    setAddAdsDetails({
                      ...addAdsDetails,
                      ads_image: e.target.files[0],
                    });
                  }}
                />
              </span>
            </label>

            <div className="halfInputsDiv justify-content-between">
              <span>
                <label htmlFor="chooseType">
                  <p className="smallText inputText boldText pWithMargin">
                    {` ${t("Choose", lan)} ${t("Type", lan).toLowerCase()} `}
                  </p>
                </label>
                <select
                  disabled
                  className="formInputs"
                  id="chooseType"
                  value={addAdsDetails?.Ads_type || "Choose"}
                  onChange={(e) => {
                    setAddAdsDetails({
                      ...addAdsDetails,
                      Ads_type: e.target.value,
                    });
                  }}
                >
                  <option value="Choose">{` ${t("Choose", lan)} ${t(
                    "Type",
                    lan
                  )} `}</option>
                  <option value={"Advertisement"}>
                    {t("Advertisement", lan)}{" "}
                  </option>
                  <option value={"Event"}>{t("Event", lan)}</option>
                </select>
              </span>
              <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>
              <span>
                <label htmlFor="whichPage ">
                  <p className="smallText inputText boldText pWithMargin">
                    {` ${t("PublishOn", lan)}`}{" "}
                  </p>
                </label>
                <select
                  className="formInputs"
                  id="chooseCourseLength"
                  value={addAdsDetails?.Ads_publish_on || "Choose"}
                  onChange={(e) => {
                    setAddAdsDetails({
                      ...addAdsDetails,
                      Ads_publish_on: e.target.value,
                    });
                  }}
                >
                  <option value={"Landing page"}>
                    {t("LandingPage", lan)}
                  </option>
                  <option value={"User Dashboard"}>
                    {t("UserDashboard", lan)}
                  </option>
                  <option value={"Both"}>{t("Both", lan)}</option>
                </select>
                {eventShowPageError && (
                  <p className="errorText mb-0">{eventShowPageError}</p>
                )}
              </span>
            </div>

            <div hidden={eventTypeStatus == "Event" ? false : true}>
              <label htmlFor="courseName">
                <p className="smallText inputText boldText pWithMargin">
                  {` ${t("Choose", lan)} ${t("Category", lan).toLowerCase()} `}
                </p>
              </label>
              <select
                className="formInputs"
                id="chooseCourseLength"
                value={addAdsDetails?.Ads_catagory || "Choose"}
                onChange={(e) => {
                  setAddAdsDetails({
                    ...addAdsDetails,
                    Ads_catagory: e.target.value,
                  });
                }}
              >
                <option value={"Choose"}>Choose</option>
                {state?.CourseCategories?.map((res) => {
                  return (
                    <option value={res.category_name}>
                      {t(res.category_name, lan)}
                    </option>
                  );
                })}
              </select>
              {categoryError && (
                <p className="errorText mb-0">{categoryError}</p>
              )}
            </div>

            <label htmlFor="courseName">
              <p className="smallText inputText boldText pWithMargin">
                {" "}
                {eventTypeStatus == "Advertisement"
                  ? ` ${t(eventTypeStatus, lan)} ${t("Title", lan)} `
                  : ` ${t("EventTitle", lan)} `}
              </p>
            </label>
            <input
              type="text"
              value={addAdsDetails?.ads_name || ""}
              id="courseName"
              placeholder={`${t("Name", lan)}`}
              className="formInputs"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  ads_name: e.target.value,
                });
              }}
            />
            {nameError && <p className="errorText mb-0">{nameError}</p>}

            <label htmlFor="courseOverview">
              <p className="smallText inputText boldText pWithMargin">
                {/* string.charAt(0).toUpperCase() + string.slice(1); */}
                {` ${
                  t("Description", lan).charAt(0).toUpperCase() +
                  t("Description", lan).slice(1)
                } `}
              </p>
            </label>
            <textarea
              rows={3}
              id="courseOverview"
              value={addAdsDetails?.Ads_desc}
              placeholder={` ${t("Ad", lan)} ${t("description", lan)} `}
              className="formInputs textArea"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  Ads_desc: e.target.value,
                });
              }}
            />

            {descError && <p className="errorText mb-0">{descError}</p>}

            <div hidden={eventTypeStatus === "Advertisement" ? true : false}>
              <label htmlFor="courseOverview">
                <p className="smallText inputText boldText pWithMargin">
                  {` ${t("Small", lan)} ${t("description", lan)} `}
                </p>
              </label>
              <textarea
                required
                rows={3}
                id="courseOverview"
                value={addAdsDetails?.Ads_small_desc}
                placeholder={t("Description", lan)}
                className="formInputs textArea"
                onChange={(e) => {
                  setAddAdsDetails({
                    ...addAdsDetails,
                    Ads_small_desc: e.target.value,
                  });
                }}
              />
            </div>

            <label htmlFor="bannerLink">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("EnterVideolink", lan)}`}
              </p>
            </label>
            <input
              type="text"
              id="bannerLink"
              value={addAdsDetails?.Ads_banner_link}
              placeholder="Video Link"
              className="formInputs"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  Ads_banner_link: e.target.value,
                });
              }}
            />
            <label htmlFor="bannerLinkOther">
              <p className="smallText inputText boldText pWithMargin">
                {`${
                  eventTypeStatus === "Event"
                    ? "Checkout"
                    : t("Advertisement", lan)
                }`}{" "}
                {t("Link", lan)} ({t("optional", lan)})
              </p>
            </label>
            <input
              type="text"
              id="bannerLinkOther"
              value={addAdsDetails?.Ads_checkout_link}
              placeholder={`${t("Video", lan)}  ${t("Link", lan)}`}
              className="formInputs"
              onChange={(e) => {
                setAddAdsDetails({
                  ...addAdsDetails,
                  Ads_checkout_link: e.target.value,
                });
              }}
            />
            <div hidden={eventTypeStatus === "Event" ? false : true}>
              <div className="halfInputsDiv justify-content-between">
                <span className="date-box">
                  <label htmlFor="chooseCourseDate ">
                    <p className="smallText inputText boldText pWithMargin">
                      {` ${t("Event", lan)} ${t("StartDate", lan)}`}
                    </p>
                  </label>
                  <input
                    type="Date"
                    min={new Date().toISOString().split("T")[0]}
                    id="chooseCourseDate"
                    value={addAdsDetails?.start_date}
                    placeholder={datePlaceholder.toUpperCase()}
                    className="formInputs"
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        start_date: e.target.value,
                      });
                    }}
                  />
                  {startDateError && (
                    <p className="errorText mb-0">{startDateError}</p>
                  )}
                </span>
                <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>
                <span className="date-box">
                  <label htmlFor="chooseCourseEndDate ">
                    <p className="smallText inputText boldText pWithMargin">
                      {" "}
                      {t("EventEndDate", lan)}
                    </p>
                  </label>
                  <input
                    type="Date"
                    name="input_date"
                    // min={new Date(addAdsDetails?.start_date).toISOString().split("T")[0]}
                    id="chooseCourseEndDate"
                    placeholder={datePlaceholder.toUpperCase()}
                    className="formInputs"
                    value={addAdsDetails?.end_date}
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        end_date: e.target.value,
                      });
                      setStartDateError("");
                    }}
                  />
                </span>
              </div>
              <span>
                <label htmlFor="startTime">
                  <p className="smallText inputText boldText pWithMargin">
                    {` ${t("Event", lan)} ${t("StartTime", lan)}`}
                  </p>
                </label>
                <input
                  type="time"
                  step="1"
                  value={addAdsDetails?.start_time}
                  id="startTime"
                  placeholder={` ${t("Event", lan)} ${t("Start", lan)}  ${t(
                    "Time",
                    lan
                  )}`}
                  className="formInputs"
                  onChange={(e) => {
                    setAddAdsDetails({
                      ...addAdsDetails,
                      start_time: e.target.value,
                    });
                  }}
                />
                {startTimeError && (
                  <p className="errorText mb-0">{startTimeError}</p>
                )}
              </span>
              <div className="halfInputsDiv mb-3">
                <span>
                  <label htmlFor="chooseFeeType ">
                    <p className="smallText inputText boldText pWithMargin">
                      {` ${t("Choose", lan)} ${t("Event", lan)}  ${t(
                        "FeeType",
                        lan
                      )}`}
                    </p>
                  </label>
                  <div className="radioDiv smallText mb-3">
                    <input
                      type="radio"
                      name="chooseFeeType"
                      value="Free"
                      checked={
                        addAdsDetails?.fees_type === "Free" ? true : false
                      }
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            fees_type: e.target.value,
                          });
                      }}
                    />
                    <span className="radioCustom"></span>
                    {t("Free", lan)}
                    <input
                      type="radio"
                      name="chooseFeeType"
                      value="Paid"
                      checked={
                        addAdsDetails?.fees_type === "Paid" ? true : false
                      }
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            fees_type: e.target.value,
                          });
                      }}
                    />
                    <span className="radioCustom"></span>
                    {t("Paid", lan)}
                  </div>
                  {/* <input
                    type="number"
                    id=""
                    placeholder="Enter Event Price "
                    className="formInputs"
                    value={
                      addAdsDetails.event_price === null
                        ? ""
                        : addAdsDetails.event_price
                    }
                    disabled={
                      addAdsDetails.fees_type !== null
                        ? addAdsDetails.fees_type !== "Free"
                          ? false
                          : true
                        : true
                    }
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        event_price: e.target.value,
                      });
                      setPriceError("");
                    }}
                  /> */}
                  <input
                    hidden={
                      !addAdsDetails.fees_type ||
                      addAdsDetails.fees_type === "Free"
                        ? true
                        : false
                    }
                    type="number"
                    id=""
                    placeholder={` ${t("EnterOriginalPrice", lan)} `}
                    className="formInputs"
                    value={
                      !addAdsDetails?.event_price
                        ? ""
                        : addAdsDetails?.event_price
                    }
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        event_price: e.target.value,
                      });
                      setOriPriceError("");
                    }}
                  />{" "}
                  {oriPriceError && (
                    <p className="errorText mb-0">{oriPriceError}</p>
                  )}
                  {/* <input
                    hidden={
                      !addAdsDetails.fees_type ||
                        addAdsDetails.fees_type === "Free"
                        ? true
                        : false
                    }
                    type="number"
                    id="chooseEventType"
                    placeholder={`${t("EnterOfferPrice", lan)}`}
                    className="formInputs w-50 mt-3"
                    value={
                      addAdsDetails.offer_price === null
                        ? ""
                        : addAdsDetails.offer_price
                    }

                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        offer_price: e.target.value,
                      });
                      setPriceError("");
                    }}
                  />
                  {priceError && <p className="errorText mb-0">{priceError}</p>} */}
                  {priceError && <p className="errorText mb-0">{priceError}</p>}
                </span>
                <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>
                <span>
                  <label htmlFor="chooseEventType ">
                    <p className="smallText inputText boldText pWithMargin">
                      {` ${t("Choose", lan)} ${t("Event", lan)} ${t(
                        "Type",
                        lan
                      )}`}
                    </p>
                  </label>
                  <div className="radioDiv smallText mb-3">
                    <input
                      type="radio"
                      name="chooseEventType"
                      value="Online"
                      checked={
                        addAdsDetails?.event_type == "Online" ? true : false
                      }
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            event_type: e.target.value,
                          });
                      }}
                    />
                    <span className="radioCustom "></span>
                    {t("Online", lan)}
                    <input
                      type="radio"
                      name="chooseEventType"
                      value="Physical"
                      checked={
                        addAdsDetails?.event_type == "Physical" ? true : false
                      }
                      className="mx-2"
                      onChange={(e) => {
                        e.target.checked &&
                          setAddAdsDetails({
                            ...addAdsDetails,
                            event_type: e.target.value,
                          });
                      }}
                    />
                    {onlineOfflineError && (
                      <p className="errorText mb-0">{onlineOfflineError}</p>
                    )}
                    <span className="radioCustom "></span>
                    {t("Physical", lan)}
                  </div>
                  {addAdsDetails.event_type == "Online" ? (
                    <input
                      type="text"
                      placeholder="Enter Meeting Link"
                      className="formInputs "
                      value={addAdsDetails?.zoom_link}
                      hidden={
                        addAdsDetails.event_type !== null
                          ? addAdsDetails.event_type == "Online"
                            ? false
                            : true
                          : true
                      }
                      onChange={(e) => {
                        setAddAdsDetails({
                          ...addAdsDetails,
                          zoom_link: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      id="chooseEventType"
                      value={addAdsDetails?.event_location}
                      placeholder={` ${t("Enter", lan)} ${t("Event", lan)} ${t(
                        "EventLocation",
                        lan
                      )}`}
                      className="formInputs"
                      onChange={(e) => {
                        setAddAdsDetails({
                          ...addAdsDetails,
                          event_location: e.target.value,
                        });
                      }}
                    />
                  )}
                </span>
              </div>

              <div className="halfInputsDiv">
                <div className="form-check mt-3 w-50">
                  <input
                    className="form-check-input"
                    checked={addAdsDetails?.is_featured}
                    onChange={(e) => {
                      setAddAdsDetails({
                        ...addAdsDetails,
                        is_featured: e.target.checked,
                      });
                    }}
                    type="checkbox"
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {` ${t("BannerPublistion", lan)}`} ?
                  </label>
                </div>
                <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>

                {/* <input
                type="text"
                placeholder="Passcode (optional) "
                value={addAdsDetails?.zoom_password}
                className="formInputs w-50"
                hidden={
                  addAdsDetails.event_type !== null
                    ? addAdsDetails.event_type == "Online"
                      ? false
                      : true
                    : true
                }
                onChange={(e) => {
                  setAddAdsDetails({
                    ...addAdsDetails,
                    zoom_password: e.target.value,
                  });
                }}
              /> */}
              </div>
            </div>

            <button
              className="primaryButton"
              disabled={loderBtn}
              onClick={() => {
                onAddAdsClick("edit", getEditAdsData?.uuid);
              }}
            >
              {loderBtn ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                `${t("Update", lan)}  ${
                  eventTypeStatus == "Advertisement"
                    ? `${t("advertisement", lan)}`
                    : `${t("event", lan)}`
                }`
              )}
            </button>
          </div>
        </SidePopup>

        <Popup
          show={popupDelete !== "" ? true : false}
          header={`${t("DeletePopUp", lan)} `}
          handleClose={handleClosePopupStatus}
          className="MB-3"
        >
          <div className="popupinfo">{/* <p>{popupStatus}</p> */}</div>
          <div className="row justify-content-around w-100 mt-4 mb-3">
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase  mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("CancleText", lan)}
            </button>

            <button
              onClick={
                eventTypeStatus === "News" ? onDeleteNews : onDeleteCourse
              }
              className="btn btn-danger text-uppercase  mt-2 col-md-5 col-sm-5 col-xs-12 "
            >
              {t("DeleteText", lan)}
            </button>
          </div>
        </Popup>

        <Popup
          show={activeInactivePopup?.value}
          header="Status"
          handleClose={handleClosePopupStatus}
          className="MB-3"
        >
          <div className="popupinfo">
            <p>{activeInactivePopup?.data}</p>

            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>

        {/* news popup  */}
        <SidePopup
          header={
            addNewsData.type === "Update"
              ? t("UpdateNews", lan)
              : `${t(addNewsData.type, lan)} ${t("news", lan)}`
          }
          show={addNewsData.value}
          handleClose={handleCloseNewsPopup}
        >
          <div className="sidepopupContents">
            <div className="wrapper">
              <div>
                <input
                  accept="image/*"
                  multiple={false}
                  onChange={(e) => {
                    if (e.target.files[0].type == "") return;
                    loadFile(e, "newsFile");
                    handleChangeNews("news_image", e.target.files[0]);
                  }}
                  id="file"
                  type="file"
                  capture="camera"
                />
                <label htmlFor="file">
                  <img
                    src={
                      addNewsData?.blob_img ||
                      addNewsData?.news_image ||
                      UploadImage
                    }
                    id="newsFile"
                    alt="Upload Icon"
                    className="img-fluid"
                  />
                </label>
              </div>
              <div>
                <p className="smallText">
                  {` ${t("UploadBannerImages", lan)} `}{" "}
                  <span className="text-danger">*</span>
                </p>
              </div>
            </div>
            {newsError.news_image && (
              <p className="errorText mb-0">{newsError.news_image}</p>
            )}

            <label htmlFor="newsName">
              <p className="smallText inputText boldText pWithMargin">
                {" "}
                {t("NewsTitleEn", lan)}
                <span className="text-danger">*</span>
              </p>
            </label>
            <input
              type="text"
              value={addNewsData.news_title_en || ""}
              id="newsName"
              placeholder={`${t("EnterNewsTitleEn", lan)}`}
              className="formInputs"
              onChange={(e) =>
                handleChangeNews("news_title_en", e.target.value)
              }
            />
            {newsError.news_title_en && (
              <p className="errorText mb-0">{newsError.news_title_en}</p>
            )}

            <label htmlFor="smallDesc">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("Small", lan)} ${t("description", lan)} `}
              </p>
            </label>
            <textarea
              required
              rows={3}
              id="smallDesc"
              placeholder={t("Description", lan)}
              className="formInputs textArea"
              value={addNewsData.news_short_description_en || ""}
              onChange={(e) => {
                handleChangeNews("news_short_description_en", e.target.value);
              }}
            />
            {/* <div className="richtext">
              <CKEditor
                placeholder={t("Description", lan)}
                className="formInputs textArea"
                id="courseOverview"
                data={addNewsData.news_short_description_en || ""}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChangeNews("news_short_description_en", data);
                }}
              />
            </div> */}

            {newsError.news_short_description_en && (
              <p className="errorText mb-0">
                {newsError.news_short_description_en}
              </p>
            )}
            <div>
              <label htmlFor="newsName">
                <p className="smallText inputText boldText pWithMargin">
                  {" "}
                  {t("NewsDescTitleEn", lan)}
                  <span className="text-danger">*</span>
                </p>
              </label>
              <input
                type="text"
                value={addNewsData.news_detailed_title_en || ""}
                id="newsName"
                placeholder={`${t("EnterNewsDescTitleEn", lan)}`}
                className="formInputs"
                onChange={(e) =>
                  handleChangeNews("news_detailed_title_en", e.target.value)
                }
              />
              {newsError.news_detailed_title_en && (
                <p className="errorText mb-0">
                  {newsError.news_detailed_title_en}
                </p>
              )}

              <label htmlFor="courseOverview">
                <p className="smallText inputText boldText pWithMargin">
                  {t("Description", lan)}
                  <span className="text-danger">*</span>
                </p>
              </label>
              <div className="richtext">
                <CKEditor
                  placeholder={t("EnterContent", lan)}
                  className="formInputs textArea"
                  id="courseOverview"
                  data={addNewsData.news_detailed_description_en || ""}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeNews("news_detailed_description_en", data);
                  }}
                />
              </div>
            </div>
            {newsError.news_detailed_description_en && (
              <p className="errorText mb-0">
                {newsError.news_detailed_description_en}
              </p>
            )}

            <button
              className="primaryButton"
              disabled={loderBtn}
              onClick={handleNewsAdd}
            >
              {loderBtn ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                `${t(addNewsData.type, lan)} ${t("news", lan)}`
              )}
            </button>
          </div>
        </SidePopup>
        {isLoader ? <Loader /> : ""}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default EventList;
