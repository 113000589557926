import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "../ProfileMain.scss";
// import CountryCodeSelect from "../../../components/CountryCodeSelect/CountryCodeSelect";
import storage from "redux-persist/lib/storage";
import { useDispatch, useSelector } from "react-redux";
import { additionalInfoData, isApproved, logout, tabPageNo } from "../../../redux/actions";
import Placeholder from "../../../assets/images/placeholder.svg";
// import Select from "react-select";
import IcCamera from "../../../assets/images/IcCamera.svg";
import API from "../../../api";
import {
  organizationEditApi,
  postOrganizationEditApi,
} from "../../../services/supplierService";
import { useHistory } from "react-router-dom";
import Popup from "../../../components/popup/popup";
import { getTranslatedText as t } from "../../../translater";

export const AdditionalInformation = forwardRef((props, ref) => {
  const [popupStatus, setPopupStatus] = useState("");
  let [imageError, setImageError] = useState(null);
  const state = useSelector((state) => state.Eddi);
  const isCorporate = state?.UserDetail?.is_corporate

  const [icImage, setIcImage] = useState({
    image: state?.additionalInfoData?.orgImage,
    upload_image: state?.additionalInfoData?.upload_image,
  });
  const [profileError, setProfileError] = useState("");
  const [reviewMsg, setReviewMsg] = useState(false);
  const [loderBtn, setLoderBtn] = useState(false);
  let lan = state?.language;
  const history = useHistory();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    async childFunction1() {
      let result = await checkValid();
      return result;
    },
  }));

  const checkValid = () => {
    if (!icImage?.image) {
      setImageError("* Image is Required !");
      return false;
    } else {
      const orgImage = {
        orgImage: icImage?.image,
        upload_image: icImage?.upload_image,
      };

      dispatch(additionalInfoData(orgImage));
      setImageError();
      return true;
    }
  };

  const preventScroll = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  };

  useEffect(() => {
    if (imageError == null) {
      submitInfo();
    }
  }, [imageError, icImage]);

  const submitInfo = () => {
    const orgImage = {
      orgImage: icImage?.image,
      upload_image: icImage?.upload_image,
    };

    dispatch(additionalInfoData(orgImage));
  };

  const [stepClick, setStepCkick] = useState();
  // const [personalNoError, setPersonalNoError] = useState("");
  // const [mobileNoError, setMobileNoError] = useState("");

  function loadFile(event) {
    let src = URL.createObjectURL(event.target.files[0]);

    setIcImage({
      ...icImage,
      image: event?.target?.files[0],
      upload_image: src,
    });
  }

  const onSubmitClick = async () => {
    setReviewMsg(true);

    let options = state?.courseCategoryData?.optionVal;

    let selectCat = [];
    options.map((val) => selectCat.push(val.value));
    imageError = null;
    if (icImage.image === null) {
      setImageError("* Please Select First ");
    } else {
      setImageError(null);
      var bodyFormData = new FormData();
      bodyFormData.append(
        "organizational_name",
        state.organizationalData.orgName
      );
      isCorporate &&  bodyFormData.append(
        "corporate_code",
        state.organizationalData.corporateCode
      );
      bodyFormData.append(
        "organization_address",
        state.organizationalData.orgAddress
      );
      bodyFormData.append(
        "organization_website",
        state.organizationalData.website
      );
      bodyFormData.append("country", state.organizationalData?.country);
      bodyFormData.append("city", state.organizationalData?.city);
      bodyFormData.append("brif_information", state.organizationalData?.brief);
      bodyFormData.append(
        "organization_email",
        state.organizationalData?.email
      );
      bodyFormData.append(
        "organization_phone_number",
        state.organizationalData?.phone
      );
      bodyFormData.append(
        "contact_person",
        state.organizationalData?.contactperson
      );

      bodyFormData.append("course_category", selectCat);
      bodyFormData.append("sub_category", state?.courseCategoryData?.sub_cat);
      bodyFormData.append(
        "organization_logo",
        state.additionalInfoData?.orgImage
      );
      setLoderBtn(true);
      if (state.UserDetail.is_first_time_login === false) {
        organizationEditApi(bodyFormData)
          .then((res) => {
            setLoderBtn(false);
            if (res?.status === "success") {
              setPopupStatus(lan == "en" ? res?.data : res?.data_sv);
              setProfileError(res.data);
              dispatch(isApproved(false))
              preventScroll();
            } else {
              setProfileError(res.data);
              preventScroll();
            }
          })
          .catch((err) => {
            setLoderBtn(false);
            console.log(err.data);
            setProfileError(err.data);
          });
      } else {
        postOrganizationEditApi(bodyFormData)
          .then((res) => {
            setLoderBtn(false);
            if (res?.status === "success") {
              setPopupStatus(lan == "en" ? res?.data : res?.data_sv);
              preventScroll();
            } else {
              preventScroll();
            }
          })
          .catch((err) => {
            setLoderBtn(false);
            console.log(err.data);
            setProfileError(err.data);
          });
      }
    }
  };

  const handleClosePopupStatus = () => {
    if (state.is_approved) {
      const body = document.querySelector("body");
      body.style.overflow = "auto";
      history.push(`/supplier/dashboard`);
      setPopupStatus("");
    } else {
      const body = document.querySelector("body");
      body.style.overflow = "auto";
      setTimeout(() => {
        localStorage.clear();
        storage.removeItem("persist:root");
        dispatch(logout(lan));
        history.push("/");
      }, 1000);
    }
  };

  return (
    <>
      <div className="all-tab all-tab2 all-tab3 mt-5">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2>
              {/* {t("You", lan)} {t("Are", lan)} {t("all", lan)} {t("Set", lan)}{" "}
              {t("Now", lan)} ! */}
              {t("YouAreAllSetNow", lan)}
            </h2>
            <div className="uploadImage">
              <div className="position-relative d-inline-block">
                <div>
                  <div className="supplier-photo">
                    <img
                      id="output"
                      alt="Profile-Image"
                      src={
                        icImage?.upload_image
                          ? icImage.upload_image
                          : icImage?.image
                          ? `${icImage.image}`
                          : Placeholder
                      }
                    />
                  </div>
                  <div className="image-upload">
                    <label for="file-input">
                      <img src={IcCamera} />
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={loadFile}
                    />
                    {/* <input type="file" accept="image/*" onChange={loadFile} /> */}

                    {/* <input id="file-input" type="file" /> */}
                  </div>
                </div>
              </div>
            </div>
            <h4 className="text-center">
              {" "}
              {t("Upload", lan)} {t("organization", lan)} {t("Logo", lan)}
            </h4>
            {imageError && <p className="errorText mb-0">{imageError}</p>}
          </div>
        </div>

        <div className="text-end">
          <button
            onClick={onSubmitClick}
            className="btn btn-next checbox mb-3"
            disabled={loderBtn}
            id="next-1"
          >
            {loderBtn ? (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              t("submit", lan)
            )}
          </button>
          {/* {state.UserDetail.is_first_time_login === true &&
          reviewMsg === true ? (
            <p className="errorText mb-0">
              Your profile is under review, you will be logged out now
            </p>
          ) : (
            ""
          )} */}
        </div>
      </div>
      <Popup
        show={popupStatus !== "" ? true : false}
        header={"Status"}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupStatus}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>
    </>
  );
});
export default AdditionalInformation;
