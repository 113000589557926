import React, { useEffect, useState } from "react";
import "./Category.scss";
import "../../../common/styles/globalStyles.scss";
import { FaFilter, FaSortAmountUp } from "react-icons/fa";
import SidePopup from "../../../components/sidePopup/sidePopup";
import UploadImage from "../../../assets/images/uploadImage.png";
import { Link } from "react-router-dom";
import {
  deleteSubCategory,
  editSubCategory,
  getCategoryApi,
  getSubCategoryApi,
  postSubCategory,
} from "../../../services/supplierService";
import { useDispatch, useSelector } from "react-redux";
import { courseCategories, courseSubCategories } from "../../../redux/actions";
import API from "../../../api";
import Popup from "../../../components/popup/popup";
import { getTranslatedText as t } from "../../../translater/index";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import placeholder from "../../../assets/images/placeholder.svg";
import NoData from "../../../components/NoData/NoData";
import InputText from "../../../components/inputText/inputText";
import IcSearch from "../../../assets/images/icSearch.svg";
import Loader from "../../../components/Loader/Loader";
import {formatDate} from "../../../services/constant";

function Category() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const [addCategoryPopup, setAddCategoryPopup] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [editCategory, setEditCategory] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isLoader,setIsLoader]= useState(false)
  const [filteredCat, setFilteredCat] = useState(state?.CourseCategories);
  const [paginationCourses, setPaginationCourses] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const role = state?.UserDetail?.user_type;
  const [isAdmin, setIsAdmin] = useState(role == "Admin" ? true : false);
  const [imageError, setImageError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");

  useEffect(() => {
    setAllCategories(state.CourseCategories);
  }, [state]);

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredCat?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationCourses(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredCat]);

//   const subCategoryCall = () => {
//     setIsLoader(true)
//     getSubCategoryApi()
//       .then((res) => {
//         setIsLoader(false)
//         if (res.status == "success") {
//           dispatch(courseSubCategories(res.data));
//         } else {
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

  const categoryCall = () => {
    setIsLoader(true)
    getCategoryApi()
      .then((res) => {
        if (res.status == "success") {
        //   setAllSubCategories(res?.data)
        setIsLoader(false)
          dispatch(courseCategories(res.data));
        } else {
          // alert("error Occured")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // subCategoryCall();
    categoryCall();
  }, []);

  useEffect(() => {
    if (categoryImage !== null && categoryImage !== undefined) {
      let image = document.getElementById("output");
      image.src = URL.createObjectURL(categoryImage);
    }
  }, [categoryImage]);

  const addSubCategory = () => {
    let category = document.getElementById("chooseCourseCategory")?.value;
    let categoryName = document.getElementById("categoryName")?.value;
    let categoryImages = categoryImage;
    const allCategories = state?.CourseCategories;
    let filteredSubCategory = allCategories?.filter(
      (filtt, index) =>
        filtt?.category_name?.toLowerCase() == categoryName?.toLowerCase()
    );
    let filtered = filteredSubCategory?.filter(
      (data) =>
        data?.category_name?.category_name?.toLowerCase() ==
        category?.toLowerCase()
    );
    if (
      (!categoryName && !categoryImages)
    ) {
      setImageError("*Image is Required");
      setCategoryError("*Category is Required");
    }else if (!categoryName && categoryName?.trim() == "") {
      setCategoryError("*Category is Required");
    } else if (filtered?.length > 0) {
      setCategoryNameError("*Category name is already taken");
    } else if (!categoryImage && categoryImage == undefined) {
      setImageError("*Image is Required");
    } else {
      var data = new FormData();
    //   data.append("category_name_id", category);
      data.append("category_name_id", categoryName);
      data.append("category_image", categoryImages);

      postSubCategory(data)
        .then((res) => {
          if (res.status == "success") {
            handleClosePopup();
            // subCategoryCall();
            categoryCall();
            setPopupStatus(t("success",lan));
            setPopupMessage(res?.data);
          } else {
            // alert("error Occured")
            setPopupStatus("Failed!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editCategoryCall = () => {
    let category = document.getElementById("chooseCourseCategory")?.value;
    let categoryName = document.getElementById("categoryName")?.value;
    let categoryImages = categoryImage
      ? categoryImage
      : editCategory?.category_image;
    const allSubCategories = state?.approvedSubCategory;
    let filteredSubCategory = allSubCategories?.filter(
      (filtt, index) =>
        filtt?.subcategory_name?.toLowerCase() == categoryName?.toLowerCase()
    );
    let filtered = filteredSubCategory?.filter(
      (data) =>
        data?.category_name?.category_name?.toLowerCase() ==
        category?.toLowerCase()
    );
    if (
        (!categoryName && !categoryImages)
      ) {
        setImageError("*Image is Required");
        setCategoryError("*Category is Required");
      }else if (!categoryName && categoryName?.trim() == "") {
        setCategoryError("*Category is Required");
      } else if (filtered?.length > 0) {
        setCategoryNameError("*Category name is already taken");
      } else if (!categoryImages && categoryImages == undefined) {
        setImageError("*Image is Required");
      } else {
      var data = new FormData();
    //   data.append("category_name_id", category);
      data.append("category_name_id", categoryName);
      data.append("category_image", categoryImages);

      editSubCategory(data, editCategory.uuid)
        .then((res) => {
          if (res.status == "success") {
            handleClosePopup();
            // subCategoryCall();
            categoryCall();
            setPopupStatus(t("success",lan));
            setPopupMessage(res?.data);
          } else {
            setPopupStatus("Failed!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteCategoryCall = (uuid) => {
    deleteSubCategory(uuid)
      .then((res) => {
        if (res.status == "success") {
        //   subCategoryCall();
        categoryCall();
          setPopupMessage(res?.data);
          setPopupStatus(t("success",lan));
        } else {
          setPopupStatus("Failed!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onApproveReject = (e, id, type) => {
    console.log("e>>", e?.target?.value, id);
    let formData = new FormData();
    if (type == "Approval") {
      formData.append("approval_status", e?.target?.value);
    } else {
      formData.append("status", e?.target?.value);
    }
    editSubCategory(formData, id)
      .then((result) => {
        if (result?.status == "success") {
        //   subCategoryCall();
        categoryCall();
          setPopupStatus(t("success",lan));
          setPopupMessage(result?.data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (editCategory !== null && editCategory !== undefined) {
      setCategoryName(editCategory.category_name);
      setAddCategoryPopup(true);
    } else {
      setCategoryName("");
    }
  }, [editCategory]);

  const handleClosePopup = () => {
    setAddCategoryPopup(false);
    setCategoryImage(null);
    setEditCategory(null);
    setImageError("");
    setCategoryError("");
    setCategoryName("");
    // setCategoryError("");
    setCategoryNameError("");
  };
  const handleClosePopupStatus = () => {
    setPopupStatus("");
  };

  const handleSearchData = (e) => {
    let searchData = [];
    if (e.target.value != "") {
      state?.CourseCategories?.map((cat) => {
        if (
          cat?.category_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(cat);
        }
      });
    } else {
      searchData = state?.CourseCategories;
    }

    setFilteredCat(searchData);
  };

  return (
    <div className="category">
      <div className="categoryHeader my-4 pb-3 pt-3">
        <p className="midText mb-0">
          {" "}
          Dashboard |{" "}
          <span className="textPrimaryColor">Manage Category</span>
        </p>
            <div className="fliter-block">
                <div className="pageSearch">
                    <span className="iconSearch">
                    <img src={IcSearch} />
                    </span>
                    <InputText
                    placeholder="Search by name"
                    onChange={handleSearchData}
                    />
                </div>

                <div className="d-flex align-items-center justify-content-between">
                    <button
                        className="primaryButton mt-0"
                        onClick={() => {
                            setAddCategoryPopup(true);
                        }}
                        >
                        Add Category
                    </button>
                </div>
            </div>

        {/* <div className="categoryHeaderRight courseHeader">
          <div className="fliter-block">
            <div className="pageSearch">
              <InputText
                placeholder="Search by name"
                onChange={handleSearchData}
              />
            </div>
            <button
              className="primaryButton mt-0"
              onClick={() => {
                setAddCategoryPopup(true);
              }}
            >
              Add Sub Category
            </button>
          </div>
        </div> */}

      </div>
      <div className="categoryListDiv">
        <table className="categoryListTable">
          <tr className="whitespaceNoWrap">
            {/* <th hidden={!isAdmin}>Created by</th> */}
            <th>Category Name</th>
            {/* <th>Sub-Category Name</th> */}
            <th>Date Created</th>
            {/* <th>Approve / Reject</th> */}
            <th>Status</th>
            <th>Action</th>
          </tr>

          {paginationCourses?.length > 0 &&
            paginationCourses?.map((cat, index) => {
              return (
                <tr key={index} className="categoryTableInfo">
                  {/* <td hidden={!isAdmin}>
                    <div className="d-flex">
                      <img src={placeholder} className="spplierImg" />
                      <div>
                        <p className="mb-1">
                          {cat?.supplier?.first_name || "N/A"}
                        </p>
                        <p className="mb-1">
                          {cat?.supplier?.email_id || "N/A"}
                        </p>
                      </div>
                    </div>
                  </td> */}
                  <td>{t(cat.category_name,lan)}</td>
                  {/* <td>{cat.subcategory_name}</td> */}
                  <td>
                    {/* {new Date(cat.created_date_time).toLocaleDateString()} */}
                    {formatDate(cat.created_date_time)}
                  </td>
                  {/* <td>
                    {isAdmin ? (
                      <select
                        value={cat.is_approved?.value}
                        onChange={(e) =>
                          onApproveReject(e, cat?.uuid, "Approval")
                        }
                        className={
                          cat.is_approved?.value == "Pending"
                            ? "StatusActive"
                            : cat?.is_approved?.value == "Rejected"
                            ? "StatusInActive"
                            : "StatusApproved"
                        }
                      >
                        <option value={"Pending"}>Pending</option>
                        <option value={"Approved"}>Approved</option>
                        <option value={"Rejected"}>Rejected</option>
                      </select>
                    ) : (
                      <span
                        style={{ backgroundImage: "none" }}
                        className={
                          cat.is_approved?.value == "Pending"
                            ? "StatusActive"
                            : cat?.is_approved?.value == "Rejected"
                            ? "StatusInActive"
                            : "StatusApproved"
                        }
                      >
                        {cat?.is_approved?.value || "Pending"}
                      </span>
                    )}
                  </td> */}
                  <td>
                    { (
                      <select
                        value={cat?.status?.value}
                        onChange={(e) =>
                          onApproveReject(e, cat?.uuid, "Activation")
                        }
                        className={
                          cat?.status?.value == "Active"
                            ? "StatusActive"
                            : "StatusInActive"
                        }
                      >
                        <option value={"Active"}>Active</option>
                        <option value={"Inactive"}>In-Active</option>
                      </select>
                    )}
                  </td>
                  <td className="whitespaceNoWrap">
                    <span
                      className="cursorPointer textPrimaryColor"
                      onClick={() => {
                        setEditCategory(cat);
                      }}
                    >
                      Edit
                    </span>{" "}
                    |{" "}
                    <span
                      className="cursorPointer textPrimaryColor"
                      onClick={() => {
                        deleteCategoryCall(cat.uuid);
                      }}
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              );
            })}
        </table>
        {paginationCourses?.length <= 0 && (
          <div>
            <NoData />
          </div>
        )}
      </div>
      <div className="mt-3 text-end">
        <div className="row">
          {filteredCat?.length > 12 && (
            <CustomPagination
              startIndex={paginationStartIndex}
              endIndex={
                paginationStartIndex + 11 > filteredCat?.length
                  ? filteredCat?.length
                  : paginationStartIndex + 11
              }
              totalData={filteredCat?.length}
              onPrev={paginationPrev}
              onNext={paginationNext}
            />
          )}
        </div>
      </div>

      <SidePopup
        header={
          editCategory !== null ? "Update Category" : "Add Category"
        }
        show={addCategoryPopup}
        handleClose={handleClosePopup}
      >
        <div className="sidepopupContents">
          <label>
            <span className="customeFileUpload">
              <img
                src={
                  editCategory !== null && editCategory !== undefined
                    ? `${editCategory.category_image}`
                    : categoryImage !== null &&
                      categoryImage !== undefined
                    ? categoryImage.name
                    : UploadImage
                }
                alt="Upload Image"
                className="uploadImage"
                id="output"
              />
              <span className="uploadImageText">
                {categoryImage !== null && categoryImage !== undefined ? (
                  <p className="smallText">{categoryImage.name}</p>
                ) : editCategory !== null && editCategory !== undefined ? (
                  <p className="smallText">
                    {editCategory?.category_image?.split("/").slice(-1)}
                  </p>
                ) : (
                  <>
                    <p className="smallText">Upload Image </p>
                    <p className="superSmallText">
                      Note:- If supplier don’t upload any course image than this
                      will <br />
                      be default course image
                    </p>
                    {imageError && (
                      <p className="errorText mb-0">{imageError}</p>
                    )}
                  </>
                )}
              </span>
              <input
                type="file"
                accept=".jpeg,image/*,.png"
                className="inputTypeFile"
                multiple={false}
                onChange={(e) => {
                  setCategoryImage(e.target.files[0]);
                  setImageError("");
                }}
              />
            </span>
          </label>

          <label htmlFor="categoryName">
            <p className="smallText inputText boldText pWithMargin">
              Category Name
            </p>
          </label>
          <input
            value={categoryName}
            type="text"
            id="categoryName"
            placeholder="Enter Category Name"
            className="formInputs"
            onChange={(e) => {
              setCategoryName(e.target.value);
              setCategoryError("");
            }}
          />
          {categoryError && (
            <p className="errorText mb-0">{categoryError}</p>
          )}

          <button
            className="primaryButton"
            onClick={() => {
              editCategory !== null ? editCategoryCall() : addSubCategory();
            }}
          >
            {editCategory !== null ? "Update Category" : "Add Category"}
          </button>
          {categoryNameError && (
            <p className="errorText mb-0">{categoryNameError}</p>
          )}
        </div>
      </SidePopup>

      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupMessage}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>
      {isLoader ? <Loader /> :""}
    </div>
  );
}

export default Category;
