import React, { useEffect, useState } from "react";
import "./supplierProfile.scss";
import placeholder from "../../../assets/images/placeholder.svg";
import { getTranslatedText as t } from "../../../translater/index";
import SidePopup from "../../../components/sidePopup/sidePopup";
import API from "../../../api";
import UploadImage from "../../../assets/images/uploadImage.png";
import { useSelector } from "react-redux";
import NoData from "../../../components/NoData/NoData";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../assets/images/IcFilter.svg";
import IcFilter2 from "../../../assets/images/IcFilter2.svg";
import FilterSelectMenu from "../../../components/FilterSelectMenu/FilterSelectMenu";
import { Link } from "react-router-dom";
import IcSearch from "../../../assets/images/icSearch.svg";

import { organizationEditApi } from "../../../services/supplierService";
import Popup from "../../../components/popup/popup";
import Loader from "../../../components/Loader/Loader";
import {
  editDetailsUsers,
  organizationalProfiles,
} from "../../../services/adminService";
import InputText from "../../../components/inputText/inputText";
import Footer from "../../../components/footer/Footer";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function SupplierProfile() {
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;

  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [userData, setUserData] = useState([]);
  const [filteredUser, setFilteredUser] = useState();
  const [paginationUser, setPaginationUser] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [isLoader, setIsLoader] = useState(false);
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("");
  const [searchData, setSearchData] = useState([]);
  const manageUsersApiCall = async () => {
    setIsLoader(true);
    organizationalProfiles().then((res) => {
      setIsLoader(false);

      if (res?.status == "success") {
        setUserData(res?.data);
        setSearchData(res?.data);
      }
    });
  };

  useEffect(async () => {
    await manageUsersApiCall();
  }, []);

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredUser?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationUser(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [
    paginationStartIndex,
    filteredUser,
    selectedRoleFilter,
    selectedTimeFilter,
  ]);

  function userComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function userComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date1 - date2;
  }

  const filterByRole = () => {
    let filterRole = [];
    let filteredbyTime = [];
    if (selectedRoleFilter !== "" && selectedRoleFilter !== "All") {
      searchData?.map((user) => {
        if (selectedRoleFilter === user?.user_type?.user_type) {
          filterRole.push(user);
        }
      });
    } else if (selectedRoleFilter === "" || selectedRoleFilter === "All") {
      filterRole = searchData;
    }
    if (selectedTimeFilter === "Newest") {
      filteredbyTime = filterRole?.sort(userComparisonbyNewestDate);
    } else {
      filteredbyTime = filterRole?.sort(userComparisonbyOldestDate);
    }
    // console.log(filteredbyTime);

    setFilteredUser(filteredbyTime);
  };

  useEffect(() => {
    filterByRole();
    pagination();

    setPaginationStartIndex(1);
  }, [selectedTimeFilter, selectedRoleFilter, searchData]);

  const onApproveReject = (e, mail, type) => {
    let formData = new FormData();
    formData.append("supplier_email", mail);
    if (type === "Approval") {
      formData.append("approval_status", e?.target?.value);
    } else {
      formData.append("status", e?.target?.value);
    }
    setIsLoader(true);
    organizationEditApi(formData)
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          manageUsersApiCall();
          // setPopupStatus(t("success",lan));
          // setPopupMessage(result?.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
  };

  const handleSearchData = (e) => {
    let searchData = [];
    if (e?.target?.value != "") {
      userData?.map((user) => {
        if (
          user?.organizational_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(user);
        }
      });
    } else {
      searchData = userData;
    }

    setSearchData(searchData);
  };

  return (
    <div className="manageUsers">
      <div className="subCategoryHeader my-4 pb-3 pt-3">
        <p className="midText mb-0">
          {" "}
          {t("Dashboard", lan)} |{" "}
          <span className="textPrimaryColor">
            {" "}
            {t("ManageSupplierProfile", lan)} 
          </span>
        </p>
        <div className="fliter-block">
          <div className="pageSearch">
            <span className="iconSearch">
              <img src={IcSearch} />
            </span>
            <InputText
              placeholder={t("searchbyName", lan)}
              onChange={handleSearchData}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            {/* <div className="headerTab smallText position-relative">
              <span className="iconsSelect">
                <img src={IcFilter} />
              </span>
              <FilterSelectMenu
                placeholder="Choose Role"
                value={["All", "User", "Supplier"]}
                onChange={(e) => {
                  setSelectedRoleFilter(e.target.value);



                }}
                isWithicon
              />
            </div> */}

            <div className="headerTab smallText position-relative">
              <span className="iconsSelect">
                <img src={IcFilter2} />
              </span>
              <FilterSelectMenu
                placeholder=""
                value={["Newest", "Oldest"]}
                selected={selectedTimeFilter}
                onChange={(e) => {
                  setSelectedTimeFilter(e.target.value);
                }}
                isWithicon
              />
            </div>
          </div>
        </div>
      </div>
      <div className="subCategoryListDiv">
        <table className="subCategoryListTable manage-supplier-profile">
          <tr className="whitespaceNoWrap">
            <th>{t("EducationalInstitution", lan)}</th>

            <th>{t("DateCreated", lan)}</th>

            <th>
              {t("Approve", lan)} / {t("Reject", lan)}
            </th>
            <th>{t("Action", lan)}</th>
          </tr>

          {paginationUser?.length > 0 &&
            paginationUser?.map((users, index) => {
              return (
                <tr key={index} className="subCategoryTableInfo">
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          users?.organization_logo
                            ? `${users?.organization_logo}`
                            : placeholder
                        }
                        className="spplierImg"
                      />

                      <div>
                        <p className="mb-0">
                          {`${users?.organizational_name} ` || "-"}
                        </p>
                        <p className="mb-1">
                          {users?.organization_email || "-"}
                        </p>
                      </div>
                    </div>
                  </td>

                  <td>
                    {new Date(users?.created_date_time).getDate()}{" "}
                    {monthNames[new Date(users?.created_date_time).getMonth()]}{" "}
                    {new Date(users?.created_date_time).getFullYear()}
                  </td>

                  <td>
                    <select
                      value={users?.is_approved?.value}
                      onChange={(e) =>
                        onApproveReject(e, users?.supplier_email, "Approval")
                      }
                      className={
                        users?.is_approved?.value === "Pending"
                          ? "StatusActive"
                          : users?.is_approved?.value === "Rejected"
                          ? "StatusInActive"
                          : "StatusApproved"
                      }
                    >
                      <option value={"Pending"}>{` ${t(
                        "Pending",
                        lan
                      )} `}</option>
                      <option value={"Approved"}>{` ${t(
                        "Approved",
                        lan
                      )} `}</option>
                      <option value={"Rejected"}>{` ${t(
                        "Rejected",
                        lan
                      )} `}</option>
                    </select>
                  </td>

                  <td className="whitespaceNoWrap">
                    <Link
                      // to={`/supplier/manage-supplier-profile/xyz`}
                      to={`/supplier/manage-supplier-profile/organizational-profile?email=${users?.supplier_email}`}
                      className="cursorPointer textPrimaryColor linkDecoreRemove"
                    >
                      {` ${t("View", lan)} `}
                    </Link>{" "}
                  </td>
                </tr>
              );
            })}
        </table>
        {paginationUser?.length <= 0 && (
          <div>
            <NoData />
          </div>
        )}
      </div>
      <div className="mt-3 text-end">
        <div className="col-12">
          {filteredUser?.length > 12 && (
            <CustomPagination
              startIndex={paginationStartIndex}
              endIndex={
                paginationStartIndex + 11 > filteredUser?.length
                  ? filteredUser?.length
                  : paginationStartIndex + 11
              }
              totalData={filteredUser?.length}
              onPrev={paginationPrev}
              onNext={paginationNext}
            />
          )}
        </div>
      </div>

      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupMessage}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", "en")}
          </button>
        </div>
      </Popup>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default SupplierProfile;
