import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./manageCourse.scss";
import { getTranslatedText as t } from "../../../../translater";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClassicEditor1 from "@ckeditor/ckeditor5-build-classic";
import ReactChipInput from "react-chip-input";
import UploadImage from "../../../../assets/images/uploadImage.png";
import API from "../../../../api";
import Popup from "../../../../components/popup/popup";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  courseCategories,
  courseSubCategories,
  viewCoursesDetails,
} from "../../../../redux/actions";
import {
  deleteCourseApi,
  editCourseApi,
  getCategoryApi,
  getCourseDetailApi,
  getCoursesApi,
  getSubCategoryApi,
  postCourse,
} from "../../../../services/supplierService";
import Loader from "../../../../components/Loader/Loader";
import { IoIosArrowBack } from "react-icons/io";
import EddiDropzone from "../../../../components/dropzone/dropzone";
import IcEddiProfile from "../../../../assets/images/times-circle.svg";
import IcPlay from "../../../../assets/images/IcPlay.svg";
import { encrypt } from "../../../../utils/encrypt";
import { checkUrlExtension } from "../../../../services/constant";

const ManageCourse = () => {
  const initialAddCourseDetails = {
    course_image: null,
    course_name: null,
    course_level_id: null,
    course_length: null,
    course_preview_video: null,
    choose_course_length: "Choose",
    course_category_id: null,
    subcategory_name_id: null,
    course_type_id: null,
    fee_type_id: null,
    course_price: null,
    additional_information: null,
    organization_location: null,
    sub_area: null,
    course_checkout_link: null,
    course_language: "en",
    course_for_organization: false,
    course_organization: null,
    course_starting_date: null,
    meeting_link: null,
    meeting_password: null,
    target_users: [],
    offer_price: "",
    author_name: "",
    about_author: "",
    // offer_price: null,
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();
  const params = useParams();
  const search = useLocation().search;
  const republish = Boolean(new URLSearchParams(search).get("republish"));
  let lan = state?.language;
  const [allCategories, setAllCategories] = useState([]);
  const [chips, setChips] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [approvedSubCategory, setApprovedSubCategory] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [editCourcePopup, setEditCourcePopup] = useState(false);
  const [editCource, setEditCource] = useState();
  const [editCourceImage, setEditCourceImage] = useState(null);
  const [addCourseDetals, setAddCourseDetails] = useState(
    initialAddCourseDetails
  );
  const [isLoader, setIsLoader] = useState(false);
  const [activeInactivePopup, setActiveInactivePopup] = useState({
    value: false,
    data: "",
  });
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupDelete, setPopupDelete] = useState("");
  const [nameError, setNameError] = useState("");
  const [overviewError, setOverviewError] = useState("");
  const [checkoutLinkError, setCheckoutLinkError] = useState("");
  const [lengthError, setLengthError] = useState("");
  const [dayLengthError, setDayLengthError] = useState("");
  const [videoPreviewError, setVideoPreviewError] = useState("");
  const [levelError, setLevelError] = useState("");
  const [dateError, setDateError] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);
  const [categoryError, setCategoryError] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const [courseTypeError, setCourseError] = useState("");
  const [feeTypeError, setFeeTypeError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [imageError, setImageError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [choosedVideos, setChoosedVideos] = useState([]);
  const [courseForOrgError, setCourseForOrgError] = useState("");
  let [isAddCourse, setIsAddCourse] = useState(
    window.location.href.includes("create-course")
  );
  const role = state?.UserDetail?.user_type;
  const [offerPriceErr, setOfferPriceErr] = useState("");

  useEffect(() => {
    // let adminSubCategory = state?.approvedSubCategory?.filter((data)=>data?.created_by == "admin")
    setAllSubCategories(state?.CourseSubCategories);
    setApprovedSubCategory(state?.approvedSubCategory);
    getAllCaltegory();
  }, [state]);

  useEffect(() => {
    var x = document.getElementsByName("chipInput");
    if (x?.length > 0) {
      x[0].placeholder = t("EnterEmails", lan);
    }
  }, []);

  useEffect(() => {
    if (isAddCourse) return;
    setIsLoader(true);
    getCourseDetailApi(params?.id)
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          setEditCource(result?.data);
          dispatch(viewCoursesDetails(result?.data));
        }
      })
      .catch((e) => setIsLoader(false))
      .finally(() => setIsLoader(false));
  }, []);

  const getAllCaltegory = () => {
    setIsLoader(true);
    getCategoryApi()
      .then((res) => {
        if (res.status === "success") {
          setAllCategories(res.data);
        }
      })
      .finally(() => setIsLoader(false));
  };

  function loadFile(e) {
    const src = URL.createObjectURL(e.target.files[0]);
    setAddCourseDetails({
      ...addCourseDetals,
      course_image: e.target.files[0],
      upload_image: src,
    });
  }

  async function loadVideoOrImageFile(evv) {
    const file = evv[evv.length - 1];
    await setAddCourseDetails({
      ...addCourseDetals,
      course_preview_video: file,
    });
    setVideoPreviewError("");
    let output = document.getElementById("bgImg");
    let iconImg = document.getElementById("iconImg");
    if (file.type.includes("image") && output) {
      iconImg.hidden = true;
      output.hidden = false;
      output.src = URL.createObjectURL(file);
      return (output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      });
    }
    iconImg["hidden"] = false;
    output["hidden"] = true;
  }

  useEffect(() => {
    if (
      addCourseDetals?.course_image !== null &&
      addCourseDetals?.course_image !== undefined
    ) {
      setEditCourceImage(null);
    }
  }, [addCourseDetals.course_image]);

  useEffect(() => {
    if (addCourseDetals.course_type_id === "Online") {
      setAddCourseDetails({ ...addCourseDetals, organization_location: "" });
    }
  }, [addCourseDetals.course_type_id]);

  useEffect(() => {
    if (addCourseDetals.fee_type_id === "Free") {
      setAddCourseDetails({ ...addCourseDetals, course_price: null });
    }
  }, [addCourseDetals.fee_type_id]);

  useEffect(() => {
    if (editCource && window.location.href.includes("edit-course")) {
      const initialEditCourseDetails = {
        course_image: null,
        course_name: editCource.course_name,
        course_level_id: editCource.course_level?.level_name,
        course_length: editCource.course_length,
        choose_course_length: editCource?.course_length_type || "",
        course_category_id: editCource.course_category?.category_name,
        subcategory_name_id: editCource.course_subcategory?.subcategory_name,
        course_preview_video: editCource.course_preview_video,
        course_type_id: editCource.course_type?.type_name,
        fee_type_id: editCource.fee_type?.fee_type_name,
        meeting_link: editCource?.meeting_link,
        meeting_password: editCource?.meeting_passcode,
        course_price: editCource.course_price || "",
        additional_information: editCource?.additional_information,
        organization_location: editCource.organization_location || "",
        sub_area: editCource.sub_area,
        course_checkout_link: editCource.course_checkout_link || "",
        course_language: editCource.course_language,
        course_for_organization: editCource.course_for_organization,
        target_users:
          editCource.target_users === null
            ? []
            : editCource.target_users?.split(","),
        course_starting_date: editCource?.course_starting_date,
        offer_price: editCource?.offer_price || "",
        author_name: editCource?.author_name || "",
        about_author: editCource?.author_bio || "",
        // offer_price: editCource?.offer_price,
      };
      setChips(
        !editCource.target_users ? [] : editCource.target_users?.split(",")
      );

      setAddCourseDetails(initialEditCourseDetails);
      setEditCourceImage(editCource.course_image);
      setEditCourcePopup(true);
    }
  }, [editCource]);

  useEffect(() => {
    if (
      editCource &&
      window.location.href.includes("edit-course") &&
      addCourseDetals?.course_preview_video
    ) {
      let output = document.getElementById("bgImg");
      let iconImg = document.getElementById("iconImg");
      const fileType = checkUrlExtension(editCource.course_preview_video);
      setAddCourseDetails({
        ...addCourseDetals,
        additional_information: editCource?.additional_information || "",
      });
      if (fileType === "image") {
        iconImg.hidden = true;
        output.src = editCource.course_preview_video;
        output.hidden = false;
      } else {
        iconImg.hidden = false;
        output.hidden = true;
      }
    }
  }, [editCource, editCourceImage]);

  const validate = () => {
    const websiteRegex =
  /^(https?|ftp|smtp):\/\/(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}\/?[a-zA-Z0-9#\/?%=-]*$/;


    if (
      !addCourseDetals?.course_name &&
      !addCourseDetals.course_level_id &&
      !addCourseDetals.course_length &&
      !addCourseDetals.course_category_id &&
      !addCourseDetals?.course_image &&
      !addCourseDetals.course_type_id &&
      !addCourseDetals.fee_type_id &&
      !addCourseDetals?.additional_information
    ) {
      setNameError("*Name is Required");
      setOverviewError("*Overview is Required");
      setLengthError("*Length is Required");
      setLevelError("*Level is Required");
      setDateError("*Date is Required");
      setCategoryError("*Category is Required");
      setCourseError("*Course Type is Required");
      setFeeTypeError("*Fee type is Required");
      setImageError("*Image is Required");

      return false;
    } else if (!addCourseDetals.course_image && !editCourceImage) {
      setImageError("*Image is Required");
      return false;
    } else if (!addCourseDetals.fee_type_id) {
      setFeeTypeError("*Fee type is Required");
      return false;
    } else if (
      !addCourseDetals?.course_name ||
      addCourseDetals?.course_name?.trim() == ""
    ) {
      setNameError("*Name is Required");
      return false;
    } else if (
      !addCourseDetals?.course_level_id ||
      addCourseDetals?.course_level_id == "Choose"
    ) {
      setLevelError("*Level is Required");
      return false;
    } else if (
      !addCourseDetals?.additional_information ||
      addCourseDetals?.additional_information?.trim() == ""
    ) {
      setOverviewError("*Overview is Required");
      return false;
    } else if (
      !addCourseDetals?.course_length ||
      addCourseDetals?.course_length == ""
    ) {
      setLengthError("*Length is Required");
      return false;
    } else if (
      !addCourseDetals?.course_category_id ||
      addCourseDetals?.course_category_id == "Choose"
    ) {
      setCategoryError("*Category is Required");
      return false;
    } else if (!addCourseDetals.course_type_id) {
      setCourseError("*Course Type is Required");
      return false;
    } else if (
      !addCourseDetals.choose_course_length ||
      addCourseDetals.choose_course_length == ""
    ) {
      setDayLengthError("*Length is Required in (Day/Hours)");
      return false;
    } else if (!addCourseDetals.course_starting_date) {
      setDateError("*Date is Required");
      return false;
    } 
    // else if (
    //   addCourseDetals.course_checkout_link &&
    //   !websiteRegex.test(addCourseDetals.course_checkout_link)
    // ) {
    //   setCheckoutLinkError("*Enter valid link");
    //   return false;
    // }
    //  else if (
    //   new Date(addCourseDetals.course_starting_date).toLocaleDateString() <
    //   new Date().toLocaleDateString()
    // ) {
    //   setDateError("*Please Enter Valid Date");
    //   return false;
    // }
    //  else if ( !addCourseDetals?.offer_price &&addCourseDetals?.fee_type_id ==="Paid") {
    //   setOfferPriceErr("*Offer price is Required");
    //   return false;
    // }
    else if (
      (!addCourseDetals?.course_price || addCourseDetals?.course_price <= 0) &&
      addCourseDetals?.fee_type_id == "Paid"
    ) {
      setPriceError("*Price is Required");
      return false;
    } else if (!addCourseDetals.course_preview_video) {
      setVideoPreviewError("*Video/Image is Required");
      return false;
    }
    //  else if (
    //   addCourseDetals?.course_for_organization == true &&
    //   (!addCourseDetals?.target_users ||
    //     addCourseDetals?.target_users?.length <= 0)
    // ) {
    //   setCourseForOrgError("*Student email is required");
    //   return false;
    // }
    else {
      return true;
    }
  };
  const addCourse = () => {
    let stat = validate();
    if (stat === true) {
      var data = new FormData();
      data.append("course_image", addCourseDetals.course_image);
      data.append("course_name", addCourseDetals.course_name || "");
      data.append("course_level_id", addCourseDetals.course_level_id || "");
      data.append("course_length", addCourseDetals.course_length);
      data.append("course_length_type", addCourseDetals.choose_course_length);
      data.append(
        "course_category_id",
        addCourseDetals.course_category_id || ""
      );
      data.append(
        "subcategory_name_id",
        addCourseDetals.subcategory_name_id || ""
      );
      data.append("course_type_id", addCourseDetals.course_type_id || "");
      data.append("fee_type_id", addCourseDetals.fee_type_id || "");
      data.append("meeting_link", addCourseDetals?.meeting_link || "");
      data.append("meeting_passcode", addCourseDetals?.meeting_password || "");
      data.append(
        "course_starting_date",
        addCourseDetals.course_starting_date || ""
      );
      data.append(
        "course_price",
        JSON.stringify(Number(addCourseDetals.course_price)) || ""
      );
      data.append(
        "additional_information",
        addCourseDetals.additional_information || ""
      );
      data.append(
        "organization_location",
        addCourseDetals.organization_location || ""
      );
      data.append("sub_area", addCourseDetals.sub_area || "");
      data.append(
        "course_checkout_link",
        addCourseDetals?.course_checkout_link || ""
      );

      data.append("course_language", addCourseDetals.course_language);
      // data.append(
      //   "course_for_organization",
      //   addCourseDetals.course_for_organization
      // );
      // data.append("target_users", addCourseDetals?.target_users || "");
      data.append(
        "offer_price",
        addCourseDetals?.offer_price ||
          //  encrypt(addCourseDetals?.offer_price)
          ""
      );
      data.append("author_bio", addCourseDetals?.about_author || "");
      data.append("author_name", addCourseDetals?.author_name || "");
      data.append("is_post", true);
      data.append("course_preview_video", addCourseDetals.course_preview_video);

      setLoderBtn(true);
      postCourse(data, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        setUploadPercentage(percentage);
        return percentage; // Because you were returning the percentage before.
      })
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            toast.success(lan == "en" ? res.data : res?.data_sv);
            setTimeout(() => {
              history.push("/supplier/course");
            }, 3000);
            // setPopupStatus(t("Success",lan));
            // setPopupMessage(lan =='en'? res.data : res?.data_sv);
          } else {
            setPopupStatus(t("Failed", lan));
            setPopupMessage(lan == "en" ? res.data : res?.data_sv);
            // toast.error(lan =='en'? res.data : res?.data_sv)
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };
  console.log("addCourseDetals.additional_information ", addCourseDetals);
  const editCourseCall = () => {
    let validation = validate();
    if (validation === true) {
      var data = new FormData();
      addCourseDetals.course_image !== null &&
        data.append("course_image", addCourseDetals.course_image);
      data.append("email_id", localStorage.getItem("logedInEmail"));
      // data.append("email_id", encrypt(localStorage.getItem("logedInEmail")));
      data.append("course_name", addCourseDetals.course_name || "");
      data.append("course_level_id", addCourseDetals.course_level_id);
      data.append("course_length", addCourseDetals.course_length);
      data.append("course_length_type", addCourseDetals.choose_course_length);
      data.append(
        "course_category_id",
        addCourseDetals.course_category_id || ""
      );
      data.append(
        "subcategory_name_id",
        addCourseDetals.subcategory_name_id || ""
      );
      data.append("course_type_id", addCourseDetals.course_type_id || "");
      data.append("fee_type_id", addCourseDetals.fee_type_id || "");
      data.append("meeting_link", addCourseDetals?.meeting_link || "");
      data.append("meeting_passcode", addCourseDetals?.meeting_password || "");
      data.append(
        "course_starting_date",
        addCourseDetals.course_starting_date || ""
      );
      data.append("course_price", addCourseDetals.course_price || 0);
      data.append("offer_price", addCourseDetals?.offer_price || 0);
      // data.append("offer_price", encrypt(addCourseDetals?.offer_price) || "");
      data.append(
        "additional_information",
        addCourseDetals.additional_information || ""
      );
      data.append(
        "organization_location",
        addCourseDetals.organization_location || ""
      );
      data.append("sub_area", addCourseDetals.sub_area || "");
      data.append(
        "course_checkout_link",
        addCourseDetals.course_checkout_link || ""
      );
      data.append("course_language", addCourseDetals.course_language);
      // data.append(
      //   "course_for_organization",
      //   addCourseDetals.course_for_organization
      // );
      data.append("author_bio", addCourseDetals?.about_author || "");
      data.append("author_name", addCourseDetals?.author_name || "");
      // data.append("target_users", addCourseDetals?.target_users || "");
      data.append("course_preview_video", addCourseDetals.course_preview_video);

      setLoderBtn(true);
      editCourseApi(editCource.uuid, data, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        setUploadPercentage(percentage);
        return percentage; // Because you were returning the percentage before.
      })
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            toast.success(lan == "en" ? res?.data : res?.data_sv);
            handleClosePopup();
            setTimeout(() => {
              history.push("/supplier/course");
            }, 3000);
          } else {
            setPopupStatus(t("Failed", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const maxLengthCheck = (e) => {
    if (e?.target?.value?.length > e?.target?.maxLength) {
      e.target.value = e?.target.value.slice(0, e?.target.maxLength);
    }
  };

  //chips user wise
  const addChip = (value) => {
    const NewChips = addCourseDetals?.target_users?.slice() || [];
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      NewChips.push(value);
      setCourseForOrgError("");
    } else {
      setCourseForOrgError("Enter valid email");
    }
    setChips(NewChips);
    setAddCourseDetails({
      ...addCourseDetals,
      target_users: NewChips,
    });
  };
  const removeChip = (index) => {
    const NewChips = addCourseDetals?.target_users?.slice();
    NewChips?.splice(index, 1);
    setChips(NewChips);
    setAddCourseDetails({
      ...addCourseDetals,
      target_users: NewChips,
    });
  };

  const handleClosePopup = () => {
    setAddCourseDetails(initialAddCourseDetails);
    setEditCourcePopup(false);
    setEditCource(null);
    setEditCourceImage(null);
    setActiveInactivePopup({ value: false, data: "" });
    setNameError("");
    setOverviewError("");
    setCheckoutLinkError("");
    setLengthError("");
    setDayLengthError("");
    setLevelError("");
    setDateError("");
    setCategoryError("");
    setCourseError("");
    setFeeTypeError("");
    setCourseError("");
    setImageError("");
    setLinkError("");
    setCourseForOrgError("");
    setPriceError("");
    setChips([]);
    setOfferPriceErr("");
  };
  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    setPopupDelete("");
    setActiveInactivePopup({ value: false, data: "" });
  };

  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  // Select all input elements
  var inputElems = document.getElementsByTagName("input");

  // Turn them into an array
  inputElems = Array.prototype.slice.call(inputElems);

  // Create event listeners for input elements where type equals number
  inputElems.forEach(function (elem) {
    if (elem.type.toLowerCase() == "number") {
      elem.addEventListener("focus", disableScroll, false);
      elem.addEventListener("blur", enableScroll, false);
    }
  });

  function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  function disableScroll() {
    if (window.addEventListener)
      // older FF
      window.addEventListener("DOMMouseScroll", preventDefault, false);
    document.addEventListener("wheel", preventDefault, { passive: false }); // Disable scrolling in Chrome
    window.onwheel = preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    window.ontouchmove = preventDefault; // mobile
    document.onkeydown = preventDefaultForScrollKeys;
  }

  function enableScroll() {
    if (window.removeEventListener)
      window.removeEventListener("DOMMouseScroll", preventDefault, false);
    document.removeEventListener("wheel", preventDefault, { passive: false }); // Enable scrolling in Chrome
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
  }

  return (
    <Fragment>
      <div className="course">
        <div className="courseHeader my-2 pb-3 pt-3">
          <div className="midText course-list-title mb-0">
            {" "}
            <div>
              <span
                className="cursor-pointer"
                onClick={() => history.push("/supplier/dashboard")}
              >
                {t("Dashboard", lan)} |{" "}
              </span>
              <span
                className="cursor-pointer"
                onClick={() => history.push("/supplier/course")}
              >
                {t("CourseList", lan)} |{" "}
              </span>
              <span className="textPrimaryColor">
                {t(isAddCourse ? "AddCourse" : "UpdateCourse", lan)}
              </span>
            </div>
            <div className="courseDetailHeaderRight">
              <span
                className="headerTab smallText cursorPointer"
                onClick={() => {
                  window.history.back();
                }}
              >
                <IoIosArrowBack /> &nbsp;{t("Back", lan)}
              </span>
            </div>
            {/* <div>
              <Link
                onClick={() => {
                  history.goBack();
                }}
                className="back-link"
              >
                {t("Back", lan)}
              </Link>
            </div> */}
          </div>
        </div>
        <div className="m-3">
          <div className="sidepopupContents main-manage">
            <div className="mb-3">
              <label>
                <span className="customeFileUpload">
                  <img
                    src={
                      addCourseDetals?.upload_image
                        ? addCourseDetals?.upload_image
                        : editCourceImage !== null
                        ? `${editCourceImage}`
                        : addCourseDetals?.course_image !== null &&
                          addCourseDetals?.course_image !== undefined
                        ? addCourseDetals?.course_image?.name
                        : UploadImage
                    }
                    alt="Upload Image"
                    className="uploadImage cursor-pointer"
                    id="output"
                  />
                  {addCourseDetals?.course_image !== null &&
                  addCourseDetals?.course_image !== undefined ? (
                    <p className="smallText">
                      {addCourseDetals?.course_image?.name}
                    </p>
                  ) : editCourceImage !== null ? (
                    <p className="smallText imageMaxText">
                      {editCourceImage?.split("/").slice(-1)}
                    </p>
                  ) : (
                    <p className="smallText">
                      {`${t("UploadCourseImages", lan)} `}
                      <span className="text-danger">*</span>
                    </p>
                  )}

                  <input
                    type="file"
                    accept=".jpeg,image/*,.png"
                    className="inputTypeFile"
                    multiple={false}
                    onChange={(e) => {
                      loadFile(e);
                      setImageError("");
                    }}
                  />
                </span>
                {imageError && <p className="errorText mb-0">{imageError}</p>}
              </label>
            </div>

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseName">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseNameTitle", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <input
                      type="text"
                      readOnly={republish}
                      id="courseName"
                      placeholder={` ${t("Course", lan)} ${t("name", lan)}`}
                      className="formInputs"
                      value={addCourseDetals.course_name}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_name: e.target.value,
                        });
                        setNameError("");
                      }}
                    />
                    {nameError && <p className="errorText mb-0">{nameError}</p>}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseCategory">
                      <p className="smallText inputText boldText pWithMargin">
                        {` ${t("CourseCategory", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="courseCategory"
                      value={addCourseDetals.course_category_id}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_category_id: e.target.value,
                        });
                        setCategoryError("");
                      }}
                    >
                      <option disabled selected>
                        {`${t("ChooseCourseCategory", lan)}`}
                      </option>
                      {console.log("allCategories", allCategories)}
                      {allCategories?.map((res) => {
                        return (
                          <option value={res.category_name}>
                            {t(res.category_name, lan)}
                          </option>
                        );
                      })}
                    </select>
                    {categoryError && (
                      <p className="errorText mb-0">{categoryError}</p>
                    )}

                    {/* <input type="text" id="courseLevel" placeholder="Choose Course Level " className="formInputs"/> */}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseSubCategory ">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("ChooseSubCategory", lan)}`}
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="courseSubCategory"
                      value={addCourseDetals?.subcategory_name_id}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          subcategory_name_id: e.target.value,
                        });
                        setSubCategoryError("");
                      }}
                    >
                      <option disabled selected>
                        {`${t("ChooseSubCategory", lan)}  `}
                      </option>

                      {allSubCategories
                        ?.filter(
                          (fil) =>
                            fil?.is_approved?.value == "Approved" &&
                            fil?.status?.value == "Active" &&
                            fil?.category_name?.category_name?.toLowerCase() ==
                              addCourseDetals?.course_category_id?.toLowerCase()
                        )
                        ?.map((res) => {
                          return (
                            <option value={res.subcategory_name}>
                              {res.subcategory_name}
                            </option>
                          );
                        })}
                    </select>
                    {subCategoryError && (
                      <p className="errorText mb-0">{subCategoryError}</p>
                    )}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  {" "}
                  <span>
                    <label htmlFor="courseOverview">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseOverview", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <div className="richtext">
                      <CKEditor
                        id="additional_information_ck"
                        data={addCourseDetals?.additional_information ?? ""}
                        editor={ClassicEditor}
                        placeholder={t("CourseOverview", lan)}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setAddCourseDetails({
                            ...addCourseDetals,
                            additional_information: data,
                          });
                          setOverviewError("");
                        }}
                      />
                    </div>

                    {overviewError && (
                      <p className="errorText mb-0">{overviewError}</p>
                    )}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseLength">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseLength", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <input
                      type="number"
                      id="courseLength"
                      placeholder={`${t("EnterLength", lan)}`}
                      className="formInputs"
                      maxLength={3}
                      onInput={maxLengthCheck}
                      value={addCourseDetals.course_length}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_length: e.target.value,
                        });
                        setLengthError("");
                      }}
                    />
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="chooseCourseLength ">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("ChooseCourseLength", lan)}  `}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="chooseCourseLength"
                      value={addCourseDetals.choose_course_length}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          choose_course_length: e.target.value,
                        });
                        setDayLengthError("");
                      }}
                    >
                      <option selected value="Choose">
                        {`${t("EnterCourseLength", lan)}`}
                      </option>
                      <option value={"hour"}>{t("Hour", lan)}</option>
                      <option value={"day"}>{t("Day", lan)}</option>
                    </select>
                    {/* <input type="text"  placeholder="Choose Course Length" /> */}
                    {dayLengthError && (
                      <p className="errorText mb-0">{dayLengthError}</p>
                    )}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span className="date-box">
                    <label htmlFor="chooseCourseDate ">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("SelectCourseDate", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <input
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_starting_date: e.target.value,
                        });
                        setDateError("");
                      }}
                      min={new Date().toISOString().split("T")[0]}
                      type="Date"
                      value={addCourseDetals?.course_starting_date}
                      id="chooseCourseDate"
                      placeholder="MM/DD/YY"
                      className="formInputs"
                    />
                    {dateError && <p className="errorText mb-0">{dateError}</p>}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseLevel">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("Course", lan)} ${t("level", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="courseLevel"
                      value={addCourseDetals.course_level_id}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_level_id: e.target.value,
                        });
                        setLevelError("");
                      }}
                    >
                      <option disabled selected>
                        {`${t("ChooseCourseLevel", lan)} `}
                      </option>
                      <option value="Beginner">{t("Beginner", lan)}</option>
                      <option value="Intermediate">
                        {t("Intermediate", lan)}
                      </option>
                      <option value="Advanced">{t("Advanced", lan)}</option>
                    </select>
                    {levelError && (
                      <p className="errorText mb-0">{levelError}</p>
                    )}

                    {/* <input type="text" id="courseLevel" placeholder="Choose Course Level " className="formInputs"/> */}
                  </span>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="col-md-12 col-12">
                  <span>
                    <div>
                      {/* Offerprice */}

                      <span>
                        <label htmlFor="chooseFeeType">
                          <p className="smallText inputText boldText pWithMargin">
                            {t("ChooseFeeType", lan)}
                            <span className="text-danger">*</span>
                          </p>
                        </label>

                        <div className="radioDiv smallText">
                          <div className="rdio rdio-primary radio-inline">
                            <input
                              name="radio2"
                              value="Free"
                              id="Free"
                              type="radio"
                              checked={
                                addCourseDetals.fee_type_id === "Free"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    fee_type_id: e.target.value,
                                  });
                                setFeeTypeError("");
                              }}
                            />
                            <label for="Free">{t("Free", lan)}</label>
                          </div>
                          <div className="rdio rdio-primary radio-inline">
                            <input
                              name="radio2"
                              value="Paid"
                              id="Paid"
                              type="radio"
                              checked={
                                addCourseDetals.fee_type_id === "Paid"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    fee_type_id: e.target.value,
                                  });
                                setFeeTypeError("");
                              }}
                            />
                            <label for="Paid">{t("Paid", lan)}</label>
                          </div>
                        </div>

                        {feeTypeError && (
                          <p className="errorText mb-0">{feeTypeError}</p>
                        )}
                      </span>
                    </div>
                    {addCourseDetals.fee_type_id === "Paid" ? (
                      <div>
                        <span>
                          <label htmlFor="courseP">
                            <p className="smallText inputText boldText pWithMargin">
                              {t("OriginalPrice", lan)} {t("ExclVATSEK", lan)}{" "}
                              <span className="text-danger">*</span>
                            </p>
                          </label>
                          <input
                            type="text"
                            id="courseP"
                            value={
                              !addCourseDetals.course_price
                                ? ""
                                : addCourseDetals.course_price
                            }
                            placeholder={`${t("EnterOriginalPrice", lan)}`}
                            className="formInputs"
                            maxLength={6}
                            onInput={maxLengthCheck}
                            onChange={(e) => {
                              const newValue = e.target.value
                                .replace(/[^0-9]/g, "")
                                .slice(0, 6);

                              setAddCourseDetails({
                                ...addCourseDetals,
                                course_price: newValue,
                              });
                              setPriceError();
                            }}
                          />
                          {priceError && (
                            <p className="errorText mb-0 ">{priceError}</p>
                          )}
                        </span>
                        {/* <span>
                          <label htmlFor="courseOff">
                            <p className="smallText inputText boldText pWithMargin">
                              {t("OfferPrice", lan)}{" "}
                            </p>
                          </label>
                          <input
                            type="number"
                            id="courseOff"
                            placeholder={`${t("EnterOfferPrice", lan)} `}
                            className="formInputs"
                            maxLength={6}
                            onInput={maxLengthCheck}
                            value={addCourseDetals.offer_price}
                            onChange={(e) => {
                              setAddCourseDetails({
                                ...addCourseDetals,
                                offer_price: e.target.value,
                              });

                              setOfferPriceErr("");
                            }}
                          />{" "}
                          {offerPriceErr && (
                            <p className="errorText mb-0 ">{offerPriceErr}</p>
                          )}
                        </span> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <span>
                      <label htmlFor="courseType">
                        <p className="smallText inputText boldText pWithMargin">
                          {`${t("CourseType", lan)} `}
                          <span className="text-danger">*</span>
                        </p>
                      </label>
                      <div className="radioDiv smallText">
                        <div className="radiobuttons">
                          <div className="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              name="radio"
                              value="Physical"
                              id="Physical"
                              checked={
                                addCourseDetals.course_type_id === "Physical"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    course_type_id: e.target.value,
                                  });
                                setCourseError("");
                              }}
                            />{" "}
                            <label for="Physical">{t("Physical", lan)}</label>
                          </div>

                          <div className="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              name="radio"
                              value="Online"
                              id="Online"
                              checked={
                                addCourseDetals.course_type_id === "Online"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    course_type_id: e.target.value,
                                  });
                                setCourseError("");
                              }}
                            />{" "}
                            <label for="Online">{t("Online", lan)}</label>
                          </div>

                          <div className="rdio rdio-primary radio-inline">
                            <input
                              type="radio"
                              name="radio"
                              value="Hybrid"
                              id="Hybrid"
                              checked={
                                addCourseDetals.course_type_id === "Hybrid"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.checked &&
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    course_type_id: e.target.value,
                                  });
                                setCourseError("");
                              }}
                            />{" "}
                            <label for="Hybrid">{t("Hybrid", lan)}</label>
                          </div>
                        </div>
                      </div>

                      {/* course type inputs starts  */}
                      {addCourseDetals?.course_type_id && (
                        <div>
                          {addCourseDetals?.course_type_id !== "Online" ? (
                            <>
                              <label htmlFor="courseLoc">
                                <p className="smallText inputText boldText pWithMargin">
                                  {t("CourseLocation", lan)}{" "}
                                </p>
                              </label>
                              <input
                                type="text"
                                id="courseType"
                                placeholder={t("EnterLocation", lan)}
                                className="formInputs"
                                value={
                                  addCourseDetals.organization_location === null
                                    ? ""
                                    : addCourseDetals.organization_location
                                }
                                onChange={(e) => {
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    organization_location: e.target.value,
                                  });
                                  setLinkError("");
                                  setCourseError("");
                                }}
                              />
                            </>
                          ) : (
                            <Fragment>
                              {/* currently we don't want meeting link in manage course  */}
                              {/* <label htmlFor="courseType">
                                <p className="smallText inputText boldText pWithMargin">
                                  {`${t("EnterMeetingLink", lan)} `}
                                  <span className="text-danger">*</span>
                                </p>
                              </label>
                              <input
                                type="text"
                                id="courseType"
                                placeholder={`${t("EnterMeetingLink", lan)}  `}
                                className="formInputs"
                                value={
                                  addCourseDetals.meeting_link === null
                                    ? ""
                                    : addCourseDetals.meeting_link
                                }
                                onChange={(e) => {
                                  setAddCourseDetails({
                                    ...addCourseDetals,
                                    meeting_link: e.target.value,
                                  });
                                  setLinkError("");
                                }}
                              /> */}
                            </Fragment>
                          )}
                        </div>
                      )}
                      {courseTypeError && (
                        <p className="errorText mb-0">{courseTypeError}</p>
                      )}
                      {linkError && (
                        <p className="errorText mb-0">{linkError}</p>
                      )}
                    </span>

                    {/* password meeeting */}
                    <div
                      hidden={
                        addCourseDetals?.course_type_id !== "Online"
                          ? true
                          : false
                      }
                    >
                      <Fragment></Fragment>
                    </div>
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="subArea">
                      <p className="smallText inputText boldText pWithMargin">
                        {t("SubArea", lan)}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="subArea"
                      placeholder={t("EnterCourseSubArea", lan)}
                      className="formInputs"
                      value={
                        addCourseDetals.sub_area == "null"
                          ? ""
                          : addCourseDetals.sub_area
                      }
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          sub_area: e.target.value,
                        });
                      }}
                    />
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="checkoutLink">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CheckoutLink", lan)}`}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="checkoutLink"
                      placeholder={`${t("EnterCheckoutLink", lan)}`}
                      className="formInputs"
                      value={addCourseDetals?.course_checkout_link}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_checkout_link: e.target.value,
                        });
                        setCheckoutLinkError("");
                      }}
                    />
                    {/* {checkoutLinkError && (
                      <p className="errorText mb-0">{checkoutLinkError}</p>
                    )} */}
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="authorname">
                      <p className="smallText inputText boldText pWithMargin">
                        {t("AuthorName", lan)}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="authorname"
                      placeholder={t("EnterAuthorName", lan)}
                      className="formInputs"
                      value={addCourseDetals.author_name}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          author_name: e?.target?.value,
                        });
                      }}
                    />
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="writeauthor">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("WriteAuthor", lan)}`}
                      </p>
                    </label>
                    <div className="richtext">
                      <CKEditor
                        // id="about_author"
                        data={
                          addCourseDetals?.about_author
                            ? addCourseDetals?.about_author
                            : ""
                        }
                        editor={ClassicEditor1}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setAddCourseDetails({
                            ...addCourseDetals,
                            about_author: data,
                          });
                        }}
                      />
                    </div>
                  </span>
                </div>
                <div className="col-md-12 col-12">
                  <span>
                    <label htmlFor="courseLanguage">
                      <p className="smallText inputText boldText pWithMargin">
                        {`${t("CourseLanguage", lan)} `}
                      </p>
                    </label>
                    <select
                      className="formInputs"
                      id="courseLanguage"
                      value={addCourseDetals.course_language}
                      onChange={(e) => {
                        setAddCourseDetails({
                          ...addCourseDetals,
                          course_language: e.target.value,
                        });
                      }}
                    >
                      {/* <option disabled selected>Choose Course Language</option> */}
                      <option value="en" selected>
                        {t("English", lan)}
                      </option>
                      <option value="sv"> {t("Swedish", lan)}</option>
                    </select>
                    {/* <input type="text" id="courseLevel" placeholder="Choose Course Level " className="formInputs"/> */}
                  </span>
                </div>
                <div className="col-12">
                  <label>
                    <p className="smallText inputText boldText pWithMargin">
                      {t("BackgroundFile", lan)}
                      <span className="text-danger">*</span>
                    </p>
                  </label>
                  <EddiDropzone
                    dragText={t("DragDrop", lan)}
                    accept="video,image"
                    selectedFiles={choosedVideos}
                    choosedFile={(evv) => loadVideoOrImageFile(evv)}
                  />
                  {videoPreviewError && (
                    <p className="errorText mb-0">{videoPreviewError}</p>
                  )}
                  {addCourseDetals.course_preview_video && (
                    <div className="mt-3 list-video">
                      <div id="iconImg" className="img-v center">
                        <img src={IcPlay} />
                      </div>
                      <img id="bgImg" alt="bgvideoImg" hidden={true} />
                      <span className="mx-2 text-break align-self-center">
                        {addCourseDetals.course_preview_video?.name?.slice(
                          0,
                          60
                        ) ||
                          addCourseDetals.course_preview_video
                            ?.split("course_preview_video/")[1]
                            ?.slice(0, 60) ||
                          ""}
                      </span>
                      <div className="align-self-center ">
                        <img
                          className="cursor-pointer"
                          onClick={() =>
                            setAddCourseDetails({
                              ...addCourseDetals,
                              course_preview_video: null,
                            })
                          }
                          src={IcEddiProfile}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="checkboxsDiv mt-4">
              <label className="checkboxLabel">
                <input
                  checked={addCourseDetals.course_for_organization}
                  onChange={(e) => {
                    setAddCourseDetails({
                      ...addCourseDetals,
                      course_for_organization: e.target.checked,
                    });
                  }}
                  type="checkbox"
                  className="checkbox"
                  id="AgreeTnC"
                />
                <span className="checkbox "></span>&nbsp;{" "}
                {` ${t("CourseForOrganization", lan)}`}
              </label>
            </div>

            <div className="mt-3 row">
              <div
                className="col-md-6 col-12"
                style={
                  addCourseDetals.course_for_organization
                    ? { display: "initial" }
                    : { display: "none" }
                }
              >
                <ReactChipInput
                  classes="class1"
                  chips={chips}
                  onChange={(e) => setCourseForOrgError("")}
                  onSubmit={(value) => {
                    addChip(value);
                  }}
                  onRemove={(index) => removeChip(index)}
                />
                {courseForOrgError && (
                  <p className="errorText mb-0">{courseForOrgError}</p>
                )}
              </div>
            </div> */}
            {loderBtn && (
              <div className="progress mt-4">
                <div
                  className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow={uploadPercentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${uploadPercentage}%` }}
                ></div>
              </div>
            )}

            <div className="d-flex justify-content-end">
              <button
                className="primaryButton"
                onClick={() => {
                  isAddCourse ? addCourse() : editCourseCall();
                }}
                disabled={loderBtn}
              >
                {loderBtn ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  `${t(
                    isAddCourse ? "AddCourseTitle" : "UpdateCourseBtn",
                    lan
                  )} `
                )}
              </button>
              <Link className="primaryButton mx-4" to={`/supplier/course`}>
                {t("CancleText", lan)}
              </Link>
            </div>
          </div>
        </div>
        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
      </div>
      {isLoader ? <Loader /> : ""}
    </Fragment>
  );
};

export default ManageCourse;
