import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/Logo.svg";
import ProfileImage from "../../assets/images/profileImage.svg";
import IcNotification from "../../assets/images/ic-notification.svg";
import languageIcon from "../../assets/images/language.svg";
import NoUserImge from "../../assets/images/noUser.svg";
import IcFilter from "../../assets/images/IcFilter.svg";
import IcFilter2 from "../../assets/images/IcFilter2.svg";
import IcAdd from "../../assets/images/add.svg";
import { getTranslatedText as t } from "../../translater/index";
import placeholder from "../../assets/images/placeholder.svg";
import { useDispatch, useSelector } from "react-redux";
import "./Header.scss";
import SidePopup from "../sidePopup/sidePopup";
import storage from "redux-persist/lib/storage";
import { language, logout, notificationCount } from "../../redux/actions";
import io from "socket.io-client";
import API from "../../api";
import FilterSelectMenu from "../FilterSelectMenu/FilterSelectMenu";
import { toast } from "react-toastify";
import { clearNoti, getNotification } from "../../services/supplierService";

// const backendSocket = io.connect("wss://testyourapp.online:5001/");

function Header() {
  let newState = useSelector((state) => state?.Eddi);
  let lan = newState?.language;

  const email = localStorage.getItem("logedInEmail");
  const dispatch = useDispatch();
  const [notificationModal, setNotificationModal] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [newNoti, setNewNoti] = useState(newState?.notificationCount);
  const state = useSelector((state) => state?.Eddi?.UserDetail);

  const notificationApi = () => {
    getNotification()
      .then((result) => {
        if (result.status == "success") {
          setNotificationData(result?.data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    // backendSocket.on("JSONreceived", (data) => {
    //   let newArray = notificationData;
    //   let filtered = data?.message?.receiver.filter((fill) => fill == email);
    //   let checkAlready = newArray?.filter(
    //     (fill) => fill?.message == data.message
    //   );
    //   if (filtered?.length > 0 && checkAlready === 0) {
    //     console.log("JSON received =>", data.message);
    //     newArray?.unshift(data.message);
    //     // setNewNoti(newNoti+1)
    //     dispatch(notificationCount(newState?.notificationCount + 1));
    //     toast.success(`New Notification Recived `, {
    //       icon: ({ theme, type }) => <img src={IcNotification} />,
    //     });
    //     setNotificationData(newArray);
    //   } else {
    //   }
    // });

    notificationApi();
  }, []);

  const clearAllNoti = () => {
    let formData = new FormData();
    formData.append("is_clear", true);
    clearNoti(formData).then((result) => {
      notificationApi();
      // setNewNoti(0)
      handleClosePopup();
      toast.success(result.data);
    });
  };

  const handleClosePopup = () => {
    dispatch(notificationCount(0));
    setNotificationModal(false);
  };

  let mobilemenustatus = false;
  function mobilemenu() {
    let Element = document.getElementById("navbarSupportedContent");
    if (!mobilemenustatus) {
      Element.style.display = "block";
      mobilemenustatus = true;
    } else {
      Element.style.display = "none";
      mobilemenustatus = false;
    }
  }
  const generateColor = () => {
    return Math.random().toString(16).substr(-6);
  };

  return (
    <div className="header-main">
      <nav className="pt-3">
        <div className="container">
          <Link
            className="navbar-brand top-mobile-logo"
            to="/supplier/dashboard"
          >
            <img src={Logo} className="mobile-logo" />
          </Link>

          <div>
            <div className="user-information">
              <div className="d-flex align-items-center noti-main-block">
                <div className="headerTab headerItem smallText">
                  {/* <FaSortAmountUp className="textPrimaryColor" /> &nbsp;Sort */}
                  <div className="language-dd">
                    <div class="dropdown">
                      <img
                        className=" dropdown-toggle"
                        src={languageIcon}
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></img>

                      <ul
                        class="dropdown-menu smallmenu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <span
                            className="dropdown-item mb-0"
                            id="en"
                            onClick={(e) => dispatch(language(e?.target?.id))}
                          >
                            EN
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item mb-0"
                            id="sw"
                            onClick={(e) => dispatch(language(e?.target?.id))}
                          >
                            SW
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <FilterSelectMenu
                    placeholder=""
                    selected={lan}
                    onChange={(e) => {
                      dispatch(language(e?.target?.value));
                    }}
                    value={["en", "sw"]}
                    isWithicon
                  /> */}
                </div>
                <div className="nav-item dropdown">
                  <span className="nav-item dropdown">
                    <div className="noti-bell">
                      <Link
                        onClick={() => {
                          setNotificationModal(true);
                        }}
                        className="dropdown-toggle"
                      >
                        <img src={IcNotification} />
                        {notificationData?.length > 0 && (
                          <span className="notiDot"></span>
                        )}
                      </Link>
                    </div>
                  </span>
                  {/* <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="">
                        <span className="notification-title">
                          notification-title
                        </span>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Etiam at at volutpat integer.
                        </p>
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="">
                        <span className="notification-title">
                          notification-title
                        </span>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Etiam at at volutpat integer.
                        </p>
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="">
                        <span className="notification-title">
                          notification-title
                        </span>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Etiam at at volutpat integer.
                        </p>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item text-end link-green"
                        to="/"
                   
                      >
                        Clear All
                      </Link>
                    </li>
                </ul> */}
                </div>
              </div>

              <div className="d-flex align-items-center user-porfile-main">
                <span className="profil-username">
                  {newState?.personalData?.data?.supplier_name}
                </span>
                <div className="nav-item dropdown">
                  <span className="user-img nav-item dropdown">
                    <Link className="dropdown-toggle">
                      <img
                        src={
                          newState?.personalData?.data?.supplier_image
                            ? `${newState?.personalData?.data?.supplier_image}`
                            : NoUserImge
                        }
                      />
                    </Link>
                  </span>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="dd-profile">
                      <span className="user-img nav-item dropdown">
                        <Link className="dropdown-toggle">
                          <img
                            src={
                              newState?.personalData?.data?.supplier_image
                                ? `${newState?.personalData?.data?.supplier_image}`
                                : NoUserImge
                            }
                          />
                        </Link>
                      </span>
                      <span className="profil-username ms-2">

                        <div>
                          {newState?.personalData?.data?.supplier_name ||newState?.personalData?.supplier_name ||''}(
                          {state?.user_type ||''})
                        </div>

                        <div className="quik-profilelink">
                          <Link to={"/supplier/eddi-profile"}>
                            {t("View", lan)}
                          </Link>{" "}
                          |{" "}
                          <Link to={"/supplier/eddi-profile/edit-profile"}>
                            {t("EditProfile", lan)}
                          </Link>
                        </div>
                      </span>
                    </div>
                    <li>
                      <Link className="dropdown-item" to="/resetPassword">
                        {t("ChangePassword", lan)}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        onClick={() => {
                          localStorage.clear();
                          storage.removeItem("persist:root");
                          dispatch(logout());
                        }}
                      >
                        {t("SignOut", lan)}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="notifications-sidebar">
        <SidePopup
          header={t("Notifications", lan)}
          show={notificationModal}
          handleClose={handleClosePopup}
        >
          <div className="w-100 position-relative">
            {notificationData?.length > 0 && (
              <>
                <div className="noti-info-with-button">
                  <div className="noti-info mb-3">
                    <p>
                      You have {notificationData?.length || 0} new notifications
                    </p>
                  </div>
                  <div className="justify-content-end">
                    <span onClick={() => clearAllNoti()} className="clearBtn">
                      Clear All
                    </span>
                  </div>
                </div>
              </>

            )}
            <ul>
              {notificationData?.length > 0 ? (
                notificationData?.map((noti, index) => {
                  return (
                    <>
                      <li key={index}>
                        <div className="usr-img">
                          <p
                            className="spplierImg-noti"
                            style={{ background: `#${generateColor()}` }}
                          >
                            {noti?.message.charAt(0).toUpperCase()}{" "}
                          </p>
                        </div>
                        <div className="notification-type">
                          <h4>
                            {lan == "en" ? noti?.message : noti?.message_sv}
                          </h4>
                          <div className="noti-date">
                            <span>
                              {new Date(
                                noti?.created_date_time
                              ).toLocaleDateString("en-GB")}
                              ,{" "}
                              {new Date(
                                noti?.created_date_time
                              ).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}
                            </span>
                            {/* <Link className="link-grn">
                                {t("ViewDetails", lan)}
                              </Link> */}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <p>0 {t("NotificationFound", lan)}</p>
                  </div>
                </>
              )}
            </ul>
            {/* {notificationData?.length > 0 && (
              <div className="justify-content-end">
                <p onClick={() => clearAllNoti()} className="clearBtn">
                  Clear All
                </p>
              </div>
            )} */}
          </div>
        </SidePopup>
      </div>
    </div>
  );
}

export default Header;
