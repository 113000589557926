import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/Logo.svg";
import ProfileImage from "../../assets/images/profileImage.svg";
import IcNotification from "../../assets/images/ic-notification.svg";
import { getTranslatedText as t } from "../../translater/index";
import { useSelector } from "react-redux";
import "./Footer.scss";

function Footer() {
  const state = useSelector((state) => state?.Eddi?.UserDetail);

  let mobilemenustatus = false;
  function mobilemenu() {
    let Element = document.getElementById("navbarSupportedContent");
    if (!mobilemenustatus) {
      Element.style.display = "block";
      mobilemenustatus = true;
    } else {
      Element.style.display = "none";
      mobilemenustatus = false;
    }
  }
  return (
    <div className="footer-blcok">
      <div className="smallFooter">© Eddi Website  {new Date().getFullYear()} </div>
    </div>
  );
}

export default Footer;
