import React, { useState, useEffect } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
// import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import Boxarrow from "../../../assets/images/boxarrow.svg";
import IcSearch from "../../../assets/images/ic_serach.svg";
import ProfileImage from "../../../assets/images/profileImage.svg";
import IcLocation from "../../../assets/images/icLocation.svg";
import IcPhone from "../../../assets/images/IcPhone.svg";
import IcMail from "../../../assets/images/IcMail.svg";
import "../EddiProfile/EddiProfile.scss";
import { getTranslatedText as t } from "../../../translater/index";
import IcAbout from "../../../assets/images/IcAbout.svg";
import Footer from "../../../components/footer/Footer";
import InputText from "../../../components/inputText/inputText";
import ScrollContainer from "react-indiana-drag-scroll";
import {} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Placeholder from "../../../assets/images/placeholder.svg";
import api from "../../../api";
import { getPersonalProfileDetail } from "../../../services/supplierService";
import { getPersonalProfileData } from "../../../redux/actions";
import Aboutme from "../../../assets/images/Aboutme.svg";
import { formatDate } from "../../../services/constant";
function EddiProfile(props) {
  let { path, url } = useRouteMatch();
  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;
  const [userData, setUserData] = useState(state?.personaldata);
  const [stripeLinkData, setStripeLinkData] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const ToggleClass = (e, index) => {
    // setActive(!isActive);
    //alert(e + index);
  };
  const personalProfileApi = () => {
    getPersonalProfileDetail()
      .then((result) => {
        if (result?.status == "success") {
          dispatch(getPersonalProfileData(result));
          console.log("data", result?.profile_link);
          setUserData(result?.data);
          setStripeLinkData(result?.profile_link);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    personalProfileApi();
  }, []);

  function timeAgo(input) {
    const date = input instanceof Date ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat(lan);
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    for (let key in ranges) {
      if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key];
        return formatter.format(Math.round(delta), key);
      }
    }
  }

  return (
    <div className="mainpage">
      {/* <Sidebar /> */}
      <div className="EddiProfileMain">
        <div className="container-fluid">
          {/* <Header /> */}
          <div className="dashboard-inner">
            <h1>
              {t("MyProfile", lan)}
              {/* {t("clickhere", lan)} */}
              {/* <span>
                {t("Completeyourprofile", lan)}
                <Link> {t("clickhere", lan)}</Link>
              </span> */}
            </h1>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12">
                <div className="supplier-photo">
                  <img
                    src={
                      userData?.supplier_image
                        ? `${userData?.supplier_image}`
                        : Placeholder
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = Placeholder;
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-12 supplier-details">
                <div className="supplier-title">
                  <div>
                    <h1>{userData?.supplier_name || "-"}</h1>
                    <h6>
                      Member since{" "}
                      {timeAgo(new Date(userData?.created_date_time))}
                    </h6>
                  </div>
                  <Link to={`${path}/edit-profile`} className="btn btn-green">
                    {t("EditProfile", lan)}
                  </Link>
                </div>
                {/* <div className="course-offered">
                  <div>
                    <h6>{t("CoursesOffered", lan)}</h6>
                  </div>
                  <ScrollContainer
                    hideScrollbars={true}
                    className="scroll-container"
                  >
                    <div className="cat-list">
                      <ul>
                        <li>Coruse Category 1</li>
                        <li>Coruse Category 2</li>
                        <li>Coruse Category 3</li>
                        <li>Coruse Category 4</li>

                        <li>Coruse Category 1</li>
                        <li>Coruse Category 2</li>
                        <li>Coruse Category 3</li>
                        <li>Coruse Category 4</li>

                        <li>Coruse Category 1</li>
                        <li>Coruse Category 2</li>
                        <li>Coruse Category 3</li>
                        <li>Coruse Category 4</li>

                        <li>Coruse Category 1</li>
                        <li>Coruse Category 2</li>
                        <li>Coruse Category 3</li>
                        <li>Coruse Category 4</li>
                      </ul>
                    </div>
                  </ScrollContainer>
                </div> */}

                <div className="supplier-basic-information">
                  <ul>
                    <li>
                      <div className="infomation-row">
                        <span className="information-icon">
                          <img src={IcLocation} />
                        </span>
                        <div>
                          <h6>{t("Address", lan)}</h6>
                          <h5>{userData?.address || "-"}</h5>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="infomation-row">
                        <span className="information-icon">
                          <img src={IcPhone} />
                        </span>
                        <div>
                          <h6>{t("phonnumber", lan)}</h6>
                          <h5>
                            {userData?.phone_number
                              ? ` ${userData?.phone_number}`
                              : "-"}
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="infomation-row">
                        <span className="information-icon">
                          <img src={IcMail} />
                        </span>
                        <div>
                          <h6>{t("EmailAddress", lan)}</h6>
                          <h5>
                            {userData?.supplier_email
                              ? userData?.supplier_email
                              : localStorage?.getItem("logedInEmail")}
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="infomation-row">
                        <span className="information-icon">
                          <img src={Aboutme} />
                        </span>
                        <div>
                          <h6>{t("About Me", lan)}</h6>
                          <h5>
                            {userData?.about_me
                              ? ` ${userData?.about_me}`
                              : "-"}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {console.log("erfwe", userData)}
                {stripeLinkData != "" && stripeLinkData != null && (
                  <div>
                    <button
                      className="btn btn-green mt-3"
                      onClick={() => window.open(stripeLinkData)}
                      // history.push(`${stripeLinkData}`)}
                    >
                      {t("CreateStripeAccount",lan)}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EddiProfile;
