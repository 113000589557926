import React, { useEffect, useRef, useState } from "react";
import "./ManageMaterial.scss";
import "../../../../common/styles/globalStyles.scss";
import SidePopup from "../../../../components/sidePopup/sidePopup";
import UploadImage from "../../../../assets/images/pdf.svg";
import IcPlay from "../../../../assets/images/IcPlay.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTranslatedText as t } from "../../../../translater";

import Loader from "../../../../components/Loader/Loader";
import Footer from "../../../../components/footer/Footer";
import EddiDropzone from "../../../../components/dropzone/dropzone";
import { BsXSquareFill } from "react-icons/bs";
import { checkTrimOrUndefined, showShorttext } from "../../../../services/constant";
import {
  addCorporateMaterial,
  addMaterial,
  changeOrderOfCorporateMaterial,
  changeOrderOfMaterial,
  deleteCorporateMaterial,
  deleteMaterial,
  editCorporateMaterial,
  editMaterial,
  getCorporateMaterail,
  getMaterail,
} from "../../../../services/supplierService";
import Popup from "../../../../components/popup/popup";
import NoData from "../../../../components/NoData/NoData";
import { IoIosArrowBack } from "react-icons/io";

function ManageMaterial() {
  const dispatch = useDispatch();
  const params = useParams();
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();
  let lan = state?.language;
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const [loaderBtn, setLoderBtn] = useState(false);
  const [materialData, setMaterialData] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupDelete, setPopupDelete] = useState("");
  const [deleteCourseId, setDeleteCourseId] = useState();
  const [fileUploadModel, setFileUploadModel] = useState({
    value: false,
    isAdd: true,
    type: "Video",
    nameEn: "",
    namesw: "",
    descEn: "",
    descSw: "",
    file: [],
    fileName:"",
    uuid:"",
  });
  const [error, setError] = useState({
    nameEn: "",
    namesw: "",
    descEn: "",
    descSw: "",
    file: "",
  });

  const materialCall = () => {
    setIsLoader(true);
    getMaterail(params?.module_id)
      .then((res) => {
        if (res.status == "success") {
          setMaterialData(res.data);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };
  useEffect(async () => {
    await materialCall();
  }, []);

  const handleSortVideos = async () => {
    let duplicateVideoArr = [...materialData];

    //remove and save dragged item content

    const draggedStartItem = duplicateVideoArr.splice(dragItem.current, 1)[0];
    //switch the position
    duplicateVideoArr.splice(dragOverItem.current, 0, draggedStartItem);

    //clear the refs
    dragItem.current = null;
    dragOverItem.current = null;


    const bodyData = duplicateVideoArr.map((m, i) => {
      return { serial_no: i + 1, uuid: m.uuid };
    });
    await changeOrderOfMaterial(bodyData);
    duplicateVideoArr = duplicateVideoArr.map((cc, i) => {
      cc.serial_no = i + 1;
      return cc;
    });
    setMaterialData(duplicateVideoArr);
  };

  const handleChange = (value, type) => {
    setFileUploadModel((e)=>{
      return {...e, [type]: value}
    })
    setError((e)=>{
     return { ...e, [type]: "" }});
  };
  const deleteMaterialCall = () => {
    setIsLoader(true);
    deleteMaterial(deleteCourseId)
      .then((res) => {
        if (res?.status == "success") {
          handleClosePopupStatus();
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          materialCall();
        } else {
          handleClosePopupStatus();
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      })
      .catch((er) => console.log(er))
      .finally(() => setIsLoader(false));
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    setPopupDelete("");
  };

  const validate = () => {
    if (checkTrimOrUndefined(fileUploadModel.namesw)) {
      setError({ ...error, namesw: t("EnterValidTitle", lan) });
      return false;
    } else if (checkTrimOrUndefined(fileUploadModel.nameEn)) {
      setError({ ...error, nameEn: t("EnterValidTitle", lan) });
      return false;
    } else if (checkTrimOrUndefined(fileUploadModel.descSw)) {
      setError({ ...error, descSw: t("EnterValidDesc", lan) });
      return false;
    }
     else if (checkTrimOrUndefined(fileUploadModel.descEn)) {
      setError({ ...error, descEn: t("EnterValidDesc", lan) });
      return false;
    } else if (fileUploadModel.file.length === 0) {
      setError({ ...error, file: t("AtLeastOneFile", lan) });
      return false;
    } else {
      return true;
    }
  };

  const onHandleAdd = async () => {
    const valid = await validate();
    if (valid) {
      let formData = new FormData();
      let res;
      formData.append("course_id", params.course_id);
      formData.append("module_id", params.module_id);
      formData.append("title_sw", fileUploadModel.namesw);
      formData.append("title_en", fileUploadModel.nameEn);
      formData.append("description_en", fileUploadModel.descEn);
      formData.append("description_sw", fileUploadModel.descSw);
      formData.append("file", fileUploadModel.file[0] );
      formData.append("file_name", fileUploadModel.file[0]["name"] || fileUploadModel.fileName || "");
      formData.append("file_type", fileUploadModel.type || "");

      try {
        setLoderBtn(true);

        if (fileUploadModel.isAdd) {
          res = await addMaterial(formData, (progressEvent) => {
            const percentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            setUploadPercentage(percentage);
            return percentage; // Because you were returning the percentage before.
          });
        } else {
          res = await editMaterial(fileUploadModel.uuid, formData, (progressEvent) => {
            const percentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            setUploadPercentage(percentage);
            return percentage; // Because you were returning the percentage before.
          });
        }
        setLoderBtn(false);

        if (res?.status == "success") {
          handleClosePopup();
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          materialCall();
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      } catch (err) {
        console.log(err);
        setLoderBtn(false);
      }
    }
  };
  const handleClosePopup = () => {
    setFileUploadModel({
      value: false,
      isAdd: true,
      type: "Video",
      nameEn: "",
      namesw: "",
      descEn: "",
      descSw: "",
    uuid:"",
      file: [],
      fileName:""

    });
  };

  const onEditClick=(data)=>{
    setFileUploadModel({
      value: true,
      isAdd: false,
      type: data?.file_type,
      nameEn: data?.title_en,
      namesw: data?.title_sw,
      descEn: data?.description_en,
      descSw: data?.description_sw,
      file: [data?.file],
    uuid:data.uuid,
      fileName:data?.file_name
    });
  }

  if (isLoader) {
    return <Loader />;
  }
  return (
    <div className="course">
      <div className="fix_height">
        <div className="courseHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">{t("ManangeModule", lan)}</span>
          </p>
          <span
            className="headerTab smallText cursorPointer"
            onClick={() => {
              window.history.back();
            }}
          >
            <IoIosArrowBack /> &nbsp;{t("Back", lan)}
          </span>
        </div>

        <div className="row">
          <div className="col-xl-7 col-lg-12 col-md-7 col-sm-12 col-12 mb-4">
            <div className="video-list p-lg-4  p-2">
              <div className="d-flex ">
                <button
                  onClick={() =>
                    setFileUploadModel({
                      ...fileUploadModel,
                      type: "Video",
                      value: true,
                      isAdd: true,
                    })
                  }
                  className="btn-green "
                >
                  {t("AddVideo", lan)}
                </button>
                <button
                  onClick={() =>
                    setFileUploadModel({
                      ...fileUploadModel,
                      type: "File",
                      value: true,
                      isAdd: true,
                    })
                  }
                  className="btn-green mx-3"
                >
                  {t("AddFile", lan)}
                </button>
              </div>

              {materialData.length > 0 ? (
                materialData.map((material, i) => {
                  return (
                    <div key={i} className="main-list my-4">
                      <div className="img-div">
                        <img
                          src={
                            material.file_type === "Video"
                              ? IcPlay
                              : UploadImage
                          }
                          alt="play-img"
                        />
                      </div>
                      <div className="mx-2 text-sm-start text-center w-65 w-sm-100">
                        <h5 className="f-20">
                          {material[`title_${lan}`] || ""}
                        </h5>
                        <span className="f-14 text-break">
                          {showShorttext(material[`description_${lan}`],150) || ""}
                        </span>
                      </div>
                      <div className="link-btns text-center">
                        <Link onClick={()=>onEditClick(material)} className="linkDecoreRemove textPrimaryColor">
                          {t("Edit", lan)}{" "}
                        </Link>
                        <Link
                          onClick={() => {
                            setDeleteCourseId(material.uuid);
                            setPopupDelete("delete");
                          }}
                          className="linkDecoreRemove dangerColor  ms-md-3 ms-2"
                        >
                          {t("Delete", lan)}{" "}
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoData />
              )}
            </div>
          </div>
          <div className="col-xl-5 col-lg-12 col-md-5 col-12">
            <div className="adjust-main p-lg-3 p-md-2 p-sm-4 p-2">
              <p className="heading">{t("AdjustMaterial", lan)}</p>

              {materialData?.length > 0 ? (
                <div className="file-list-main my-3">
                  {materialData.map((item, i) => {
                    return (
                      <div
                        draggable
                        onDragEnter={() => (dragItem.current = i)}
                        onDragStart={() => (dragOverItem.current = i)}
                        onDragEnd={handleSortVideos}
                        onDragOver={(e) => e.preventDefault()}
                        key={i}
                        className="file-list d-flex mb-2"
                      >
                        <div className="img-div">
                          <img
                            src={
                              item.file_type === "Video" ? IcPlay : UploadImage
                            }
                            alt="dragg"
                          />
                        </div>
                        <span className="mx-3 f-18 align-self-center">
                          {item[`title_${lan}`] || "-"}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ):<NoData />}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>

      <SidePopup
      header={
        fileUploadModel.isAdd
          ? t("Add", lan) + " " + t(`${fileUploadModel.type.toLocaleLowerCase()}`,lan)
          : t("Update", lan) + " " + t(`${fileUploadModel.type.toLocaleLowerCase()}`,lan)
      }
        show={fileUploadModel.value}
        handleClose={handleClosePopup}
      >
        <div className="sidepopupContents">
          <div className="my-3">
            <label htmlFor="nameInSw">
              <p className="smallText inputText boldText pWithMargin">
                {t(fileUploadModel.type, lan) + t("NameInSw", lan)}
              </p>
            </label>
            <input
              type="text"
              id="nameInSw"
              value={fileUploadModel.namesw}
              onChange={(e) => handleChange(e.target.value, "namesw")}
              placeholder={t(`Enter${fileUploadModel.type}TitleSw`, lan)}
              className="formInputs"
            />
            {error.namesw && <p className="errorText mb-0">{error.namesw}</p>}
          </div>
          <div className="my-3">
            <label htmlFor="categoryNameEng">
              <p className="smallText inputText boldText pWithMargin">
                {t(fileUploadModel.type, lan) + t("NameInEn", lan)}
              </p>
            </label>
            <input
              type="text"
              id="categoryNameEng"
              value={fileUploadModel.nameEn}
              onChange={(e) => handleChange(e.target.value, "nameEn")}
              placeholder={t(`Enter${fileUploadModel.type}TitleEn`, lan)}
              className="formInputs"
            />
            {error.nameEn && <p className="errorText mb-0">{error.nameEn}</p>}
          </div>
          <div className="my-3">
            <label htmlFor="ModuleDescSwe">
              <p className="smallText inputText boldText pWithMargin">
                {t(fileUploadModel.type, lan)+' ' + t("DescInSw", lan)}
              </p>
            </label>
            <textarea
              required
              rows={3}
              id="ModuleDescSwe"
              value={fileUploadModel.descSw}
              onChange={(e) => handleChange(e.target.value, "descSw")}
              placeholder={t("EnterDescFile", lan)}
              className="formInputs textArea"
            />
            {error.descSw && <p className="errorText mb-0">{error.descSw}</p>}
          </div>
          <div className="my-3">
            <label htmlFor="ModuleDecEng">
              <p className="smallText inputText boldText pWithMargin">
                {t(fileUploadModel.type, lan)+' ' + t("DescInEn", lan)}
              </p>
            </label>
            <textarea
              required
              rows={3}
              id="ModuleDecEng"
              value={fileUploadModel.descEn}
              onChange={(e) => handleChange(e.target.value, "descEn")}
              placeholder={t("EnterDescFile", lan)}
              className="formInputs textArea"
            />
            {error.descEn && <p className="errorText mb-0">{error.descEn}</p>}
          </div>

          <div className="my-3">
            <label>
            <p className="smallText inputText boldText pWithMargin">
                {`${t("Choose", lan)}  ${t(
                  fileUploadModel.type == "File" ? "" : 'video',
                  lan
                )} ${t("file", lan)}`}
              </p>
            </label>
            <EddiDropzone
              dragText={t("DragDrop", lan)}
              selectedFiles={fileUploadModel.file}
              accept={
                fileUploadModel.type === "Video" ? "video" : "application"
              }
              choosedFile={(e) => {e.length>0 && handleChange([e[e?.length-1]], "file");handleChange(e[e.length-1], "fileName")}}
            />
            {error.file && <p className="errorText mb-0">{error.file}</p>}
            {fileUploadModel.file.length > 0 && (
              <div className="my-3 popup-file-list">
                <div className="img-div">
                  <img
                    className="main-img-material"
                    src={
                      fileUploadModel.type == "Video"
                        ? IcPlay
                        : UploadImage
                    }
                    alt="drop"
                  />
                </div>
                <span className="mx-3 w-75 text-break f-18 align-self-center">
                  {fileUploadModel.file[0]?.name?.slice(0, 60) || fileUploadModel.fileName||''}
                </span>
                <BsXSquareFill
                  onClick={() => handleChange([], "file")}
                  className="textPrimaryColor cursorPointer f-20"
                />
              </div>
            )}
          </div>
          {loaderBtn && (
          <div className="progress mt-4">
            <div
              className="progress-bar bg-success progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={uploadPercentage}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${uploadPercentage}%` }}
            ></div>
          </div>
        )}

          <button
            className="primaryButton"
            disabled={loaderBtn}
            onClick={onHandleAdd}
          >
            {loaderBtn ? (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : fileUploadModel.isAdd ? (
              t("Add", lan) + " " + t(`${fileUploadModel.type.toLocaleLowerCase()}`,lan)
            ) : (
              t("Update", lan) + " " + t(`${fileUploadModel.type.toLocaleLowerCase()}`,lan)
            )}
          </button>
        </div>

    
      </SidePopup>

      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupMessage}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>

      <Popup
        show={popupDelete !== "" ? true : false}
        header={t("DeletePopUp", lan)}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">{/* <p>{popupStatus}</p> */}</div>
        <div className="row justify-content-around w-100">
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase  mt-2  col-md-6 col-sm-6 col-xs-12"
          >
            {t("CancleText", lan)}
          </button>

          <button
            onClick={deleteMaterialCall}
            className="btn btn-danger text-uppercase  mt-2 col-md-5 col-sm-5 col-xs-12 "
          >
            {t("DeleteText", lan)}
          </button>
        </div>
      </Popup>
    </div>
  );
}

export default ManageMaterial;
