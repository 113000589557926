import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BoxUserIcon from "../../assets/images/BoxUserIcon.svg";
import { getTranslatedText as t } from "../../translater/index";
import { useSelector } from "react-redux";
import "./TopCounterBox.scss";

function TopCounterBox(props) {
  const state = useSelector((state) => state?.Eddi?.UserDetail);

  let mobilemenustatus = false;
  function mobilemenu() {
    let Element = document.getElementById("navbarSupportedContent");
    if (!mobilemenustatus) {
      Element.style.display = "block";
      mobilemenustatus = true;
    } else {
      Element.style.display = "none";
      mobilemenustatus = false;
    }
  }
  return (
    <div className="TopBox">
      <div
        className="TopBoxdetails col-12"
        style={{ backgroundColor: props.bgColor }}
      >
        <div className="topline">
          <h4>{props.boxTitle}</h4>
          {props.isWithIcon && <span>
            <img src={BoxUserIcon} />
          </span>}
        </div>
        <div className="numbers">{props.number}</div>
      </div>
    </div>
  );
}

export default TopCounterBox;
