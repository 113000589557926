import React, { Fragment, useEffect, useState } from "react";
import "./paymentDetail.scss";
import { getTranslatedText as t } from "../../../../../translater/index";
import API from "../../../../../api";

import { useSelector } from "react-redux";
import IcFilter from "../../../../../assets/images/IcFilter.svg";
import IcSearch from "../../../../../assets/images/icSearch.svg";
import FilterSelectMenu from "../../../../../components/FilterSelectMenu/FilterSelectMenu";
import InputText from "../../../../../components/inputText/inputText";

import { Link, useHistory, useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";
import Footer from "../../../../../components/footer/Footer";
import { useDispatch } from "react-redux";
import Popup from "../../../../../components/popup/popup";
import { getPaymentDetailById, sendPaymentPayout } from "../../../../../services/adminService";
import { toast } from "react-toastify";

function PaymentDetail() {
  let state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );
  const [popup, setPopup] = useState(false);
  const [loderBtn, setLoderBtn] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState();
  const [ammountEntered, setAmmountEntered] = useState();

  useEffect(() => {
    setIsLoader(true);
    getPaymentDetailById(params?.id)
      .then((res) => {
        setIsLoader(false);
        if (res?.status == "success") {
          setPaymentDetail(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  }, []);
  
  const validate = ()=>{
    if(!ammountEntered || ammountEntered?.trim()===""){
        toast.error('Please Enter ammount')
        return false
    }else if(ammountEntered <20){
        toast.error('Minimum 20 SEK required')
        return false
    }else if(ammountEntered >paymentDetail?.total_amount_due){
        toast.error('You can not sent more than due ammount ')
        return false
    }else{
        return true
    }
  }

  const sendPayment = () => {
    let validation = validate()
    if(validation){
      console.log(ammountEntered)

      let formData = new FormData()
      formData.append('amount',ammountEntered)
      setLoderBtn(true)
      sendPaymentPayout(paymentDetail.uuid,formData).then((res)=>{
        setLoderBtn(false)
        if(res.status == 'success'){
          handleClosePopUp()
          toast.success(lan=='en' ? res.data : res.data_sv)
          setTimeout(()=>{
            history.push(`/supplier/payment-list/${params?.id}`)
          },2000)
        }else{
          toast.error(lan=='en' ? res.data : res.data_sv)
        }

      }).catch((e)=>{setLoderBtn(false);console.log(e)})
    }
  };
  const handleClosePopUp = () => {
    setPopup(false);
    setLoderBtn(false)
    startScroll();
    setAmmountEntered();
  };
  const startScroll = () => {
    const body = document.querySelector("body");
    body.style.overflow = "scroll";
  };

  const preventScroll = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  };

  return (
    <div className="batchDetail">
      <div className="fix_height">
        <div className="subCategoryHeader ">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/payment-list")}
            >
              {t("PaymentList", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">
              {t("ViewPaymentDetails", lan)}
            </span>
          </p>
        </div>
        <Fragment>
          <div className="subCategoryListDiv">
            <div className="rc-paymentList">
              <div className="table-htext">
                <h3>{t("AllPaymentDetails", lan)}</h3>
                <p>{t("PaymentDetailsText", lan)}</p>
              </div>
              <table className="subCategoryListTable ">
                <tr className="payment-dHead">
                  <th>{`${t("SupplierName", lan)}`} </th>
                  <th> {` ${t("TotalEarning", lan)}`}</th>
                  <th> {` ${t("Commission", lan)}`}</th>
                  <th> {` ${t("AdminFee", lan)}`}</th>
                  <th> {` ${t("TotalPaid", lan)}`}</th>
                  <th> {` ${t("TotalDue", lan)}`}</th>
                  <th> {` ${t("Action", lan)} `}</th>
                </tr>

                <tbody>
                  <tr className="subCategoryTableInfo">
                    <td>{`${paymentDetail?.supplier?.first_name} ${paymentDetail?.supplier?.last_name}`}</td>
                    <td>{paymentDetail?.total_earnings || 0}</td>
                    <td>{paymentDetail?.commission ? paymentDetail?.commission+'%' : '-'}</td>
                        <td>{paymentDetail?.admin_fee ? paymentDetail?.admin_fee+'%' : '-'}</td>
                    <td>{paymentDetail?.total_amount_withdraw || 0}</td>
                    <td>{paymentDetail?.total_amount_due || 0}</td>
                    <td>
                      <button
                        className="primaryButton mt-0"
                        onClick={() => {
                          preventScroll();
                          setPopup(true);
                        }}
                      >
                        {` ${t("SendPayment", lan)}  `}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Fragment>

        <div className="popup-start">
          <Popup
            show={popup}
            header={t("PayOut", lan)}
            handleClose={handleClosePopUp}
          >
            <div className="userRatingList">
              <div className="sidepopupContents">
                <label htmlFor="ammount">
                  <p className="smallText inputText boldText pWithMargin">
                    {` ${t("EnterAmmount", lan)}`}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <input
                  type="number"
                  id="ammount"
                  placeholder={`${t("EnterAmmount", lan)}`}
                  className="formInputs"
                  onChange={(e) =>{ setAmmountEntered(e?.target?.value);}}
                />
              </div>

              <button
                className="primaryButton"
                disabled={loderBtn}
                onClick={() => {
                  sendPayment();
                }}
              >
                {loderBtn ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  t("SendPayment", lan)
                )}
              </button>
            </div>
          </Popup>
        </div>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default PaymentDetail;
