import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CustomPagination.scss";
import ArrowNext from "../../assets/images/arrow_right.svg";
import ArrowPrev from "../../assets/images/arrow_left.svg";
import { getTranslatedText as t } from "../../translater/index";

function CustomPagination(props) {
  return (
    <div className="CustomPagination">
      <span>{props.startIndex}-{props.endIndex}</span> of
      <span>{props?.totalData}</span>
      <div className="CustomPaginationButton">
        <button>
          <img
            src={ArrowPrev}
            onClick={props.startIndex == 1 ? null : props.onPrev}
          />
        </button>
        <button>
          <img
            src={ArrowNext}
            onClick={props.endIndex >= props?.totalData ? null : props.onNext}
          />
        </button>
      </div>
    </div>
  );
}

export default CustomPagination;
