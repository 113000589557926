let platform;
// const backendUrl = process.env[`REACT_APP_${platform}_BACKEND_URL`];

if (window.location.href.includes("testyourapp") || window.location.href.includes("localhost")) {
    localStorage.setItem("Platform","DEV");
    platform="DEV"
    // platform="PROD"
  }
  else if (window.location.href.includes("nu")) {
    localStorage.setItem("Platform","PROD");
    platform="PROD"
  }
  const backendUrl = process.env[`REACT_APP_${platform}_BACKEND_URL`];
  // const backendUrl = process.env[`REACT_APP_${platform}_BACKEND_URL`];
  // console.log("backendurl",backendUrl)
  // console.log("process env",process.env)
  // console.log("dev",process.env[`REACT_APP_DEV_BACKEND_URL`]);
  // console.log("prod",process.env[`REACT_APP_PROD_BACKEND_URL`]);


export default {
    // backendApi: "http://127.0.0.1:8000",
    backendApi: backendUrl
    // backendApi: "https://46ef-103-85-10-23.in.ngrok.io"
}
