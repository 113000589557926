import React, { useEffect, useState } from "react";
import "./RecuritmentAds.scss";
import placeholder from "../../../assets/images/placeholder.svg";
import { getTranslatedText as t } from "../../../translater/index";
import SidePopup from "../../../components/sidePopup/sidePopup";
import API from "../../../api";
import UploadImage from "../../../assets/images/uploadImage.png";
import { useSelector } from "react-redux";
import NoData from "../../../components/NoData/NoData";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../assets/images/IcFilter.svg";
import IcSearch from "../../../assets/images/icSearch.svg";
import FilterSelectMenu from "../../../components/FilterSelectMenu/FilterSelectMenu";
import InputText from "../../../components/inputText/inputText";

import { Link, useHistory } from "react-router-dom";
import {
  deleteRecuritmentAds,
  editRecuritmentAdsApi,
  getRecuritmentAdsApi,
  postRecuritmentAdsApi,
} from "../../../services/supplierService";
import Popup from "../../../components/popup/popup";
import Loader from "../../../components/Loader/Loader";
import Footer from "../../../components/footer/Footer";

function RecuritmentAds() {
  const initialAdsDetails = {
    ads_image: null,
    ads_name: null,
    ads_description: null,
    ads_bannerVideoLink: null,
    ads_expiry: null,
  };

  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const history = useHistory();
  const [searchData, setSearchData] = useState([]);
  const [addAdsPopup, setAddAdsPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);
  const [editAds, setEditAds] = useState(null);
  const [adsImage, setAdsImage] = useState(null);
  const [filteredAds, setFilteredAds] = useState();
  const [ads, setAds] = useState([]);
  const [paginationAds, setPaginationAds] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );
  const [addAdsDetail, setAdsDetails] = useState(initialAdsDetails);
  const [error, setError] = useState({
    imageError: "",
    nameError: "",
    descError: "",
    dateError: "",
    bannerLinkError: "",
  });

  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [isLoader, setIsLoader] = useState(false);

  const recuritmentAdsApiCall = async () => {
    setIsLoader(true);
    getRecuritmentAdsApi().then((res) => {
      setIsLoader(false);
      if (res?.status == "success") {
        setAds(res?.data);
        setSearchData(res?.data);
      }
    });
  };

  useEffect(async () => {
    await recuritmentAdsApiCall();
  }, []);

  useEffect(() => {
    if (
      addAdsDetail?.ads_image != null &&
      addAdsDetail?.ads_image !== undefined
    ) {
      let image = document?.getElementById("output");
      if (image) {
        image.src = URL.createObjectURL(addAdsDetail?.ads_image);
      }
    }
  }, [addAdsDetail?.ads_image]);

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredAds?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationAds(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredAds]);

  useEffect(() => {
    setPaginationStartIndex(1);
  }, [filteredAds, searchData]);

  useEffect(() => {
    if (editAds !== null && editAds !== undefined) {
      // setSubCategoryName(editAds.recruitmentAd_title);
      setAddAdsPopup(true);
    } else {
      // setSubCategoryName("");
    }
  }, [editAds]);

  function adsComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function adsComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date1 - date2;
  }
  const handleSearchData = (e) => {
    let searchData = [];
    if (e?.target?.value !== "") {
      ads?.map((ad) => {
        if (
          ad?.recruitmentAd_title
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(ad);
        }
      });
    } else {
      searchData = ads;
    }

    setSearchData(searchData);
  };

  const filterAdsFun = () => {
    let filteredbyTime = [];

    if (selectedTimeFilter === "Newest") {
      filteredbyTime = searchData.sort(adsComparisonbyNewestDate);
    } else {
      filteredbyTime = searchData?.sort(adsComparisonbyOldestDate);
    }

    setFilteredAds(filteredbyTime);
  };

  useEffect(() => {
    filterAdsFun();
    pagination();
    setPaginationStartIndex(1);
  }, [selectedTimeFilter, searchData]);

  const handleClosePopup = () => {
    setAddAdsPopup(false);
    setAdsDetails(initialAdsDetails);
    setEditAds(null);
    setError({
      nameError: "",
      imageError: "",
      descError: "",
      dateError: "",
      bannerLinkError: "",
    });
  };

  const onApproveReject = (e, id, type) => {
    let formData = new FormData();
    if (type == "Approval") {
      formData.append("approval_status", e?.target?.value);
    } else {
      formData.append("status", e?.target?.value);
    }
    editRecuritmentAdsApi(id, formData)
      .then((result) => {
        if (result?.status == "success") {
          recuritmentAdsApiCall();
          // setPopupStatus(t("success",lan));
          // setPopupMessage(result?.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const validate = () => {
    var urlRegex = new RegExp(
      "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    );

    if (
      !addAdsDetail?.ads_image &&
      !addAdsDetail?.ads_name &&
      !addAdsDetail?.ads_description &&
      !addAdsDetail?.ads_expiry
    ) {
      setError({
        nameError: "*Name is Required",
        imageError: "*Image is Required",
        descError: "*Description is Required",
        dateError: "*Date is Required",
      });
      return false;
    } else if (!addAdsDetail?.ads_image) {
      setError({ imageError: "*Image is Required" });
      return false;
    } else if (
      !addAdsDetail?.ads_name ||
      addAdsDetail?.ads_name?.trim() == ""
    ) {
      setError({ nameError: "*Name is Required" });
      return false;
    } else if (
      !addAdsDetail?.ads_description ||
      addAdsDetail?.ads_description?.trim() == ""
    ) {
      setError({ descError: "*Description is Required" });
      return false;
    } else if (!addAdsDetail?.ads_expiry) {
      setError({ dateError: "*Date is Required" });
      return false;
    } else if (
      addAdsDetail?.ads_bannerVideoLink &&
      !urlRegex.test(addAdsDetail?.ads_bannerVideoLink)
    ) {
      setError({ bannerLinkError: "*Enter Valid Link" });
      return false;
    } else {
      return true;
    }
  };

  const postRecuritmentAds = async () => {
    const validation = await validate();
    if (validation == true) {
      setLoderBtn(true);
      var data = new FormData();
      data.append("recruitmentAd_File", addAdsDetail?.ads_image);
      data.append("recruitmentAd_title", addAdsDetail?.ads_name);
      data.append("recruitmentAd_description", addAdsDetail?.ads_description);
      data.append(
        "recruitmentAd_banner_video_link",
        addAdsDetail?.ads_bannerVideoLink
      );
      data.append("recruitmentAd_Expiry", addAdsDetail?.ads_expiry);

      postRecuritmentAdsApi(data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            recuritmentAdsApiCall();
            setPopupStatus(t("Success", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            setPopupStatus(t("Failed", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const editRecuritmentAds = async () => {
    const validation = await validate();
    if (validation == true) {
      setLoderBtn(true);

      var data = new FormData();
      data.append("recruitmentAd_File", addAdsDetail?.ads_image);
      data.append("recruitmentAd_title", addAdsDetail?.ads_name);
      data.append("recruitmentAd_description", addAdsDetail?.ads_description);
      data.append(
        "recruitmentAd_banner_video_link",
        addAdsDetail?.ads_bannerVideoLink
      );
      data.append("recruitmentAd_Expiry", addAdsDetail?.ads_expiry);

      editRecuritmentAdsApi(editAds?.uuid, data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            recuritmentAdsApiCall();
            setPopupStatus(t("success",lan));
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            setPopupStatus("Failed!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const deleteRecruitmentAdsCall = (uuid) => {
    deleteRecuritmentAds(uuid)
      .then((res) => {
        if (res.status == "success") {
          recuritmentAdsApiCall();
          setPopupStatus(t("success",lan));
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        } else {
          setPopupStatus("Failed!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
  };

  return (
    <div className="recuritmentAds">
      <div className="fix_height">
        <div className="subCategoryHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">{t("RecruitmentAds", lan)}</span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchbyAdsName", lan)}
                onChange={handleSearchData}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="headerTab smallText position-relative">
                <span className="iconsSelect">
                  <img src={IcFilter} />
                </span>
                <FilterSelectMenu
                  placeholder=""
                  value={["Newest", "Oldest"]}
                  selected={selectedTimeFilter}
                  onChange={(e) => {
                    setSelectedTimeFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div>

              {/* <div className="headerTab smallText position-relative">
                        <span className="iconsSelect">
                            <img src={IcFilter2} />
                        </span>
                        <FilterSelectMenu
                            placeholder="Filter By"
                            value={["Paid", "Free"]}
                            onChange={(e) => {
                            // setSelectedPriceFilter(e.target.value);
                            }}
                            isWithicon
                        />
                    </div> */}
              {!isAdmin && (
                <button
                  className="primaryButton mt-0"
                  onClick={() => {
                    setAddAdsPopup(true);
                  }}
                >
                  {` ${t("Add", lan)} ${t("Adss", lan)} `}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="subCategoryListDiv">
          <table className="subCategoryListTable rc-ads">
            <tr className="whitespaceNoWrap">
              <th hidden={!isAdmin}>
                {" "}
                {` ${t("Created", lan)} ${t("by", lan)}`}
              </th>
              <th hidden={isAdmin}>{` ${t("Ads", lan)} ${t("Id", lan)}`} </th>
              <th> {` ${t("Ads", lan)} ${t("name", lan)}`}</th>
              <th> {` ${t("Total", lan)} ${t("viewers", lan)}`}</th>
              {/* <th>Date Created</th> */}
              <th> {` ${t("Approve", lan)} / ${t("Reject", lan)}`}</th>
              <th hidden={isAdmin}> {` ${t("Status", lan)} `}</th>
              <th>{` ${t("Action", lan)} `}</th>
            </tr>

            {paginationAds?.length > 0 &&
              paginationAds?.map((Ads, index) => {
                return (
                  <tr key={index} className="subCategoryTableInfo">
                    <td hidden={!isAdmin}>
                      <div className="d-flex">
                        <img
                          src={
                            Ads?.supplier_profile?.supplier_image
                              ? `${Ads?.supplier_profile?.supplier_image}`
                              : placeholder
                          }
                          className="spplierImg"
                        />
                        <div>
                          <p className="mb-1">
                            {Ads?.supplier_profile?.supplier_name || "-"}
                          </p>
                          <p className="mb-1">
                            {Ads?.supplier_profile?.supplier_email || "-"}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td hidden={isAdmin}>{Ads.id}</td>
                    <td>{Ads.recruitmentAd_title}</td>
                    <td>{Ads.subscriber_count}</td>
                    <td>
                      {isAdmin ? (
                        <select
                          value={Ads.is_approved?.value}
                          onChange={(e) => {
                            onApproveReject(e, Ads?.uuid, "Approval");
                          }}
                          className={
                            Ads.is_approved?.value == "Pending"
                              ? "StatusActive"
                              : Ads?.is_approved?.value == "Rejected"
                              ? "StatusInActive"
                              : "StatusApproved"
                          }
                        >
                          <option value={"Pending"}>{` ${t(
                            "Pending",
                            lan
                          )} `}</option>
                          <option value={"Approved"}>{` ${t(
                            "Approved",
                            lan
                          )} `}</option>
                          <option value={"Rejected"}>{` ${t(
                            "Rejected",
                            lan
                          )} `}</option>
                        </select>
                      ) : (
                        <span
                          style={{ backgroundImage: "none" }}
                          className={
                            Ads.is_approved?.value == "Pending"
                              ? "StatusActive"
                              : Ads?.is_approved?.value == "Rejected"
                              ? "StatusInActive"
                              : "StatusApproved"
                          }
                        >
                          {Ads?.is_approved?.value || "Pending"}
                        </span>
                      )}
                    </td>
                    <td hidden={isAdmin}>
                      {!Ads.is_approved?.value ||
                      Ads.is_approved?.value == "Pending" ||
                      Ads.is_approved?.value == "Rejected" ? (
                        <p>--</p>
                      ) : (
                        <select
                          value={Ads?.status?.value}
                          onChange={(e) => {
                            onApproveReject(e, Ads?.uuid, "Activation");
                          }}
                          className={
                            Ads?.status?.value == "Active"
                              ? "StatusActive"
                              : "StatusInActive"
                          }
                        >
                          <option value={"Active"}>{` ${t(
                            "Active",
                            lan
                          )} `}</option>
                          <option value={"Inactive"}>{`${t(
                            "inActive",
                            lan
                          )} `}</option>
                        </select>
                      )}
                    </td>
                    <td className="whitespaceNoWrap">
                      <Link
                        to={`/supplier/recuritmentAdsDetails/details/${Ads?.uuid}`}
                        className="cursorPointer textPrimaryColor linkDecoreRemove"
                        onClick={() => {
                          // setEditAds(Ads);
                        }}
                      >
                        {` ${t("View", lan)} `}
                      </Link>{" "}
                      {!isAdmin && (
                        <>
                          |{" "}
                          <span
                            className="cursorPointer textPrimaryColor"
                            onClick={() => {
                              setEditAds(Ads);
                              setAdsDetails({
                                ads_image: Ads?.recruitmentAd_File,
                                ads_name: Ads?.recruitmentAd_title,
                                ads_description: Ads?.recruitmentAd_description,
                                ads_bannerVideoLink:
                                  Ads?.recruitmentAd_banner_video_link,
                                ads_expiry: Ads?.recruitmentAd_Expiry,
                              });
                            }}
                          >
                            {` ${t("Edit", lan)} `}
                          </span>{" "}
                          |{" "}
                          <span
                            className="cursorPointer textPrimaryColor"
                            onClick={() => {
                              deleteRecruitmentAdsCall(Ads.uuid);
                            }}
                          >
                            {` ${t("Delete", lan)} `}
                          </span>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </table>
          {paginationAds?.length <= 0 && (
            <div>
              <NoData />
            </div>
          )}
        </div>
        <div className="mt-3 text-end">
          <div className="row">
            {filteredAds?.length > 12 && (
              <CustomPagination
                startIndex={paginationStartIndex}
                endIndex={
                  paginationStartIndex + 11 > filteredAds?.length
                    ? filteredAds?.length
                    : paginationStartIndex + 11
                }
                totalData={filteredAds?.length}
                onPrev={paginationPrev}
                onNext={paginationNext}
              />
            )}
          </div>
        </div>

        <SidePopup
          header={
            editAds !== null
              ? ` ${t("Update", lan)} ${t("Recruitment", lan)}  ${t("Ad", lan)}`
              : ` ${t("Add", lan)} ${t("Recruitment", lan)}  ${t("Ad", lan)}`
          }
          show={addAdsPopup}
          handleClose={handleClosePopup}
        >
          <div className="sidepopupContents">
            <label>
              <span className="customeFileUpload">
                <img
                  src={
                    editAds != null && editAds !== undefined
                      ? `${editAds?.recruitmentAd_File}`
                      : addAdsDetail?.ads_image !== null &&
                        addAdsDetail?.ads_image !== undefined
                      ? addAdsDetail?.ads_image?.name
                      : UploadImage
                  }
                  alt="Upload Image"
                  className="uploadImage"
                  id="output"
                />
                <span className="uploadImageText">
                  {addAdsDetail?.ads_image !== null &&
                  addAdsDetail?.ads_image !== undefined ? (
                    <p className="smallText">
                      {addAdsDetail?.ads_image?.name
                        ? addAdsDetail?.ads_image?.name
                        : addAdsDetail?.ads_image.split("/").pop()}
                    </p>
                  ) : editAds != null && editAds !== undefined ? (
                    <p className="smallText imageMaxText">
                      {editAds?.recruitmentAd_File?.split("/").slice(-1)}
                    </p>
                  ) : (
                    // .split("/").pop())
                    <>
                      <p className="smallText">
                        {" "}
                        {t("UploadBannerImages", lan)}
                        <span className="text-danger">*</span>{" "}
                      </p>

                      {/* <p className="superSmallText">
                      Note:- If supplier don’t upload any course image than this
                      will <br />
                      be default course image
                    </p> */}
                      {/* {imageError && (
                      <p className="errorText mb-0">{imageError}</p>
                    )} */}
                    </>
                  )}
                </span>
                <input
                  type="file"
                  className="inputTypeFile"
                  multiple={false}
                  accept=".jpeg,image/*,.png"
                  onChange={(e) => {
                    // setAdsImage(e.target.files[0]);
                    setError({ ...error, imageError: "" });
                    setAdsDetails({
                      ...addAdsDetail,
                      ads_image: e.target.files[0],
                    });
                    //   setImageError("");
                  }}
                />
              </span>
              {error?.imageError && (
                <p className="errorText mb-0">{error?.imageError}</p>
              )}
            </label>

            <label htmlFor="adsName">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("Ads", lan)} ${t("Name", lan)} / ${t("Title", lan)}`}
                <span className="text-danger">*</span>
              </p>
            </label>
            <input
              type="text"
              id="adsName"
              maxlength="80"
              placeholder={` ${t("Enter", lan)} ${t("Ads", lan)}  ${t(
                "name",
                lan
              )}`}
              className="formInputs"
              value={addAdsDetail?.ads_name}
              onChange={(e) => {
                setError({ ...error, nameError: "" });
                setAdsDetails({
                  ...addAdsDetail,
                  ads_name: e.target.value,
                });
              }}
            />
            {error?.nameError && (
              <p className="errorText mb-0">{error?.nameError}</p>
            )}

            <label htmlFor="adsDescription ">
              <p className="smallText inputText boldText pWithMargin">
                {" "}
                {` ${t("Ads", lan)}  ${t("Description", lan)} ${t(
                  "Name",
                  lan
                )}`}
                <span className="text-danger">*</span>
              </p>
            </label>
            <textarea
              rows={3}
              id="adsDescription"
              placeholder={` ${t("Ad", lan)} ${t("Description", lan)}`}
              className="formInputs textArea"
              value={addAdsDetail?.ads_description}
              onChange={(e) => {
                setError({ ...error, descError: "" });
                setAdsDetails({
                  ...addAdsDetail,
                  ads_description: e.target.value,
                });
              }}
            />
            {error?.descError && (
              <p className="errorText mb-0">{error?.descError}</p>
            )}

            <label htmlFor="videoLink">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("Banner", lan)}  ${t("Link", lan)}`}
              </p>
            </label>
            <input
              type="text"
              id="videoLink"
              placeholder={` ${t("Banner", lan)} ${t("Link", lan)}`}
              className="formInputs"
              value={addAdsDetail?.ads_bannerVideoLink}
              onChange={(e) => {
                setAdsDetails({
                  ...addAdsDetail,
                  ads_bannerVideoLink: e.target.value,
                });
                setError({ ...error, bannerLinkError: "" });
              }}
            />
            {error?.bannerLinkError && (
              <p className="errorText mb-0">{error?.bannerLinkError}</p>
            )}

            <div className="halfInputsDiv">
              <span>
                <label htmlFor="expireDate ">
                  <p className="smallText inputText boldText pWithMargin">
                    {" "}
                    {` ${t("Expiry", lan)}  ${t("Date", lan).toLowerCase()}`}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <input
                  onChange={(e) => {
                    setError({ ...error, dateError: "" });
                    setAdsDetails({
                      ...addAdsDetail,
                      ads_expiry: e.target.value,
                    });
                    //   setDateError("");
                  }}
                  max={`${new Date().getFullYear() + 10}-12-31`}
                  min={new Date().toISOString().split("T")[0]}
                  type="Date"
                  value={addAdsDetail?.ads_expiry}
                  id="expireDate"
                  placeholder="MM/DD/YY"
                  className="formInputs"
                />
                {error?.dateError && (
                  <p className="errorText mb-0">{error?.dateError}</p>
                )}
              </span>
            </div>

            {loderBtn ? (
              <button className="primaryButton" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className="primaryButton"
                onClick={() => {
                  // postRecuritmentAds();
                  editAds !== null
                    ? editRecuritmentAds()
                    : postRecuritmentAds();
                }}
              >
                {editAds !== null
                  ? ` ${t("Update", lan)} ${t(
                      "RecruitmentAd",
                      lan
                    ).toLowerCase()}`
                  : ` ${t("Add", lan)} ${t(
                      "RecruitmentAd",
                      lan
                    ).toLowerCase()}`}
              </button>
            )}
          </div>
        </SidePopup>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default RecuritmentAds;
