import axios from "axios"
import API from "../api";



const axiosInstance = axios.create({
  baseURL: `${API.backendApi}/`,
});

 function createRequestFunc(methods,url, body,headers) {
    let config = {
      method: methods,
      url,
      headers: headers,
      data:body,
    };
    return axiosInstance(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
}

export const loginApi = async(body) =>{
  return await createRequestFunc("post",`login/`,body,{
    "Content-Type": "multipart/form-data",
  });
}

export const resetPassApi = async(uuid,body) =>{
  return await createRequestFunc("post",uuid ? `resetpassword/${uuid}/` : `resetpassword/`,body,{
    "Authorization" : localStorage.getItem("Authorization"),
    "Content-Type": "multipart/form-data",
  });
}

export const forgotPassApi = async(body) =>{
  return await createRequestFunc("post",`forgot/`,body,{
    "Authorization" : localStorage.getItem("Authorization"),
    "Content-Type": "multipart/form-data",
  });
}

export const verifyStripeAccApi = async(body) =>{
  return await createRequestFunc("post",`save-stripe-account/`,body,{});
}
