import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { getTranslatedText as t } from "../../translater/index";

import "./BarChart.scss";

const BarChart = (props) => {
  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;
  const [ycategories, setycategories] = useState(
    Object.values(props?.state?.data)
  );
  const [xcategories, setxcategories] = useState(
    Object.keys(props?.state?.data)
  );

  const [data, setData] = useState({
    options: {
      colors: ["#1A4840"],
      chart: {
        id: "apexchart-example",
      },
      plotOptions: {
        bar: {
          columnWidth: "25px",

          dataLabels: {
            position: "top",
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: "horizontal",
          },
        },
      },
      // fill: ["#FF0000"],
      xaxis: {
        categories: xcategories?.map((item) => {
          return t(`${item}`, lan);
        }),
      },
    },

    columnWidth: 10,
    series: [
      {
        name: "series-1",
        data: ycategories,
      },
    ],
  });

  useEffect(() => {
    setxcategories(Object.keys(props?.state?.data));
    setycategories(Object.values(props?.state?.data));
  }, [props?.state]);

  useEffect(() => {
    setData({
      options: {
        colors: ["#1A4840"],
        chart: {
          id: "apexchart-example",
        },
        plotOptions: {
          bar: {
            columnWidth: "25px",

            dataLabels: {
              position: "top",
              maxItems: 100,
              hideOverflowingLabels: true,
              orientation: "horizontal",
            },
          },
        },
        // fill: ["#FF0000"],
        xaxis: {
          categories: xcategories?.map((item) => {
            return t(`${item}`, lan);
          }),
          // categories: xcategories,
        },
      },

      columnWidth: 10,
      series: [
        {
          name: "series-1",
          data: ycategories,
        },
      ],
    });
  }, [xcategories, ycategories, lan]);

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="bar"
      width="100%"
      height={320}
    />
  );
};

export default BarChart;
