import React, { useEffect, useState } from "react";
import "./CorporateCourseDetails.scss";
import "../../../../../common/styles/globalStyles.scss";
import { IoIosArrowBack } from "react-icons/io";
import {
  FaStar,
  FaUsers,
  FaCalendar,
  FaLevelUpAlt,
  FaClock,
  FaBookmark,
} from "react-icons/fa";
import { BsXSquareFill } from "react-icons/bs";
import Dummy from "../../../../../assets/images/dummy.png";
import { Link, useHistory, useParams } from "react-router-dom";
import IcReadmore from "../../../../../assets/images/ic_readmore.svg";
import IcReadless from "../../../../../assets/images/ic_readless.svg";
import IcTrue from "../../../../../assets/images/IcTrue.svg";
import Starrating from "../../../../../assets/images/Starrating.svg";

import SidePopup from "../../../../../components/sidePopup/sidePopup";
import EddiDropzone from "../../../../../components/dropzone/dropzone";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../../api";
import {
  addMaterialCourse,
  editMaterialCourse,
  getCorporateCourseApi,
  getCorporateCourseByID,
  getCourseDetailApi,
  getMaterialCourse,
} from "../../../../../services/supplierService";
import user from "../../../../../assets/images/noUser.svg";
import {
  isPublishedCourse,
  selectedUserEmail,
  viewCoursesDetails,
} from "../../../../../redux/actions";
import Popup from "../../../../../components/popup/popup";
import { getTranslatedText as t } from "../../../../../translater";
import Loader from "../../../../../components/Loader/Loader";
import api from "../../../../../api";
import { formatDate } from "../../../../../services/constant";
import NoData from "../../../../../components/NoData/NoData";
import Footer from "../../../../../components/footer/Footer";

const initialDetail = {
  video_name: null,
  video_file: null,
  file_name: null,
  choose_file: null,
};

function CorporateCourseDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const role = state?.UserDetail?.user_type;
  const params = useParams();

  const [selectedCourseDetails, setSelectedCourseDetails] = useState();

  const [uploadMaterialPopup, setUploadMaterialPopup] = useState(false);
  const [choosedVideos, setChoosedVideos] = useState([]);
  const [choosedDoc, setChoosedDoc] = useState([]);
  const [enrolledUser, setEnrolledUser] = useState();
  const [popupStatus, setPopupStatus] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);
  const [addMaterial, setAddMaterial] = useState(initialDetail);
  const [error, setError] = useState({
    vNameError: "",
    vFileError: "",
    fNameError: "",
    fFileError: "",
  });
  const [getMaterial, setMaterial] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [isEditMaterial, setIsEditMaterial] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [isAdmin, setIsAdmin] = useState(role == "Admin" ? true : false);
  const [ratingModal, setRatingModal] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [allCourseData, setAllCourseData] = useState();

  useEffect(() => {
    setIsLoader(true);
    getCorporateCourseByID(params?.id)
      .then((res) => {
        setIsLoader(false);
        if (res?.status == "success") {
          setEnrolledUser(res?.Enrolled);
          setAllCourseData(res);
          setSelectedCourseDetails(res.data);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const handleClosePopup = () => {
    setUploadMaterialPopup(false);
    setAddMaterial(initialDetail);
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    setRatingModal(false);
  };
  return (
    <div className="courseDetail">
      <div className="max_height">
        <div className="courseDetailHeader">
          <p className="midText">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/course")}
            >
              {t("ManageModuleAndContent", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">{` ${t(
              "CourseContent",
              lan
            )}`}</span>
          </p>
          <div className="courseDetailHeaderRight">
            <div className="d-flex align-items-center justify-content-between">
             {new Date(selectedCourseDetails?.course_expiry) < new Date() && <button onClick={()=>history.push(`/supplier/corporate/course/edit-course/${selectedCourseDetails.uuid}?republish=1`)} className="primaryButton mt-0">
                {t("Republish", lan)}
              </button>}

              <span
                className="headerTab smallText cursorPointer"
                onClick={() => {
                  window.history.back();
                }}
              >
                <IoIosArrowBack /> &nbsp;{t("Back", lan)}
              </span>
            </div>
          </div>
        </div>
        <div className="courseDetailsMain">
          <div className="courseDetailsMainDetailView">
            <div className="courseDetailsMainDetailViewLeft">
              <span className="summaryDetail">
                <div className="imgBlock">
                  <img
                    src={
                      selectedCourseDetails?.course_image
                        ? `${selectedCourseDetails?.course_image}`
                        : Dummy
                    }
                    className="courseDetailImage obj-contain"
                  />
                </div>
                <span className="ms-lg-2">
                  <p
                    className="midText semiBold mb-2 cursor-pointer"
                    onClick={
                      allCourseData?.rating?.length > 0
                        ? () => setRatingModal(true)
                        : ""
                    }
                  >
                    {selectedCourseDetails?.course_name || "-"}{" "}
                    {allCourseData?.final_rating && (
                      <div>
                        <FaStar className="starIcon" />{" "}
                        <span className="superSmallText">
                          {" "}
                          {allCourseData?.final_rating
                            ? allCourseData.final_rating?.slice(0, 3)
                            : "-"}
                        </span>
                      </div>
                    )}
                  </p>
                  <p className="superSmallText mb-1">
                    {selectedCourseDetails?.course_category?.category_name ||
                      selectedCourseDetails?.course_category[
                        `category_${lan}`
                      ] ||
                      "-"}
                  </p>
                  <p className="superSmallText mb-1">
                    {selectedCourseDetails?.course_subcategory
                      ?.subcategory_name || "-"}
                  </p>
                  <p className="superSmallText mb-1">
                    {selectedCourseDetails?.sub_area || "-"}
                  </p>
                  <p className="superSmallText mb-2">
                    {` ${t("Course", lan)} ${t("Type", lan)} `} -{" "}
                    {selectedCourseDetails?.course_type?.type_name || "-"}
                  </p>
                  <p className="pWithMargin midText boldText whitespaceNoWrap">
                    <Link
                      className="uploadMaterialButton"
                      onClick={() =>
                        dispatch(
                          isPublishedCourse(selectedCourseDetails?.is_published)
                        )
                      }
                      to={`/supplier/corporate/course/module/${params.id}?is_published=${selectedCourseDetails?.is_published}`}
                    >
                      {isEditMaterial
                        ? `${t("ManageCourseContent", lan)}`
                        : `${t("ManageCourseContent", lan)}`}
                    </Link>
                  </p>
                </span>
              </span>
              <span className="pWithMargin">
                {t("PostedBy",lan)}
                :{" "}
                <span className="textPrimaryColor">
                  {selectedCourseDetails?.supplier?.first_name
                    ? selectedCourseDetails?.supplier?.first_name
                    : "-"}
                </span>
                , {t("on",lan)} {formatDate(selectedCourseDetails?.created_date_time)}
              </span>
             {selectedCourseDetails?.is_published && <span className="mx-2">
                <img className="me-1" src={IcTrue} alt="published"></img>
                <span className="published">{t("Published", lan)} </span>
              </span>}
              <p className="boldText pWithMargin">
                {" "}
                {`${t("CourseDescription", lan)} `}
              </p>
              {selectedCourseDetails?.additional_information?.length < 150 ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedCourseDetails?.additional_information,
                  }}
                  className
                ></p>
              ) : (
                <>
                  <p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: isReadMore
                          ? selectedCourseDetails?.additional_information?.slice(
                              0,
                              150
                            )
                          : selectedCourseDetails?.additional_information,
                      }}
                      className
                    ></p>
                    <div
                      hidden={
                        selectedCourseDetails?.additional_information?.length >
                        150
                          ? false
                          : true
                      }
                    >
                      <span
                        onClick={() => setIsReadMore(!isReadMore)}
                        className="cursor-pointer mt-3"
                      >
                        <img
                          hidden={
                            selectedCourseDetails?.additional_information
                              ?.length > 150
                              ? false
                              : true
                          }
                          height={18}
                          style={{ marginTop: "-4px" }}
                          src={isReadMore ? IcReadmore : IcReadless}
                          className="me-2"
                        />
                        {isReadMore
                          ? `${t("readmore", lan)}`
                          : `${t("readless", lan)}`}
                      </span>
                    </div>
                  </p>
                </>
              )}

              {/* <p className="text pWithMargin smallText">
              {isReadMore
                ? selectedCourseDetails?.additional_information?.slice(0, 150)
                : selectedCourseDetails?.additional_information}

              <div
                hidden={
                  selectedCourseDetails?.additional_information?.length > 150
                    ? false
                    : true
                }
              >
                <span
                  onClick={() => setIsReadMore(!isReadMore)}
                  className="cursor-pointer mt-3"
                >
                  <img
                    hidden={
                      selectedCourseDetails?.additional_information?.length >
                      150
                        ? false
                        : true
                    }
                    height={18}
                    style={{ marginTop: "-4px" }}
                    src={isReadMore ? IcReadmore : IcReadless}
                    className="me-2"
                  />
                  {isReadMore
                    ? `${t("readmore", lan)}`
                    : `${t("readless", lan)}`}
                </span>
              </div>
            </p> */}
            </div>
            <div className="courseDetailsMainDetailViewRight">
              <span className="courseDetailRightContent">
                <FaUsers className="textPrimaryColor midText" /> &nbsp; &nbsp;
                {state?.viewCoursesDetails?.learners_count || 0}{" "}
                {t("Learners", lan)}
              </span>
              <span className="courseDetailRightContent">
                <FaCalendar className="textPrimaryColor midText" /> &nbsp;
                &nbsp;{t("StartFrom",lan)}{" "}
                {selectedCourseDetails?.course_starting_date || "-"}
              </span>
              <span className="courseDetailRightContent">
                <FaLevelUpAlt className="textPrimaryColor midText" /> &nbsp;
                &nbsp;{t(selectedCourseDetails?.course_level?.level_name||'',lan)}
              </span>
              <span className="courseDetailRightContent">
                <FaClock className="textPrimaryColor midText" /> &nbsp; &nbsp;
                {selectedCourseDetails?.course_length} {t("Days", lan)}
              </span>
              <span className="courseDetailRightContent">
                <FaBookmark className="textPrimaryColor midText" /> &nbsp;
                &nbsp;
                {selectedCourseDetails?.fee_type?.fee_type_name != "Free"
                  ? `SEK ${selectedCourseDetails?.course_price || 0}`
                  : t("Free",lan)}
              </span>
            </div>
          </div>
          <div className="courseDetailsMainListView">
            <p>{t("TotalIndiCourse", lan)}</p>
            <div className="courseDetailsMainListTable">
              <table className="courseListTable desktop-list">
                {enrolledUser?.length > 0 ? (
                  enrolledUser?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td width="64px">
                            <img
                              src={
                                item?.profile_image
                                  ? `${item?.profile_image}`
                                  : user
                              }
                              alt="Avtar"
                              className="courseAvtars"
                            />
                          </td>
                          <td>
                            {`${item?.full_name ||''}`} <br />{" "}
                            <span className="superSmallText textGrayColor">
                              {item?.email ||''}
                            </span>
                          </td>
                          <td>
                            {` ${t("Contact", lan)} ${t("Information", lan)} `}{" "}
                            <br />{" "}
                            <span className="superSmallText textGrayColor">
                              {item?.contact_number || "-"}
                            </span>
                          </td>
                          <td>
                            {t("Action", lan)} <br />{" "}
                            <Link
                              to={`/supplier/manage-corporate-users/users-detail/${item?.uuid}`}
                              className="linkDecoreRemove textPrimaryColor"
                            >
                              {t("View", lan)}
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    <NoData />
                  </>
                )}
              </table>

              {/* <div className="mobile-list-blk">
              <ul>
                <li>
                  <div className="img-block">
                    <img
                      src={IcReadmore}
                      alt="Avtar"
                      className="courseAvtars"
                    />
                  </div>
                  <div className="list-details">
                    <div>
                      <h6>Nishant user</h6>
                      <div>
                        <span>mail</span>
                        <span>123</span>
                      </div>
                    </div>
                    <div>
                      <Link
                        to="/"
                        className="linkDecoreRemove textPrimaryColor"
                      >
                        {t("View", lan)}
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div> */}
            </div>
          </div>
        </div>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={"Status"}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupStatus}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
        {isLoader ? <Loader /> : ""}

        <Popup
          show={ratingModal}
          header={allCourseData?.data?.course_name}
          handleClose={handleClosePopupStatus}
        >
          <div className="userRatingList">
            {allCourseData?.rating?.length > 0 ? (
              allCourseData?.rating?.map((rating, index) => {
                return (
                  <>
                    <div key={index} className="d-flex mb-3">
                      <div className="user-images">
                        <img
                          src={
                            rating?.user?.profile_image
                              ? `${rating?.user?.profile_image}`
                              : user
                          }
                        />
                      </div>
                      <div>
                        <h3>
                          {rating?.user?.first_name || "-"}
                          <span>
                            <img
                              height={18}
                              src={Starrating}
                              style={{ marginTop: "-6px" }}
                              className="me-1"
                            />
                            {rating?.star || "-"}
                          </span>
                        </h3>
                        <p>
                          {rating?.comment?.length < 100
                            ? rating?.comment
                            : `${rating?.comment?.slice(0, 100)}...`}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <NoData />
            )}
          </div>
        </Popup>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CorporateCourseDetails;
