import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import Boxarrow from "../../../assets/images/boxarrow.svg";
import IcSearch from "../../../assets/images/ic_serach.svg";
import amtDot from "../../../assets/images/amtDot.svg";
import { BsInfoCircle } from "react-icons/bs";
import "../dashboard/dashboard.scss";
import { getTranslatedText as t } from "../../../translater/index";
import TopCounterBox from "../../../components/TopCounterBox/TopCounterBox";
import Footer from "../../../components/footer/Footer";
import InputText from "../../../components/inputText/inputText";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import BarChart from "../../../components/BarChart/BarChart";
import DonutChart from "../../../components/DonutChart/DonutChart";
import {
  editCourseStatus,
  getCouseGraphApi,
  getEarningGraphApi,
  getOfferedCourse,
  getPersonalProfileDetail,
  getSubCategoryApi,
  organizationalProfile,
} from "../../../services/supplierService";
import { useDispatch, useSelector } from "react-redux";
import {
  additionalInfoData,
  courseCategoryDetails,
  courseGraphData,
  getAllApprovedSubCategory,
  getAllCourses,
  getAllUser,
  getPersonalProfileData,
  isApproved,
  organizationalInfoData,
  searchCourseText,
  selectedUserEmail,
  tabPageNo,
  viewCoursesDetails,
} from "../../../redux/actions";
import NoData from "../../../components/NoData/NoData";
import Loader from "../../../components/Loader/Loader";
import {
  adminDashboard,
  adminSupplierDataDashboard,
  adminUserDataDashboard,
  editDetailsUsers,
} from "../../../services/adminService";
import { formatDate } from "../../../services/constant";

function Dashboard(props) {
  const state = useSelector((state) => state?.Eddi);
  const usertype = state.UserDetail?.user_type;
  const dispatch = useDispatch();
  let lan = state?.language;
  const [isActive, setActive] = useState(false);
  const [paginationCourses, setPaginationCourses] = useState([]);

  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [supplierDashboardData, setSupplierDashboardData] = useState();
  const [changeFilterStatus, setFilterStatus] = useState("all");
  const [searchResult, setsearchResult] = useState();
  const email = localStorage.getItem("logedInEmail");
  const [isLoader, setIsLoader] = useState(false);
  const [userSearchText, setUserSearchText] = useState("");
  const [paginationUsers, setPaginationUsers] = useState([]);
  const [paginationStartIndexUser, setPaginationStartIndexUser] = useState(1);
  const [filteredUser, setFilteredUser] = useState([]);
  const [totalCourseCount, setTotalCourseCount] = useState(0);
  const [suplierCoursesCount, setSuplierCoursesCount] = useState(0);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [purchaseCount, setPurchaseCount] = useState(0);
  const [courseGraphMode, setCourseGraphMode] = useState("Weekly");
  const [courseGraphResponse, setCourseGraphResponse] = useState();
  const [earningGraphType, setEarningGraphType] = useState("Weekly");
  const [dashboardData, allDashboardData] = useState();
  const [firstTableData, setFirstTableData] = useState();
  const [secondTableData, setSecondTableData] = useState();
  const [adminSecondFilterStatus, setAdminSecondFilterStatus] = useState("all");
  const [profileCheck, setProfileCheck] = useState(true);
  const [myEarningData, setMyEarningData] = useState();

  function convertDataToObject(data) {
    const dataArray = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        dataArray.push(data[key][0]);
      }
    }

    return dataArray;
  }

  const adminData = async () => {
    setIsLoader(true);
    await adminDashboard()
      .then((result) => {
        setIsLoader(false);
        // setFirstTableData(result?.users);
        // setSecondTableData(result?.suppliers);
        allDashboardData(result);
      })
      .catch((e) => console.log(e));
    await adminUserDataDashboard()
      .then((result) => {
        setFirstTableData(result?.users);
      })
      .catch((e) => console.log(e));

    await adminSupplierDataDashboard()
      .then((result) => {
        setSecondTableData(result?.suppliers);
      })
      .catch((e) => console.log(e));
  };
  const allSubCategory = () => {
    getSubCategoryApi().then((result) => {
      if (result?.status == "success") {
        dispatch(getAllApprovedSubCategory(result?.data));
      }
    });
  };

  const ToggleClass = (e, index) => {
    let formData = new FormData();
    formData.append("status", e?.target?.value);
    formData.append("course_name", index?.course_name);
    formData.append("is_corporate", index?.is_corporate);
    formData.append("course_id", index?.uuid);
    console.log("index", index);
    editCourseStatus(formData)
      .then((result) => {
        if (result.status == "success") {
          getAllCourse();
        }
      })
      .catch((e) => console.log(e));
    // setActive(!isActive);
    //alert(e + index);
  };

  useEffect(() => {
    if (usertype == "Supplier") {
      getAllCourse();
    } else {
      adminData();
      allSubCategory();
    }
  }, []);
  const onApproveReject = (e, id) => {
    if (usertype == "Admin") {
      const targetedVal = e?.target?.value;
      setIsLoader(true);
      let formData = new FormData();
      formData.append("status", e?.target?.value);
      editDetailsUsers(id, formData)
        .then((result) => {
          setIsLoader(false);
          const newUesr = [];
          if (result?.status == "success") {
            console.log("firs", firstTableData);
            firstTableData?.map((user) => {
              if (user?.uuid == id) {
                user.status.value = targetedVal;
                newUesr.push(user);
              } else {
                newUesr.push(user);
              }
            });
            setFirstTableData(newUesr);
            // adminData()
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoader(false);
        });
    }
  };

  const onSearchChange = (e) => {
    setsearchText(e.target.value);
    dispatch(searchCourseText(e?.target?.value));
    setTimeout(() => {
      searchFilter();
    }, 1000);
  };

  const searchFilter = () => {
    var searchData = [];
    firstTableData
      .filter((course) =>
        course?.course_name?.toLowerCase().includes(searchText?.toLowerCase())
      )
      .map((crs) => {
        searchData.push(crs);
      });
    setsearchResult(searchData);
    // filterCourses()
  };

  useEffect(() => {
    filterCourseDetail();
    setPaginationStartIndex(1);
  }, [searchText, changeFilterStatus, isActive, firstTableData]);

  const filterCourseDetail = () => {
    let searchData = [];
    let filteredData = [];

    if (usertype == "Supplier") {
      // supplier filter
      if (searchText != "") {
        firstTableData?.map((course) => {
          if (
            course?.course_name
              ?.toLowerCase()
              .includes(searchText?.toLowerCase())
          ) {
            searchData.push(course);
          }
        });
      } else {
        searchData = state?.AllCourses;
      }
      if (changeFilterStatus && changeFilterStatus != "all") {
        searchData?.map((course) => {
          if (
            course?.status?.value?.toLowerCase() ==
            changeFilterStatus?.toLowerCase()
          ) {
            filteredData.push(course);
          }
        });
      } else {
        filteredData = searchData;
      }
      setFilteredCourses(filteredData);
    } else {
      // admin filter
      if (searchText != "") {
        firstTableData?.map((course) => {
          if (
            course?.first_name
              ?.toLowerCase()
              .includes(searchText?.toLowerCase()) ||
            course?.last_name?.toLowerCase().includes(searchText?.toLowerCase())
          ) {
            searchData.push(course);
          }
        });
      } else {
        searchData = firstTableData;
      }
      if (changeFilterStatus && changeFilterStatus != "all") {
        searchData?.map((course) => {
          if (
            course?.status?.value?.toLowerCase() ==
            changeFilterStatus?.toLowerCase()
          ) {
            filteredData.push(course);
          }
        });
      } else {
        filteredData = searchData;
      }
      setFilteredCourses(filteredData);
    }
  };

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 4);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 4);
  };

  const paginationCourse = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredCourses?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 3) {
        paginationData.push(data);
      }
    });
    setPaginationCourses(paginationData);
  };

  useEffect(() => {
    paginationCourse();
  }, [paginationStartIndex, filteredCourses]);

  useEffect(() => {
    filterUserDetail();
  }, [userSearchText, secondTableData, adminSecondFilterStatus]);

  //user table

  const filterUserDetail = () => {
    let searchData = [];
    let filteredData = [];
    // supplier
    if (usertype == "Supplier") {
      if (userSearchText != "") {
        secondTableData?.map((course) => {
          if (
            course?.username
              ?.toLowerCase()
              .includes(userSearchText?.toLowerCase())
          ) {
            filteredData.push(course);
          }
        });
      } else {
        filteredData = state?.AllUsers;
      }
      setFilteredUser(filteredData);
    } else {
      // admin filter
      if (userSearchText != "") {
        secondTableData?.map((user) => {
          if (
            user?.course_name
              ?.toLowerCase()
              .includes(userSearchText?.toLowerCase())
          ) {
            searchData.push(user);
          }
        });
      } else {
        searchData = secondTableData;
      }

      if (adminSecondFilterStatus && adminSecondFilterStatus != "all") {
        searchData?.map((user) => {
          if (
            user?.status?.value?.toLowerCase() ==
            adminSecondFilterStatus?.toLowerCase()
          ) {
            filteredData.push(user);
          }
        });
      } else {
        filteredData = searchData;
      }
      setFilteredUser(filteredData);
    }
  };

  const paginationPrevUser = () => {
    setPaginationStartIndexUser(paginationStartIndexUser - 4);
  };
  const paginationNextUser = () => {
    setPaginationStartIndexUser(paginationStartIndexUser + 4);
  };

  const paginationUser = () => {
    const paginationData = [];

    const actualIndex = paginationStartIndexUser - 1;
    filteredUser?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 3) {
        paginationData.push(data);
      }
    });
    setPaginationUsers(paginationData);
  };

  useEffect(() => {
    paginationUser();
  }, [paginationStartIndexUser, filteredUser]);

  const getAllCourse = () => {
    let formData = new FormData();
    formData.append("email", email);
    setIsLoader(true);
    getOfferedCourse(formData)
      .then(async (result) => {
        setIsLoader(false);
        let indi =
          result?.Individuals && convertDataToObject(result?.Individuals);
        dispatch(getAllCourses(result?.Course_Offered));
        dispatch(getAllUser(indi));
        dispatch(getAllApprovedSubCategory(result?.all_subcategory));
        setFirstTableData(result?.Course_Offered);
        setSecondTableData(indi);
        setPurchaseCount(result?.purchased_course_count);
        setSuplierCoursesCount(result?.supplier_course_count);
        setTotalCourseCount(result?.total_course_count);
        setTotalUserCount(result?.total_user_count);
        setSupplierDashboardData(result);
        filterCourseDetail();
        setActive(!isActive);
      })
      .catch((e) => console.log(e));
  };

  const onCourseOfferFilterClick = (e) => {
    if (e?.target?.id == "radio1") {
      setFilterStatus(e?.target?.name);
    } else if (e?.target?.id == "radio2") {
      setFilterStatus(e?.target?.name);
    } else {
      setFilterStatus(e?.target?.name);
    }
  };

  const adminStatus = (e) => {
    if (e?.target?.id == "radio1Admin") {
      setAdminSecondFilterStatus(e?.target?.name);
    } else if (e?.target?.id == "radio2Admin") {
      setAdminSecondFilterStatus(e?.target?.name);
    } else {
      setAdminSecondFilterStatus(e?.target?.name);
    }
  };

  // api for graph
  useEffect(() => {
    if (usertype === "Supplier") {
      getCourseGraphData();
    }
  }, [courseGraphMode]);

  const getCourseGraphData = () => {
    let formData = new FormData();
    formData.append("time_period", courseGraphMode.toLowerCase());
    setIsLoader(true);
    getCouseGraphApi(formData).then((result) => {
      setIsLoader(false);
      if (result) {
        setCourseGraphResponse(result);
        dispatch(courseGraphData(result));
      }
    });
  };

  // earning graph
  useEffect(() => {
    if (usertype == "Supplier") {
      getEarningData();
    }
  }, [earningGraphType]);

  const getEarningData = () => {
    let formData = new FormData();
    formData.append("time_period", earningGraphType.toLowerCase());
    getEarningGraphApi(formData)
      .then((result) => {
        if (result?.status == "success") {
          setMyEarningData(result);
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <div className="mainpage">
      {/* <Sidebar /> */}
      <div className="DashboardMain">
        <div className="container-fluid">
          {/* <Header /> */}
          <div className="dashboard-inner">
            <h1>
              {t("Hello", lan)}, {state?.personalData?.data?.supplier_name}
            </h1>
            <div className="top-box">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-6">
                  <TopCounterBox
                    bgColor="#876C6F"
                    isWithIcon={true}
                    boxTitle={
                      usertype === "Supplier"
                        ? `${t("TotalCourses", lan)} ( ${t("On", lan)} ${t(
                            "Platform",
                            lan
                          )})`
                        : t("TotalSuppliers", lan)
                    }
                    number={
                      usertype === "Supplier"
                        ? totalCourseCount
                        : dashboardData?.supplier_count
                    }
                    Boxarrow={Boxarrow}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-6">
                  <TopCounterBox
                    bgColor="#616A46"
                    isWithIcon={true}
                    boxTitle={
                      usertype === "Supplier"
                        ? `${t("TotalIndividuals", lan)} ( ${t("On", lan)} ${t(
                            "Platform",
                            lan
                          )})`
                        : t("TotalIndividuals", lan)
                    }
                    number={
                      usertype == "Supplier"
                        ? totalUserCount
                        : dashboardData?.user_count
                    }
                    Boxarrow={Boxarrow}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-6">
                  <TopCounterBox
                    bgColor="#481A20"
                    isWithIcon={true}
                    boxTitle={
                      usertype == "Supplier"
                        ? ` ${t("CoursesOffered", lan)}`
                        : t("TotalCourses", lan)
                    }
                    number={
                      usertype == "Supplier"
                        ? suplierCoursesCount
                        : dashboardData?.total_course
                    }
                    viewLink={"/supplier/course"}
                    text={t("ViewDetails", lan)}
                    Boxarrow={Boxarrow}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-6">
                  <TopCounterBox
                    bgColor="#A3B6B3"
                    isWithIcon={true}
                    boxTitle={
                      usertype == "Supplier"
                        ? t("PurchasedCourses", lan)
                        : t("PurchasedCourses", lan)
                    }
                    number={
                      usertype == "Supplier"
                        ? purchaseCount
                        : dashboardData?.purchased_course_count
                    }
                    viewLink={"/supplier/course"}
                    text={t("ViewDetails", lan)}
                    Boxarrow={Boxarrow}
                  />
                </div>
              </div>

              <div className="table-box">
                <div className="row">
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <div className="table-information">
                      <div className="tbel-haed">
                        <h4>
                          {usertype == "Supplier"
                            ? t("CoursesOffered", lan)
                            : t("TotalUser", lan)}
                        </h4>
                        <div className="filter-block">
                          <div className="serach-filter">
                            <label>{t("Search", lan)}</label>
                            <input
                              type="text"
                              className="form-control"
                              labelname={t("Search", lan)}
                              placeholder={
                                usertype == "Supplier"
                                  ? t("searchforcourses", lan)
                                  : t("searchforusers", lan)
                              }
                              value={searchText}
                              onChange={(e) => onSearchChange(e)}
                              onKeyDown={(event) => {
                                if (event.keyCode === 13) {
                                  dispatch(searchCourseText(searchText));
                                }
                              }}
                            />
                            {searchResult && (
                              <datalist id="CourseName">
                                {searchResult?.map((course, index) => {
                                  return (
                                    index < 8 && (
                                      <option value={course?.course_name} />
                                    )
                                  );
                                })}
                              </datalist>
                            )}
                            <span
                              className="filtericon"
                              style={{ bottom: "8px" }}
                            >
                              <img src={IcSearch} />
                            </span>
                          </div>
                          <div className="quick-filter">
                            <div className="radio-toolbar">
                              <input
                                type="radio"
                                id="radio1"
                                name="all"
                                value="all"
                                onClick={onCourseOfferFilterClick}
                              />
                              <label
                                style={{
                                  background:
                                    changeFilterStatus == "all"
                                      ? "#1f41731a"
                                      : "transparent",
                                }}
                                htmlFor="radio1"
                                className="text-darkblue"
                              >
                                {t("All", lan)}
                              </label>
                              <input
                                type="radio"
                                id="radio2"
                                name="Active"
                                value="false"
                                onClick={onCourseOfferFilterClick}
                              />
                              <label
                                style={{
                                  background:
                                    changeFilterStatus == "Active"
                                      ? "#003ad21a"
                                      : "transparent",
                                  color:
                                    changeFilterStatus == "Active"
                                      ? "#003ad2"
                                      : "#212529",
                                }}
                                htmlFor="radio2"
                                className="text-blue"
                              >
                                {t("Active", lan)}
                              </label>
                              <input
                                type="radio"
                                id="radio3"
                                name="Inactive"
                                value="true"
                                onClick={onCourseOfferFilterClick}
                              />
                              <label
                                style={{
                                  background:
                                    changeFilterStatus == "Inactive"
                                      ? "#ff00001a"
                                      : "transparent",
                                  color:
                                    changeFilterStatus == "Inactive"
                                      ? "#e80000"
                                      : "#212529",
                                }}
                                htmlFor="radio3"
                                className="text-red"
                              >
                                {t("inActive", lan)}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-data">
                        <div className="table-responsive">
                          {/* supplier first table  */}
                          {usertype == "Supplier" ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  
                                  <th scope="col" className="th-head">
                                    {t("CourseName", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("Status", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("Type", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("DateCreated", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("Action", lan)}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {paginationCourses?.length > 0 ? (
                                  paginationCourses.map(
                                    (CoursesOfferedData, index) => {
                                      return (
                                        <tr key={index}>
                                        
                                          <td className="coursename course_Name">
                                            {CoursesOfferedData?.course_name ||
                                              "-"}
                                          </td>
                                          <td className="course_Staut">
                                            <select
                                              className={
                                                CoursesOfferedData?.status?.value.toLowerCase() ==
                                                "active"
                                                  ? "StatusActive"
                                                  : "StatusInActive"
                                              }
                                              onChange={(e) =>
                                                ToggleClass(
                                                  e,
                                                  CoursesOfferedData
                                                )
                                              }
                                            >
                                              <option
                                                value={"Active"}
                                                selected={
                                                  CoursesOfferedData?.status
                                                    ?.value == "Active"
                                                }
                                              >
                                                {t("Active", lan)}
                                              </option>
                                              <option
                                                value={"Inactive"}
                                                selected={
                                                  CoursesOfferedData?.status
                                                    ?.value == "Inactive"
                                                }
                                              >
                                                {t("inActive", lan)}
                                              </option>
                                            </select>
                                          </td>
                                          <th
                                            className="coursename"
                                          >
                                            {!CoursesOfferedData?.is_corporate ? t("general",lan):t("organisation") || "-"}
                                          </th>
                                          <td className="DateCreated course_Date">
                                            {formatDate(
                                              CoursesOfferedData?.created_date_time
                                            )}
                                          </td>
                                          <td className="course_action">
                                            <Link
                                              to={
                                                CoursesOfferedData?.is_corporate
                                                  ? `/supplier/corporate/course/details/${CoursesOfferedData?.uuid}`
                                                  : `/supplier/course/details/${CoursesOfferedData?.uuid}`
                                              }
                                              onClick={() => {
                                                dispatch(
                                                  viewCoursesDetails(
                                                    CoursesOfferedData
                                                  )
                                                );
                                              }}
                                              className="link-green"
                                            >
                                              {t("View", lan)}
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td className="border-0" colSpan={5}>
                                      <NoData />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          ) : (
                            // admin first table

                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" className="th-head">
                                    {t("UserID", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("UserName", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("Status", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("UserRole", lan)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="th-head text-end  "
                                  >
                                    <span className="pe-lg-3">
                                      {t("Action", lan)}
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {paginationCourses?.length > 0 ? (
                                  paginationCourses.map(
                                    (CoursesOfferedData, index) => {
                                      return (
                                        <tr key={index}>
                                          <th
                                            scope="row"
                                            className="coureIDNumber course_no"
                                          >
                                            {CoursesOfferedData?.id || "-"}
                                          </th>
                                          <td className="coursename course_Name">
                                            {`${
                                              CoursesOfferedData?.first_name ||
                                              "-"
                                            } ${
                                              CoursesOfferedData?.last_name ||
                                              "-"
                                            }`}
                                          </td>
                                          <td className="course_Staut">
                                            <select
                                              className={
                                                CoursesOfferedData?.status?.value.toLowerCase() ==
                                                "active"
                                                  ? "StatusActive"
                                                  : "StatusInActive"
                                              }
                                              onChange={(e) =>
                                                onApproveReject(
                                                  e,
                                                  CoursesOfferedData?.uuid
                                                )
                                              }
                                            >
                                              <option
                                                value={"Active"}
                                                selected={
                                                  CoursesOfferedData?.status
                                                    ?.value == "Active"
                                                }
                                              >
                                                {t("Active", lan)}
                                              </option>
                                              <option
                                                value={"Inactive"}
                                                selected={
                                                  CoursesOfferedData?.status
                                                    ?.value == "Inactive"
                                                }
                                              >
                                                {t("inActive", lan)}
                                              </option>
                                            </select>
                                          </td>
                                          <td className="DateCreated course_Date">
                                            {CoursesOfferedData?.user_type
                                              ?.user_type || "-"}
                                          </td>
                                          <td className="course_action">
                                            <Link
                                              to={
                                                CoursesOfferedData?.is_corporate
                                                  ? `/supplier/manage-corporate-users/users-detail/${CoursesOfferedData?.uuid}`
                                                  : `/supplier/manage-users/manage-users-detail/${CoursesOfferedData?.uuid}`
                                              }
                                              onClick={() => {
                                                dispatch(
                                                  selectedUserEmail(
                                                    CoursesOfferedData?.email_id
                                                  )
                                                );
                                              }}
                                              className="link-green"
                                            >
                                              {t("View", lan)}
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td className="border-0" colSpan={5}>
                                      <NoData />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                        <div className="table-pagination">
                          {filteredCourses?.length > 4 && (
                            <CustomPagination
                              startIndex={paginationStartIndex}
                              endIndex={
                                paginationStartIndex + 3 >
                                filteredCourses?.length
                                  ? filteredCourses?.length
                                  : paginationStartIndex + 3
                              }
                              totalData={filteredCourses?.length}
                              onPrev={paginationPrev}
                              onNext={paginationNext}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <div className="table-information">
                      <div className="tbel-haed">
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <h4>{t("TotalIndividualforMyCourses", lan)}</h4> */}
                          <h4>
                            {usertype == "Supplier"
                              ? t("TotalIndividualforMyCourses", lan)
                              : t("CourseBySupplier", lan)}
                          </h4>
                          {usertype !== "Supplier" && (
                            <Link
                              className="link-green fw-bold"
                              to={
                                usertype == "Supplier" ? "" : `/supplier/course`
                              }
                            >
                              {t("ViewAll", lan)}
                            </Link>
                          )}
                        </div>
                        <div className="filter-block">
                          <div className="serach-filter">
                            <label>{t("Search", lan)}</label>
                            <input
                              type="text"
                              className="form-control"
                              labelname={t("Search", lan)}
                              placeholder={
                                usertype == "Supplier"
                                  ? t("searchforusers", lan)
                                  : t("searchforcourses", lan)
                              }
                              value={userSearchText}
                              onChange={(e) =>
                                setUserSearchText(e?.target?.value)
                              }
                            />
                            <span
                              style={{ bottom: "8px" }}
                              className="filtericon"
                            >
                              <img src={IcSearch} />
                            </span>
                          </div>
                          <div className="quick-filter">
                            {/* <Link className="link-green text-decoration-none">
                              {t("ViewAll", lan)}
                            </Link> */}
                            {usertype == "Admin" && (
                              <div className="radio-toolbar">
                                <input
                                  type="radio"
                                  id="radio1Admin"
                                  name="all"
                                  value="all"
                                  onClick={adminStatus}
                                />
                                <label
                                  style={{
                                    background:
                                      adminSecondFilterStatus == "all"
                                        ? "#1f41731a"
                                        : "transparent",
                                  }}
                                  htmlFor="radio1Admin"
                                  className="text-darkblue"
                                >
                                  {t("All", lan)}
                                </label>
                                <input
                                  type="radio"
                                  id="radio2Admin"
                                  name="Active"
                                  value="false"
                                  onClick={adminStatus}
                                />
                                <label
                                  style={{
                                    background:
                                      adminSecondFilterStatus == "Active"
                                        ? "#003ad21a"
                                        : "transparent",
                                    color:
                                      adminSecondFilterStatus == "Active"
                                        ? "#003ad2"
                                        : "#212529",
                                  }}
                                  htmlFor="radio2Admin"
                                  className="text-blue"
                                >
                                  {t("Active", lan)}
                                </label>
                                <input
                                  type="radio"
                                  id="radio3Admin"
                                  name="Inactive"
                                  value="true"
                                  onClick={adminStatus}
                                />
                                <label
                                  style={{
                                    background:
                                      adminSecondFilterStatus == "Inactive"
                                        ? "#ff00001a"
                                        : "transparent",
                                    color:
                                      adminSecondFilterStatus == "Inactive"
                                        ? "#e80000"
                                        : "#212529",
                                  }}
                                  htmlFor="radio3Admin"
                                  className="text-red"
                                >
                                  {t("inActive", lan)}
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="table-data">
                        <div className="table-responsive">
                          {usertype == "Supplier" ? (
                            //  supplier second table
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" className="th-head">
                                    {t("UserID", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("UserName", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("CoruseName", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("CourseType", lan)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="th-head text-end  "
                                  >
                                    <span className="pe-lg-3">
                                      {t("Action", lan)}
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {paginationUsers.length > 0 ? (
                                  paginationUsers?.map(
                                    (TotalIndividualMyCourses, index) => {
                                      return (
                                        <tr key={index}>
                                          <th
                                            scope="row"
                                            className="coureIDNumber"
                                          >
                                            {index + 1}
                                          </th>
                                          <td className="coursename">
                                            {TotalIndividualMyCourses?.username ||
                                              "-"}
                                          </td>
                                          <td>
                                            {TotalIndividualMyCourses?.coursename ||
                                              "-"}
                                          </td>
                                          <td>
                                            {TotalIndividualMyCourses?.coursetype ||
                                              "-"}
                                          </td>

                                          <td className="text-end ">
                                            <Link
                                              to={`/supplier/manage-users/manage-users-detail/${TotalIndividualMyCourses?.user_uuid}`}
                                              className="link-green pe-lg-3"
                                            >
                                              {t("View", lan)}{" "}
                                              {t("Profile", lan).toLowerCase()}
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td className="border-0" colSpan={5}>
                                      <NoData />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          ) : (
                            // admin second table
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" className="th-head">
                                    {t("CourseID", lan)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="th-head mobi_coursename"
                                  >
                                    {t("CourseName", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("Status", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("DateCreated", lan)}
                                  </th>
                                  <th scope="col" className="th-head">
                                    {t("Action", lan)}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {paginationUsers.length > 0 ? (
                                  paginationUsers?.map(
                                    (TotalIndividualMyCourses, index) => {
                                      return (
                                        <tr key={index}>
                                          <th
                                            scope="row"
                                            className="coureIDNumber"
                                          >
                                            {TotalIndividualMyCourses?.id}
                                          </th>
                                          <td className="coursename mobi_coursename">
                                            {TotalIndividualMyCourses?.course_name ||
                                              "-"}
                                          </td>
                                          <td>
                                            <span
                                              style={{
                                                backgroundImage: "none",
                                              }}
                                              className={
                                                TotalIndividualMyCourses?.status?.value?.toLowerCase() ==
                                                "active"
                                                  ? "StatusActive"
                                                  : "StatusInActive"
                                              }
                                            >
                                              {t(
                                                TotalIndividualMyCourses?.status
                                                  ?.value || "",
                                                lan
                                              )}
                                            </span>
                                          </td>
                                          <td>
                                            {formatDate(
                                              TotalIndividualMyCourses?.created_date_time
                                            ) || "-"}
                                          </td>
                                          <td className="">
                                            <Link
                                              to={`/supplier/course/details/${TotalIndividualMyCourses?.uuid}`}
                                              // onClick={() =>
                                              //   dispatch(
                                              //     selectedUserEmail(
                                              //       TotalIndividualMyCourses
                                              //         ?.user_profile?.email_id
                                              //     )
                                              //   )
                                              // }
                                              className="link-green pe-lg-3"
                                            >
                                              {t("ViewCourse", lan)}
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td className="border-0" colSpan={5}>
                                      <NoData />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                        {filteredUser?.length > 4 && (
                          <div className="table-pagination">
                            <CustomPagination
                              startIndex={paginationStartIndexUser}
                              endIndex={
                                paginationStartIndexUser + 3 >
                                filteredUser?.length
                                  ? filteredUser?.length
                                  : paginationStartIndexUser + 3
                              }
                              totalData={filteredUser?.length}
                              onPrev={paginationPrevUser}
                              onNext={paginationNextUser}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-box">
                <div className="row">
                  {usertype && usertype == "Supplier" ? (
                    <>
                      <div className="col-lg-6 mb-4">
                        <div className="table-information">
                          <div className="tbel-haed">
                            <div className="d-flex justify-content-between align-items-center">
                              <h4>{t("WeeklyDataofMyCourses", lan)}</h4>
                              <div>
                                <select
                                  value={courseGraphMode}
                                  onChange={(e) =>
                                    setCourseGraphMode(e?.target?.value)
                                  }
                                  className="dropdowngraph"
                                >
                                  <option value={"Weekly"}>
                                    {t("Weekly", lan)}
                                  </option>
                                  <option value={"Yearly"}>
                                    {t("Yearly", lan)}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {courseGraphResponse?.Course_Offered !== 0 ? (
                            <DonutChart state={courseGraphResponse} />
                          ) : (
                            <NoData />
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <div className="table-information">
                          <div className="tbel-haed">
                            <div className="d-flex justify-content-between">
                              <div>
                                <h4>
                                  {t("WeeklyDataofMyEarning", lan)}
                                  <span
                                    className="d-inline-block cursor-pointer ms-2"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={t("IncludingVat", lan)}
                                  >
                                    <BsInfoCircle
                                      className="f-14"
                                      color="#1a4840"
                                    />
                                  </span>
                                </h4>

                                <h4 className="amount">
                                  {/* <img src={amtDot} className="me-2" /> */}
                                  {"SEK "}
                                  {myEarningData?.total_earning}
                                </h4>
                              </div>
                              <div>
                                <select
                                  value={earningGraphType}
                                  onChange={(e) =>
                                    setEarningGraphType(e?.target?.value)
                                  }
                                  className="dropdowngraph"
                                >
                                  <option value={"Weekly"}>
                                    {t("Weekly", lan)}
                                  </option>
                                  <option value={"Monthly"}>
                                    {t("Monthly", lan)}
                                  </option>
                                  <option value={"Yearly"}>
                                    {t("Yearly", lan)}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {myEarningData ? (
                            <BarChart state={myEarningData} />
                          ) : (
                            <div>
                              <NoData />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <Footer />
          </div>
        </div>
      </div>
      {isLoader ? <Loader /> : ""}
    </div>
  );
}

export default Dashboard;
