import React, { Fragment, useEffect, useState } from "react";
import "./CorporateSession.scss";
import placeholder from "../../../../../assets/images/placeholder.svg";
import { getTranslatedText as t } from "../../../../../translater/index";
import SidePopup from "../../../../../components/sidePopup/sidePopup";
import API from "../../../../../api";
import UploadImage from "../../../../../assets/images/uploadImage.png";
import { useSelector } from "react-redux";
import NoData from "../../../../../components/NoData/NoData";
import CustomPagination from "../../../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../../../assets/images/IcFilter.svg";
import IcSearch from "../../../../../assets/images/icSearch.svg";
import FilterSelectMenu from "../../../../../components/FilterSelectMenu/FilterSelectMenu";
import InputText from "../../../../../components/inputText/inputText";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import {
  addCorporateSessionApi,
  addSessionApi,
  deleteCorporateSessionApi,
  deleteSessionApi,
  editCorporateSessionApi,
  editRecuritmentAdsApi,
  editSessionApi,
  getBatch,
  getBatchForCorporate,
  getCorporateSessionApi,
  getRecuritmentAdsApi,
  getSessionApi,
  postRecuritmentAdsApi,
} from "../../../../../services/supplierService";
import Popup from "../../../../../components/popup/popup";
import Loader from "../../../../../components/Loader/Loader";
import Footer from "../../../../../components/footer/Footer";
import { logDOM } from "@testing-library/react";
import { wait } from "@testing-library/user-event/dist/utils";
import { formatDate } from "../../../../../services/constant";

// for create event
const platform = localStorage.getItem("Platform");
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
const SCOPES = "https://www.googleapis.com/auth/calendar";
const GOOGLE_API_KEY = process.env[`REACT_APP_${platform}_GOOGLE_API_KEY`];
// const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY; //leave management
// const CLIENT_ID =
//   "221724648466-pa9ks5qn5somfqt79jj45um2d1ie068q.apps.googleusercontent.com";
// const CLIENT_ID =process.env.REACT_APP_CLIENT_ID; //leave management

const CLIENT_ID = process.env[`REACT_APP_${platform}_CLIENT_ID`];
var gapi = window.gapi;

function CorporateSession() {
  const initialSessionDetails = {
    session_name: null,
    batch_name: null,
    session_start_date: null,
    session_end_date: null,
    session_start_time: null,
    session_end_time: null,
    total_duration: null,
    session_url: null,
    session_days: null,
  };

  const daysOption = [
    { value: "All Days" },
    { value: "Week Days [ MON - FRI ]" },
    { value: "Weekend [ SAT - SUN ]" },
    { value: "Custom" },
  ];
  var day = [
    { name: "Monday", value: false, key: 0, tag: "MO" },
    { name: "Tuesday", value: false, key: 1, tag: "TU" },
    { name: "Wednesday", value: false, key: 2, tag: "WE" },
    { name: "Thursday", value: false, key: 3, tag: "TH" },
    { name: "Friday", value: false, key: 4, tag: "FR" },
    { name: "Saturday", value: false, key: 5, tag: "SA" },
    { name: "Sunday", value: false, key: 6, tag: "SU" },
  ];
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const history = useHistory();

  const [searchData, setSearchData] = useState([]);
  const [addSessionPopup, setAddSessionPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [customDaysChecked, setCustomDaysChecked] = useState([]);
  const [customDays, setCustomdays] = useState(day);
  const [batch, setBatch] = useState();
  const [clickedSessionId, setClickedSessionId] = useState();
  const [loderBtn, setLoderBtn] = useState(false);
  const [editSession, setEditSession] = useState(null);
  const [filteredSession, setFilteredSession] = useState();
  const [session, setSession] = useState([]);
  const [paginationSession, setPaginationSession] = useState();
  const [eventRes, setEventRes] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );
  const [addSessionDetail, setAddSessionDetail] = useState(
    initialSessionDetails
  );
  const [error, setError] = useState({
    nameError: "",
    batchError: "",
    durationError: "",
    daysError: "",
    startDateError: "",
    endDateError: "",
    startTimeError: "",
    endTimeError: "",
  });

  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [isLoader, setIsLoader] = useState(false);


  function formatTime(start, end) {
    const startTime = new Date(`2000-01-01T${start}`);
    let endTime = new Date(`2000-01-01T${end}`);
  
    if (endTime < startTime) {
      endTime = new Date(endTime.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours to end time
    }
  
    const duration = endTime - startTime;
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const formattedTime = `${hours.toString().padStart(2, '0')} hours and ${minutes.toString().padStart(2, '0')} minutes`;
    return formattedTime;
  }
  
  //get selected batch max date
  const getCourseMaxDate = () => {
    const filtered = batch?.filter(
      (fil) => fil.batch_name == addSessionDetail?.batch_name
    );
    if (filtered?.length > 0) {
      const date = filtered[0].course?.course_starting_date;
      const length = parseInt(filtered[0].course?.course_length);

      const futureDate = new Date(date);
      futureDate.setDate(futureDate.getDate() + length);
      return new Date(futureDate).toISOString().split("T")[0];
    }
  };

  // this for start and end event time combine
  const combineDateTime = (date, time, end, type) => {

    let eventType;;
    if(time.length >5){
       eventType = ""
    }else{
      eventType=":00"
    }
    console.log("addSessionDetail?.session_start_time",addSessionDetail);
    if (end) {
      if (
        addSessionDetail?.session_start_time <
        addSessionDetail?.session_end_time
      ) {
        let apptDate = new Date(date).toLocaleDateString("en-CA");
        let converted = `${apptDate}T${time}${eventType}`;
        return converted;
      } else {
        var currentDate = new Date(date);
        currentDate.setDate(currentDate.getDate() + 1);
        let apptDate = new Date(currentDate).toLocaleDateString("en-CA");
        let converted = `${apptDate}T${time}${eventType}`;
        return converted;
      }
    } else {
      let apptDate = new Date(date).toLocaleDateString("en-CA");
      let converted = `${apptDate}T${time}${eventType}`;
      return converted;
    }
  };

  //for reccurence event dates
  function formatedDates(types) {
    let type =addSessionDetail?.session_end_time.length >5  ? "Z" : "00Z";

    if (addSessionDetail?.session_days == "Week Days [ MON - FRI ]") {
      const datearr = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      const date = new Date(addSessionDetail?.session_end_date)
        .toLocaleDateString("en-CA")
        .split("-")
        .join("");
      const time =
        addSessionDetail?.session_end_time?.split(":").join("") + `${type}`;
      return `RRULE:FREQ=WEEKLY;UNTIL=${date}T${time};WKST=SU;BYDAY=MO,TU,WE,TH,FR`;
      //this is for specific date range
      // return dateRange(
      //   addSessionDetail?.session_start_date,
      //   addSessionDetail?.session_end_date,
      //   datearr
      // );
    } else if (addSessionDetail?.session_days == "All Days") {
      const datearr = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const date = new Date(addSessionDetail?.session_end_date)
        .toLocaleDateString("en-CA")
        .split("-")
        .join("");
      const time =
        addSessionDetail?.session_end_time?.split(":").join("") + `${type}`;
      return `RRULE:FREQ=DAILY;UNTIL=${date}T${time}`;
      //this is for specific date range
      // return dateRange(
      //   addSessionDetail?.session_start_date,
      //   addSessionDetail?.session_end_date,
      //   datearr
      // );
    } else if (addSessionDetail?.session_days == "Weekend [ SAT - SUN ]") {
      const datearr = ["Saturday", "Sunday"];
      const date = new Date(addSessionDetail?.session_end_date)
        .toLocaleDateString("en-CA")
        .split("-")
        .join("");
      const time =
        addSessionDetail?.session_end_time?.split(":").join("") + `${type}`;
      return `RRULE:FREQ=WEEKLY;UNTIL=${date}T${time};WKST=SU;BYDAY=SA,SU`;
      //this is for specific date range
      // return dateRange(
      //   addSessionDetail?.session_start_date,
      //   addSessionDetail?.session_end_date,
      //   datearr
      // );
    } else if (addSessionDetail?.session_days == "Custom") {
      const datearr = customDaysChecked?.map((date) => {
        return date.slice(0, 2).toUpperCase();
      });
      const date = new Date(addSessionDetail?.session_end_date)
        .toLocaleDateString("en-CA")
        .split("-")
        .join("");
      const time =
        addSessionDetail?.session_end_time?.split(":").join("") + `${type}`;
      return `RRULE:FREQ=WEEKLY;UNTIL=${date}T${time};WKST=SU;BYDAY=${datearr?.join(
        ","
      )}`;
      //this is for specific date range
      // return dateRange(
      //   addSessionDetail?.session_start_date,
      //   addSessionDetail?.session_end_date,
      //   datearr
      // );
    } else {
      toast.error("something went wrong!");
      return;
    }
  }

  //create googlr calender event
  const onEventCreateClick = async () => {
    const filtered = batch?.filter(
      (fil) => fil.batch_name == addSessionDetail?.batch_name
    );
    const validation = await validate();
    if (filtered?.length > 0 && validation) {
      setIsLoader(true);
      // let datess = await formatedDates().join();
      let students = [];
      filtered[0]?.students?.map((stu, i) => {
        students.push({ email: stu?.email_id });
      });
      gapi.load("client:auth2", async () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: "",
          plugin_name: "fcmDemo",
        }).catch(errrr=>{console.log("errrrrrr",errrr); toast.error(errrr.details || 'User is not authorized')});
        gapi.client.load("calender", "v3", (response) => {
          // toast.error(response.error.message);
        });
        return gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(async (data) => {
            var event = {
              summary: addSessionDetail?.session_name,
              // 'location': '800 Howard St., San Francisco, CA 94103',
              // 'description': 'A chance to hear more about Google\'s developer products.',
              start: {
                dateTime: `${combineDateTime(
                  addSessionDetail?.session_start_date,
                  addSessionDetail?.session_start_time,
                  false,
                  "add"
                )}`,
                // 'dateTime':'2022-07-30T17:47:00+02:00',
                timeZone: "Europe/Paris",
              },
              end: {
                dateTime: `${combineDateTime(
                  addSessionDetail?.session_start_date,
                  addSessionDetail?.session_end_time,
                  true,
                  "add"
                )}`,
                // 'dateTime':'2022-07-30T19:47:00+02:00',
                timeZone: "Europe/Paris",
              },
              recurrence: [formatedDates()],
              conferenceData: {
                createRequest: {
                  conferenceSolutionKey: {
                    type: "hangoutsMeet",
                  },
                  requestId: "RandomString",
                },
              },
              attendees: students,
              reminders: {
                useDefault: false,
                overrides: [
                  { method: "email", minutes: 24 * 60 },
                  { method: "popup", minutes: 60 },
                ],
              },
            };

            var request = gapi?.client?.calendar?.events?.insert({
              calendarId: "primary",
              resource: event,
              conferenceDataVersion: 1,
            });
            return request?.execute((event) => {
              if (event?.status == "confirmed") {
                // window.open(event?.htmlLink);
                setEventRes(event);
                postSessions(event);
              } else {
                toast.error("Something went wrong,please try again!");
                setEventRes();
              }
      setIsLoader(false);
              return event;
            });
          }).catch((eee)=>  setIsLoader(false));
      });
      setIsLoader(false);
    }

  };

  // for edit eveent

 const onEditEvent = async () => {
    const filtered = batch?.filter(
      (fil) => fil.batch_name == addSessionDetail?.batch_name
    );
    if(filtered.length === 0){
      return toast.error('Batch not found')
    }
    const validation = await validate();
    console.log(">>validation",validation,filtered);
    if ( validation) {
      setIsLoader(true);
      // let datess = await formatedDates().join();
      let students = [];
      filtered[0]?.students?.map((stu, i) => {
        students.push({ email: stu?.email_id });
      });
      gapi.load("client:auth2", async () => {
        gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: "",
          plugin_name: "fcmDemo",
        }).catch(errrr=>{ toast.error(errrr.details || 'User is not authorized')});
        gapi.client.load("calender", "v3", (response) => {
          // toast.error(response.error.message);
        });
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(async () => {
            // this code for static event update

            // return console.log("newEvent",newEvent);
            var eventData = {
              summary: addSessionDetail?.session_name,
              // 'location': '800 Howard St., San Francisco, CA 94103',
              // 'description': 'A chance to hear more about Google\'s developer products.',
              start: {
                dateTime: `${combineDateTime(
                  addSessionDetail?.session_start_date,
                  addSessionDetail?.session_start_time,
                  false,
                  "edit"
                )}`,
                // 'dateTime':'2022-07-30T17:47:00+02:00',
                timeZone: "Europe/Paris",
              },
              end: {
                dateTime: `${combineDateTime(
                  addSessionDetail?.session_start_date,
                  addSessionDetail?.session_end_time,
                  true,
                  "edit"
                )}`,
                // 'dateTime':'2022-07-30T19:47:00+02:00',
                timeZone: "Europe/Paris",
              },
              // recurrence: [`RRULE:FREQ=WEEKLY;UNTIL=20220901T000000Z;WKST=SU;BYDAY=MO,WE,FR`],
              recurrence: [formatedDates('edit')],
              // recurrence: [`RDATE;TZID=Europe/Paris:${datess}`],
              conferenceData: {
                createRequest: {
                  conferenceSolutionKey: {
                    type: "hangoutsMeet",
                  },
                  requestId: "RandomString",
                },
              },
              attendees: students,
              reminders: {
                useDefault: false,
                overrides: [
                  { method: "email", minutes: 24 * 60 },
                  { method: "popup", minutes: 60 },
                ],
              },
            };

            var request = gapi.client.calendar.events.update({
              calendarId: "primary",
              eventId: editSession.event_id,
              resource: eventData,
            });

            request.execute((event) => {
              if (event?.status == "confirmed") {
                setEventRes(event);
                setIsLoader(false);
                editSessions(event);
              } else {
                toast.error("Something went wrong,please try again!");
                setEventRes();
              }
              return event;
            });
          });
      });
      setIsLoader(false);
    }
  };

  const getAllSession = async () => {
    setIsLoader(true);
    getCorporateSessionApi().then((res) => {
      setIsLoader(false);
      if (res?.status == "success") {
        setSession(res?.data);
        setSearchData(res?.data);
        // setSession(res?.data);
        // setSearchData(res?.data);
      }
    });
  };

  //get batch with active filtered

  const getAllBatches = () => {
    getBatchForCorporate()
      .then((result) => {
        if (result?.status == "success") {
          const filteredBatch = [];
          result.data?.map((item) => {
            if (item?.status?.value == "Active") {
              filteredBatch.push(item);
            }
          });
          setBatch(filteredBatch);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };

  useEffect(async () => {
    // await recuritmentAdsApiCall();

    getAllSession();
  }, []);

  //custom days select
  const onDaysClick = (e, item) => {
    let initialChecked = customDaysChecked;
    if (e?.target?.checked == true) {
      initialChecked.push(item?.name);
    } else {
      initialChecked = initialChecked.filter((fil) => fil != item?.name);
    }
    setCustomDaysChecked(initialChecked);
  };

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredSession?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationSession(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredSession]);

  useEffect(() => {
    setPaginationStartIndex(1);
  }, [filteredSession, searchData]);

  useEffect(() => {
    if (editSession !== null && editSession !== undefined) {
      // setSubCategoryName(editSession.recruitmentAd_title);
      setAddSessionPopup(true);
    } else {
      // setSubCategoryName("");
    }
  }, [editSession]);

  function adsComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function adsComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date1 - date2;
  }
  const handleSearchData = (e) => {
    let searchData = [];
    if (e?.target?.value !== "") {
      session?.map((ses) => {
        if (
          ses?.session_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(ses);
        }
      });
    } else {
      searchData = session;
    }

    setSearchData(searchData);
  };

  const filterAdsFun = () => {
    let filteredbyTime = [];

    if (selectedTimeFilter === "Newest") {
      filteredbyTime = searchData.sort(adsComparisonbyNewestDate);
    } else {
      filteredbyTime = searchData?.sort(adsComparisonbyOldestDate);
    }

    setFilteredSession(filteredbyTime);
  };

  useEffect(() => {
    filterAdsFun();
    pagination();
    setPaginationStartIndex(1);
  }, [selectedTimeFilter, searchData]);

  const handleClosePopup = () => {
    setAddSessionPopup(false);
    setCustomDaysChecked([]);
    setCustomdays(day);
    setEventRes();
    setAddSessionDetail(initialSessionDetails);
    setEditSession(null);
    setError({
      nameError: "",
      batchError: "",
      durationError: "",
      daysError: "",
      startDateError: "",
      endDateError: "",
      startTimeError: "",
      endTimeError: "",
    });
  };
  const onEditSession = (session) => {
    getAllBatches();
    setEditSession(session);
    setAddSessionPopup(true);
    setAddSessionDetail({
      session_name: session?.session_name || "",
      batch_name: session?.batch?.batch_name || "",
      session_start_date: session?.start_date || "",
      session_end_date: session?.end_date || "",
      session_start_time: session?.start_time || "",
      session_end_time: session?.end_time || "",
      total_duration: session?.total_duration || "",
      session_url: session?.url || "",
      session_days: session?.choose_days || "",
    });
    if (session?.choose_days == "Custom") {
      const newcus = customDays?.map((day, ind) => {
        session?.customDays?.split(",")?.map((cus) => {
          if (cus?.toLowerCase() == day?.name?.toLowerCase()) {
            day.value = true;
            return day;
          }
        });
        return day;
      });
      setCustomdays(newcus);
      setCustomDaysChecked(session?.customDays?.split(","));
      // console.log("newcus",newcus);
    }
  };

  const deleteSessionClick = async(e, sessionData) => {
    setIsLoader(true);
    await gapi.load("client:auth2", async () => {
      gapi.client.init({
        apiKey: GOOGLE_API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: "",
      });
     await gapi.client.load("calender", "v3", (e) => {
        console.log(e);
      });
      return await gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(async () => {
          var request =  gapi.client.calendar.events.delete({
            calendarId: "primary",
            eventId: sessionData?.event_id,
          });
          return request.execute(function (response) {
            if (response.error || response == false) {
              setIsLoader(false);
              if(response.error.code === 404){
              return toast.error('You are not authorized to delete it');
              }
              toast.error(response.message);
            } else {
              setIsLoader(true);
              deleteCorporateSessionApi(sessionData?.uuid)
                .then((result) => {
                  setIsLoader(false);
                  if (result.status == "success") {
                    const newSession = session?.filter(
                      (fil) => fil?.uuid !== sessionData?.uuid
                    );
                    setSession(newSession);
                    setSearchData(newSession);
                    setPopupStatus(t("Success", lan));
                    setPopupMessage(result?.data);
                  } else {
                    setPopupStatus(t("Failed", lan));
                    setPopupMessage(result?.data);
                  }
                })
                .catch((e) => {
                  setIsLoader(false);
                });
            }
          });
        })
        .catch((err) => {setIsLoader(false)
        console.log(">>>google errr",err);
        });
    });
  };

  const validate = () => {
    var urlRegex = new RegExp(
      "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    );

    if (
      !addSessionDetail?.session_name &&
      !addSessionDetail?.batch_name &&
      !addSessionDetail?.session_start_date &&
      !addSessionDetail?.session_end_date &&
      !addSessionDetail?.session_start_time &&
      !addSessionDetail?.session_end_time &&
      !addSessionDetail?.session_days
    ) {
      setError({
        nameError: "*Name is required",
        batchError: "*Batch is required",
        startDateError: "*Start date is required",
        endDateError: "*End date is required",
        startTimeError: "*Start time is required",
        endTimeError: "*End time is required",
        daysError: "*Days is required",
      });
      return false;
    } else if (
      !addSessionDetail?.session_name ||
      addSessionDetail?.session_name?.trim() == ""
    ) {
      setError({ ...error, nameError: "*Name is Required" });
      return false;
    } else if (
      !addSessionDetail?.batch_name ||
      addSessionDetail?.batch_name?.trim() == ""
    ) {
      setError({ ...error, batchError: "*Batch is Required" });
      return false;
    } else if (!addSessionDetail?.session_start_date) {
      setError({ ...error, startDateError: "*Start date is Required" });
      return false;
    } else if (!addSessionDetail?.session_end_date) {
      setError({ ...error, endDateError: "*End date is Required" });
      return false;
    }
    else if (new Date(addSessionDetail?.session_end_date) < new Date(addSessionDetail?.session_start_date)) {
      setError({ ...error, endDateError: "*Enter valid end date" });
      return false;
    }
     else if (!addSessionDetail?.session_start_time) {
      setError({ ...error, startTimeError: "*Start time is Required" });
      return false;
    } else if (!addSessionDetail?.session_end_time) {
      setError({ ...error, endTimeError: "*End time is Required" });
      return false;
    } else if (!addSessionDetail?.session_days) {
      setError({ ...error, daysError: "*Days is Required" });
      return false;
    } else if (
      addSessionDetail?.session_days === "Custom" &&
      customDaysChecked?.length === 0
    ) {
      setError({ ...error, daysError: "*Please choose custom days" });
      return false;
    } else {
      return true;
    }
  };

  const postSessions = async (event) => {
    const validation = await validate();
    if (validation == true) {
      setLoderBtn(true);
      var data = new FormData();
      data.append("session_name", addSessionDetail?.session_name);
      data.append("batch_name", addSessionDetail?.batch_name);
      data.append("start_date", addSessionDetail?.session_start_date);
      data.append("end_date", addSessionDetail?.session_end_date);
      data.append("start_time", addSessionDetail?.session_start_time);
      data.append("end_time", addSessionDetail?.session_end_time);
      data.append("total_duration", addSessionDetail?.total_duration);
      data.append("choose_days", addSessionDetail?.session_days);
      data.append("event_id", event?.id);
      data.append("url", event?.hangoutLink);
      data.append("custom_days ", customDaysChecked);
      addCorporateSessionApi(data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            getAllSession();
            setPopupStatus(t("Success", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            console.log("gapp", gapi?.client);
            gapi.client.load("calender", "v3", () => {});
            gapi.client.calendar.events
              .delete({
                calendarId: "primary",
                eventId: event?.id,
              })
              .execute();
            setPopupStatus(t("Failed", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const editSessions = async (event) => {
    const validation = await validate();
    if (validation == true) {
      setLoderBtn(true);
      var data = new FormData();
      data.append("session_name", addSessionDetail?.session_name);
      data.append("batch_name", addSessionDetail?.batch_name);
      data.append("start_date", addSessionDetail?.session_start_date);
      data.append("end_date", addSessionDetail?.session_end_date);
      data.append("start_time", addSessionDetail?.session_start_time);
      data.append("end_time", addSessionDetail?.session_end_time);
      data.append("total_duration", addSessionDetail?.total_duration);
      data.append("choose_days", addSessionDetail?.session_days);
      data.append("custom_days", customDaysChecked);
      data.append("event_id", event?.id);
      data.append("url", event?.hangoutLink);
      editCorporateSessionApi(editSession?.uuid, data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            getAllSession();
            setPopupStatus(t("success",lan));
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            setPopupStatus("Failed!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
  };

  return (
    <div className="batchDetail">
      <div className="fix_height">
        <div className="subCategoryHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">
              {t("SessionLisiting", lan)}
            </span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchBySessionName", lan)}
                onChange={handleSearchData}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="headerTab smallText position-relative">
                <span className="iconsSelect">
                  <img src={IcFilter} />
                </span>
                <FilterSelectMenu
                  placeholder=""
                  value={["Newest", "Oldest"]}
                  selected={selectedTimeFilter}
                  onChange={(e) => {
                    setSelectedTimeFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div>

              {/* <div className="headerTab smallText position-relative">
                        <span className="iconsSelect">
                            <img src={IcFilter2} />
                        </span>
                        <FilterSelectMenu
                            placeholder="Filter By"
                            value={["Paid", "Free"]}
                            onChange={(e) => {
                            // setSelectedPriceFilter(e.target.value);
                            }}
                            isWithicon
                        />
                    </div> */}
              {
                <button
                  className="primaryButton mt-0"
                  onClick={() => {
                    setAddSessionPopup(true);
                    getAllBatches();
                  }}
                >
                  {` ${t("AddSession", lan)}`}
                </button>
              }
            </div>
          </div>
        </div>
        {paginationSession?.length > 0 ? (
          <div>
            <div className="subCategoryListDiv">
              <table className="subCategoryListTable rc-session">
                <tr className="whitespaceNoWrap">
                  <th>{`${t("Id", lan)}`} </th>
                  <th> {` ${t("SessionName", lan)}`}</th>
                  <th> {` ${t("BatchName", lan)}`}</th>
                  <th> {` ${t("DateCreated", lan)}`}</th>
                  <th> {` ${t("SessionURL", lan)} `}</th>
                  <th>{` ${t("Action", lan)} `}</th>
                </tr>

                {paginationSession?.length > 0 &&
                  paginationSession?.map((session, index) => {
                    return (
                      <tr key={index} className="subCategoryTableInfo">
                        <td>{session.id}</td>
                        <td className="firstLetterCapital">{session.session_name}</td>
                        <td>{session?.batch?.batch_name}</td>
                        <td>{formatDate(session?.created_date_time)}</td>
                        <td>
                          <a about="session url" href={session?.url || "#"}>
                            {session?.url}{" "}
                          </a>{" "}
                        </td>
                        <td className="whitespaceNoWrap">
                          {
                            <>
                              <span
                                className="cursorPointer textPrimaryColor"
                                onClick={() => {
                                  onEditSession(session);
                                }}
                              >
                                {` ${t("Edit", lan)} `}
                              </span>{" "}
                              |{" "}
                              <span
                                className="cursorPointer textPrimaryColor"
                                onClick={(e) => {
                                  deleteSessionClick(e, session);
                                }}
                              >
                                {` ${t("Delete", lan)} `}
                              </span>{" "}
                            </>
                          }
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            <div className="mt-3 text-end">
              <div className="row">
                {filteredSession?.length > 12 && (
                  <CustomPagination
                    startIndex={paginationStartIndex}
                    endIndex={
                      paginationStartIndex + 11 > filteredSession?.length
                        ? filteredSession?.length
                        : paginationStartIndex + 11
                    }
                    totalData={filteredSession?.length}
                    onPrev={paginationPrev}
                    onNext={paginationNext}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <NoData />
          </div>
        )}

        <SidePopup
          header={
            editSession !== null
              ? ` ${t("UpdateSession", lan)}`
              : ` ${t("AddSession", lan)}`
          }
          show={addSessionPopup}
          handleClose={handleClosePopup}
        >
          <div className="sidepopupContents">
            <label htmlFor="sessionname">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("SessionName", lan)}`}
                <span className="text-danger">*</span>
              </p>
            </label>
            <input
              type="text"
              id="sessionname"
              maxLength="100"
              placeholder={`${t("EnterSessionName", lan)}`}
              className="formInputs"
              value={addSessionDetail?.session_name}
              onChange={(e) => {
                setError({ ...error, nameError: "" });
                setAddSessionDetail({
                  ...addSessionDetail,
                  session_name: e.target.value,
                });
              }}
            />
            {error?.nameError && (
              <p className="errorText mb-0">{error?.nameError}</p>
            )}

            <label htmlFor=" ">
              <p className="smallText inputText boldText pWithMargin">
                {" "}
                {` ${t("chooseBatch", lan)}`}
                <span className="text-danger">*</span>
              </p>
            </label>
            <select
              value={addSessionDetail?.batch_name}
              onChange={(e) => {
                setError({ ...error, batchError: "" });
                setAddSessionDetail({
                  ...addSessionDetail,
                  batch_name: e.target.value,
                });
              }}
              className={"formInputs"}
            >
              <option value={''}>{` ${t(
                "chooseBatchName",
                lan
              )} `}</option>
              {batch &&
                batch.map((batch, ind) => {
                  return (
                    <Fragment key={ind}>
                      <option value={batch?.batch_name}>{`${t(
                        batch?.batch_name,
                        lan
                      )} `}</option>
                    </Fragment>
                  );
                })}
            </select>

            {error?.batchError && (
              <p className="errorText mb-0">{error?.batchError}</p>
            )}

            {/*start date  */}
            <div className="halfInputsDiv">
              <span className="date-box">
                <label htmlFor="chooseCourseDate ">
                  <p className="smallText inputText boldText pWithMargin">
                    {`${t("SessionStartDate", lan)}`}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <input
                  onChange={(e) => {
                    setError({ ...error, startDateError: "" });
                    setAddSessionDetail({
                      ...addSessionDetail,
                      session_start_date: e.target.value,
                    });
                  }}
                  min={new Date().toISOString().split("T")[0]}
                  type="Date"
                  value={addSessionDetail?.session_start_date}
                  id="chooseCourseDate"
                  placeholder="MM/DD/YY"
                  className="formInputs"
                />
                {error?.startDateError && (
                  <p className="errorText mb-0">{error?.startDateError}</p>
                )}
              </span>
              <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>

              {/* end date  */}
              <span className="date-box">
                <label htmlFor="chooseCourseDate ">
                  <p className="smallText inputText boldText pWithMargin">
                    {`${t("SessionEndDate", lan)}`}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <input
                  onChange={(e) => {
                    setError({ ...error, endDateError: "" });
                    setAddSessionDetail({
                      ...addSessionDetail,
                      session_end_date: e.target.value,
                    });
                  }}
                  min={
                    addSessionDetail?.session_start_date
                      ? new Date(addSessionDetail?.session_start_date)
                          .toISOString()
                          .split("T")[0]
                      : new Date().toISOString().split("T")[0]
                  }
                  max={getCourseMaxDate()}
                  type="Date"
                  value={addSessionDetail?.session_end_date}
                  id="chooseCourseDate"
                  placeholder="MM/DD/YY"
                  className="formInputs"
                />
                {error?.endDateError && (
                  <p className="errorText mb-0">{error?.endDateError}</p>
                )}
              </span>
            </div>

            {/* time  */}
            <div className="halfInputsDiv">
              {/* start time  */}
              <span className="">
                <label htmlFor="chooseCourseTime ">
                  <p className="smallText inputText boldText pWithMargin">
                    {`${t("StartTime", lan)}`}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <input
                  onChange={(e) => {
                    setError({ ...error, startTimeError: "" });
                    setAddSessionDetail({
                      ...addSessionDetail,
                      session_start_time: e.target.value,
                    });
                  }}
                  type="time"
                  value={addSessionDetail?.session_start_time}
                  id="chooseCourseTime"
                  placeholder="00/00/00"
                  className="formInputs"
                />
                {error?.startTimeError && (
                  <p className="errorText mb-0">{error?.startTimeError}</p>
                )}
              </span>
              <p className="smallText visibilityHidden">&nbsp;OR&nbsp;</p>

              {/* end time  */}
              <span className="">
                <label htmlFor="chooseCourseTime ">
                  <p className="smallText inputText boldText pWithMargin">
                    {`${t("EndTime", lan)}`}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <input
                  onChange={(e) => {
                    setError({ ...error, endTimeError: "" });
                    setAddSessionDetail({
                      ...addSessionDetail,
                      session_end_time: e.target.value,
                    });
                  }}
                  type="Time"
                  value={addSessionDetail?.session_end_time}
                  id="chooseCourseTime"
                  placeholder="00/00"
                  className="formInputs"
                />
                {error?.endTimeError && (
                  <p className="errorText mb-0">{error?.endTimeError}</p>
                )}
              </span>
            </div>

            <label htmlFor="stuname">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("TotalDuration", lan)}`}
              </p>
            </label>
            <input
              type="text"
              id="stuname"
              placeholder={` ${t("ChooseDate", lan)}`}
              className="formInputs"
              // value={(addSessionDetail?.session_end_time -addSessionDetail?.session_start_time || 0}
              value={
                (addSessionDetail?.session_end_time &&
                  formatTime(
                    addSessionDetail?.session_start_time,
                    addSessionDetail?.session_end_time
                  )) ||
                0
              }
              readOnly
              onChange={(e) => {
                setError({ ...error, durationError: "" });
                setAddSessionDetail({
                  ...addSessionDetail,
                  total_duration: e.target.value,
                });
              }}
            />
            {error?.durationError && (
              <p className="errorText mb-0">{error?.durationError}</p>
            )}
            <div>
              {/* choose days  */}
              <div>
                <label htmlFor="adsDescription ">
                  <p className="smallText inputText boldText pWithMargin">
                    {" "}
                    {` ${t("ChooseDays", lan)}`}
                    <span className="text-danger">*</span>
                  </p>
                </label>
                <select
                  value={addSessionDetail?.session_days}
                  onChange={(e) => {
                    setError({ ...error, daysError: "" });

                    setAddSessionDetail({
                      ...addSessionDetail,
                      session_days: e.target.value,
                    });
                  }}
                  className={"formInputs"}
                >
                  <option selected disabled>{` ${t(
                    "ChooseDays",
                    lan
                  )} `}</option>
                  {daysOption?.map((day, ind) => {
                    return (
                      <Fragment key={ind}>
                        <option value={day?.value}>{`${t(
                          day?.value,
                          lan
                        )} `}</option>
                      </Fragment>
                    );
                  })}
                </select>

                {addSessionDetail?.session_days == "Custom" && (
                  <div className="mt-3 row">
                    <label htmlFor="AgreeTnC ">
                      <p className="smallText inputText boldText pWithMargin">
                        {" "}
                        {` ${t("SelectDays", lan)}`}
                        <span className="text-danger">*</span>
                      </p>
                    </label>
                    {customDays?.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="checkboxsDiv mt-3 col-lg-4 col-sm-4 col-6">
                            <label className="checkboxLabel">
                              <input
                                defaultChecked={item?.value}
                                onClick={(e) => {
                                  onDaysClick(e, item);
                                  setError({ ...error, daysError: "" });
                                }}
                                name={item?.name}
                                type="checkbox"
                                className="checkbox"
                                id="AgreeTnC"
                              />
                              <span className="checkbox "></span>&nbsp;{" "}
                              {item?.name}
                            </label>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                )}

                {error?.daysError && (
                  <p className="errorText mb-0">{error?.daysError}</p>
                )}
              </div>
              {/* session url  */}
              {/* we don't need now  */}

              {/* <div>
            <label htmlFor="sessionUrls">
            <p className="smallText inputText boldText pWithMargin">
              {` ${t("SessionURL", lan)}`}
            </p>
          </label>
          <input
            type="text"
            id="sessionUrls"    
            placeholder={` ${t("EnterSessionURL", lan)}`}
            className="formInputs"
            defaultValue={addSessionDetail?.session_url}
            onChange={(e) => {
              setAddSessionDetail({
                ...addSessionDetail,
                session_url: e.target.value,
              });
            }}
          />
            </div> */}
            </div>

            {loderBtn ? (
              <button className="primaryButton" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className="primaryButton"
                onClick={() => {
                  // postSessions();
                  editSession !== null ? onEditEvent() : onEventCreateClick();
                }}
              >
                {editSession !== null
                  ? ` ${t("UpdateSession", lan)}`
                  : ` ${t("AddSession", lan)}`}
              </button>
            )}
          </div>
        </SidePopup>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CorporateSession;
