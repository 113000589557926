import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "../ProfileMain.scss";
// import CountryCodeSelect from "../../../components/CountryCodeSelect/CountryCodeSelect";

import { useDispatch, useSelector } from "react-redux";
import { courseCategoryDetails, tabPageNo } from "../../../redux/actions";
import { getTranslatedText as t } from "../../../translater/index";
import Select from "react-select";
import {
  getAllSubCategory,
  getCategoryApi,
} from "../../../services/supplierService";
import { useHistory } from "react-router-dom";

export const CourseCategory = forwardRef((props, ref) => {
  const state = useSelector((state) => {
    return state.Eddi;
  });
  let lan = state?.language;
  const dispatch = useDispatch();
  const [cats, setCats] = useState([]);
  const [totalSubCats, setTotalSubCats] = useState([]);
  const [SubCats, setSubCats] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const history = useHistory();
  const [optionError, setoptionError] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [stepClick, setStepCkick] = useState();
  const [category, setCategory] = useState([]);
  // const [personalNoError, setPersonalNoError] = useState("");
  // const [mobileNoError, setMobileNoError] = useState("");

  useImperativeHandle(ref, () => ({
    async childFunction1() {
      let result = await checkValid();
      return result;
    },
  }));

  const getSubCat = () => {
    getAllSubCategory().then((res) => {
      if (res?.status === "success") {
        setTotalSubCats(res.all_subcategory);
      }
    });
  };

  useEffect(() => {
    let newSubCat = [];
    let ind = 0;
    cats?.map((cat) => {
      totalSubCats.map((subCat) => {
        if (cat?.value == subCat?.category_name?.category_name) {
          newSubCat.push(subCat);
          let ele = document.getElementById(`btn-${ind}`);
          ind += 1;
        }
      });
    });
    setSubCats(newSubCat);
  }, [cats, totalSubCats]);

  useEffect(() => {
    getSubCat();
    if (typeof state?.courseCategoryData?.sub_cat === "string") {
      setSelectedSubCat(
        state?.courseCategoryData?.sub_cat !== (null || undefined)
          ? state?.courseCategoryData?.sub_cat?.split(",")
          : []
      );
    } else {
      setSelectedSubCat(
        state?.courseCategoryData?.sub_cat !== (null || undefined)
          ? state?.courseCategoryData?.sub_cat
          : []
      );
    }

    setCats(state?.courseCategoryData?.optionVal);
    getCategoryApi().then((res) => {
      setCategory(res?.data);
    });
  }, []);

  useEffect(() => {
    if (state.courseCategoryData?.optionVal !== null) {
      setSelectedOption(state.courseCategoryData?.optionVal);
    }

    if (optionError === null) {
      submitInfo("call");
    }
  }, [optionError]);

  const onCategoryChange = (e) => {
    setCats(e);
  };
  const onSubCategoryClick = (e, i) => {
    if (e?.target?.id == `btn-${i}`) {
      const ClassName = e?.target?.className;
      if (ClassName == "btn-area-inactive") {
        setSelectedSubCat([...selectedSubCat, e?.target?.name]);
        e.target.className = "btn-area-active";
      } else {
        let filtered = selectedSubCat?.filter(
          (fill) => fill != e?.target?.name
        );
        setSelectedSubCat(filtered);
        e.target.className = "btn-area-inactive";
      }
    }
  };

  const checkValid = (e) => {
    if (cats?.length === 0) {
      setoptionError("* Please Select First ");
      return false;
    } else {
      const courseObj = {
        optionVal: cats,
        sub_cat: selectedSubCat,
      };
      dispatch(courseCategoryDetails(courseObj));
      setoptionError();
      return true;
    }
  };

  const submitInfo = (type) => {
    const courseObj = {
      optionVal: cats,
      sub_cat: selectedSubCat,
    };

    dispatch(courseCategoryDetails(courseObj));
    if (type == "call") {
      stepClick == "next"
        ? dispatch(tabPageNo(3))
        : history.push(`/supplier/dashboard`);
    }
  };

  const onNextSkipClick = (e) => {
    e.preventDefault();

    if (cats?.length === 0) {
      setoptionError("* Please Select First ");
    } else {
      setoptionError(null);
    }
  };

  return (
    <>
      <div className="all-tab all-tab2 mt-5">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <p className="p-head mb-1">
              {t("CourseCategoryMultiple", lan)}
              <span className="text-danger">*</span>
            </p>
            <div className="mb-4">
              {/* <input
                type="text"
                className="form-control input-profile px-2 py-3"
                placeholder="Enter Organization Name"
                ref={emailref}
              /> */}
              <Select
                // value={cats}
                value={
                  cats?.map((cor) => {
                    return {
                      label: t(cor?.label,lan),
                      value: t(cor?.value,lan),
                    };
                  })
                }
                onChange={onCategoryChange}
                closeMenuOnSelect={false}
                options={category?.map((cor) => {
                  return {
                    label: t(cor?.category_name,lan),
                    value: t(cor?.category_name,lan),
                  };
                })}
                isMulti
                placeholder="Choose Course Category"
                className="multiselectCategory"
              />
              {optionError && <p className="errorText mb-0">{optionError}</p>}
            </div>
          </div>

          <div className="subCategory-blk">
            <h4>
              {t("Sub-Category Based on ", lan)}
              <span>{t("Selected Category", lan)}</span>
              {/* <span> “Business and Fianace”</span> {t("and", lan)}
              <span> “IT and Information”</span>. */}
            </h4>
            <div className="mb-lg-5 mb-2 row">
              {SubCats?.map((cat, i) => {
                return (
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3 "
                    key={i}
                  >
                    <button
                      id={`btn-${i}`}
                      name={cat?.subcategory_name}
                      onClick={(e) => onSubCategoryClick(e, i)}
                      className={
                        selectedSubCat?.filter(
                          (fll) => fll == cat?.subcategory_name
                        )?.length > 0
                          ? "btn-area-active"
                          : "btn-area-inactive"
                      }
                    >
                      {cat?.subcategory_name}
                    </button>
                  </div>
                );
              })}
              {/* <div className="checbox">
                <input
                  type="checkbox"
                  id="cb_1"
                  value="cb_val"
                  name="cb_name"
                />
                <label for="cb_1">Sub-Category</label>
              </div> */}
            </div>
          </div>
        </div>

        <div className="text-end">
          {state?.UserDetail?.is_first_time_login ? (
            ""
          ) : (
            <button
              onClick={(e) => {
                onNextSkipClick(e);
                setStepCkick("skip");
              }}
              id="skip3"
              className="btn btn-skip me-3 mb-3"
            >
              {t("SKIP", lan)}
            </button>
          )}

          <button
            onClick={(e) => {
              onNextSkipClick(e);
              setStepCkick("next");
            }}
            className="btn btn-next checbox mb-3"
            id="next-1"
          >
            {t("NEXT", lan)}
          </button>
        </div>
      </div>
    </>
  );
});

export default CourseCategory;
