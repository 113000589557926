const sw = {
  Login: "Logga in",
  email: "E-post",
  emailPlaceholder: "E-post",
  PasswordPlaceholder: "Lösenord",
  ForgotPassword: "Glömt ditt lösenord?",
  DontHaveAnAccount: "Har du inget konto?",
  SignUp: "Bli Medlem",
  SocialMediaTitle: "Logga in via sociala medier!",
  terms: "Allmänna Villkor",
  privacy: "Integritetspolicy",
  ResetPassword: "Återställ lösenord",
  ConfirmPassword: "Bekräfta lösenord",
  Alreadymember: "redan medlem?",
  ForgotPasswordHeader: "Glömt ditt lösenord",
  submit: "Skicka in",
  Error: "Fel",
  Success: "Grattis",
  Failed: "Misslyckades",
  CreateAccount: "Skapa konto",
  FirstName: "Förnamn",
  LastName: "Efternamn",
  pwd1: "Innehåller minst en stor bokstav",
  pwd2: "Innehåller minst ett specialtecken",
  pwd3: "Innehåller minst ett nummer",
  pwd4: "Lösenord matchar",
  pwd5: "Minst 8 tecken lång",
  GETSTARTED: "KOMMA IGÅNG",
  GETTOKNOWUS: "LÄR KÄNNA OSS",
  readmore: "Läs mer",
  REGISTERNOW: "REGISTRERA NU",
  AllNews: "Alla nyheter",
  phonnumber: "Telefonnummer",
  Message: "Meddelande",
  VIEWALL: "VISA ALLA",
  ViewAll: "Visa alla",
  Back: "Tillbaka",
  Dashboard: "Översikt",
  MySpace: "Mina sidor",
  MyCourses: "Mitt innehåll",
  EddiLabs: "Eddi labb",
  EddiProfile: "Eddi profil",
  MyFavorites: "Mina favoriter",
  Welcome: "Välkommen,",
  Favorite: "Favoriter",
  EddiSuggestion: "Eddi förslag",
  searchby: "Sök efter kursnamn",
  Okbutton: "OK",
  VIEW: "Läs mera",
  RateNow: "Betygsätt nu",
  aboutThisCourse: "Om denna kurs",
  aboutThisEvent: "Om denna händelse",
  Whatyouwilllearn: "Vad du kommer att lära dig",
  readless: "Läs mindre",
  AboutInstructor: "Om instruktör",
  ENROLLNOW: "REGISTRERA NU",
  CONTINUE: "FORTSÄTTA",
  Checkout: "Kolla upp",
  No_RESULT_FOUND: "Inga resultat",
  ChooseCountry: "Välj land",
  ChooseState: "Välj State",
  PayStripe: "Betala med Stripe",
  PayCard: "Betala med kort",
  PayNow: "Betala nu",
  OrderSummary: "Ordersammanfattning",
  CourseFlow: "Kursflöde",
  CourseMaterial: "Kursmaterial",
  JoinyourZoomClass: "Gå med i din zoomklass",
  JoinZoomMeeting: "Gå med i Zoom Meeting",
  JOINNOW: "GÅ MED NU",
  ZoomId: "Zoom-ID",
  EnterZoomID: "Ange Zoom ID",
  Passcode: "Lösenord",
  EnterPasscode: "Ange lösenord",
  ThriveintheSector: "Trivs i branschen",
  RegisterfortheupcomingEvents: "Anmäl dig till kommande evenemang",
  ShowingAllEvents: "Visar alla händelser",
  Location: "Plats",
  Startsfrom: "Börjar från",
  CourseType: "Kurstyp",
  NewsArticles: "Nyhetsartiklar",
  NewsEvents: "Nyheter och evenemang    ",
  WhatOn: "Nyhetsbrev",
  Continue: "FORTSÄTTA",
  MyProgress: "Min utveckling",
  UpcomingSchedules: "Kommande schema",
  EditProfile: "Redigera profil",
  SaveProfile: "Spara profil",
  FullName: "Fullständiga namn",
  EmailAddress: "E-postadress",
  changesPassword: "Ändrar lösenord",
  PopularCategories: "Populära kategorier",
  CoursesInterested: "Utbildningar intresserade",
  AboutOrganization: "Om organisation",
  Reviews: "Recensioner",
  ViewMaterial: "Visa material",
  MeetingLink: "Möteslänk",
  Home: "Hem",
  AboutEddi: "Handla om Eddi",
  Courses: "Utbildningar",
  ContactUs: "Kontakta oss",
  NoData: "Ingen information tillgänglig",
  EddiEvent: "Annons / Nyheter / Event",
  CourseSubCategory: "Utbildningar underkategori",
  CourseCategory: "Kategori",
  RecruitmentAd: "Rekryteringsannonser",
  ManageCourses: "Hantera utbildningar",
  ManageUsers: "Hantera användare",
  Settings: "inställningar",
  AboutUs: "Om oss",
  Hello: "Hej",
  Completeyourprofile: "Gör klart din profil,",
  clickhere: "Klicka här",
  ViewDetails: "Visa detaljer",
  CoursesOffered: "Våra utbildningar på plattformen",
  searchforcourses: "Sök efter utbildningar...",
  CourseID: "Kurs-ID",
  CourseName: "Namn på utbildning",
  Status: "Status",
  DateCreated: "Skapad",
  Action: "Hantera",
  ViewAll: "Visa alla",
  searchforusers: "Sök efter användare...",
  TotalIndividualforMyCourses: "Totalt anmälda studenter på våra utbildningar",
  UserName: "Användarnamn",
  CoruseName: "Namn på utbildning",
  UserID: "Användar ID",
  CourseType: "Kurstyp",
  ShareZoomLink: "Dela zoomlänk",
  WeeklyDataofMyCourses: "Data för våra utbildningar",
  WeeklyDataofMyEarning: "Data för våra inkomster",
  MyProfile: "Min profil",
  EditProfile: "Redigera profil",
  YourName: "Ditt namn",
  SupplierName: "Leverantör",
  Address: "Adress",
  EmailAddress: "E-postadress",
  CoursesYouOffer: "Utbildningar du erbjuder",
  SaveProfile: "Spara profil",
  DeleteText: "Radera",
  CancleText: "Avbryt",
  ContactPersonName: "Kontaktperson",
  OrganizationEmail: "Organisations E-postadress",
  OrganizationalName: "Organisations namn",
  OrganizationWebsite: "Organisationens webbplats",
  OrganizationLocation_Address: "Organisation adress",
  Country: "Land",
  City: "Stad",
  BreifInformation: "Information om organisationen",
  OrganizationPhoneNumber: "Telefonnummer",
  ContactPerson: "Kontaktperson på Eddi",
  CourseCategoryMultiple: "Kurskategori (Flera kan väljas)",
  and: "och",
  SubCategorybasedon: "Underkategori baserad på",
  TotalUser: "Totalt antal användare på Eddi",
  CourseBySupplier: "Utbildningar tillagda av leverantörer",
  UserRole: "Användarroll",
  ViewCourse: "Visa utbildning ",
  TotalSuppliers: "Leverantörer totalt",
  TotalIndividuals: "Individer totalt",
  TotalCourses: "Utbildningar totalt",
  PurchasedCourses: "Beställda utbildningar",
  AboutMe: "Om mig",
  EditProfile: "Edit Profile",
  All: "Allt",
  Weekly: "Varje vecka",
  Monthly: "En gång i månaden",
  Yearly: "Årlig",
  Related: "Relate rade",
  CourseFound: "Utbildningar hittad från alla ",
  FavoriteCourse: "Favorit Kurs",
  DiscoverCourses: "Upptäck utbildningar",
  PopularArea: "Över populära områden",
  SelectCourse: "Välj en kurs så visar vi dig relevant resurs för det!",
  NewCourseProfile: "Nya utbildningar baserade på din profil",
  FollowUs: "Följ oss:",
  Copyright: "Copyright © 2022 Drivs av eddi webbplats",
  QuickLinks: "Quick Links",
  FooterTag: "© Eddi webbplats 2022",
  AboutUs: "Om oss",
  EditProfile: "Redigera profil",
  ChangePassword: "Ändra lösenord",
  SignOut: "Logga ut",
  NotificatioAktivans: "Aviseringar",
  Active: "Aktiv",
  inActive: "Inaktiv",
  JoinNow: "Gå med nu",
  View: "Visa",
  SubCategory: "underkategori",
  Manage: "Hantera",
  Add: "Lägg till",
  searchbyName: "Sök efter namn",
  EducationalInstitution: "Utbildningsinstitution",
  by: "av",
  created: "Skapad",
  Name: "Namn",
  name: "namn",
  Approve: "Godkänn",
  Reject: "Avvisa",
  Status: "Status",
  Pending: "Pågående",
  Approved: "Godkänd",
  Rejected: "Avvisad",
  Active: "Aktiv",
  Inactive: "Inaktiv",
  Edit: "Ändra",
  Delete: "Radera",
  Enter: "Stiga på",
  subPlaceHolder: "Ange underkategori namn",
  Category: "Kategori",
  Course: "Utbildningens",
  Choose: "Välj",
  Image: "Bild",
  Upload: "Ladda upp",
  UpDate: "Uppdatering",
  Paid: "Med avgift",
  Free: "Utan avgift",
  Newest: "Nyaste",
  Oldest: "Äldst",
  Images: "Bilder",
  Overview: "Översikt",
  Length: "Längd",
  Days: "dagar",
  Date: "Datum",
  Level: "nivå",
  level: "nivå",
  Fee: "Avgift",
  Type: "Typ",
  Price: "Pris",
  Physical: "Fysisk",
  Online: "Digital",
  Checkout: "Kolla upp",
  Link: "Länk",
  Language: "Språk",
  Organization: "Företagsspecifikt",
  For: "För",
  Update: "Uppdatering",
  Detail: "information",
  EditMaterial: "Redigera material",
  AddMaterial: "Lägg till material",
  UploadMaterial: "ladda upp material",
  Learners: "Lärare",
  Description: "Beskrivning",
  description: "beskrivning",
  TotalIndiCourse: "Anmälda deltagare  ",
  TotalIndiEvent: "Personer som har sökt denna event",
  event: "event",
  Contact: "Kontakt",
  Information: "Information",
  DragDrop: "Dra och släpp fil eller välj från enhet",
  Recruitment: "Rekryterings",
  Ad: "Annonser",
  Ads: "Annonser",
  ads: "annonser",
  Viewers: "Tittare",
  viewers: "antal tittare",
  Total: "Total",
  Expiry: "Upphörande",
  Banner: "Baner",
  Subscribers: "Subscribers",
  List: "Lista",
  ExpiresOn: "Går ut den",
  PostedOn: "Postat på",
  PostedBy: "Postat av",
  on: "på",
  StartsOn: "Startar på",
  Advertisement: "Annons",
  advertisement: "annons",
  Role: "Roll",
  User: "Användare",
  UserType: "Användarroll",
  Supplier: "Leverantör",
  Profile: "Profil",
  DeletePopUp: "Är du säker att du vill radera?",
  PublishOn: "Publicera på",
  Title: "Titel",
  Created: "Skapad ",
  Individual: "Enskild",
  had: "hade",
  applied: "applicerad",
  for: "för",
  course: "utbildning",
  Company: "Företag",
  Email: "E-post",
  Website: "Website",
  Phone: "Telefon",
  Number: "siffra",
  About: "Handla om ",
  Logo: "logotype",
  Offer: "Erbjudande",
  Person: "Person",
  Contact: "Kontakt",
  Organizational: "Organisatorisk",
  Additional: "Ytterligare",
  You: "Du",
  Are: "Är",
  All: "Alla",
  Set: "Ställa",
  Now: "Nu",
  Search: "Sök",
  Platform: "plattformen",
  My: "Min",
  ClearAll: "Rensa alla",
  Purchased: "köp/anmälningar",
  Not: "Inte",
  PaymentId: "Betalnings-ID",
  PaymentStatus: "Betalningsstatus",
  PaymentAmt: "Betalningsbelopp",
  Individual: "Individ",
  PaymentMode: "Betalningssätt",
  Payment: "Betalning",
  Length: "Längd",
  Link: "länk",
  FilterBy: "Filtrera efter",
  EventName: "Händelsens namn",
  searchbyAdsName: " Sök efter annonsnamn",
  OriginalPrice: "Ursprungligt pris",
  ManageSupplierProfile: "Hantera leverantörsprofil",
  ManagePayments: "Hantera betalningar",
  IndividualName: " Student namn",
  PaymentStatus: "Betalningsstatus",
  ChooseRole: "Välj roll",
  CourseCategory: "Utbildningskategorier",
  CourseSubCategory: "Underkategori utbildningar",
  CourseSubCategoryForCourse: "Underkategori",
  UploadBannerImages: "Ladda upp bannerbilder",
  ChooseType: "Välj typ",
  VideoLink: "videolänk",
  VLink: "Videolänk",
  EnterLink: "Ange länk",
  AboutOrganization: " Om organisationen",
  CoursesOffer: "Utbildningar erbjuder",
  Highestlvl: "Högsta utbildningsnivå",
  HighestDegree: "Högsta grad",
  EducationArea: "Utbildningsområde",
  OtherRelevantEducation: "Annan relevant utbildning",
  DiplomaCertificates: "Diplom och certifikat",
  CurrentProfessionalRole: "Nuvarande yrkesroll",
  AdditionalRole: "Ytterligare roll (om någon)",
  ExtraCurricular: "Extra kompetens du vill ha",
  CoreResponsibilities: "Kärnansvar",
  LevelOfRole: "Rollnivå",
  FutureProfessionalRole: "Framtida yrkesroll",
  AreaOfInterest: "Intresseområde",
  On: "på",
  UploadCourseImages: "Ladda upp bild för utbildningen",
  CourseNameTitle: "Namn på utbildningen",
  EnterCourseName: "Ange namn på utbildningen",
  CourseOverview: "Beskriv utbildningen",
  CourseLength: "Utbildningens längd",
  EnterCourseLength: "Ange h/dag",
  ChooseCourseLength: "Utbildningens längd",
  OfferPrice: "Erbjudandets pris",
  EnterOriginalPrice: "Ange originalpris",
  EnterOfferPrice: "Ange pris på erbjudande",
  ChooseLevel: "Välj nivå",
  ChooseCourseLevel: "Välj svårighetsgrad",
  SelectCourseDate: "Välj kursdatum",
  ChooseCourseCategory: "Välj utbildningens huvudkategori",
  ChooseSubCategory: "Utbildningsområde",
  CourseType: "Typ av utbildning",
  EnterOrganizationLoaction: "Ange Organisation Loaction",
  EnterMeetingLink: "Ange möteslänk",
  ChooseFeeType: "Välj avgiftstyp",
  EnterCoursePrice: "Ange kurspris",
  SubArea: "Delområde (separera med komma)",
  EnterCourseSubArea: "Ange relevanta delområden som utbildningen omfattar",
  EnterCheckoutLink: "Ange länk för utcheckning hos leverantör",
  CourseLanguage: "Utbildningens språk",
  CourseForOrganization: "Organisationsspecifik utbildning",
  DateofBirth: "Födelsedatum",
  optional: "valfri",
  Passcode_optional: "Lösenkod (valfritt)",
  EnterEventLocation: "Plats",
  ChooseEventType: "Välj händelsetyp",
  EnterEventPrice: "Ange händelsepris",
  ChooseEventFeeType: "Välj typ av händelseavgift",
  EventStartTime: "Starttid för evenemang",
  EventStartDate: "Startdatum för evenemanget",
  all: "allt",
  AdditionalInformation: "Ytterligare information",
  OrganizationalInformation: "Organisatorisk information",
  ResetyourPassword: "Återställ ditt lösenord",
  Enteryourpasswordbelow: "Ange ditt lösenord nedan",
  PASSWORD: "LÖSENORD",
  RecruitmentAds: "Rekryteringsannonser",
  CourseName: "Namn på utbildning",
  UniversityName: "Utbildningsinstitution",
  ManageSubCategory: " Underkategori utbildningar",
  External: "Extern",
  Invoice: "Faktura",
  EventEndDate: "Eventets slutdatum",
  NoData: "Ingen information tillgänglig",
  AddCourse: "Lägg till en utbildning",
  UpdateCourse: "Uppdatering till en utbildning",
  UpdateCourseBtn: "Uppdatera utbildningen",
  AddCourseTitle: "Lägg till utbildning",
  OR: "eller",
  or: "Eller",
  Advanced: "Avancerad nivå",
  Intermediate: "Medelnivå",
  Beginner: "Grundnivå",
  MeetingPasscode: "Ange möteslösenord",
  CourseLocation: "Utbildningsort",
  EnterLocation: "Ange utbildningsort(er), separerat med komma",
  English: "Engelska",
  Swedish: "Svenska",
  EnterEmails: "Ange emailadresser till specifika användare ...",
  CheckoutLink: "Länk för utcheckning",
  CourseCategoryName: "Huvudkategori",
  LoginEddi: "Logga in på Eddi",
  EnterEmailBelow: "Ange din e-postadress och ditt lösenord nedan",
  PASSWORD: "LÖSENORD",
  EMAIL: "E-POST",
  AuthorName: "Huvudlärare",
  EnterAuthorName: "Lägg till namn på huvudlärare",
  WriteAuthor: "Lägg till information om huvudlärare",
  CourseList: "Utbildning lista",
  BatchList: "Batch lista",
  searchbyBatchName: "Sök batchnamn",
  AddBatch: "Lägg till batch",
  BatchName: "Batch namn",
  UpdateBatch: "Update batch",
  EnterBatchName: "Skriv namn på batch",
  chooseCourseName: "Utbildningens namn",
  "Choose Course Name": "Välj utbildning",
  AddStudents: "Lägg till deltagare",
  SearchSByName: "Sök på deltagarnas namn",
  showAllStu: "Alla deltagare som är anmälda till utbildningen",
  SessionLisiting: "Session lista",
  AddSession: "Lägg till session",
  UpdateSession: "Update Session",
  SessionName: "Namn på session",
  SessionURL: "Session URL",
  searchBySessionName: "Search by session name",
  EnterSessionName: "Lägg till namn på session",
  chooseBatch: "Välj batch",
  chooseBatchName: "Välj batch för session",
  SessionStartDate: "Startdatum",
  SessionEndDate: "Slutdatum",
  StartTime: "Starttid",
  EndTime: "Sluttid",
  TotalDuration: "Total tid för session",
  ChooseDate: "Choose Date",
  ChooseDays: "Välj dagar för session",
  EnterSessionURL: "Enter Session URL",
  Batch: "Batch",
  Session: "Session",
  SelectDays: "Select days",
  TotalEarning: "Total intäkt (inkl. moms)",
  AmmountDue: "Utestående belopp för kortbetalningar (inkl. moms)",
  AmmountWithdrow: "Överfört belopp för kortbetalningar (inkl. moms)",
  PaymentList: "Payment list",
  TotalEarning: "Total intäkt (inkl. moms)",
  Commission: "Commission",
  TotalPaid: "Total paid",
  TotalDue: "Total due",
  ViewPaymentDetails: "View Payment Details",
  AllPaymentDetails: "All Payment Details",
  PaymentDetailsText: "Here are the alll payment details of User.",
  SendPayment: "Send Payment",
  EnterAmmount: "Enter withdrawal ammount",
  Amount: "Amount",
  Ammount: "withdrawal ammount",
  PayOut: "Payout",
  Withdraw: "Begär uttag",
  PaymentDetails: "Payment Details",
  SupplierPayouts: "Supplier Payouts",
  MyPayment: "My Payment",
  MyPaymentLisiting: "My Payment Lisiting",
  SupplierPaymentList: "Supplier payment list",
  PaymentMethod: "Payment method",
  WithdrawalRequest: "Withdrawal request",
  Transfer: "Transfer",
  Hold: "On hold",
  SureTransfer: "Är du säker på att överföra beloppet",
  ProvideReason: "Vänligen ange skäl till att uttaget nekas",
  EnterReason: "Ange förklaring",
  Reason: "Förklaring",
  WalletAmmount: "Total wallet amount",
  "Select Course Date": "Välj datum",
  "Health and Care": "Hälsa och sjukvård",
  "Administrative and Extra Curricular": "Administration och fritid",
  "School and Teaching": "Skola och undervisning",
  "Executive Education": "Ledarskap",
  "Technology and Society": "Samhälle och teknik",
  "Legal and Law": "Juridik",
  "Legal and law": "Juridik",
  "Safety academy": "Trygghetsakademin",
  "IT and information": "IT och information",
  "Marketing academy": "Marknadsakademin",
  "Executive education": "Ledarskap",
  "Business and finance": "Ekonomi och finans",
  "IT and Information": "IT och information",
  "Society and technology": "Samhälle och teknik",
  Gric: "GRC",
  GRC: "GRC",
  "Business and Finance": "Ekonomi och finans",
  PayByInvoice: "Fakturabetalning",
  TotalPaymentAmt: "Totalt belopp (inkl. moms)",
  PaymentMethod: "Betalningsmetod",
  ExclVATSEK: "(Exkl. moms, SEK)",
  Adss: "annonser",
  SKIP: "Hoppa över",
  NEXT: "Nästa",
  YouAreAllSetNow: "Lägg till er logotype",
  organization: "organisationens",
  InDays: "(Ange längd i dagar)",
  Monday: "Måndag",
  Tuesday: "Tisdag",
  Wednesday: "Onsdag",
  Thursday: "Torsdag",
  Friday: "Fredag",
  Saturday: "Lördag",
  Sunday: "Söndag",
  AgreeToReceiveNotificationInSwedish:
    "Jag önskar kommunikation från Eddi på engelska",
  ExpireOn: "Går ut den",
  TotalValueSoldCourses:
    "Totalt värde sålda utbildningar (på plattformen) (inkl. moms)",
  UploadBannerImages: "Ladda upp bild",
  Small: "Kort",
  EnterVideolink: "Extern länk",
  StartDate: "Startdatum",
  StartTime: "Starttid",
  FeeType: "Avgiftstyp",
  EventLocation: "Plats/ort",
  EventTitle: "Namn på event",
  LandingPage: "Hemsida",
  UserDashboard: "Användarsida",
  Both: "båda",
  AreYouSure:
    "Vill du fortsätta med Google sign in? (Behövs för kalenderbokningar med deltagarna)",
  //phase -2
  ExpireOn: "Går ut den",
  Categories: "Kategorier",
  Event: "Event",
  CorporateCourse: "Våra utbildningar",
  CateInSwe: "Kategori på svenska (in svenska) ",
  EnterCateInSWe: "Skriv kategorinamn på svenska",
  CatInEng: "Kategori på engelska",
  EnterCatName: "Skriv kategorinamn på engelska",
  CatDescSwe: "Beskriv kategori på svenska",
  EnterCatDesSwe: "Beskriv kategori på svenska",
  EnterCatDesEng: "Beskriv kategori på engelska",
  CatDesEng: "Beskriv kategori på engelska",
  UpdateCat: "Uppdatera kategori",
  AddCat: "Lägg till kategori",
  DeleteCat: "Delete Category",
  TotalIndi: "Total individuals",
  ManageCourseContent: "Hantera innehåll",
  ManangeModule: "Hantera moduler",
  ModuleNo: "Nummer på modul",
  ModuleName: "Modulens namn",
  AddModule: "Lägg till modul",
  UpdateModule: "Update Module",
  ManageMaterial: "Hantera innehåll",
  AddVideo: "Lägg till video",
  AddFile: "Lägg till fil",
  AdjustMaterial: "Justera ordningföljd",
  Video: "Video",
  video: "video",
  File: "Fil",
  file: "fil",
  NameInEn: " namn/titel (på engelska)",
  NameInSw: " namn/titel (på svenska)",
  DescInEn: "beskrivning på engelska",
  DescInSw: "beskrivning på svenska",
  ChooseVideo: "Choose video file",
  BackgroundFile: "Välj bakgrundsvideo/bild",
  ChooseFile: "Välj fil",
  EnterValidTitle: "Please enter valid title",
  EnterValidDesc: "Please enter valid description",
  AtLeastOneFile: "Please select atleast 1 file",
  CorporateDashboard: "Om oss",
  BackgroundImg: "Bakgrundsbild",
  WhatsOn: "Aktuellt",
  Logo: "Logotyp",
  AboutCompany: "Vår utbildningsstrategi",
  AddInfo: "Lägg till information",
  UpdateInfo: "Uppdatera information",
  AboutCompanySw: "Om vår utbildningsstrategi på svenska",
  AboutCompanyEn: "Om vår utbildningsstrategi på engelska",
  EnterContent: "Enter Content",
  WhatOnEn: "Nyhetsbrev på engelska",
  WhatOnSw: "Nyhetsbrev på svenska",
  CorporateUser: "Våra användare",
  CorporateBatch: "Skapa Batch",
  CorporateSession: "Skapa Session",
  CourseInstructorname: "Kurslärarens namn",
  CourseInstructoremail: "Kurslärarens e-post",
  EnterCourseInstructoremail: "Ange kursförfattarens e-postadress",
  EnterCourseInstructorname: "Ange kursförfattarens namn",
  ManageModuleAndContent: "Hantera moduler och innehåll",
  CourseContent: "innehåll",
  RejectReason: "Ange skäl för avvisning",
  InviteUser: "Bjud in användare",
  EnterEmail: "Ange e-mailadresser",
  PressEnter: "Skriv e-post och tryck enter..",
  CorporateCode: "Kod för identifikation",
  Published: "Publicerad",
  PublishContent: "Publicera innehåll",
  UnpublishContent: "Avpublicera innehåll",
  CatName: "Namn kategori",
  EnterVideoTitleEn: "Ange videons titel",
  AddMNameSwe: "Lägg till modulens namn på svenska",
  AddMNameEng: "Lägg till modulens namn på engelska",
  EnterMNameSwe: "Lägg till namn på svenska",
  EnterMNameEng: "Lägg till namn på engelska",
  EnterMDesEng: "Skriv kort beskrivning på engelska",
  EnterMDesSwe: "Skriv kort beskrivning på svenska",
  MngCat: "Hantera kategori",
  ComingSoon: "kommer snart..",
  FileNaTiISW: "Filnamn / Titel (på svenska)",
  EnterVideoTitleSw: "Ange videons titel",
  EnterDescFile: "Ange beskrivning",
  EnterFileTitleSw: "Ange filtitel",
  EnterFileTitleEn: "Ange filtitel",
  Notifications: "Aviseringar",
  NotificationFound: "Meddelande hittades",
  Republish: "Re-publish course",
  EnterDescription: "Lägg till beskrivning",
  OurOrganization: "Vår organisation",
  EnterOrgEmail: "Skriv in email till dig/er",
  OrgProfile: "Organisationsprofil",
  EnterCorporateCode: "Välj egen kod med siffror/bokstäver",
  EnterOrgInformation: "Kort information om er organisation",
  ContactEddi: "Kontaktperson hos Eddi",
  Learners: "Anmälda",
  UserInfo: "Användarinformation",
  BannerPublistion: "Banner publicering",
  success: "Grattis!",
  Hour: "Timmar",
  terms: "Användarvillkor",
  privacy: "Datahantering",
  AgreeTermsUse: "Godkänn användarvillkor",
  ForgotPassword: "Glömt ditt lösenord?",
  TotalvalueSoldCourse:
    "Totalt värde sålda utbildningar (på plattformen) (inkl. moms)",
  EnterLength: "Utbildningens längd",
  Day: "Dagar",
  EnterYourEmailBelow: "Ange din e-postadress nedan",
  EnterYourEmail: "ANGE DIN E-POSTADRESS",
  StartFrom: "Börjar från",
  CourseDescription: "Beskrivning av utbildningen",
  AddMDescSwe: "Beskriv modulen på svenska",
  AddMDescEng: "Beskriv modulen på engelska ",
  UserDashboard: "Användarsida",
  AdvertisementTitle: "Annons titel",
  AdminFee: "Admin fee",
  IncludingVat: "Inklusive moms",
  news: "nyheter",
  News: "Nyheter",
  NewsTitle: "Nyheter title",
  EnterNewsTitle: "Enter nyheter title",
  NewsDescTitleEn: "Nyheter beskrivning title",
  EnterNewsDescTitleEn: "Enter Nyheter beskrivning title",
  EnterShortDesc: "Enter short description",
  Gender: "Kön",
  OngoingCourses: "Pågående Utbildningar",
  Ongoing: "Pågående",
  Completed: "Avslutad",
  CreateStripeAccount: "Skapa Stripekonto",
  general: "allmän",
  UpdateNews: "Uppdatera innehåll",
  DateCreatedOn: "Skapad",
  Active: "Aktiv",
  Inactive: "Inaktiv",
  Personal: "Personlig ",
  Information: "information",
  Educational: "Utbildnings information",
  ProfessionalInfo: "Professionell information",
  CourseList: "Anmälningar",
  CompanyInformation: "Information om organisationen",
  CoursesOfferOrg: "Utbildningskategorier",
  SupplierProfile: "leverantörsprofil",
  ChooseCourseLevelCorporate: "Utbildningens startdatum",
  CourseLevel: "Välj svårighetsgrad",
  Order: "Ändra ordningsföljd (dra/släpp)",
  CoursesOfferedChart: "utbildningar på plattformen",
  NotPurchased: "Inga anmälningar",
};
export default sw;
