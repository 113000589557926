import logo from './logo.svg';
import './App.css';
import Routing from './routing';
import NoInternet from './components/NoInternet/NoInternet';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {onMessageListener, requestPermission} from "./services/firebaseService"
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

export const massageRecived = (payload)=>{
  console.log("payy",payload);
  toast.info(payload.notification.body)
}

function App() {
  let internet = navigator.onLine;
  const state = useSelector(state=>state?.Eddi)

  useEffect(()=>{
    
    requestPermission()
    // if (window.location.href.includes("localhost")) {
    //   localStorage.setItem("Platform","PROD");
    // }
    if (window.location.href.includes("testyourapp") || window.location.href.includes("localhost")) {
      localStorage.setItem("Platform","DEV");
      console.log("plat",localStorage.getItem("Platform"))
    }
    else if (window.location.href.includes("nu")) {
      localStorage.setItem("Platform","PROD");
    }
  },[])

    onMessageListener()
    .then((payload) => {
      // toast.info(payload.notification.body)
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <div className="App">
      {internet ?
      <Routing /> :
        <NoInternet />
      }
        <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop={false}
        closeOnClick
        limit={1}
        rtl={false}
        draggable
        theme='colored'
      />
      {/* Same as */}
    </div>
  );
}

export default App;
