import React, { useEffect, useState } from "react";
import "./subCategory.scss";
import "../../../common/styles/globalStyles.scss";
import { FaFilter, FaSortAmountUp } from "react-icons/fa";
import SidePopup from "../../../components/sidePopup/sidePopup";
import UploadImage from "../../../assets/images/uploadImage.png";
import { Link, useHistory } from "react-router-dom";
import {
  deleteSubCategory,
  editSubCategory,
  getCategoryApi,
  getSubCategoryApi,
  postSubCategory,
} from "../../../services/supplierService";
import { useDispatch, useSelector } from "react-redux";
import { courseCategories, courseSubCategories } from "../../../redux/actions";
import API from "../../../api";
import Popup from "../../../components/popup/popup";
import { getTranslatedText as t } from "../../../translater/index";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import placeholder from "../../../assets/images/placeholder.svg";
import NoData from "../../../components/NoData/NoData";
import InputText from "../../../components/inputText/inputText";
import IcSearch from "../../../assets/images/icSearch.svg";
import Loader from "../../../components/Loader/Loader";
import Footer from "../../../components/footer/Footer";
import { formatDate } from "../../../services/constant";

function SubCategory() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const history = useHistory();

  const [addSubCategoryPopup, setAddSubCategoryPopup] = useState(false);
  const [subCategoryImage, setSubCategoryImage] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [editCategory, setEditCategory] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [filteredCat, setFilteredCat] = useState();
  const [paginationCourses, setPaginationCourses] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const role = state?.UserDetail?.user_type;
  const [isAdmin, setIsAdmin] = useState(role == "Admin" ? true : false);
  const [imageError, setImageError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");
  const [subCategoryNameError, setSubCategoryNameError] = useState("");
  const [popupDelete,setPopupDelete]=useState('')
  const [loderBtn, setLoderBtn] = useState(false);

  useEffect(() => {
    setAllCategories(state.CourseCategories);
  }, [state]);

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredCat?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationCourses(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredCat]);

  const subCategoryCall = () => {
    setIsLoader(true);
    getSubCategoryApi()
      .then((res) => {
        setIsLoader(false);
        if (res.status == "success") {
          const result = res.data.map((item)=>{
            item.colour =`#${generateColor()}` 
            return item
          })
          setAllSubCategories(result);
          setFilteredCat(result);
          dispatch(courseSubCategories(result));
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const categoryCall = () => {
    getCategoryApi()
      .then((res) => {
        if (res.status == "success") {
          // setAllSubCategories(res?.data)
          dispatch(courseCategories(res.data));
        } else {
          // alert("error Occured")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    subCategoryCall();
    categoryCall();
  }, []);

  useEffect(() => {
    if (subCategoryImage !== null && subCategoryImage !== undefined) {
      let image = document.getElementById("output");
      image.src = URL.createObjectURL(subCategoryImage);
    }
  }, [subCategoryImage]);

  const addSubCategory = () => {
    let category = document.getElementById("chooseCourseCategory")?.value;
    let subCategory = document.getElementById("subCategoryName")?.value;
    let categoryImage = subCategoryImage;
    const allSubCategories = state?.approvedSubCategory;
    let filteredSubCategory = allSubCategories?.filter(
      (filtt, index) =>
        filtt?.subcategory_name?.toLowerCase() == subCategory?.toLowerCase()
    );
    let filtered = filteredSubCategory?.filter(
      (data) =>
        data?.category_name?.category_name?.toLowerCase() ==
        category?.toLowerCase()
    );
    console.log("filteredCategory", category);
    if (!category || (category === "Choose course category" && !subCategory)) {
      setSubCategoryError("*Sub-Category is Required");
      setCategoryError("*Category is Required");
    } else if (!category || category == "Choose course category") {
      setCategoryError("*Category is Required");
    } else if (!subCategory && subCategory?.trim() === "") {
      setSubCategoryError("*Sub-Category is Required");
    } else if (filtered?.length > 0) {
      setSubCategoryNameError("*Sub-category name is already taken");
    } else {
      var data = new FormData();
      data.append("category_name_id", category);
      data.append("subcategory_name", subCategory);

      setLoderBtn(true);
      postSubCategory(data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            subCategoryCall();
            setPopupStatus(t("Success", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            // alert("error Occured")
            setPopupStatus(t("Failed", lan) + "!");
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const editCategoryCall = () => {
    let category = document.getElementById("chooseCourseCategory")?.value;
    let subCategory = document.getElementById("subCategoryName")?.value;
    let categoryImage = subCategoryImage
      ? subCategoryImage
      : editCategory?.subcategory_image;
    const allSubCategories = state?.approvedSubCategory;
    let filteredSubCategory = allSubCategories?.filter(
      (filtt, index) =>
        filtt?.subcategory_name?.toLowerCase() == subCategory?.toLowerCase()
    );
    let filtered = filteredSubCategory?.filter(
      (data) =>
        data?.category_name?.category_name?.toLowerCase() ==
        category?.toLowerCase()
    );
    console.log("filteredCategory", filteredSubCategory, filtered);
    if (!category && !subCategory) {
      setSubCategoryError("*Sub-Category is Required");
      setCategoryError("*Category is Required");
    } else if (!category && category == "Choose") {
      setCategoryError("*Category is Required");
    } else if (!subCategory && subCategory?.trim() == "") {
      setSubCategoryError("*Sub-Category is Required");
    } else if (filtered?.length > 0) {
      setSubCategoryNameError("*Sub-category name is already taken");
    } else {
      var data = new FormData();
      data.append("category_name_id", category);
      data.append("subcategory_name", subCategory);

      setLoderBtn(true);
      editSubCategory(editCategory.uuid, data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            subCategoryCall();
            setPopupStatus(t("Success", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            setPopupStatus(t("Failed", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const deleteCategoryCall = () => {
    deleteSubCategory(popupDelete)
      .then((res) => {
        setPopupDelete('')
        if (res.status == "success") {
          subCategoryCall();
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          setPopupStatus(t("Success", lan) + "!");
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onApproveReject = (e, id, type) => {
    let formData = new FormData();
    if (type == "Approval") {
      formData.append("approval_status", e?.target?.value);
    } else {
      formData.append("status", e?.target?.value);
    }
    editSubCategory(id, formData)
      .then((result) => {
        if (result?.status == "success") {
          subCategoryCall();
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (editCategory !== null && editCategory !== undefined) {
      setSubCategoryName(editCategory.subcategory_name);
      setAddSubCategoryPopup(true);
    } else {
      setSubCategoryName("");
    }
  }, [editCategory]);

  const handleClosePopup = () => {
    setAddSubCategoryPopup(false);
    setSubCategoryImage(null);
    setEditCategory(null);
    setSubCategoryError("");
    setCategoryError("");
    setSubCategoryName("");
    setSubCategoryNameError("");
  };
  const handleClosePopupStatus = () => {
    setPopupStatus("");
    setPopupDelete('')
  };

  const handleSearchData = (e) => {
    let searchData = [];
    if (e.target.value != "") {
      allSubCategories?.map((subCat) => {
        if (
          subCat?.subcategory_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(subCat);
        }
      });
    } else {
      searchData = allSubCategories;
    }

    setFilteredCat(searchData);
  };
  const generateColor = () => {
    return Math.random().toString(16).substr(-6);
  };
  console.log(">>>>",paginationCourses);
  return (
    <div className="subCategory">
      <div className="fix_height">
        <div className="subCategoryHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">
              {" "}
              {t("ManageSubCategory", lan)}
            </span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchbyName", lan)}
                onChange={handleSearchData}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <button
                className="primaryButton mt-0"
                onClick={() => {
                  setAddSubCategoryPopup(true);
                }}
              >
                {t("Add", lan)} {t("SubCategory", lan)}
              </button>
            </div>
          </div>

          {/* <div className="subCategoryHeaderRight courseHeader">
          <div className="fliter-block">
            <div className="pageSearch">
              <InputText
                placeholder="Search by name"
                onChange={handleSearchData}
              />
            </div>
            <button
              className="primaryButton mt-0"
              onClick={() => {
                setAddSubCategoryPopup(true);
              }}
            >
              Add Sub Category
            </button>
          </div>
        </div> */}
        </div>
        <div className="subCategoryListDiv">
          <table
            className={`subCategoryListTable subCategoryListTable-table table-${
              isAdmin ? "admin" : "supplier"
            }`}
          >
            <tr className="whitespaceNoWrap">
              {isAdmin && (
                <th>
                  {" "}
                  {t("created", lan)} {t("by", lan)}
                </th>
              )}
              <th> {t("Category", lan)}</th>
              <th className="w45">
                {" "}
                {t("SubCategory", lan).charAt(0).toUpperCase() +
                  t("SubCategory", lan).slice(1)}
              </th>
              <th>{t("DateCreated", lan)}</th>
              <th>
                {" "}
                {t("Approve", lan)}/ {t("Reject", lan)}
              </th>
              <th>Status</th>
              <th>{t("Action", lan)}</th>
            </tr>

            {paginationCourses?.length > 0 &&
              paginationCourses?.map((cat, index) => {
                return (
                  <tr key={index} className="subCategoryTableInfo">
                    {isAdmin && (
                      <td>
                        <div className="d-flex">
                          <p
                            className="spplierImg"
                            style={{ backgroundColor: cat?.colour }}
                          >
                            {cat?.supplier?.first_name.charAt(0).toUpperCase()}{" "}
                          </p>
                          <div>
                            <p className="mb-1">
                              {cat?.supplier?.first_name || "-"}
                            </p>
                            <p className="mb-1">
                              {cat?.supplier?.email_id || "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                    )}
                    <td>{t(cat.category_name.category_name, lan)}</td>
                    <td title={cat.subcategory_name}>
                      {cat.subcategory_name?.length > 50
                        ? cat.subcategory_name.slice(0, 60).concat("...")
                        : cat.subcategory_name}
                    </td>
                    <td>
                      {formatDate(cat.created_date_time)}
                    </td>
                    <td>
                      {isAdmin ? (
                        <select
                          value={cat.is_approved?.value}
                          onChange={(e) =>
                            onApproveReject(e, cat?.uuid, "Approval")
                          }
                          className={
                            cat.is_approved?.value == "Pending"
                              ? "StatusActive"
                              : cat?.is_approved?.value == "Rejected"
                              ? "StatusInActive"
                              : "StatusApproved"
                          }
                        >
                          <option value={"Pending"}>{t("Pending", lan)}</option>
                          <option value={"Approved"}>
                            {t("Approved", lan)}
                          </option>
                          <option value={"Rejected"}>
                            {t("Rejected", lan)}
                          </option>
                        </select>
                      ) : (
                        <span
                          style={{ backgroundImage: "none" }}
                          className={
                            cat.is_approved?.value == "Pending"
                              ? "StatusActive"
                              : cat?.is_approved?.value == "Rejected"
                              ? "StatusInActive"
                              : "StatusApproved"
                          }
                        >
                          {cat?.is_approved?.value
                            ? t(cat?.is_approved?.value, lan)
                            : t("Pending", lan)}
                        </span>
                      )}
                    </td>
                    {!isAdmin && (
                      <td>
                        {!cat.is_approved?.value ||
                        cat.is_approved?.value == "Pending" ||
                        cat.is_approved?.value == "Rejected" ? (
                          <p>--</p>
                        ) : (
                          <select
                            value={cat?.status?.value}
                            onChange={(e) =>
                              onApproveReject(e, cat?.uuid, "Activation")
                            }
                            className={
                              cat?.status?.value === "Active"
                                ? "StatusActive"
                                : "StatusInActive"
                            }
                          >
                            <option value={"Active"}>{t("Active", lan)}</option>
                            <option value={"Inactive"}>
                              {t("Inactive", lan)}
                            </option>
                          </select>
                        )}
                      </td>
                    )}
                    {isAdmin && (
                      <td>
                        {!cat.is_approved?.value ||
                        cat.is_approved?.value == "Pending" ||
                        cat.is_approved?.value == "Rejected" ? (
                          <p>--</p>
                        ) : isAdmin ? (
                          <span
                            style={{ backgroundImage: "none" }}
                            className={
                              cat?.status?.value === "Active"
                                ? "StatusActive"
                                : "StatusInActive"
                            }
                          >
                            {t(cat?.status?.value, lan)}
                          </span>
                        ) : (
                          <select
                            value={cat?.status?.value}
                            onChange={(e) =>
                              onApproveReject(e, cat?.uuid, "Activation")
                            }
                            className={
                              cat?.status?.value === "Active"
                                ? "StatusActive"
                                : "StatusInActive"
                            }
                          >
                            <option value={"Active"}>{t("Active", lan)}</option>
                            <option value={"Inactive"}>
                              {t("Inactive", lan)}
                            </option>
                          </select>
                        )}
                      </td>
                    )}
                    <td className="whitespaceNoWrap">
                      <span
                        className="cursorPointer textPrimaryColor"
                        onClick={() => {
                          setEditCategory(cat);
                        }}
                      >
                        {t("Edit", lan)}
                      </span>{" "}
                      
                     {isAdmin &&  <span
                        className="cursorPointer textPrimaryColor"
                        onClick={() => {
                          setPopupDelete(cat.uuid)
                        }}
                      >
                        |{" "}
                        {t("Delete", lan)}
                      </span>}
                    </td>
                  </tr>
                );
              })}
          </table>
          {paginationCourses?.length <= 0 && (
            <div>
              <NoData />
            </div>
          )}
        </div>
        <div className="mt-3 text-end">
          <div className="row">
            {filteredCat?.length > 12 && (
              <CustomPagination
                startIndex={paginationStartIndex}
                endIndex={
                  paginationStartIndex + 11 > filteredCat?.length
                    ? filteredCat?.length
                    : paginationStartIndex + 11
                }
                totalData={filteredCat?.length}
                onPrev={paginationPrev}
                onNext={paginationNext}
              />
            )}
          </div>
        </div>

        <SidePopup
          header={
            editCategory !== null
              ? `${t("UpDate", lan)} ${t("SubCategory", lan)}`
              : `${t("Add", lan)} ${t("SubCategory", lan)}`
          }
          show={addSubCategoryPopup}
          handleClose={handleClosePopup}
        >
          <div className="sidepopupContents">
            {/* <label>
            <span className="customeFileUpload">
              <img
                src={
                  editCategory !== null && editCategory !== undefined
                    ? `${API.backendApi}${editCategory.subcategory_image}`
                    : subCategoryImage !== null &&
                      subCategoryImage !== undefined
                      ? subCategoryImage.name
                      : UploadImage
                }
                alt="Upload Image"
                className="uploadImage"
                id="output"
              />
              <span className="uploadImageText">
                {subCategoryImage !== null && subCategoryImage !== undefined ? (
                  <p className="smallText">{subCategoryImage.name}</p>
                ) : editCategory !== null && editCategory !== undefined ? (
                  <p className="smallText imageMaxText">
                    {editCategory?.subcategory_image?.split("/").slice(-1)}
                  </p>
                ) : (
                  <>
                    <p className="smallText">
                      {" "}
                      {t("Upload", lan)} {t("Image", lan)}{" "}
                    </p>
                    <p className="superSmallText">
                      Note:- If supplier don’t upload any course image than this
                      will <br />
                      be default course image
                    </p>
                    {imageError && (
                      <p className="errorText mb-0">{imageError}</p>
                    )}
                  </>
                )}
              </span>
              <input
                type="file"
                accept=".jpeg,image/*,.png"
                className="inputTypeFile"
                multiple={false}
                onChange={(e) => {
                  setSubCategoryImage(e.target.files[0]);
                  setImageError("");
                }}
              />
            </span>
          </label> */}

            <label htmlFor="chooseCourseCategory ">
              <p className="smallText inputText boldText pWithMargin">
                {t("ChooseCourseCategory", lan)}
              </p>
            </label>

            <select
              className="formInputs"
              id="chooseCourseCategory"
              onChange={() => setCategoryError("")}
            >
              <option disabled selected={editCategory !== null ? false : true}>
                {t("ChooseCourseCategory", lan)}
              </option>
              {allCategories?.map((res) => {
                return (
                  <option
                    selected={
                      editCategory !== null
                        ? editCategory?.category_name?.category_name ===
                          res.category_name
                          ? true
                          : false
                        : false
                    }
                    value={res.category_name}
                  >
                    {t(res.category_name, lan)}
                  </option>
                );
              })}
            </select>
            {categoryError && <p className="errorText mb-0">{categoryError}</p>}

            {/* <input type="text"  placeholder="Choose Course Length" /> */}
            <label htmlFor="subCategory">
              <p className="smallText inputText boldText pWithMargin">
                {t("SubCategory", lan).charAt(0).toUpperCase() +
                  t("SubCategory", lan).slice(1)}
              </p>
            </label>
            <input
              value={subCategoryName}
              type="text"
              id="subCategoryName"
              placeholder={t("subPlaceHolder", lan)}
              className="formInputs"
              onChange={(e) => {
                setSubCategoryName(e.target.value);
                setSubCategoryError("");
              }}
            />
            {subCategoryError && (
              <p className="errorText mb-0">{subCategoryError}</p>
            )}

            <button
              className="primaryButton"
              disabled={loderBtn}
              onClick={() => {
                editCategory !== null ? editCategoryCall() : addSubCategory();
              }}
            >
              {loderBtn ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : editCategory !== null ? (
                `${t("UpDate", lan)} ${t("SubCategory", lan).toLowerCase()}`
              ) : (
                `${t("Add", lan)} ${t("SubCategory", lan).toLowerCase()}`
              )}
            </button>
            {subCategoryNameError && (
              <p className="errorText mb-0">{subCategoryNameError}</p>
            )}
          </div>
        </SidePopup>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>

        <Popup
          show={popupDelete !== "" ? true : false}
          header={t("DeletePopUp", lan)}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">{/* <p>{popupStatus}</p> */}</div>
          <div className="row justify-content-around w-100">
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase  mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("CancleText", lan)}
            </button>

            <button
              onClick={deleteCategoryCall}
              className="btn btn-danger text-uppercase  mt-2 col-md-5 col-sm-5 col-xs-12 "
            >
              {t("DeleteText", lan)}
            </button>
          </div>
        </Popup>
        {isLoader ? <Loader /> : ""}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default SubCategory;
