import React, { useEffect, useState } from "react";
import "./CorporateCategory.scss";
import "../../../../common/styles/globalStyles.scss";
import SidePopup from "../../../../components/sidePopup/sidePopup";

import {
  addCorporateCategoryApi,
  deleteCorporateCategoryApi,
  editCorporateCategoryApi,
  getCorporateCategoryApi,
  statusChangeCorporateCategoryApi,
} from "../../../../services/supplierService";
import { useDispatch, useSelector } from "react-redux";

import Popup from "../../../../components/popup/popup";
import { getTranslatedText as t } from "../../../../translater/index";
import NoData from "../../../../components/NoData/NoData";
import InputText from "../../../../components/inputText/inputText";
import IcSearch from "../../../../assets/images/icSearch.svg";
import Loader from "../../../../components/Loader/Loader";
import {
  checkTrimOrUndefined,
  formatDate,
} from "../../../../services/constant";

function CorporateCategory() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;

  const [allCategories, setAllCategories] = useState([]);
  const [editCategory, setEditCategory] = useState(null);
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [filteredCat, setFilteredCat] = useState([]);
  const role = state?.UserDetail?.user_type;
  const [popupDelete, setPopupDelete] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [addCategoryPopup, setAddCategoryPopup] = useState({
    value: false,
    category_en: "",
    category_sw: "",
    category_dec_sw: "",
    category_dec_en: "",
  });
  const [error, setError] = useState({
    category_en: "",
    category_sw: "",
    category_dec_sw: "",
    category_dec_en: "",
  });

  const categoryCall = () => {
    setIsLoader(true);
    getCorporateCategoryApi()
      .then((res) => {
        if (res.status == "success") {
          setAllCategories(res?.data);
          setFilteredCat(res?.data);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoader(false));
  };

  useEffect(() => {
    categoryCall();
  }, []);

  const handleChange = (value, type) => {
    setAddCategoryPopup({ ...addCategoryPopup, [type]: value });
    setError({ ...error, [type]: "" });
  };

  const onClickEdit = (cat) => {
    setEditCategory(cat);
    setAddCategoryPopup({
      value: true,
      category_en: cat?.category_en,
      category_sw: cat?.category_sw,
      category_dec_sw: cat?.category_detail_sw,
      category_dec_en: cat?.category_detail_en,
    });
  };

  const validate = () => {
    if (checkTrimOrUndefined(addCategoryPopup.category_sw)) {
      setError({ ...error, category_sw: "Please enter category name" });
      return false;
    } else if (addCategoryPopup.category_sw?.length > 100) {
      setError({ ...error, category_sw: "only 100 character is allowed" });
      return false;
    } else if (checkTrimOrUndefined(addCategoryPopup.category_en)) {
      setError({ ...error, category_en: "Please enter category name" });
      return false;
    } else if (checkTrimOrUndefined(addCategoryPopup.category_dec_en)) {
      setError({
        ...error,
        category_dec_en: "Please enter category description",
      });
      return false;
    } else if (checkTrimOrUndefined(addCategoryPopup.category_dec_sw)) {
      setError({
        ...error,
        category_dec_sw: "Please enter category description",
      });
      return false;
    } else if (addCategoryPopup.category_dec_en.length > 250) {
      setError({ ...error, category_dec_en: "max 250 character allowed" });
      return false;
    } else if (addCategoryPopup.category_dec_sw.length > 250) {
      setError({ ...error, category_dec_sw: "max 250 character allowed" });
      return false;
    } else {
      return true;
    }
  };

  const handleAddUpdateCategory = () => {
    if (validate()) {
      let formData = new FormData();
      formData.append("category_en", addCategoryPopup.category_en);
      formData.append("category_sw", addCategoryPopup.category_sw);
      formData.append("category_detail_en", addCategoryPopup.category_dec_en);
      formData.append("category_detail_sw", addCategoryPopup.category_dec_sw);
      setLoaderBtn(true);
      if (editCategory) {
        editCorporateCategoryApi(formData, editCategory.uuid)
          .then((res) => {
            if (res.status == "success") {
              setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
              let newCategoryArr = allCategories.map((cat) => {
                if (cat.uuid === editCategory.uuid) {
                  cat.category_en = addCategoryPopup.category_en;
                  cat.category_sw = addCategoryPopup.category_sw;
                  cat.category_detail_en = addCategoryPopup.category_dec_en;
                  cat.category_detail_sw = addCategoryPopup.category_dec_sw;
                }
                return cat;
              });
              handleClosePopup();
              setAllCategories(newCategoryArr);
              setFilteredCat(newCategoryArr);
              setPopupStatus(t("success", lan));
            } else {
              setPopupStatus("Failed!");
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setLoaderBtn(false));
      } else {
        addCorporateCategoryApi(formData)
          .then((res) => {
            if (res.status == "success") {
              //   subCategoryCall();
              categoryCall();
              handleClosePopup();
              setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
              setPopupStatus(t("success", lan));
            } else {
              setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
              setPopupStatus("Failed!");
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setLoaderBtn(false));
      }
    }
  };

  const deleteCategoryCall = () => {
    const uuid = popupDelete;
    deleteCorporateCategoryApi(uuid)
      .then((res) => {
        if (res.status == "success") {
          let newCategoryArr = allCategories.filter((ff) => ff.uuid !== uuid);
          setAllCategories(newCategoryArr);
          setFilteredCat(newCategoryArr);
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          setPopupStatus(t("success", lan));
        } else {
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          setPopupStatus("Failed!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onApproveReject = (e, id, type) => {
    let formData = new FormData();
    formData.append("status", e?.target?.value);
    setIsLoader(true);
    statusChangeCorporateCategoryApi(formData, id)
      .then((result) => {
        if (result?.status == "success") {
          setPopupStatus(t("success", lan));
          setPopupMessage(lan == "en" ? result?.data : result?.data_sv);
          let newCategoryArr = allCategories.map((cat) => {
            if (cat.uuid === id) {
              cat.is_active = e.target.value == "Active" ? true : false;
            }
            return cat;
          });
          setAllCategories(newCategoryArr);
          setFilteredCat(newCategoryArr);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoader(false));
  };

  const handleClosePopup = () => {
    setAddCategoryPopup({
      value: false,
      category_en: "",
      category_sw: "",
      category_dec_sw: "",
      category_dec_en: "",
    });
    setError({
      category_en: "",
      category_sw: "",
      category_dec_sw: "",
      category_dec_en: "",
    });
    setEditCategory(null);
  };
  const handleClosePopupStatus = () => {
    setPopupStatus("");
    setPopupDelete("");
    // handleClosePopup()
  };

  const handleSearchData = (e) => {
    let searchData = [];
    if (e.target.value != "") {
      allCategories?.map((cat) => {
        if (
          cat[`category_${lan}`]
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(cat);
        }
      });
    } else {
      searchData = allCategories;
    }

    setFilteredCat(searchData);
  };

  return (
    <div className="category">
      <div className="categoryHeader my-4 pb-3 pt-3">
        <p className="midText mb-0">
          {" "}
          {t("Dashboard", lan)} |{" "}
          <span className="textPrimaryColor">{t("MngCat", lan)}</span>
        </p>
        <div className="fliter-block">
          <div className="pageSearch">
            <span className="iconSearch">
              <img src={IcSearch} />
            </span>
            <InputText
              placeholder={t("SearchSByName", lan)}
              onChange={handleSearchData}
            />
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <button
              className="primaryButton mt-0"
              onClick={() => {
                handleChange(true, "value");
              }}
            >
              {t("AddCat", lan)}
            </button>
          </div>
        </div>
      </div>
      <div className="categoryListDiv">
        <table className="categoryListTable">
          <tr className="whitespaceNoWrap">
            <th>{t("CatName", lan)}</th>
            <th>{t("DateCreated", lan)}</th>
            <th>{t("Status", lan)}</th>
            <th>{t("Action", lan)}</th>
          </tr>

          {filteredCat?.length > 0 &&
            filteredCat?.map((cat, index) => {
              return (
                <tr key={index} className="categoryTableInfo">
                  <td>{cat[`category_${lan}`]}</td>
                  <td>{formatDate(cat.created_date_time)}</td>
                  <td>
                    {
                      <select
                        defaultValue={cat?.is_active ? "Active" : "Inactive"}
                        onChange={(e) =>
                          onApproveReject(e, cat?.uuid, "Activation")
                        }
                        className={
                          cat?.is_active ? "StatusActive" : "StatusInActive"
                        }
                      >
                        <option value={"Active"}>{t("Active", lan)}</option>
                        <option value={"Inactive"}>{t("inActive", lan)}</option>
                      </select>
                    }
                  </td>
                  <td className="whitespaceNoWrap">
                    <span
                      className="cursorPointer textPrimaryColor"
                      onClick={() => {
                        onClickEdit(cat);
                      }}
                    >
                      {t("Edit", lan)}
                    </span>{" "}
                    |{" "}
                    <span
                      className="cursorPointer textPrimaryColor"
                      onClick={() => {
                        setPopupDelete(cat.uuid);
                      }}
                    >
                      {t("Delete", lan)}
                    </span>
                  </td>
                </tr>
              );
            })}
        </table>
        {filteredCat?.length <= 0 && (
          <div>
            <NoData />
          </div>
        )}
      </div>

      <SidePopup
        header={editCategory !== null ? t("UpdateCat", lan) : t("AddCat", lan)}
        show={addCategoryPopup.value}
        handleClose={handleClosePopup}
      >
        <div className="sidepopupContents">
          <label htmlFor="categoryName">
            <p className="smallText inputText boldText pWithMargin">
              {t("CateInSwe", lan)}
            </p>
          </label>
          <input
            value={addCategoryPopup.category_sw}
            onChange={(e) => {
              handleChange(e.target.value, "category_sw");
            }}
            type="text"
            id="categoryName"
            placeholder={t("EnterCateInSWe", lan)}
            className="formInputs"
          />
          {error.category_sw && (
            <p className="errorText mb-0">{error.category_sw}</p>
          )}

          <label htmlFor="categoryNameEng">
            <p className="smallText inputText boldText pWithMargin">
              {t("CatInEng", lan)}
            </p>
          </label>
          <input
            type="text"
            id="categoryNameEng"
            placeholder={t("EnterCatName", lan)}
            className="formInputs"
            value={addCategoryPopup.category_en}
            onChange={(e) => {
              handleChange(e.target.value, "category_en");
            }}
          />
          {error.category_en && (
            <p className="errorText mb-0">{error.category_en}</p>
          )}

          <label htmlFor="categoryover">
            <p className="smallText inputText boldText pWithMargin">
              {t("CatDescSwe", lan)}
            </p>
          </label>
          <textarea
            required
            rows={3}
            id="categoryover"
            placeholder={t("EnterCatDesSwe", lan)}
            className="formInputs textArea"
            value={addCategoryPopup.category_dec_sw}
            onChange={(e) => {
              handleChange(e.target.value, "category_dec_sw");
            }}
          />
          {error.category_dec_sw && (
            <p className="errorText mb-0">{error.category_dec_sw}</p>
          )}

          <label htmlFor="categoryover">
            <p className="smallText inputText boldText pWithMargin">
              {t("CatDesEng", lan)}
            </p>
          </label>
          <textarea
            required
            rows={3}
            id="categoryover"
            placeholder={t("EnterCatDesEng", lan)}
            className="formInputs textArea"
            value={addCategoryPopup.category_dec_en}
            onChange={(e) => {
              handleChange(e.target.value, "category_dec_en");
            }}
          />
          {error.category_dec_en && (
            <p className="errorText mb-0">{error.category_dec_en}</p>
          )}
          <button
            className="primaryButton"
            disabled={loaderBtn}
            onClick={handleAddUpdateCategory}
          >
            {loaderBtn ? (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : editCategory !== null ? (
              t("UpdateCat", lan)
            ) : (
              t("AddCat", lan)
            )}
          </button>
        </div>
      </SidePopup>

      <Popup
        show={popupDelete !== "" ? true : false}
        header={t("DeleteCat", lan)}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{t("DeletePopUp", lan)}</p>
        </div>
        <div className="row justify-content-around w-100">
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase  mt-2  col-md-6 col-sm-6 col-xs-12"
          >
            {t("CancleText", lan)}
          </button>

          <button
            onClick={() => deleteCategoryCall()}
            className="btn btn-danger text-uppercase  mt-2 col-md-5 col-sm-5 col-xs-12 "
          >
            {t("DeleteText", lan)}
          </button>
        </div>
      </Popup>

      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupMessage}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>
      {isLoader ? <Loader /> : ""}
    </div>
  );
}

export default CorporateCategory;
