import React, { useEffect, useState } from "react";
// import "./userDetails.scss";
import "./CorporateUserDetail.scss";
import { useHistory, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import placeholder from "../../../../../assets/images/placeholder.svg";
import IcDownload from "../../../../../assets/images/IcDownload.svg";
import { getTranslatedText as t } from "../../../../../translater/index";
import { FaBook, FaUsers, FaLevelUpAlt } from "react-icons/fa";
import API from "../../../../../api";
import { Link } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import NoData from "../../../../../components/NoData/NoData";
import Footer from "../../../../../components/footer/Footer";
import { getCorporateUserById } from "../../../../../services/supplierService";
import { formatDate } from "../../../../../services/constant";

function CorporateUserDetail() {
  const params = useParams();
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();

  const usertype = state.UserDetail.user_type;
  let lan = state?.language;
  const [list, setList] = useState([]);
  const [basicInfo, setBasicInfo] = useState({});
  const [allInfo, setAllInfo] = useState({});
  const [numCourses, setNumCourses] = useState([]);
  const [userType, setUserType] = useState("");
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [learners, setLearners] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [coursesOffered, setCourseOffered] = useState([]);
  const userDetailApiCall = () => {
    setIsLoader(true);
    getCorporateUserById(params?.id).then((res) => {
      setIsLoader(false);
      if (res?.status === "success") {
        setAllInfo(res?.data);
        setList(res.course_list)
      }
    }).catch((e)=>console.log(e))
    .finally((e)=>setIsLoader(false))
    
  };

  useEffect(() => {
    userDetailApiCall();
  }, [params.id]);

  return (
    <div className="userDetail">
      <div className="userDetailHeader">
        <p className="midText">
          {" "}
          <span
            className="cursor-pointer"
            onClick={() => history.push("/supplier/dashboard")}
          >
            {t("Dashboard", lan)} |{" "}
          </span>
          <span
            className="cursor-pointer"
            onClick={() => history.push("/supplier/manage-corporate-users")}
          >{`${t("ManageUsers", lan)} |`}</span>
          <span className="textPrimaryColor">
            {" "}
            {` ${t("User", lan)} ${t("Detail", lan)}`}
          </span>
        </p>
        <div className="userDetailHeaderRight">
          <span
            className="headerTab smallText cursorPointer"
            onClick={() => {
              window.history.back();
            }}
          >
            <IoIosArrowBack /> &nbsp;{t("Back", lan)}
          </span>
        </div>
      </div>
      <div className="userDetailsMain">
        <div className="userDetailsMainDetailView">
          <div className="userDetailsMainDetailViewLeft">
            <span className="summaryDetail">
              <div className="imgBlock">
                <img
                  src={
                   allInfo?.profile_image
                      ? `${allInfo?.profile_image}`
                      : placeholder
                  }
                  className="userDetailImage"
                />
              </div>
              <span className="ms-lg-2">
                {/* <p className="midText boldText mb-2">
                  {selectedAdsDetails?.data?.course_name || "-"}{" "}
                  <FaStar className="starIcon" />{" "}
                  <span className="superSmallText"> 4.5 </span>
                </p> */}
                <p className="midText">
                  {`${allInfo?.first_name ?? ""} ${allInfo?.last_name ?? ""}` ||
                    "-"}
                </p>
                <p className="superSmallText">
                  {t("UserType", lan)} :{" "}
                  <span className="textPrimaryColor">
                    {allInfo?.usersignup?.user_type?.user_type || "-"}
                  </span>
                </p>

                <p className="superSmallText">
                  {` ${t("DateCreated", lan)}  ${t("On", lan)}`} :{" "}
                  <span className="textPrimaryColor">
                    {formatDate(allInfo?.created_date_time)}
                  </span>
                </p>

                <p className="pWithMargin f-18  whitespaceNoWrap">
                  {"Status"} :
                  <span
                    className={
                      allInfo.status?.value == "Active"
                        ? "StatusActive ms-4"
                        : "StatusInActive ms-4"
                    }
                    style={{ backgroundImage: "unset" }}
                  >
                    {allInfo?.status?.value}
                  </span>
                  
                </p>
              </span>
            </span>
            {
              <>
                {" "}
                <p className="boldText pWithMargin p-title">
                  {t("UserInfo",lan)}
                </p>
                <div className="personalInfo justify-content-start mb-5">
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {t("Gender", lan)}
                    </p>
                    <p className="superSmallText">{allInfo?.gender}</p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {t("DateofBirth", lan)}
                    </p>
                    <p className="superSmallText">
                      {formatDate(allInfo?.dob)}
                    </p>
                  </span>
                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("phonnumber", lan)} `}
                    </p>
                    <p className="superSmallText">
                      {allInfo?.phone_number || "-"}
                    </p>
                  </span>

                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("EmailAddress", lan)}`}
                    </p>
                    <p className="superSmallText">{allInfo?.email_id}</p>
                  </span>

                  <span className="singleInfothree">
                    <p className="smallText boldText mb-2">
                      {" "}
                      {` ${t("CorporateCode", lan)}`}
                    </p>
                    <p className="superSmallText">{allInfo?.corporate_code || '-'}</p>
                  </span>
                </div>
              </>
            }
          </div>

          <div className="userDetailsMainDetailViewRight">
            <span className="userDetailRightContent">
              {<FaBook className="textPrimaryColor midText" />}
              &nbsp; &nbsp;
              {learners || 0} {t("Learners",lan)}
            </span>

            {/* <span className='userDetailRightContent'><FaClock className='textPrimaryColor midText'/> &nbsp; &nbsp;3-4 Weeks</span>
                    <span className='userDetailRightContent'><FaBookmark className='textPrimaryColor midText'/> &nbsp; &nbsp;$80</span> */}
          </div>
        </div>
        <div className="userDetailsMainListView">
          <p>
            {` ${t("Course", lan)} `}
          </p>
          <div className="userDetailsMainListTable">
            <table className="courseListTable">
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>
                          <img
                            src={
                              item?.course_image|| placeholder
                            }
                            alt="Avtar"
                            className="courseAvtars"
                          />
                        </td>

                        <td>
                          {item?.course_name ||''}
                          <br />{" "}
                          <span className="superSmallText textGrayColor">
                            { item?.course_category ||'-'}
                          </span>
                        </td>
                        <td>
                         {item?.supplier_name ||'-'}
                          <br />{" "}
                          <span className="superSmallText textGrayColor">
                           {item?.supplier_email ||'-'}
                          </span>
                        </td>
                        <td>
                          <span style={{color:item?.course_status =="Completed" ? "#3E8181" :item?.course_status == "Ongoing" ? "#DBBA0D" : "#5C59E7" }}>
                            {t( item?.course_status,lan) ||t("Pending",lan)}
                          </span>
                          <br />{" "}
                          <span className="superSmallText textPrimaryColor">
                            <Link
                              to={`/supplier/corporate/course/details/${item?.course_uuid}`}
                              className="cursorPointer textPrimaryColor linkDecoreRemove"
                            >
                              {t("VIEW", lan)}
                            </Link>
                          </span>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <NoData />
              )}
            </table>
          </div>
        </div>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CorporateUserDetail;
