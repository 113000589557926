import React, { Fragment, useEffect, useState } from 'react'
import "./redirect.scss"
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Loader from '../../../components/Loader/Loader'
import { verifyStripeAccApi } from '../../../services/authService'
import { toast } from "react-toastify";
import { getTranslatedText as t } from "../../../translater/index";
import { useSelector } from 'react-redux'

const Redirect = () => {
  const history = useHistory()
  const location = useLocation().search
  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;
  const [isLoader,setIsLoader] = useState(true)
    const [text,setText]= useState("We Are Verifying You...")
    const name = new URLSearchParams(location).get('code');
    const uuid = new URLSearchParams(location).get('state');
    const getVarificationData = ()=>{
      let formData =new FormData()
      formData.append('auth_code',name)
      formData.append('supplier_id ',uuid)
      verifyStripeAccApi(formData).then((result)=>{
            if(result?.status == 'success'){
                setText('Verification Successfully! We Are Redirecting You To Login Page...')
                setTimeout(() => {
                  history.push('/login')
                }, 3000);
            }else{
              toast.error(lan=='en' ? result?.data : result?.data_svs)
              setText('Verification failed...')
              setTimeout(() => {
                history.goBack()
              }, 3000);
            }
        }).catch((e)=>console.log(e))
    }

    useEffect(()=>{
        getVarificationData()
    },[])

  return (
    <div className='container verifyText'>

    <h2 className='text-center '>{text}</h2>

    {isLoader ? <Loader/> : ""}
    </div>
  )
}


export default Redirect