import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import "../ProfileMain.scss";
// import CountryCodeSelect from "../../../components/CountryCodeSelect/CountryCodeSelect";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import {
  getPersonalProfileData,
  organizationalInfoData,
  tabPageNo,
} from "../../../redux/actions";
import PhoneInput from "react-phone-number-input";
import InputText from "../../../components/inputText/inputText";
import FilterSelectMenu from "../../../components/FilterSelectMenu/FilterSelectMenu";
import CountryCodeSelect from "../../../components/CountryCodeSelect/CountryCodeSelect";
import { getTranslatedText as t } from "../../../translater/index";
import { useHistory } from "react-router-dom";
import { getPersonalProfileDetail } from "../../../services/supplierService";

export const OrgInfo = forwardRef((props, ref) => {
  const state = useSelector((state) => state?.Eddi);

  const statedata = useSelector((state) => state?.Eddi.organizationalData);
  let lan = state?.language;
  const dispatch = useDispatch();
  const history = useHistory();
  const countryRef = useRef();
  const isCorporate = state?.UserDetail?.is_corporate
  const [orgNameError, setorgNameError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [corporateCodeError, setCorporateCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [countryError, setcountryError] = useState("");
  const [cityError, setcityError] = useState("");
  const [webErr, setWebErr] = useState("");
  const [stepClick, setStepCkick] = useState();
  const [ProfileData, setProfileData] = useState({});
  const [phValue, setPhValue] = useState();
  const [countryList, setCountryList] = useState();
  // const [personalNoError, setPersonalNoError] = useState("");
  const [mobileNoError, setMobileNoError] = useState(null);
  useImperativeHandle(ref, () => ({
    async childFunction1() {
      let result = await checkValid();
      return result;
    },
  }));

  const personalProfileApi = () => {
    getPersonalProfileDetail()
      .then((result) => {
        if (result?.status == "success") {
          dispatch(getPersonalProfileData(result?.data));
        }
      })
      .catch((e) => console.log(e));
  };

  const checkValid = async (data) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const websiteRegex =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    let orgName = ProfileData?.orgNameVal;
    let orgAddress = ProfileData?.orgAddVal;
    let corporateCodeVal = ProfileData?.corporateCodeVal;
    let contactpersonVal = ProfileData.contactpersonVal;
    let briefVal = ProfileData.briefVal;
    let email = ProfileData.emailVal;
    let country = countryRef?.current?.value;
    let phone = phValue;
    let websiteVal = ProfileData?.websiteVal;
    let city = ProfileData.cityVal;

    if (orgName?.trim() === "" || !orgName) {
      setorgNameError("* Please Enter Organizational name ");
      return false;
    } else if (orgAddress?.trim() === "" || !orgAddress) {
      setaddressError("* Please Enter Organization Location / Address ");
      return false;
    } else if (email?.trim() === "" || !email) {
      setEmailError("* Please Enter Email ");
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("* Please Enter Valid Email ");
      return false;
    } else if (country?.trim() === "" || !country) {
      setcountryError("* Please Select Country  ");
      return false;
    } else if (city?.trim() === "" || !city) {
      setcityError("* Please Select City ");
      return false;
    } else if (websiteVal && !websiteRegex.test(websiteVal)) {
      setWebErr("* Invalid Website URL ");
      return false;
    } else {
      const organizationInfoObj = {
        orgName: orgName,
        orgAddress: orgAddress,
        website: websiteVal,
        country: country,
        city: city,
        brief: briefVal,
        email: email,
        phone: phone,
        contactperson: contactpersonVal,
        corporateCode:corporateCodeVal

      };
      dispatch(organizationalInfoData(organizationInfoObj));

      setEmailError();
      setWebErr();
      setaddressError();
      setcountryError();
      setcityError();
      setorgNameError();
      return true;
    }
  };

  useEffect(() => {
    if (state?.UserDetail?.is_first_time_login == true) {
      personalProfileApi();
    }
  }, [state?.UserDetail?.is_first_time_login]);

  useEffect(() => {
    setProfileData({
      orgNameVal: statedata?.orgName,
      corporateCodeVal: statedata?.corporateCode,
      orgAddVal: statedata?.orgAddress,
      websiteVal: statedata?.website,
      countryVal: statedata?.country,
      cityVal: statedata?.city,
      briefVal: statedata?.brief,
      emailVal: statedata?.email,
      contactpersonVal: statedata?.contactperson,
      phoneVal: statedata?.phone,
    });
    setPhValue(statedata?.phone);
  }, [statedata]);

  useEffect(() => {
    const list = [];
    const con = window.ale();
    con?.map((country) => {
      list.push(country.name);
    });
    setCountryList(list);
  }, []);

  const submitInfo = (type) => {
    let orgNameVal = ProfileData.orgNameVal;
   let  corporateCodeVal= ProfileData?.corporateCodeVal;
    let orgAddVal = ProfileData.orgAddVal;
    let websiteVal = ProfileData.websiteVal;
    let countryVal = countryRef?.current?.value;
    let cityVal = ProfileData.cityVal;
    let briefVal = ProfileData.briefVal;
    let emailVal = ProfileData.emailVal;
    let phoneVal = phValue;
    let contactpersonVal = ProfileData.contactpersonVal;

    const organizationInfoObj = {
      orgName: orgNameVal,
      orgAddress: orgAddVal,
      website: websiteVal,
      country: countryVal,
      city: cityVal,
      brief: briefVal,
      email: emailVal,
      phone: phoneVal,
      contactperson: contactpersonVal,
      corporateCode:corporateCodeVal
    };

    dispatch(organizationalInfoData(organizationInfoObj));
    if (type == "call") {
      stepClick == "next"
        ? dispatch(tabPageNo(2))
        : history.push(`/supplier/dashboard`);
    }
  };

  const onNextSkipClick = (e) => {
    // e.preventDefault();
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const websiteRegex =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    let orgName = ProfileData.orgNameVal;
    let orgAddress = ProfileData.orgAddVal;
    let corporateCodeVal = ProfileData?.corporateCodeVal
    let email = ProfileData.emailVal;
    let country = countryRef.current.value;
    // e.preventDefault()
    let websiteVal = ProfileData.websiteVal;
    let city = ProfileData.cityVal;
    if (
      orgName == "" &&
      orgAddress == "" &&
      email == "" &&
      country == "" &&
      city == ""
    ) {
      setorgNameError("* Please Enter  Organizational  name ");
      setaddressError("* Please Enter Organization Location");
      setEmailError("* Please Enter Email ");
      setcountryError("* Please Select Country ");
      setcityError("* Please Enter City");
    }
    if (orgName?.trim() === "" || !orgName) {
      setorgNameError("* Please Enter Organizational name ");
    } else {
      setorgNameError(null);
    }
    if (orgAddress?.trim() === "" || !orgAddress) {
      setaddressError("* Please Enter Organization Location / Address ");
    } else {
      setaddressError(null);
    }

    if ( isCorporate ===true && (corporateCodeVal?.trim() === "" || !corporateCodeVal)) {
      setCorporateCodeError("* Please enter your organisation code");
    } else {
      setCorporateCodeError(null);
    }
    if (email?.trim() === "" || !email) {
      setEmailError("* Please Enter Email ");
    } else if (!emailRegex.test(email)) {
      setEmailError("* Please Enter Valid Email ");
    } else {
      setEmailError(null);
    }
    if (country?.trim() === "" || !country) {
      setcountryError("* Please Select Country  ");
    } else {
      setcountryError(null);
    }
    if (city?.trim() === "" || !city) {
      setcityError("* Please Select City ");
    } else {
      setcityError(null);
    }
    if (websiteVal && !websiteRegex.test(websiteVal)) {
      setWebErr("* Invalid Website URL ");
    } else {
      setWebErr(null);
    }
    // dispatch(tabPageNo(2));
  };
  useEffect(() => {
    if (
      orgNameError === null &&
      addressError === null &&
      emailError === null &&
      countryError === null &&
      webErr === null &&
      corporateCodeError === null &&
      cityError === null
    ) {
      submitInfo("call");
    }
  }, [orgNameError, addressError, emailError, countryError, webErr, cityError,corporateCodeError]);

  const handleChange = (e) => {
    setProfileData({ ...ProfileData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="all-tab mt-5">
        <div className="row m-0">
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">
              {t("OrganizationalName", lan)}
              <span className="text-danger">*</span>
            </p>
            <div className="mb-4">
              {/* <input
                type="text"
                className="form-control input-profile px-2 py-3"
                placeholder="Enter Organization Name"
                inputref={addressRef}
              /> */}
              <InputText
                placeholder={t("OrganizationalName", lan)}
                name="orgNameVal"
                value={ProfileData.orgNameVal}
                onChange={(e) => handleChange(e)}
              />
              {orgNameError && <p className="errorText mb-0">{orgNameError}</p>}
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">
              {t("OrganizationEmail", lan)}
              <span className="text-danger">*</span>
            </p>
            <div className="mb-4">
              <InputText
                placeholder={t("EnterOrgEmail",lan)}
                value={ProfileData.emailVal}
                name="emailVal"
                onChange={(e) => handleChange(e)}
              />
              {emailError && <p className="errorText mb-0">{emailError}</p>}
            </div>
          </div>

         { !isCorporate ? <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">{t("OrganizationWebsite", lan)}</p>
            <div className="mb-4">
              <InputText
                placeholder={t("OrganizationWebsite", lan)}
                value={ProfileData.websiteVal}
                name="websiteVal"
                onChange={(e) => handleChange(e)}
              />
              {webErr && <p className="errorText mb-0">{webErr}</p>}
            </div>
          </div>
:
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">{t("CorporateCode", lan)}
             <span className="text-danger">*</span>
            </p>
            <div className="mb-4">
              <InputText
                placeholder={t("EnterCorporateCode", lan)}
                value={ProfileData.corporateCodeVal}
                name="corporateCodeVal"
                onChange={(e) => handleChange(e)}
              />
              {corporateCodeError && <p className="errorText mb-0">{corporateCodeError}</p>}
            </div>
          </div>}

          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">
              {t("OrganizationLocation_Address", lan)}
              <span className="text-danger">*</span>
            </p>
            <div className="mb-4">
              <InputText
                placeholder={t("OrganizationLocation_Address", lan)}
                value={ProfileData.orgAddVal}
                name="orgAddVal"
                onChange={(e) => handleChange(e)}
              />
              {addressError && <p className="errorText mb-0">{addressError}</p>}
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">
              {t("Country", lan)}
              <span className="text-danger">*</span>
            </p>

            <div className="mb-4 ">
              <FilterSelectMenu
                value={countryList}
                selected={ProfileData.countryVal}
                placeholder={t("Country", "en")}
                isWithicon
                inputref={countryRef}
                name="countryVal"
              />
              {countryError && <p className="errorText mb-0">{countryError}</p>}
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">
              {t("City", lan)}
              <span className="text-danger">*</span>
            </p>
            <div className="mb-4">
              <InputText
                placeholder={t("City",lan)}
                value={ProfileData.cityVal}
                onChange={(e) => handleChange(e)}
                name="cityVal"
              />
              {cityError && <p className="errorText mb-0">{cityError}</p>}
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">{t("BreifInformation", lan)}</p>
            <div className="mb-4">
              <InputText
                placeholder={t("EnterOrgInformation",lan)}
                value={ProfileData.briefVal}
                onChange={(e) => handleChange(e)}
                name="briefVal"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">{t("OrganizationPhoneNumber", lan)}</p>
            <div className="mb-4">
         
              <div className="selectbox-main">
                <PhoneInput
                  placeholder={t("phonnumber", lan)}
                  value={phValue}
                  defaultCountry="SE"
                  onChange={setPhValue}
                  flags={false}
                  name="phoneVal"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6 col-12 ">
            <p className="p-head mb-1">{t("ContactPerson", lan)}</p>
            <div className="mb-4">
              <InputText
                placeholder={t("ContactEddi", lan)}
                value={ProfileData.contactpersonVal}
                onChange={(e) => handleChange(e)}
                name="contactpersonVal"
              />
            </div>
          </div>
        </div>

        <div className="text-end">
          {state?.UserDetail?.is_first_time_login ? (
            ""
          ) : (
            <button
              onClick={(e) => {
                onNextSkipClick(e);
                setStepCkick("skip");
              }}
              id="skip3"
              className="btn btn-skip me-3 mb-3"
            >
              {t("SKIP", lan)}
            </button>
          )}

          <button
            onClick={(e) => {
              onNextSkipClick(e);
              setStepCkick("next");
            }}
            className="btn btn-next me-2 mb-3"
            id="next-1"
          >
            {t("NEXT", lan)}
          </button>
        </div>
      </div>
    </>
  );
});

export default OrgInfo;
