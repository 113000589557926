import React, { useEffect, useState,useRef } from "react";
import "./ModuleList.scss";
import SidePopup from "../../../../components/sidePopup/sidePopup";
import { IoIosArrowBack } from "react-icons/io";
import IcSearch from "../../../../assets/images/icSearch.svg";
import { Link, useHistory,useParams,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiMenu } from "react-icons/fi"
import Popup from "../../../../components/popup/popup";
import { getTranslatedText as t } from "../../../../translater";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import NoData from "../../../../components/NoData/NoData";
import InputText from "../../../../components/inputText/inputText";
import Loader from "../../../../components/Loader/Loader";
import Footer from "../../../../components/footer/Footer";
import { addModule, changeOrderOfCourseModule,  changeStatusModule, deleteModule, editModule,  getModuleListById, postPublishUnpublishContent } from "../../../../services/supplierService";
import { isPublishedCourse } from "../../../../redux/actions";
import { checkTrimOrUndefined } from "../../../../services/constant";
import { toast } from "react-toastify";

const initialModule = {
  uuid: "",
  moduleNameSw: "",
  moduleNameEn: "",
  moduleDescSw: "",
  moduleDescEn: "",
};
function ModuleList() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.Eddi);
  const history = useHistory();
  const params = useParams();
  const search = useLocation().search;
  let lan = state?.language;

  const [moduleData, setModuleData] = useState([]);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const [loaderBtn, setLoderBtn] = useState(false);
  const [activeInactivePopup, setActiveInactivePopup] = useState({
    value: false,
    data: "",
  });
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupDelete, setPopupDelete] = useState("");
  const [popupPublish, setPopupPublish] = useState("");
  const [deleteCourseId, setDeleteCourseId] = useState();
  const [isPublished,setIsPublished] = useState(state.isPublishedCourse)
  const [addModuleModel, setAddModuleModel] = useState({
    value: false,
    data: initialModule,
    type: "add",
  });
  const [filteredModule, setFilteredModule] = useState([]);
  const [error, setError] = useState(initialModule);
  const role = state?.UserDetail?.user_type;
  const [isAdmin, setIsAdmin] = useState(role == "Admin" ? true : false);
  const [searchText, setSearchtext] = useState();



  const moduleCall = () => {
    setIsLoader(true);
    getModuleListById(params?.id)
      .then((res) => {
        if (res.status == "success") {
          setIsLoader(false);
          setModuleData(res?.data);
          setFilteredModule(res?.data);
        } else {
          setIsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      });
  };

  useEffect(async () => {
    await moduleCall();
  }, []);



  //post publish
  const modulePublishCall = () => {
    setIsLoader(true);
    let formdata = new FormData();
    formdata.append("publish_status", !isPublished);
    postPublishUnpublishContent(params?.id, formdata)
      .then((res) => {
        if (res.status == "success") {
          setIsPublished(!isPublished)
          toast.success(lan === 'en' ? res?.data : res?.data_sv)
          dispatch(isPublishedCourse(!isPublished))
          setIsLoader(false);
          handleClosePopupStatus()
        } else {
          setIsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      });
  };

  const onStatusChange = (e, uuid) => {
    setIsLoader(true);
    let formdata = new FormData()
    formdata.append('status_s', e.target.value)
    changeStatusModule(uuid, formdata)
      .then((res) => {
        if (res?.status == "success") {
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          moduleCall();
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      })
      .catch((er) => console.log(er))
      .finally(() => setIsLoader(false));
  }

  const deleteModuleCall = () => {
    setIsLoader(true);
    deleteModule(deleteCourseId)
      .then((res) => {
        if (res?.status == "success") {
          handleClosePopupStatus();
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          moduleCall();
        } else {
          handleClosePopupStatus();
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      })
      .catch((er) => console.log(er))
      .finally(() => setIsLoader(false));
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    setPopupDelete("");
    setPopupPublish('')
    setActiveInactivePopup({ value: false, data: "" });
  };

  const handleClosePopup = () => {
    setAddModuleModel({ value: false, data: initialModule, type: "add" });
  };

  const onEditClick = (data) => {
    setAddModuleModel({
      value: true,
      data: {
        moduleDescEn: data?.module_description_en,
        moduleDescSw: data?.module_description_sw,
        moduleNameEn: data?.module_name_en,
        moduleNameSw: data?.module_name_sw,
        uuid: data?.uuid,
      },
      type: "edit",
    });
  };

  const handleSearchData = (e) => {
    setSearchtext(e?.target?.value);
    let searchData = [];
    if (e?.target?.value != "") {
      moduleData?.map((cour) => {
        if (
          cour[`module_name_${lan}`]
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(cour);
        }
      });
    } else {
      searchData = moduleData;
    }
    setFilteredModule(searchData);
  };

  const handleChange = (value, type) => {
    const newData = { ...addModuleModel.data, [type]: value };
    setAddModuleModel({ ...addModuleModel, data: newData });
    setError({ ...error, [type]: "" });
  };

  const validate = () => {
    if (checkTrimOrUndefined(addModuleModel.data.moduleNameSw)) {
      setError({ ...error, moduleNameSw: "Please enter module name" });
      return false;
    } else if (addModuleModel.data.moduleNameSw.length > 50) {
      setError({ ...error, moduleNameSw: "Module name is too long" });
      return false;
    } else if (checkTrimOrUndefined(addModuleModel.data.moduleNameEn)) {
      setError({ ...error, moduleNameEn: "Please enter module name" });
      return false;
    } else if (addModuleModel.data.moduleNameEn.length > 50) {
      setError({ ...error, moduleNameEn: "Module name is too long" });
      return false;
    } else if (checkTrimOrUndefined(addModuleModel.data.moduleDescSw)) {
      setError({ ...error, moduleDescSw: "Please enter module description" });
      return false;
    } else if (checkTrimOrUndefined(addModuleModel.data.moduleDescEn)) {
      setError({ ...error, moduleDescEn: "Please enter module description" });
      return false;
    } else {
      return true;
    }
  };

  const onSubmitModule = async () => {
    if (validate()) {
      let formdata = new FormData();
      formdata.append("module_name_sw", addModuleModel.data.moduleNameSw);
      formdata.append("module_name_en", addModuleModel.data.moduleNameEn);
      formdata.append(
        "module_description_sw",
        addModuleModel.data.moduleDescSw
      );
      formdata.append(
        "module_description_en",
        addModuleModel.data.moduleDescEn
      );
      formdata.append("course_uuid", params?.id);
      formdata.append("module_number", moduleData?.length + 1);
      try {
        let res;
        setLoderBtn(true);

        if (addModuleModel.type == "add") {
          res = await addModule(formdata);
        } else {
          res = await editModule(addModuleModel.data.uuid, formdata);
        }
        setLoderBtn(false);

        if (res?.status == "success") {
          handleClosePopup();
          setPopupStatus(t("Success", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          moduleCall();
        } else {
          setPopupStatus(t("Failed", lan) + "!");
          setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
        }
      } catch (err) {
        console.log(err);
        setLoderBtn(false);
      }
    }
  };

  const handleSortVideos = async () => {
    let duplicateArr = [...filteredModule];

    //remove and save dragged item content

    const draggedStartItem = duplicateArr.splice(dragItem.current, 1)[0];
    //switch the position
    duplicateArr.splice(dragOverItem.current, 0, draggedStartItem);

    //clear the refs
    dragItem.current = null;
    dragOverItem.current = null;



    const bodyData = duplicateArr.map((m, i) => { return { model_number: i + 1, uuid: m.uuid } })
    await changeOrderOfCourseModule(bodyData)
    duplicateArr = duplicateArr.map((cc, i) => {
      cc.module_number = i + 1
      return cc;
    })
    setFilteredModule(duplicateArr);
  };

  return (
    <div className="course">
      <div className="fix_height">
      <div className="text-end">
              <span
                className="headerTab smallText cursorPointer"
                onClick={() => {
                  window.history.back();
                }}
              >
                <IoIosArrowBack /> &nbsp;{t("Back", lan)}
              </span>
            </div>
        <div className="courseHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">{t("ManangeModule", lan)}</span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchbyName", lan)}
                onChange={handleSearchData}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between">


              {/* <span className="headerTab smallText">
            <FaFilter className="textPrimaryColor" /> &nbsp;Filter
          </span> */}
              {/* <Link
                onClick={modulePublishCall}
                className="primaryButton mx-2 mt-0"
              >
                {t("PublishContent", lan)}
              </Link> */}

              <button
                onClick={()=>setPopupPublish(isPublished ? t("ReadyToUnPublish", lan) : t("ReadyToPublish", lan))}
                className="primaryButton mx-2 mt-0"
              >
                {isPublished ? t("UnpublishContent", lan) : t("PublishContent", lan)}
              </button>
              <button
                onClick={() =>
                  setAddModuleModel({
                    value: true,
                    data: initialModule,
                    type: "add",
                  })
                }
                className="primaryButton mt-0"
              >
                {t("AddModule", lan)}
              </button>
            </div>
          </div>
        </div>
       
        <div className="courseListDiv">
          <table
            className={`courseListTable table-module-${isAdmin ? "admin" : "supplier"
              }`}
          >
            {
              <tr className="whitespaceNoWrap">
                {<th>{`${t("ModuleNo", lan)}`}</th>}

                <th>{`${t("ModuleName", lan)}`}</th>

                <th>{t("Status", lan)}</th>
                <th>{t("Action", lan)}</th>
              </tr>
            }
            {
              filteredModule?.map((cou, index) => {
                return (
                  <tr draggable
                    onDragEnter={() => (dragItem.current = index)}
                    onDragStart={() => (dragOverItem.current = index)}
                    onDragEnd={handleSortVideos}
                    onDragOver={(e) => e.preventDefault()}
                    key={index}

                    className="courseTableInfo">
                    <td>{cou?.module_number}</td>
                    <td className="text-capital">
                      {cou[`module_name_${lan}`]}
                    </td>

                    <td>
                      <select
                        value={cou?.status?.value}
                        onChange={(e) => onStatusChange(e, cou.uuid)}
                        className={
                          cou?.status?.value == "Active"
                            ? "StatusActive"
                            : "StatusInActive"
                        }
                      >
                        <option value={"Active"}>{t("Active", lan)}</option>
                        <option value={"Inactive"}>{t("inActive", lan)}</option>
                      </select>
                    </td>
                    <td className="whitespaceNoWrap">
                      <Link
                        to={`/supplier/course/manage-material/${params.id}/${cou.uuid}`}
                        className="linkDecoreRemove textPrimaryColor"
                      >
                        {t("ManageMaterial", lan)}{" "}
                      </Link>{" "}
                      |{" "}
                      <Link
                        className="cursorPointer linkDecoreRemove textPrimaryColor"
                        onClick={() => onEditClick(cou)}
                      >
                        {t("Edit", lan)}
                      </Link>{" "}
                      |{" "}
                      <span
                        className="cursorPointer textPrimaryColor"
                        // onClick={()=>{deleteModuleCall(cou.uuid)}}
                        onClick={() => {
                          setPopupDelete("delete");
                          setDeleteCourseId(cou?.uuid);
                        }}
                      >
                        {t("Delete", lan)}
                      </span>
                    </td>
                    <td >


                      <FiMenu style={{ cursor: 'grab', fontSize:"20px" }} />

                    </td>
                  </tr>
                );
              })}
          </table>
          {filteredModule?.length <= 0 && (
            <div>
              <NoData />
            </div>
          )}
        </div>


        <SidePopup
          header={
            addModuleModel.type === "add"
              ? t("AddModule", lan)
              : t("UpdateModule", lan)
          }
          show={addModuleModel.value}
          handleClose={handleClosePopup}
        >
          <div className="sidepopupContents">
            <div className="my-3">
              <label htmlFor="categoryNameSwe">
                <p className="smallText inputText boldText pWithMargin">
                  {t("AddMNameSwe", lan)}
                </p>
              </label>
              <input
                type="text"
                id="categoryNameSwe"
                defaultValue={addModuleModel.data.moduleNameSw}
                onChange={(e) => handleChange(e.target.value, "moduleNameSw")}
                placeholder={t("EnterMNameSwe", lan)}
                className="formInputs"
              />
              {error.moduleNameSw && (
                <p className="errorText mb-0">{error.moduleNameSw}</p>
              )}
            </div>
            <div className="my-3">
              <label htmlFor="categoryNameEng">
                <p className="smallText inputText boldText pWithMargin">
                  {t("AddMNameEng", lan)}
                </p>
              </label>
              <input
                type="text"
                id="categoryNameEng"
                defaultValue={addModuleModel.data.moduleNameEn}
                onChange={(e) => handleChange(e.target.value, "moduleNameEn")}
                placeholder={t("EnterMNameEng", lan)}
                className="formInputs"
              />
              {error.moduleNameEn && (
                <p className="errorText mb-0">{error.moduleNameEn}</p>
              )}
            </div>
            <div className="my-3">
              <label htmlFor="ModuleDescSwe">
                <p className="smallText inputText boldText pWithMargin">
                  {t("AddMDescSwe", lan)}
                </p>
              </label>
              <textarea
                required
                rows={3}
                id="ModuleDescSwe"
                defaultValue={addModuleModel.data.moduleDescSw}
                onChange={(e) => handleChange(e.target.value, "moduleDescSw")}
                placeholder={t("EnterMDesSwe", lan)}
                className="formInputs textArea"
              />
              {error.moduleDescSw && (
                <p className="errorText mb-0">{error.moduleDescSw}</p>
              )}
            </div>
            <div className="my-3">
              <label htmlFor="ModuleDecEng">
                <p className="smallText inputText boldText pWithMargin">
                  {t("AddMDescEng", lan)}
                </p>
              </label>
              <textarea
                required
                rows={3}
                id="ModuleDecEng"
                defaultValue={addModuleModel.data.moduleDescEn}
                onChange={(e) => handleChange(e.target.value, "moduleDescEn")}
                placeholder={t("EnterMDesEng", lan)}
                className="formInputs textArea"
              />
              {error.moduleDescEn && (
                <p className="errorText mb-0">{error.moduleDescEn}</p>
              )}
            </div>

            <button
              className="primaryButton"
              disabled={loaderBtn}
              onClick={() => onSubmitModule(addModuleModel.type)}
            >
              {loaderBtn ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : addModuleModel.type === "add" ? (
                t("AddModule", lan)
              ) : (
                t("UpdateModule", lan)
              )}
            </button>
          </div>
        </SidePopup>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>

        <Popup
          show={popupPublish !== "" ? true : false}
          header={''}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupPublish}</p>
          </div>
          <div>
          {/* modulePublishCall */}
          <div className="d-flex gap-2 justify-content-betweem ">
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase  mt-2  "
            >
              {t("CancleText", lan)}
            </button>

            <button
              onClick={modulePublishCall}
              className="btn btn-green mt-2 "
            >
                {isPublished ? t("UnpublishContent", lan) : t("PublishContent", lan)}
            </button>
          </div>
          </div>
        </Popup>

        <Popup
          show={popupDelete !== "" ? true : false}
          header={t("DeletePopUp", lan)}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">{/* <p>{popupStatus}</p> */}</div>
          <div className="row justify-content-around w-100">
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase  mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("CancleText", lan)}
            </button>

            <button
              onClick={deleteModuleCall}
              className="btn btn-danger text-uppercase  mt-2 col-md-5 col-sm-5 col-xs-12 "
            >
              {t("DeleteText", lan)}
            </button>
          </div>
        </Popup>

        <Popup
          show={activeInactivePopup?.value}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
          className="MB-3"
        >
          <div className="popupinfo">
            <p>{activeInactivePopup?.data}</p>

            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
        {isLoader ? <Loader /> : ""}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ModuleList;
