import "./login.scss";
import "../../../common/styles/globalStyles.scss";
import LOGO from "../../../assets/logo/Logo.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { loginApi } from "../../../services/authService";
import { getTranslatedText as t } from "../../../translater/index";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  additionalInfoData,
  courseCategoryDetails,
  getPersonalProfileData,
  isApproved,
  language,
  logout,
  organizationalInfoData,
  tabPageNo,
  userDetail,
} from "../../../redux/actions";
import {
  getPersonalProfileDetail,
  organizationalProfile,
} from "../../../services/supplierService";
import { fetchToken } from "../../../services/firebaseService";
import { encrypt } from "../../../utils/encrypt";

const Login = () => {
  const history = useHistory();
  const emailref = useRef();
  const passwordref = useRef();
  const ancRef = useRef();
  const state = useSelector((state) => state?.Eddi);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [loderBtn, setLoderBtn] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [loginDissabled, setLoginDissabled] = useState(true);
  const [inputchange, setInputChange] = useState("");
  const [loginError, setLoginError] = useState("");

  const dispatch = useDispatch();
  let lan = state?.language;

  useEffect(() => {
    let emailVal = emailref.current.value;
    let passwordVal = passwordref.current.value;
    let AgreeTnC = ancRef.current.checked;

    if (emailVal.trim() === "") {
      setLoginDissabled(true);
    } else {
      if (!emailRegex.test(emailVal)) {
        setLoginDissabled(true);
      } else if (passwordVal.trim() === "") {
        setLoginDissabled(true);
      } else if (!AgreeTnC) {
        setLoginDissabled(true);
      } else {
        setLoginDissabled(false);
      }
    }
  }, [inputchange]);

  useEffect(() => {
    localStorage.clear();
    dispatch(logout(lan));
    const platform = window.location.href.includes("nu") ? 'PROD':'DEV'
    localStorage.setItem("Platform",platform);

  }, []);

  const personalProfileApi = () => {
    getPersonalProfileDetail()
      .then((result) => {
        if (result?.status === "success") {
          dispatch(getPersonalProfileData(result));
        }
      })
      .catch((e) => console.log(e));
  };

  const loginApiCall = async () => {
    let emailVal = emailref.current.value;
    let passwordVal = passwordref.current.value;
    setLoderBtn(true);
    const deviceToken = await fetchToken();
    console.log("dev", deviceToken);
    var bodyFormData = new FormData();
    bodyFormData.append("email_id", encrypt(emailVal));
    bodyFormData.append("password", encrypt(passwordVal));
    bodyFormData.append("user_type", "Supplier");
    deviceToken && bodyFormData.append("device_token", deviceToken);
    loginApi(bodyFormData)
      .then((res) => {
        setLoderBtn(false);
        if (res.status === "error") {
          setLoginError(lan == "en" ? res?.data : res?.data_sv||res.data);
          localStorage.setItem("logedInUser", false);
          setTimeout(() => {
            setLoginError("");
          }, 3500);
        } else {
          if (res.user_type === "Supplier" || res.user_type == "Admin") {
            setLoginError("");
            localStorage.setItem("logedInUser", true);
            localStorage.setItem("logedInEmail", emailVal);
            localStorage.setItem("Authorization", res.Authorization);

            personalProfileApi();
            dispatch(language(res?.is_swedishdefault ? "sw":"en"))
            dispatch(userDetail(res));
            if (res.user_type === "Admin") {
              if (res?.is_resetpassword === true) {
                history.push("/resetPassword");
              } else {
                history.push("/supplier");
              }
            } else {
              organizationalProfile().then((data) => {
                //for redirecting
                if (res?.is_resetpassword === true) {
                  history.push("/resetPassword");
                } else {
                  if (res?.is_first_time_login === true) {
                    history.push("/supplier/create-profile");
                  } else {
                    if (data?.data?.approved_once === false) {
                      history.push("/supplier/create-profile");
                    } else {
                      history.push("/supplier");
                    }
                  }
                }

                const organizationInfoObj = {
                  orgName: data.data.organizational_name,
                  orgAddress: data.data.organization_address,
                  website: data.data.organization_website,
                  country: data.data.country,
                  city: data.data.city,
                  brief: data.data.brif_information,
                  email: data.data.organization_email,
                  phone: data.data.organization_phone_number,
                  contactperson: data.data.contact_person,
                };

                dispatch(organizationalInfoData(organizationInfoObj));

                const category =
                  data?.data?.course_category === ""
                    ? []
                    : data?.data?.course_category.split(",");
                const optionCats = [];
                category?.map((cat) => {
                  optionCats.push({
                    label: cat,
                    value: cat,
                  });
                });

                const courseObj = {
                  optionVal: optionCats,
                  sub_cat: data.data.sub_category,
                };
                dispatch(courseCategoryDetails(courseObj));
                const orgImage = {
                  orgImage: data.data.organization_logo,
                };

                dispatch(additionalInfoData(orgImage));

                const is_approved = data?.data?.approved_once;

                dispatch(isApproved(is_approved));

                dispatch(tabPageNo(1));
              });
            }
          } else {
            setLoginError("Unauthorised user");
            setTimeout(() => {
              setLoginError("");
            }, 5000);
          }
        }
      })
      .catch((err) => {
        setLoderBtn(false);
        console.log(err.data);
      });
  };

  const showPassword = (show) => {
    show ? setPasswordType("text") : setPasswordType("password");
  };
  // const hidePassword = () =>{
  //     passwordref?.current?.type = "password"
  // }

  return (
    <div className="login">
      <div className="loginCard">
        <img src={LOGO} alt="Eddi Logo" className="eddiLogo" />
        <p className="midText pWithMargin boldText">{t("LoginEddi", lan)}</p>
        <p className="textBlackColor smallText ">{t("EnterEmailBelow", lan)}</p>
        <div className="inputDiv">
          <label htmlFor="email">
            <p className="smallText inputText boldText">{t("EMAIL", lan)}</p>
          </label>
          <input
            type="email"
            id="email"
            placeholder="Email Address"
            className="authInputs"
            ref={emailref}
            onChange={(e) => {
              setInputChange(e.target.value);
            }}
          />
          <label htmlFor="Password">
            <p className="smallText inputText boldText">{t("PASSWORD", lan)}</p>
          </label>
          <div className="passwordDiv mb-3">
            <input
              type={passwordType}
              id="Password"
              placeholder="Password"
              className="authInputs pwd"
              ref={passwordref}
              onChange={(e) => {
                setInputChange(e.target.value);
              }}
            />
            {passwordType == "text" ? (
              <FaEyeSlash
                className="eyeIcon cursorPointer"
                onClick={() => {
                  showPassword(false);
                }}
              />
            ) : (
              <FaEye
                className="eyeIcon cursorPointer"
                onClick={() => {
                  showPassword(true);
                }}
              />
            )}
          </div>
          
        </div>
        <div className={lan == 'en' ?"d-flex align-items-baseline gap-2":'d-flex flex-column align-items-center'}>
        <div className="checkboxsDiv">
            <label className="checkboxLabel align-items-baseline cursor-pointer">
              <input
                type="checkbox"
                className="checkbox"
                id="AgreeTnC"
                ref={ancRef}
                onChange={(e) => {
                  setInputChange(e.target.checked);
                }}
              />
              <span className="checkbox cursor-pointer"></span>
              &nbsp; {t("AgreeTermsUse",lan)}
            </label>
        </div>
        <Link
            to={"/forgot_password"}
            className="smallText forgetLink rightAlignText"
          >
            {t("ForgotPassword",lan)}
          </Link>
        
        </div>
        <button
          className={
            loginDissabled || loderBtn
              ? "authPrimaryButtonDissabled"
              : "authPrimaryButton"
          }
          disabled={loginDissabled || loderBtn ? true : false}
          onClick={() => {
            loginApiCall();
          }}
        >
          {loderBtn ? (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            t("Login",lan)
          )}
        </button>
        <p>&nbsp;</p>
        {loginError !== "" && (
          <>
            <p className="errorText">{loginError}</p>
            <p>&nbsp;</p>
          </>
        )}
        <p className="smallText ">
          <Link to={"/terms-condition"} className="smallText decoration-none">
           {t("terms",lan)}{' '} |{' '}
          </Link>
          <Link to={"/privacy-policy"} className="smallText decoration-none">
           {t("privacy",lan)}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
