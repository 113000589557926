import axios from "axios"
import API from "../api";


const axiosInstance = axios.create({
  baseURL: `${API.backendApi}/`,
});

 function createRequestFunc(methods,url, body,headers ,handleUploadProgress = ()=>{}) {
    let config = {
      method: methods,
      url,
      headers: headers,
      data:body,
      onUploadProgress : handleUploadProgress,
    };
    return axiosInstance(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if(error.response.status == 401){
          // localStorage.clear();
          // window.location.replace('/')
          if (error.response.data.is_authenticated == "False") {
            localStorage.clear();
            window.location.replace("/");
          }
        }else{
          return error.response.data;
        }    
      });
}


export const getCategoryApi = async() =>{
  return await createRequestFunc("get",`get-category-details/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getSubCategoryApi = async() =>{
  return await createRequestFunc("get",`get-sub-category-details/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const postSubCategory = async(body) =>{
  return await createRequestFunc("post",`add-sub-category/`,body,{
    "Content-Type": "multipart/form-data", 
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editSubCategory = async(uuid,body) =>{
  return await createRequestFunc("put",`get-sub-category-details/${uuid}/`,body,{
    "Content-Type": "multipart/form-data", 
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const deleteSubCategory = async(uuid) =>{
  return await createRequestFunc("delete",`get-sub-category-details/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


//Course Apis

export const getCoursesApi = async() =>{
  return await createRequestFunc("get",`get-course-details/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const postCourse = async(body,event) =>{
  return await createRequestFunc("post",`add-course/`,body,{
    "Content-Type": "multipart/form-data", 
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}

export const editCourseApi = async(uuid,body,event) =>{
  return await createRequestFunc("put",`get-course-details/${uuid}/`,body,{
    "Content-Type": "multipart/form-data", 
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}

export const deleteCourseApi = async(uuid) =>{
  return await createRequestFunc("delete",`get-course-details/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

// course Detail by id

export const getCourseDetailApi = async(uuid) =>{
  return await createRequestFunc("get",`get-course-details/${uuid}/`,{},{
    "Content-Type": "multipart/form-data", 
    "Authorization": localStorage.getItem("Authorization"),
  });
}


// get user detail by Supplier 

export const getUserDetail = async(body) =>{
  return await createRequestFunc("post",`view-individual-profile_User/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getOfferedCourse = async(body) =>{
  return await createRequestFunc("post",`supplier-dashboard/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editCourseStatus = async(body) =>{
  return await createRequestFunc("put",`supplier-dashboard-Active_InActive/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getCouseGraphApi = async(body) =>{
  return await createRequestFunc("post",`supplier-dashboard-courseGraph/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getEarningGraphApi = async(body) =>{
  return await createRequestFunc("post",`supplier-dashboard-earningGraph/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


// get all subcategory 

export const getAllSubCategory = async() =>{
  return await createRequestFunc("get",`all-sub-category/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getTnCApi = async() =>{
  return await createRequestFunc("get",`get-termsSupplier-page-data/`);
}

export const getPrivacyPolicyApi = async() =>{
  return await createRequestFunc("get",`get-privacySupplier-page-data/`);
}

export const getPersonalProfileDetail = async() =>{
  return await createRequestFunc("get",`supplier-profile/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
export const getAdminPersonalData = async() =>{
  return await createRequestFunc("get",`admin-profile/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editAdminPersonalData = async(body) =>{
  return await createRequestFunc("put",`admin-profile/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
export const editPersonalProfileDetail = async(body) =>{
  return await createRequestFunc("put",`supplier-profile/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const addMaterialCourse = async(uuid,body,event) =>{
  return await createRequestFunc("post",`course-material-upload/${uuid}/`,body,{
    "Content-Type": "multipart/form-data",
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}

export const editMaterialCourse = async(uuid,body,event) =>{
  return await createRequestFunc("put",`course-material-upload/${uuid}/`,body,{
    "Content-Type": "multipart/form-data",
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}

export const getMaterialCourse = async(uuid) =>{
  return await createRequestFunc("get",`course-material-upload/${uuid}/`,{},{
    "Content-Type": "multipart/form-data",
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getRecuritmentAdsApi = async() =>{
  return await createRequestFunc("get",`recruitmentAd/`,{},{
    "Content-Type": "multipart/form-data",
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getRecuritmentAdsDetailApi = async(uuid) =>{
  return await createRequestFunc("get",`recruitmentAd/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const postRecuritmentAdsApi = async(body) =>{
  return await createRequestFunc("post",`recruitmentAd/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editRecuritmentAdsApi = async(uuid,body) =>{
  return await createRequestFunc("put",`recruitmentAd/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const deleteRecuritmentAds = async(uuid) =>{
  return await createRequestFunc("delete",`recruitmentAd/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const organizationalProfile = async() =>{
  return await createRequestFunc("get",`supplier-organization-profile/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const organizationEditApi = async(body) =>{
  return await createRequestFunc("put",`supplier-organization-profile/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const postOrganizationEditApi = async(body) =>{
  return await createRequestFunc("post",`supplier-organization-profile/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//for notification

export const getNotification = async() =>{
  return await createRequestFunc("get",`notifications/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const clearNoti = async(body) =>{
  return await createRequestFunc("put",`notifications/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

// for course list 

export const getCourseNamesApi = async() =>{
  return await createRequestFunc("get",`get-allcourse-list/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getCorporateCourseNamesApi = async() =>{
  return await createRequestFunc("get",`organization-courses-for-batch/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//for batch

export const addBatch = async(body) =>{
  return await createRequestFunc("post",`add-batch/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//for batch

export const addCorporateBatch = async(body) =>{
  return await createRequestFunc("post",`add-organization-batch/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getBatch = async() =>{
  return await createRequestFunc("get",`get-batch/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getBatchForCorporate = async() =>{
  return await createRequestFunc("get",`get-organization-batch/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getBatchById = async(uuid) =>{
  return await createRequestFunc("get",`get-batch/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editBatchById = async(uuid,body) =>{
  return await createRequestFunc("put",`get-batch/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editCorporateBatchById = async(uuid,body) =>{
  return await createRequestFunc("put",`get-organization-batch/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editCorporationBatchById = async(uuid,body) =>{
  return await createRequestFunc("put",`get-organization-batch/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const deleteBatchById = async(uuid) =>{
  return await createRequestFunc("delete",`get-batch/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
export const deleteCorporateBatchById = async(uuid) =>{
  return await createRequestFunc("delete",`get-organization-batch/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//session apis
export const getSessionApi = async() =>{
  return await createRequestFunc("get",`get-session/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//session apis
export const getCorporateSessionApi = async() =>{
  return await createRequestFunc("get",`get-organization-session/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const addSessionApi = async(body) =>{
  return await createRequestFunc("post",`add-session/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const addCorporateSessionApi = async(body) =>{
  return await createRequestFunc("post",`add-organization-session/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editSessionApi = async(uuid,body) =>{
  return await createRequestFunc("put",`get-session/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editCorporateSessionApi = async(uuid,body) =>{
  return await createRequestFunc("put",`get-organization-session/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const deleteSessionApi = async(uuid) =>{
  return await createRequestFunc("delete",`get-session/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
export const deleteCorporateSessionApi = async(uuid) =>{
  return await createRequestFunc("delete",`get-organization-session/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//send  withdrawal request through suppliers side
export const sendWitdrawalRequest = async(body) =>{
  return await createRequestFunc("post",`supplier-withdraw-request/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//get widrawal request list

export const getWithdrawalList = async() =>{
  return await createRequestFunc("get",`supplier-withdraw-list/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//transfer/hold money

export const withdrawalReqStatusChange = async(body,id) =>{
  return await createRequestFunc("post",`supplier-withdraw-status/${id}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


//add corporate dashboard content


export const editCorporateDashboardData = async(body,uuid) =>{
  return await createRequestFunc("put",`edit-corporate-details/${uuid}/`,body,{
    "Content-Type": "multipart/form-data", 
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getCorporateDashboard = async() =>{
  return await createRequestFunc("get",`get-corporate-details/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


export const getCorporateCategoryApi = async() =>{
  return await createRequestFunc("get",`get-corporate-category/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
export const addCorporateCategoryApi = async(body) =>{
  return await createRequestFunc("post",`add-corporate-category/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const editCorporateCategoryApi = async(body,uuid) =>{
  return await createRequestFunc("put",`edit-corporate-category/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const deleteCorporateCategoryApi = async(uuid) =>{
  return await createRequestFunc("delete",`delete-corporate-category/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const statusChangeCorporateCategoryApi = async(body,uuid) =>{
  return await createRequestFunc("put",`corporate-category-active-inactive/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//get corporate course
export const getCorporateCourseApi = async() =>{
  return await createRequestFunc("get",`get-corporate-course/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//get corporate course with id
export const getCorporateCourseByID = async(uuid) =>{
  return await createRequestFunc("get",`get-corporate-course/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


//add corporate course
export const addCorporateCourseApi = async (body,event) => {
  return await createRequestFunc("post", `add-corporate-course/`, body, {
    Authorization: localStorage.getItem("Authorization"),
  },event);
};

//add corporate course
export const editCorporateCourseApi = async (body,uuid,event) => {
  return await createRequestFunc("put", `edit-corporate-course/${uuid}/`, body, {
    Authorization: localStorage.getItem("Authorization"),
  },event);
};

//delete corporate course

export const deleteCorporateCourseApi = async (uuid) => {
  return await createRequestFunc("delete", `delete-corporate-course/${uuid}/`, {}, {
    Authorization: localStorage.getItem("Authorization"),
  });
};

//active inactove corporate course

export const activeInactiveCorporateCourseApi = async (uuid,data) => {
  return await createRequestFunc("put", `active-inactive-corporate-course/${uuid}/`, data, {
    Authorization: localStorage.getItem("Authorization"),
  });
};


//get corporate user list
export const getCorporateUserApi = async() =>{
  return await createRequestFunc("get",`corporate-users-list/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


export const getCorporateUserById = async(uuid) =>{
  return await createRequestFunc("get",`corporate-users-list/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const changeCorporateUserStatusApi = async (data) => {
  return await createRequestFunc("put", `user-corporate-profile/`, data, {
    Authorization: localStorage.getItem("Authorization"),
  });
};

//invite corporate user
export const inviteCorporateUserApi = async (data) => {
  return await createRequestFunc("post", `corporate-user-invitation/`, data, {
    Authorization: localStorage.getItem("Authorization"),
  });
};
//module list
export const getCorporateModuleListById = async(uuid) =>{
  return await createRequestFunc("get",`add-corporate-module/?course=${uuid}`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//module list normal
export const getModuleListById = async(uuid) =>{
  return await createRequestFunc("get",`add-course-module/?course=${uuid}`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//add module
export const addCorporateModule = async(body) =>{
  return await createRequestFunc("post",`add-corporate-module/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//add module normal
export const addModule = async(body) =>{
  return await createRequestFunc("post",`add-course-module/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//edit module
export const editCorporateModule = async(uuid, body) =>{
  return await createRequestFunc("put",`edit-corporate-module/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//edit module course
export const editModule = async(uuid, body) =>{
  return await createRequestFunc("put",`edit-course-module/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//delete module
export const deleteCorporateModule = async(uuid) =>{
  return await createRequestFunc("delete",`edit-corporate-module/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//delete module course
export const deleteModule = async(uuid) =>{
  return await createRequestFunc("delete",`edit-course-module/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//active inactive module 

export const changeStatusCorporateModule = async(uuid, body) =>{
  return await createRequestFunc("put",`active-inactive-corporate-module/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//active inactive module 

export const changeStatusModule = async(uuid, body) =>{
  return await createRequestFunc("put",`active-inactive-course-module/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//add module
export const changeOrderOfModule = async(body) =>{
  return await createRequestFunc("put",`change-order-of-module/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//add module
export const changeOrderOfCourseModule = async(body) =>{
  return await createRequestFunc("put",`change-order-of-course-module/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//add material
export const addCorporateMaterial = async(body,event) =>{
  return await createRequestFunc("post",`add-corporate-module-resource/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}
//add material course
export const addMaterial = async(body,event) =>{
  return await createRequestFunc("post",`add-course-module-resource/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}

//edit corporate material
export const editCorporateMaterial = async(uuid,body,event) =>{
  return await createRequestFunc("put",`edit-corporate-module-resource/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}
//edit  material
export const editMaterial = async(uuid,body,event) =>{
  return await createRequestFunc("put",`edit-course-module-resource/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  },event);
}

//get corporate material
export const getCorporateMaterail = async(uuid) =>{
  return await createRequestFunc("get",`add-corporate-module-resource/?module_uuid=${uuid}`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//get  material
export const getMaterail = async(uuid) =>{
  return await createRequestFunc("get",`add-course-module-resource/?module_uuid=${uuid}`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


//delete corporate material
export const deleteCorporateMaterial = async(uuid) =>{
  return await createRequestFunc("delete",`delete-corporate-module-resource/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//delete  material
export const deleteMaterial = async(uuid) =>{
  return await createRequestFunc("delete",`delete-course-module-resource/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//drag material api
export const changeOrderOfCorporateMaterial = async(body) =>{
  return await createRequestFunc("put",`change-order-of-module-resources/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

//drag material api normal
export const changeOrderOfMaterial = async(body) =>{
  return await createRequestFunc("put",`change-order-of-course-module-resources/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


export const postPublishContent = async(uuid,body) =>{
  return await createRequestFunc("put",`publish-unpublish-org-course/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}
//PUBLISH COURSE
export const postPublishUnpublishContent = async(uuid,body) =>{
  return await createRequestFunc("put",`publish-unpublish-course/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


export const postNews = async(body) =>{
  return await createRequestFunc("post",`add-news/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getNews = async(is_corporate) =>{
  return await createRequestFunc("get",`get-news/?is_corporate=${is_corporate??false}`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const getNewsById = async(uuid) =>{
  return await createRequestFunc("get",`get-news-details/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}


export const editNews = async(uuid,body) =>{
  return await createRequestFunc("put",`edit-news/${uuid}/`,body,{
    "Authorization": localStorage.getItem("Authorization"),
  });
}

export const deleteNews = async(uuid) =>{
  return await createRequestFunc("delete",`delete-news/${uuid}/`,{},{
    "Authorization": localStorage.getItem("Authorization"),
  });
}