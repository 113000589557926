import axios from "axios";
import API from "../api";

const axiosInstance = axios.create({
  baseURL: `${API.backendApi}/`,
});

function createRequestFunc(methods, url, body, headers) {
  let config = {
    method: methods,
    url,
    headers: headers,
    data: body,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status == 401) {
        // localStorage.clear();
        // window.location.replace("/");
        if (error.response.data.is_authenticated == "False") {
          localStorage.clear();
          window.location.replace("/");
        }
      } else {
        return error.response.data;
      }
    });
}

export const postEventDetails = async (body) => {
  return await createRequestFunc("post", `event/`, body, {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("Authorization"),
  });
};
export const addCorporateEvent = async (body) => {
  return await createRequestFunc("post", `add-org-event/`, body, {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("Authorization"),
  });
};

export const getAllEvent = async () => {
  return await createRequestFunc("get",`event/`,{},{
      Authorization: localStorage.getItem("Authorization"),
    });
};

export const getAllCorporateEvent = async () => {
  return await createRequestFunc("get",`get-org-event/`,{},{
      Authorization: localStorage.getItem("Authorization"),
    });
};

export const editEventById = async (uuid, body) => {
  return await createRequestFunc("put", `event/${uuid}/`, body, {
    Authorization: localStorage.getItem("Authorization"),
  });
};

export const editCorporateEventById = async (uuid, body) => {
  return await createRequestFunc("put", `edit-org-event/${uuid}/`, body, {
    Authorization: localStorage.getItem("Authorization"),
  });
};

export const deleteEventById = async (id) => {
  return await createRequestFunc("delete",`event/${id}/`,{},{
      Authorization: localStorage.getItem("Authorization"),
    });
};
export const deleteCorporateEventById = async (id) => {
  return await createRequestFunc("delete",`delete-org-event/${id}/`,{},{
      Authorization: localStorage.getItem("Authorization"),
    });
};

export const getEventById = async (uuid,organization) => {
  return await createRequestFunc("get",`event/${uuid}/?is_organization=${organization||false}`,{},{
      Authorization: localStorage.getItem("Authorization"),
  });
};

export const getCorporateEventById = async (uuid) => {
  return await createRequestFunc("get",`get-org-event/${uuid}/`,{},{
      Authorization: localStorage.getItem("Authorization"),
  });
};

export const adminDashboard = async () => {
  return await createRequestFunc(
    "get",
    `admin-dashboard/`,
    {},
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};
export const adminUserDataDashboard = async () => {
  return await createRequestFunc(
    "get",
    `admin-dashboard-users/`,
    {},
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};
export const adminSupplierDataDashboard = async () => {
  return await createRequestFunc(
    "get",
    `admin-dashboard-suppliers/`,
    {},
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};
export const getAllUsers = async () => {
  return await createRequestFunc(
    "get",
    `get-user-details/`,
    {},
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};

export const getDetailsUsers = async (uuid) => {
  return await createRequestFunc(
    "get",
    `get-user-details/${uuid}/`,
    {},
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};

export const editDetailsUsers = async (uuid, body) => {
  return await createRequestFunc("put", `get-user-details/${uuid}/`, body, {
    Authorization: localStorage.getItem("Authorization"),
  });
};

export const organizationalProfiles = async () => {
  return await createRequestFunc(
    "get",
    `supplier-organization-profile-adminview/`,
    {},
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};

export const organizationalDetails = async (body) => {
  return await createRequestFunc(
    "post",
    `supplier-organization-profile-adminview/`,
    body,
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};
export const getPayments = async () => {
  return await createRequestFunc(
    "get",
    `manage-payment/`,
    {},
    {
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};
export const editPayments = async (uuid, body) => {
  return await createRequestFunc("put", `manage-payment/${uuid}/`, body, {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("Authorization"),
  });
};

//admin payment listing page get api
export const getPaymentList = async () => {
  return await createRequestFunc(
    "get",
    `admin-payment-list/`,
    {},
    {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};

//admin payment listing page get api
export const getPaymentDetailById = async (id) => {
  return await createRequestFunc(
    "get",
    `admin-payment-list/${id}/`,
    {},
    {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};

//send  payment to supplier
export const sendPaymentPayout = async (id, body) => {
  return await createRequestFunc("post", `supplier-payout/${id}/ `, body, {
    Authorization: localStorage.getItem("Authorization"),
  });
};

//get my payment list api for admin only

export const getAdminsPaymentApi = async () => {
  return await createRequestFunc(
    "get",
    `admin-manage-payment/`,
    {},
    {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("Authorization"),
    }
  );
};
