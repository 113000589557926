import React, { useEffect, useState } from "react";
import "./CorporateEventDetails.scss";
import "../../../../../common/styles/globalStyles.scss";
import { IoIosArrowBack } from "react-icons/io";
import { FaUsers, FaCalendar, FaClock } from "react-icons/fa";
import Dummy from "../../../../../assets/images/dummy.png";
import { Link, useHistory, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import user from "../../../../../assets/images/noUser.svg";
import { selectedEventDetails } from "../../../../../redux/actions";
import Popup from "../../../../../components/popup/popup";
import { getTranslatedText as t } from "../../../../../translater";
import {
  editCorporateEventById,
  editEventById,
  getCorporateEventById,
  getEventById,
} from "../../../../../services/adminService";
import Loader from "../../../../../components/Loader/Loader";
import Footer from "../../../../../components/footer/Footer";
import NoData from "../../../../../components/NoData/NoData";
import { formatDate } from "../../../../../services/constant";

function CorporateEventDetails(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const [popupStatus, setPopupStatus] = useState("");
  const [eventStatus, setStatus] = useState(
    state?.SelectedEvent?.status?.value
  );
  const [enrolledUser, setEnrolledUser] = useState();
  const [eventDetailsCount, setEventDetailsCount] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    let id = props?.match?.params?.id
      ? props?.match?.params?.id
      : state?.SelectedEvent?.uuid;
    setIsLoader(true);
    getEventById(id, true)
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          setEnrolledUser(result?.individuals);
          setEventDetailsCount(result?.subscriber_count);
        }
      })
      .catch((e) => console.log("something went wrong!"));
  }, []);

  const onStatusChange = (e) => {
    const updateed = {
      ...state?.SelectedEvent,
      status: {
        ...state?.SelectedEvent?.status,
        value: e?.target?.value,
      },
    };
    let formData = new FormData();
    formData.append("status", e?.target?.value);
    editCorporateEventById(state?.SelectedEvent?.uuid, formData)
      .then((result) => {
        if (result?.status == "success") {
          dispatch(selectedEventDetails(updateed));
        }
        console.log("res", result);
      })
      .catch((e) => console.log("something went wrong!"));
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
  };

  return (
    <div className="courseDetail">
      <div className="fix_height">
        <div className="courseDetailHeader">
          <p className="midText m-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/event")}
            >
              Manage {state?.SelectedEvent?.event_choose_type} |{" "}
            </span>
            <span className="textPrimaryColor">
              {state?.SelectedEvent?.event_choose_type + " "} Detail{" "}
            </span>
          </p>
          <div className="courseDetailHeaderRight">
            <span
              className="headerTab smallText cursorPointer"
              onClick={() => {
                history.goBack();
              }}
            >
              <IoIosArrowBack /> &nbsp;{t("Back", lan)}
            </span>
          </div>
        </div>
        <div className="courseDetailsMain">
          <div className="courseDetailsMainDetailView">
            <div className="courseDetailsMainDetailViewLeft">
              <span className="summaryDetail">
                <div className="imgBlock">
                  <img
                    src={
                      state?.SelectedEvent?.event_image
                        ? `${state?.SelectedEvent?.event_image}`
                        : Dummy
                    }
                    className="courseDetailImage"
                  />
                </div>
                <span className="ms-lg-2">
                  <p className="midText boldText mb-2">
                    {state?.SelectedEvent?.event_name || "-"}
                  </p>
                  <p className="superSmallText mb-3">
                    {` ${t("Posted", lan)} ${t("By", lan)}`} :{" "}
                    {state?.SelectedEvent?.event_organizer ||
                      state?.SelectedEvent?.admin_name ||
                      ""}{' '}
                    on {formatDate(state?.SelectedEvent?.created_date_time)}
                  </p>
                  <p className="superSmallText boldText statuText mb-1 ">
                    {"Status"}
                    <select
                      value={eventStatus}
                      className={
                        state?.SelectedEvent?.status?.value == "Active"
                          ? "StatusActive ms-4"
                          : "StatusInActive ms-4"
                      }
                      onChange={(e) => {
                        setStatus(e?.target?.value);
                        onStatusChange(e);
                      }}
                    >
                      <option
                        value="Active"
                        // selected={
                        //   CoursesOfferedData?.status
                        //     ?.value == "Active"
                        // }
                      >
                        {t("Active", lan)}
                      </option>
                      <option
                        value="Inactive"
                        // selected={
                        //   CoursesOfferedData?.status
                        //     ?.value == "Inactive"
                        // }
                      >
                        {t("inActive", lan)}
                      </option>
                    </select>
                  </p>
                </span>
              </span>

              <p className="boldText pWithMargin">
                {state?.SelectedEvent?.event_choose_type}{" "}
                {t("Description", lan)}
              </p>
              <p className="pWithMargin smallText">
                {state?.SelectedEvent?.event_description || "-"}
              </p>
            </div>
            <div className="courseDetailsMainDetailViewRight">
              <span className="courseDetailRightContent">
                <FaUsers className="textPrimaryColor midText" /> &nbsp; &nbsp;
                {/* {eventDetailsCount || 0}
              {" " + "Registered"} */}
                {state?.SelectedEvent?.event_choose_type !== "Advertisement"
                  ? eventDetailsCount
                    ? `${eventDetailsCount} Registered`
                    : `0 Registered`
                  : state?.SelectedEvent?.event_subscriber
                  ? `${state?.SelectedEvent?.event_subscriber} Viewers`
                  : `0 Viewers`}
              </span>
              <span className="courseDetailRightContent">
                <FaCalendar className="textPrimaryColor midText" /> &nbsp;
                &nbsp;{t("StartsOn", lan)}{" "}
                {console.log("state?.SelectedEvent", state?.SelectedEvent)}
                {new Date(state?.SelectedEvent?.start_date).getDate()}{" "}
                {
                  monthNames[
                    new Date(state?.SelectedEvent?.start_date).getMonth()
                  ]
                }{" "}
                {new Date(state?.SelectedEvent?.start_date).getFullYear()}
              </span>
              {state?.SelectedEvent?.end_date ? (
                <span className="courseDetailRightContent">
                  <FaClock className="textPrimaryColor midText" /> &nbsp; &nbsp;
                  {t("ExpireOn", lan)}{" "}
                  {new Date(state?.SelectedEvent?.end_date).getDate()}{" "}
                  {
                    monthNames[
                      new Date(state?.SelectedEvent?.end_date).getMonth()
                    ]
                  }{" "}
                  {new Date(state?.SelectedEvent?.end_date).getFullYear()}
                </span>
              ) : (
                <span className="courseDetailRightContent">
                  <FaClock className="textPrimaryColor midText" /> &nbsp; &nbsp;{" "}
                  {t("ExpireOn", lan)}{" "}
                  {new Date(state?.SelectedEvent?.created_date_time).getDate()}{" "}
                  {
                    monthNames[
                      new Date(
                        state?.SelectedEvent?.created_date_time
                      ).getMonth() + 6
                    ]
                  }{" "}
                  {new Date(
                    state?.SelectedEvent?.created_date_time
                  ).getFullYear()}
                </span>
              )}
            </div>
          </div>
          {state?.SelectedEvent?.event_choose_type !== "Advertisement" && (
            <div className="eventDetailsMainListView">
              <p>{t("TotalIndiEvent", lan)}</p>
              <div className="courseDetailsMainListTable">
                <table className="courseListTable desktop-list">
                  {enrolledUser?.length > 0 ? (
                    enrolledUser?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td width="64px">
                              <img
                                src={
                                  item?.profile_image
                                    ? `${item?.profile_image}`
                                    : user
                                }
                                alt="Avtar"
                                className="courseAvtars"
                              />
                            </td>
                            <td>
                              {`${item?.first_name} ${item?.last_name}`} <br />{" "}
                              <span className="superSmallText textGrayColor">
                                {item?.email_id ? item?.email_id : ""}
                              </span>
                            </td>
                            <td>
                              {` ${t("Contact", lan)} ${t(
                                "Information",
                                lan
                              )} `}{" "}
                              <br />{" "}
                              <span className="superSmallText textGrayColor">
                                {item?.phone_number ? item?.phone_number : "-"}
                              </span>
                            </td>
                            <td>
                              {t("Action", lan)} <br />{" "}
                              <Link
                                to={`/supplier/manage-corporate-users/users-detail/${item?.uuid}`}
                                className="linkDecoreRemove textPrimaryColor"
                              >
                                {t("View", lan)}
                              </Link>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoData />
                    </>
                  )}
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">{/* <p>{popupStatus}</p> */}</div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default CorporateEventDetails;
