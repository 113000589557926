const initialState = {
  CourseCategories: [],
  CourseSubCategories: [],
  Courses: [],
  viewCoursesDetails: {},
  selectedUserEmail: "",
  AllUsers: [],
  UserDetail: [],
  SearchCourseText: "",
  courseGraphData: {},
  AllEvent: [],
  personalData: {
    supplier_email: "",
    supplier_name: "",
    address: "",
    phone_number: "",
    supplier_image: "",
  },
  SelectedEvent: [],
  getCourseNames: [],
  approvedSubCategory: [],
  tabPageNo: 1,
  organizationalData: {},
  courseCategoryData: {},
  additionalInfoData: {},
  language: "sw",
  is_approved: "",
  notificationCount: 0,
  allNotificationData: [],
  isPublishedCourse: undefined,
};
const CountReduser = (state = initialState, action) => {
  switch (action.type) {
    case "logout":
      console.log("action.payload", action.payload);
      return { ...initialState, language: action.payload || "sw" };

    case "addCourseCategories":
      return {
        ...state,
        CourseCategories: action.payload,
      };
    case "addCourseSubCategories":
      return {
        ...state,
        CourseSubCategories: action.payload,
      };
    case "addCourses":
      return {
        ...state,
        Courses: action.payload,
      };
    case "viewCoursesDetails":
      return {
        ...state,
        viewCoursesDetails: action.payload,
      };
    case "selectedUserEmail":
      return {
        ...state,
        selectedUserEmail: action.payload,
      };
    case "tabNo":
      return {
        ...state,
        tabPageNo: action.payload,
      };
    case "adduserDetail":
      return {
        ...state,
        UserDetail: action.payload,
      };
    case "getAllCourses":
      return {
        ...state,
        AllCourses: action.payload,
      };
    case "getAllUsers":
      return {
        ...state,
        AllUsers: action.payload,
      };
    case "getCourseNames":
      return {
        ...state,
        getCourseNames: action.payload,
      };
    case "getAllApprovedSubCategory":
      return {
        ...state,
        approvedSubCategory: action.payload,
      };
    case "searchCourseText":
      return {
        ...state,
        SearchCourseText: action.payload,
      };

    case "courseGraphData":
      return {
        ...state,
        courseGraphData: action.payload,
      };

    case "allEventDetails":
      return {
        ...state,
        AllEvent: action.payload,
      };

    case "selectedEventDetails":
      return {
        ...state,
        SelectedEvent: action.payload,
      };

    case "courseCategoryDetails":
      return {
        ...state,
        courseCategoryData: action.payload,
      };

    case "additionalInfoData":
      return {
        ...state,
        additionalInfoData: action.payload,
      };
    case "organizationalInfoData":
      return {
        ...state,
        organizationalData: action.payload,
      };
    case "getPersonalProfileData":
      return {
        ...state,
        personalData: action.payload,
      };
    case "language":
      return {
        ...state,
        language: action.payload,
      };
    case "isApproved":
      return {
        ...state,
        is_approved: action.payload,
      };
    case "notificationCount":
      return {
        ...state,
        notificationCount: action.payload,
      };
    case "allNotificationData":
      return {
        ...state,
        allNotificationData: action.payload,
      };
    case "isPublishedCourse":
      return {
        ...state,
        isPublishedCourse: action.payload,
      };

    default:
      return state;
  }
};

export default CountReduser;
