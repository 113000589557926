import CryptoJS from "crypto-js";
export const  encrypt=(msgString)=> {
    // msgString is expected to be Utf8 encoded
    const platform=localStorage.getItem("Platform")
    const key = CryptoJS.enc.Utf8.parse(process.env[`REACT_APP_${platform}_SECRET_KEY`]);

    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(msgString, key, {
        iv: iv
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}