import "./resetPassword.scss";
import "../../../common/styles/globalStyles.scss";
import LOGO from "../../../assets/logo/Logo.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { resetPassApi } from "../../../services/authService";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getTranslatedText as t } from "../../../translater/index";
import { encrypt } from "../../../utils/encrypt";
import { toast } from "react-toastify";
const ResetPassword = () => {
  const history = useHistory();
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const uuid = new URLSearchParams(search).get('uuid');
  const first_time_login = useSelector((state) => state?.Eddi?.UserDetail?.is_first_time_login);
  const userType = useSelector((state) => state?.Eddi?.UserDetail?.user_type);
  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;
  const passRegex = /^(?=.*\d)(?=.*[!@#_$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;
  const [resetPawwordResponce, setResetPawwordResponce] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);
  const [loginDissabled, setLoginDissabled] = useState(true);
  const [passError, setPassError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setCpasswordType] = useState("password");
  const [keyCode, setKeyCode] = useState();
  const [passWord, setpassWord] = useState("");
  const [cpass, setCpass] = useState("");

  const resetPassApiCall = () => {
    let emailVal = email ? email : localStorage.getItem("logedInEmail");
    let passwordVal = passWord;
    if (passRegex.test(passwordVal) === true) {
      setPassError("");
      var bodyFormData = new FormData();
      bodyFormData.append("email_id", encrypt(emailVal));
      bodyFormData.append("password", encrypt(passwordVal));

      setLoderBtn(true)
      resetPassApi(uuid,bodyFormData)
        .then((res) => {
          setLoderBtn(false)
          if (res?.status === "success") {
            setResetPawwordResponce(lan == "en" ? res?.data : res?.data_sv);
            toast.success(lan == "en" ? res?.data : res?.data_sv);
            localStorage.setItem("logedInUser", true);
            localStorage.setItem("logedInEmail", emailVal);
          
            setTimeout(() => {
              if(email){
                history.push("/");
              }else{
                if(first_time_login){
                  if (userType === "Admin") {
                  history.push("/supplier");
                } else {
                  history.push("/supplier/create-profile");
                }
                }else{
                  history.push("/supplier");
                }
              }

              setResetPawwordResponce("");
            }, 2500);
          } else {
            setResetPawwordResponce(lan == "en" ? res?.data : res?.data_sv);
            setTimeout(() => {
              setResetPawwordResponce("");
            }, 2500);
            //   preventScroll()
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err.data);
          // preventScroll()
          setLoderBtn(false)
          setResetPawwordResponce(err.data);
          setTimeout(() => {
            setResetPawwordResponce("");
          }, 2000);
        });
    } else {
      setPassError(
        "Your password must be at least 8 characters long, contain at least one number, contain at least one special character and have a mixture of uppercase and lowercase letters."
      );
    }
  };

  const onPassChange = (e) => {
    if (keyCode !== 32) {
      setpassWord(e.target.value);
    }
  };

  useEffect(() => {
    let passwordVal = passWord;
    let cPasswordVal = cpass;

    if (passwordVal.trim() === "") {
      setLoginDissabled(true);
      //   setPasswordError("Password should not be e mpty")
    } else if (cPasswordVal !== passwordVal) {
      setLoginDissabled(true);
      //   setCPasswordError("Password does not match")
    } else {
      setLoginDissabled(false);
      //   setCPasswordError(null)
    }
  }, [cpass, passWord]);

  const showPassword = (show) => {
    show ? setPasswordType("text") : setPasswordType("password");
  };
  const showCPassword = (show) => {
    show ? setCpasswordType("text") : setCpasswordType("password");
  };

  return (
    <div className="login">
      <div className="loginCard">
        <img src={LOGO} alt="Eddi Logo" className="eddiLogo" />
        <p className="midText pWithMargin boldText">{t("ResetyourPassword", lan)}</p>
        <p className="textBlackColor smallText ">{t("Enteryourpasswordbelow", lan)}</p>
        <div className="inputDiv">
          <label htmlFor="Password">
            <p className="smallText inputText boldText">{t("PASSWORD", lan)}</p>
          </label>
          <div className="passwordDiv">
            <input
              type={passwordType}
              id="Password"
              placeholder={t("PasswordPlaceholder", lan)}
              className="authInputs pwd"
              value={passWord}
              onKeyDown={(e) => setKeyCode(e.keyCode)}
              onChange={(e) => {
                onPassChange(e);
              }}
            />
            {passwordType === "password" ? (
              <FaEye
                className="eyeIcon cursorPointer"
                onClick={() => {
                  showPassword(true);
                }}
              />
            ) : (
              <FaEyeSlash
                className="eyeIcon cursorPointer"
                onClick={() => {
                  showPassword(false);
                }}
              />
            )}
            {/* <FaEye className="eyeIcon cursorPointer"/> */}
          </div>
          <label htmlFor="ConfirmPassword">
            <p className="smallText inputText boldText">{t("ConfirmPassword", lan).toUpperCase()}</p>
          </label>
          <div className="passwordDiv">
            <input
              type={cpasswordType}
              id="ConfirmPassword"
              placeholder={t("ConfirmPassword", lan)}
              className="authInputs pwd"
              value={cpass}
              onKeyDown={(e) => setKeyCode(e.keyCode)}
              onChange={(e) => {
                if (keyCode !== 32) {
                  setCpass(e.target.value);
                }
              }}
            />

            {cpasswordType === "password" ? (
              <FaEye
                className="eyeIcon cursorPointer"
                onClick={() => {
                  showCPassword(true);
                }}
              />
            ) : (
              <FaEyeSlash
                className="eyeIcon cursorPointer"
                onClick={() => {
                  showCPassword(false);
                }}
              />
            )}

            {/* <FaEye className="eyeIcon cursorPointer"/> */}
          </div>
        </div>
        {passError && <p className="errorText mb-0">{passError}</p>}
        
          <button
          className={
            loginDissabled ? "authPrimaryButtonDissabled" : "authPrimaryButton"
          }
          onClick={resetPassApiCall}
          disabled={loginDissabled || loderBtn ? true : false}
        >

          { loderBtn ? <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                :
          t("ResetPassword", lan)
          }
          
        </button>


        <p>&nbsp;</p>
        {resetPawwordResponce !== "" && (
          <>
            <p className="errorText">{resetPawwordResponce}</p>
            <p>&nbsp;</p>
          </>
        )}
        <p className="smallText ">
          <Link to={"/terms-condition"} className="smallText decoration-none">
          {t("terms", lan)} | {"  "}
          </Link>
          <Link to={"/privacy-policy"} className="smallText decoration-none">
          {t("privacy", lan)} 
          </Link>
        </p>
      </div>
     
    </div>
  );
};

export default ResetPassword;
