import React, { useEffect, useMemo, useRef, useState } from "react";
import "./ProfileMain.scss";
// import AreaOfInterest from "./ProfileTab/AreaOfInterest";
// import EducationInfo from "./ProfileTab/EducationInfo";
// import { PersonalInformation } from "./ProfileTab/PersonalInformation";
import { getTranslatedText as t } from "../../translater/index";
// import ProfessionalInfo from "./ProfileTab/ProfessionalInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  additionalInfoData,
  courseCategoryDetails,
  isApproved,
  organizationalInfoData,
  tabPageNo,
} from "../../redux/actions";
import { useHistory } from "react-router-dom";
import OrgInfo from "./ProfileTab/OrgInfo";
import CourseCategory from "./ProfileTab/CourseCategory";
import AdditionalInformation from "./ProfileTab/AdditionalInformation";

import { organizationalProfile } from "../../services/supplierService";

const ProfileMain = (props) => {
 
  const orgRef = useRef()
  const categoryRef = useRef()
  const additionalRef = useRef()

  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;

  const dispatch = useDispatch();
  const history = useHistory();

  useMemo(() => {
    if (localStorage.getItem("logedInUser") != "true") {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    organizationalProfile().then((data) => {

      const organizationInfoObj = {
        orgName: data.data.organizational_name,
        orgAddress: data.data.organization_address,
        website: data.data.organization_website,
        country: data.data.country,
        city: data.data.city,
        brief: data.data.brif_information,
        email: data.data.organization_email,
        phone: data.data.organization_phone_number,
        contactperson: data.data.contact_person,
        corporateCode:data?.data?.corporate_code
      };

      dispatch(organizationalInfoData(organizationInfoObj));

      const category =
        data?.data?.course_category === ""
          ? []
          : data?.data?.course_category.split(",");
      const optionCats = [];
      category?.map((cat) => {
        optionCats.push({
          label: cat,
          value: cat,
        });
      });

      const courseObj = {
        optionVal: optionCats,
        sub_cat: data.data.sub_category,
      };
      dispatch(courseCategoryDetails(courseObj));
      const orgImage = {
        orgImage: data.data.organization_logo,
      };

      dispatch(additionalInfoData(orgImage));


      const is_approved = data?.data?.approved_once
      dispatch(isApproved(is_approved))

      dispatch(tabPageNo(1));
    });
  }, [])

  const checkWhichPage = async(data)=>{
    if(state?.tabPageNo ==1){
      let res = await orgRef?.current?.childFunction1()
      return res
    }else if(state?.tabPageNo == 2){
      let res = await categoryRef?.current?.childFunction1()

      return res
    }else if(state?.tabPageNo == 3){
      let res = await additionalRef?.current?.childFunction1()
      return res
    }else{
      return false
    }
  }

  const onTabClick =async(e)=>{
    
    if (e?.target?.id === "tab-1") {
      const res = await checkWhichPage(state?.tabPageNo)
      if(res === true) return dispatch(tabPageNo(1));
    
    } else if (
      e?.target?.id === "tab-2" ) {
        const res = await checkWhichPage(state?.tabPageNo)
        if(res === true) return dispatch(tabPageNo(2));

    } else if (
      e?.target?.id == "tab-3") {
      const res = await checkWhichPage(state?.tabPageNo)
        if(res === true) return dispatch(tabPageNo(3));

    } else  {
    }
  }


  // const onTabClick = async(e) => {

    // if (e?.target?.id === "tab-1") {
    //   dispatch(tabPageNo(1));
    // } else if (
    //   e?.target?.id === "tab-2" &&
    //   (state?.organizationalData?.orgName !== undefined && state?.organizationalData?.orgAddress !== undefined
    //     && state?.organizationalData?.country !== undefined
    //     && state?.organizationalData?.city !== undefined
    //     && state?.organizationalData?.email !== undefined
    //   )
    // ) {
    //   dispatch(tabPageNo(2))
      
    // } else if (
    //   e?.target?.id == "tab-3" &&
    //   state?.courseCategoryData?.optionVal.length > 0

    // ) {
    //   dispatch(tabPageNo(3));

    // } else if (
    //   e?.target?.id == "tab-4" &&
    //   state?.additionalInfoData?.orgImage !== undefined
    // ) {
    //   dispatch(tabPageNo(4));
    // }
  // };

  return (
    <div className="profileMain">
      <div className="profile-main-page ">
        <div className="text-header ms-3 pb-5 ">
          <p className="midText whitespaceNoWrap">
            {" "}
            {t("Dashboard", lan)}  | <span className="textPrimaryColor">{t("OrgProfile",lan)} </span>
          </p>
        </div>

        <div className=" main-wizard mt-5 mx-lg-5 mx-md-5 mx-sm-4 mx-3">
          <div className="wizard">
            <div className="wizard-inner mx-lg-4 mx-md-2 mx-0">
              <div className="connecting-line"></div>
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="disable">
                  <a
                    data-toggle="tab"
                    aria-controls="step1"
                    role="tab"
                    aria-expanded="true"
                    className="wizard-round"
                  >
                    <span
                      id="tab-1"
                      onClick={onTabClick}
                      className={
                        state?.tabPageNo == 1
                          ? "active-tab rounded-circle "
                          : "inactive-tab rounded-circle"
                      }
                    >
                      1{" "}
                    </span>{" "}
                    <i
                      className={
                        state?.tabPageNo == 1 ? "text-active" : "text-inactive"
                      }
                    >
                      {t("OrganizationalInformation", lan)}
                    </i>
                  </a>
                </li>
                <li role="presentation" className="active">
                  <a
                    data-toggle="tab"
                    aria-controls="step2"
                    role="tab"
                    aria-expanded="false"
                    className="wizard-round"
                  >
                    <span
                      id="tab-2"
                      onClick={onTabClick}
                      className={
                        state?.tabPageNo == 2
                          ? "active-tab rounded-circle "
                          : "inactive-tab rounded-circle"
                      }
                    >
                      2
                    </span>{" "}
                    <i
                      className={
                        state?.tabPageNo == 2 ? "text-active" : "text-inactive"
                      }
                    >
                      {t("CourseCategory", lan)}
                    </i>
                  </a>
                </li>
                <li role="presentation" className="disabled">
                  <a
                    data-toggle="tab"
                    aria-controls="step3"
                    className="wizard-round"
                    role="tab"
                  >
                    <span
                      id="tab-3"
                      onClick={onTabClick}
                      className={
                        state?.tabPageNo == 3
                          ? "active-tab rounded-circle "
                          : "inactive-tab rounded-circle"
                      }
                    >
                      3
                    </span>{" "}
                    <i
                      className={
                        state?.tabPageNo == 3 ? "text-active" : "text-inactive"
                      }
                    >
                      {t("AdditionalInformation", lan)}
                    </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="main-content m-0 mt-4">
          {state?.tabPageNo === 1 ? (
            <OrgInfo ref={orgRef} onChange={onTabClick} />
          ) : state?.tabPageNo === 2 ? (
            <CourseCategory ref={categoryRef} onChange ={onTabClick}/>
          ) : state?.tabPageNo === 3 ? (
            <AdditionalInformation ref={additionalRef} onChange={onTabClick} />
          ) : (
            <OrgInfo ref={orgRef} onChange={onTabClick} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileMain;
