import React, { Fragment, useEffect, useState } from "react";
import "./withdrawalList.scss";
import { getTranslatedText as t } from "../../../../translater/index";
import API from "../../../../api";

import { useSelector } from "react-redux";
import NoData from "../../../../components/NoData/NoData";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../../assets/images/IcFilter.svg";
import IcSearch from "../../../../assets/images/icSearch.svg";
import FilterSelectMenu from "../../../../components/FilterSelectMenu/FilterSelectMenu";
import InputText from "../../../../components/inputText/inputText";

import { Link, useHistory } from "react-router-dom";
import { getBatch, getWithdrawalList, withdrawalReqStatusChange } from "../../../../services/supplierService";
import Loader from "../../../../components/Loader/Loader";
import Footer from "../../../../components/footer/Footer";
import { useDispatch } from "react-redux";
import { getPaymentList } from "../../../../services/adminService";
import Popup from "../../../../components/popup/popup";
import { toast } from "react-toastify";
import { formatDate } from "../../../../services/constant";

function WithdrawalList() {
  let state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState();
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [paginationPayments, setPaginationPayments] = useState();
  const [holdReason, setHoldReason] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );
  const [withdrawalStatus, setWithdrawalStatus] = useState({
    value: false,
    type: "",
    uuid: ""
  });
  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [isLoader, setIsLoader] = useState(false);

  const getAllwidrawalreq = () => {
    setIsLoader(true);
    getWithdrawalList()
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          setWithdrawalList(result?.data);
          setSearchData(result?.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };

  useEffect(async () => {
    getAllwidrawalreq();
  }, []);

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setWithdrawalStatus({
      value: false,
      type: "",
      uuid: ""
    });
  };

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredPayments?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationPayments(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredPayments]);

  useEffect(() => {
    setPaginationStartIndex(1);
  }, [filteredPayments, searchData]);

  const onHoldClick = () => {
    if (holdReason?.trim() == '' || !holdReason) {
      toast.error('Please enter reason')
    } else {
      let formData = new FormData()
      formData?.append('status', 'hold')
      formData?.append('reason', holdReason)
      setIsLoader(true)
      withdrawalReqStatusChange(formData, withdrawalStatus?.uuid).then((result) => {
        setIsLoader(false)
        if (result?.status == 'success') {
          const newRes = []
          withdrawalList?.map((payment, i) => {
            if (withdrawalStatus?.uuid == payment?.uuid) {
              payment['status'] = 'hold'
              newRes.push(payment)
              return payment
            } else {
              newRes.push(payment)
            }
          })
          setWithdrawalList(newRes);
          setSearchData(newRes);
          toast.success(lan == 'en' ? result?.data : result?.data_sv)
        } else {
          toast.error(lan == 'en' ? result?.data : result?.data_sv)
        }
      }).catch((e) => { console.log(e); setIsLoader(false) })
      handleClosePopupStatus()
    }
  }
  const onTransferClick = () => {
    let formData = new FormData()
    formData?.append('status', 'transfer')
    setIsLoader(true)
    withdrawalReqStatusChange(formData, withdrawalStatus?.uuid).then((result) => {
      setIsLoader(false)
      if (result?.status == 'success') {
        const newRes = []
        withdrawalList?.map((payment, i) => {
          if (withdrawalStatus?.uuid == payment?.uuid) {
            payment['status'] = 'transfer'
            newRes.push(payment)
            return payment
          } else {
            newRes.push(payment)
          }
        })
        setWithdrawalList(newRes);
        setSearchData(newRes);
        toast.success(lan == 'en' ? result?.data : result?.data_sv)
      } else {
        toast.error(lan == 'en' ? result?.data : result?.data_sv)

      }
    }).catch((e) => { console.log(e); setIsLoader(false) })
    handleClosePopupStatus()
  }

  const onApproveReject = (e, id) => {
    console.log("eeee", id);
    const types = e?.target?.value?.toLowerCase();
    if (types == "transfer") {
      setWithdrawalStatus({
        value: true,
        type: "transfer",
        uuid: id
      });
    } else if (types == "hold") {
      setWithdrawalStatus({
        value: true,
        type: "hold",
        uuid: id
      });
    } else return;
  };

  function adsComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function adsComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date1 - date2;
  }
  const handleSearchData = (e) => {
    let searchData = [];
    if (e?.target?.value !== "") {
      withdrawalList?.map((item) => {
        if (
          item?.supplier?.supplier?.first_name
            ?.toLowerCase()
            .includes(
              e.target.value?.toLowerCase() ||
              item?.supplier?.supplier?.first_name
                ?.toLowerCase()
                .includes(e.target.value?.toLowerCase())
            )
        ) {
          searchData.push(item);
        }
      });
    } else {
      searchData = withdrawalList;
    }

    setSearchData(searchData);
  };

  const filterAdsFun = () => {
    let filteredbyTime = [];

    if (selectedTimeFilter === "Newest") {
      filteredbyTime = searchData.sort(adsComparisonbyNewestDate);
    } else {
      filteredbyTime = searchData?.sort(adsComparisonbyOldestDate);
    }

    setFilteredPayments(filteredbyTime);
  };

  useEffect(() => {
    filterAdsFun();
    pagination();
    setPaginationStartIndex(1);
  }, [selectedTimeFilter, searchData]);

  return (
    <div className="batchDetail">
      <div className="fix_height">
        <div className="subCategoryHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">
              {t("WithdrawalRequest", lan)}
            </span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchbyName", lan)}
                onChange={handleSearchData}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="headerTab smallText position-relative">
                <span className="iconsSelect">
                  <img src={IcFilter} />
                </span>
                <FilterSelectMenu
                  placeholder=""
                  value={["Newest", "Oldest"]}
                  selected={selectedTimeFilter}
                  onChange={(e) => {
                    setSelectedTimeFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div>

              {/* <div className="headerTab smallText position-relative">
                        <span className="iconsSelect">
                            <img src={IcFilter2} />
                        </span>
                        <FilterSelectMenu
                            placeholder="Filter By"
                            value={["Paid", "Free"]}
                            onChange={(e) => {
                            // setSelectedPriceFilter(e.target.value);
                            }}
                            isWithicon
                        />
                    </div> */}
            </div>
          </div>
        </div>
        {paginationPayments?.length > 0 ? (
          <Fragment>
            <div className="subCategoryListDiv">
              <table className="subCategoryListTable rc-withdrawalList">
                <tr className="whitespaceNoWrap">
                  <th>{`${t("Id", lan)}`} </th>
                  <th> {` ${t("SupplierName", lan)}`}</th>
                  <th> {` ${t("WalletAmmount", lan)}`}</th>
                  <th> {` ${t("WithdrawalRequestAmount", lan)}`}</th>
                  <th> {` ${t("RequestedDate", lan)} `}</th>

                  <th>{` ${t("Action", lan)} `}</th>
                </tr>

                {paginationPayments?.length > 0 &&
                  paginationPayments?.map((withdrawalList, index) => {
                    return (
                      <tr key={index} className="subCategoryTableInfo">
                        <td>{withdrawalList?.id}</td>
                        <td>{`${withdrawalList.supplier?.supplier?.first_name||''} ${withdrawalList.supplier?.supplier?.last_name||''}`}</td>
                        <td>{withdrawalList?.supplier?.total_amount_due || 0}</td>
                        <td>
                          {withdrawalList?.amount}
                        </td>
                        <td>{formatDate(withdrawalList?.created_date_time)}</td>
                        <td className="whitespaceNoWrap">
                          <select
                            value={withdrawalList?.status}
                            onChange={(e) =>
                              onApproveReject(e, withdrawalList?.uuid)
                            }
                            className={
                              (withdrawalList?.status)?.toLowerCase() === "pending"
                                ? "StatusActive"
                                : (withdrawalList?.status)?.toLowerCase() ===
                                  "hold"
                                  ? "StatusInActive"
                                  : "StatusApproved"
                            }
                          >
                            <option disabled={(withdrawalList?.status)?.toLowerCase() !== "pending" ? true : false} value={"pending"}>{` ${t(
                              "Pending",
                              lan
                            )} `}</option>
                            <option disabled={(withdrawalList?.status)?.toLowerCase() == "transfer" ? true : false} value={"Hold"}>{` ${t(
                              "Hold",
                              lan
                            )} `}</option>
                            <option value={"Transfer"}>{` ${t(
                              "Transfer",
                              lan
                            )} `}</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            <div className="mt-3 text-end">
              <div className="row">
                {filteredPayments?.length > 12 && (
                  <CustomPagination
                    startIndex={paginationStartIndex}
                    endIndex={
                      paginationStartIndex + 11 > filteredPayments?.length
                        ? filteredPayments?.length
                        : paginationStartIndex + 11
                    }
                    totalData={filteredPayments?.length}
                    onPrev={paginationPrev}
                    onNext={paginationNext}
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <div>
            <NoData />
          </div>
        )}
      </div>

      <div className="withdrawal-popup">
        <Popup
          show={withdrawalStatus?.value}
          header={
            withdrawalStatus?.type == "transfer"
              ? t("SureTransfer", lan)
              : t("ProvideReason", lan)
          }
          handleClose={handleClosePopupStatus}
        >
          {withdrawalStatus?.type == "hold" ? (
            <div className="popupinfo">
              <div className="reason-info form-group">
                <label htmlFor="courseOverview">
                  <p className="smallText inputText boldText pWithMargin">
                    {` ${t("Reason", lan)} `}
                  </p>
                </label>
                <textarea
                  rows={3}
                  id="courseOverview"
                  placeholder={` ${t("EnterReason", lan)} `}
                  className="form-control textArea mb-3"
                  onChange={(e) => setHoldReason(e?.target?.value)}
                />
              </div>
              <button
                onClick={onHoldClick}
                className="btn btn-green text-uppercase w-100 mt-2  col-md-6 col-sm-6 col-xs-12"
              >
                {t("Okbutton", lan)}
              </button>
            </div>
          ) : (
            <div className="row justify-content-around w-100">
              <button
                onClick={onTransferClick}
                className="btn btn-green text-uppercase  mt-2  col-md-6 col-sm-6 col-xs-12"
              >
                {t("Transfer", lan)}
              </button>

              <button onClick={() => {
                handleClosePopupStatus();
                setPaginationPayments(paginationPayments);
              }}
                className="btn btn-danger text-uppercase  mt-2 col-md-5 col-sm-5 col-xs-12 ">
                {t("CancleText", lan)}
              </button>
            </div>
          )}
        </Popup>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default WithdrawalList;
