const en = {
  Login: "Login",
  email: "Email",
  emailPlaceholder: "Email",
  PasswordPlaceholder: "Password",
  ForgotPassword: "Forgot password?",
  DontHaveAnAccount: "Don’t have an account?",
  SignUp: "Sign up",
  SocialMediaTitle: "Login via social media!",
  terms: "Terms & conditions",
  privacy: "Privacy policy",
  ResetPassword: "Reset password",
  ConfirmPassword: "Confirm password",
  Alreadymember: "Already a member?",
  ForgotPasswordHeader: "Forgot password",
  submit: "Submit",
  CreateAccount: "Create account",
  FirstName: "First name",
  LastName: "Last name",
  pwd1: "Contains at least one uppercase letter",
  pwd2: "Contains at least one special character",
  pwd3: "Contains at least one number",
  pwd4: "Passwords are matching",
  GETSTARTED: "GET STARTED",
  GETTOKNOWUS: "GET TO KNOW US",
  readmore: "Read more",
  REGISTERNOW: "REGISTER NOW",
  AllNews: "All news",
  ENROLLNOW: "ENROLL NOW",
  phonnumber: "Phone number",
  Message: "Message",
  VIEWALL: "VIEW ALL",
  Back: "Back",
  Dashboard: "Dashboard",
  MySpace: "My space",
  MyCourses: "My courses",
  EddiLabs: "Eddi labs",
  EddiProfile: "Eddi profile",
  EddiEvent: "Advertisement / News / Event",
  Welcome: "Welcome,",
  advertisement: "advertisement",
  Advertisement: "Advertisement",
  Favorite: "Favorite",
  EducationalInstitution: "Educational institution",
  EddiSuggestion: "Eddi suggestion",
  searchby: "Search by category and course areas",
  Okbutton: "OK",
  VIEW: "View",
  RateNow: "Rate now",
  aboutThisCourse: "About this course",
  Whatyouwilllearn: "What you will learn",
  readless: "Read less",
  AboutInstructor: "About instructor",
  CONTINUE: "CONTINUE",
  CourseCategoryName: "Course category",
  LoginEddi: "Log in to eddi",
  Checkout: "Checkout",
  AddCourse: "Add course",
  UpdateCourse: "Update course",
  UpdateCourseBtn: "Update course",
  AddCourseTitle: "Add course",
  CourseNameTitle: "Course Name / Title",
  CourseSubCategory: "Course sub-category",
  CourseCategory: "Course category",
  RecruitmentAd: "Recruitment ads",
  ManageCourses: "Manage courses",
  ManageUsers: "Manage users",
  Settings: "Settings",
  AboutUs: "About us",
  Hello: "Hello",
  Completeyourprofile: "Complete your profile,",
  clickhere: "click here",
  ViewDetails: "View details",
  CoursesOffered: "Courses offered",
  searchforcourses: "Search for courses...",
  CourseID: "Course ID",
  CourseName: "Course name",
  Status: "Status",
  DateCreated: "Date created",
  Action: "Action",
  ViewAll: "View all",
  searchforusers: "Search for users...",
  TotalIndividualforMyCourses: "Total students enrolled to my courses",
  UserName: "User name",
  CoruseName: "Coruse name",
  UserID: "User ID",
  CourseType: "Course type",
  ShareZoomLink: "Share zoom link",
  WeeklyDataofMyCourses: "Data of my courses",
  WeeklyDataofMyEarning: "Data of my earning",
  MyProfile: "My profile",
  EditProfile: "Edit profile",
  CoursesOffered: "Courses offered",
  YourName: "Your name",
  SupplierName: "Supplier name",
  Address: "Address",
  EmailAddress: "Email address",
  CoursesYouOffer: "Courses you offer",
  SaveProfile: "Save profile",
  DeleteText: "Delete",
  CancleText: "Cancel",
  ContactPersonName: "Contact person ",
  OrganizationEmail: "Organization email",
  OrganizationalName: "Organizational name",
  OrganizationWebsite: "Organization website",
  OrganizationLocation_Address: "Organization address",
  Country: "Country",
  City: "City",
  BreifInformation: "Organization information",
  OrganizationPhoneNumber: "Organization phone number",
  ContactPerson: "Contact person at eddi",
  CourseCategoryMultiple: "Course category (can choose multiple)",
  and: "and",
  SubCategorybasedon: "Sub-categorybased on",
  TotalUser: "Total users of the eddi site",
  CourseBySupplier: "Courses added by suppliers",
  UserRole: "User role",
  ViewCourse: "View course",
  TotalSuppliers: "Total suppliers",
  TotalIndividuals: "Total individuals",
  TotalCourses: "Total courses",
  PurchasedCourses: "Purchased courses",
  AboutMe: "About me",
  EditProfile: "Edit profile",
  ChangePassword: "Change password",
  SignOut: "Sign out",
  Notifications: "Notifications",
  All: "All",
  Active: "Active",
  inActive: "In-Active",
  JoinNow: "Join now",
  Dashboard: "Dashboard ",
  SubCategory: "Sub-category ",
  Add: "Add",
  searchbyName: "Search by name",
  by: "by",
  created: "Created",
  Name: "name",
  Approve: "Approve",
  Reject: "Reject",
  Status: "Status",
  Action: "Action",
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
  Active: "Active",
  Inactive: "In-Active",
  Edit: "Edit",
  Delete: "Delete",
  Enter: "Enter",
  subPlaceHolder: "Enter sub category name",
  Category: "Category",
  Course: "Course",
  Image: "Image",
  Upload: "Upload",
  UpDate: "Update",
  filterBy: "Filter by",
  TotalIndiCourse: "Individuals who has applied for this course",
  TotalIndiEvent: "Individuals who has applied for this event",
  EditMaterial: "Edit material",
  UploadMaterial: "Upload material",
  DreagDrop: "Drag and drop file or choose from device",
  ExpiresOn: "Expires on",
  PostedOn: "Posted on",
  PostedBy: "Posted by",
  StartsOn: "Starts on",
  Role: "Role",
  User: "User",
  Supplier: "Supplier",
  Profile: "Profile",
  DeletePopUp: "Are you sure you want to delete ?",
  PublishOn: "Publish on",
  Organizational: "Organizational",
  Additional: "Additional",
  You: "You",
  Are: "are",
  Set: "set",
  Now: "now",
  Search: "Search",
  Purchased: "Purchased",
  Not: "Not",
  Platform: "platform",
  PaymentId: "Payment Id",
  PaymentStatus: "Payment status",
  PaymentAmt: "Payment amount",
  TotalPaymentAmt: "Payment amount (incl. VAT)",
  Individual: "Individual",
  PaymentMode: "Payment mode",
  Payment: "Payment",
  Length: "Length",
  Link: "link",
  UniversityName: "University name",
  FilterBy: "Filter by",
  EventName: "Event name",
  Ad: "Ad",
  searchbyAdsName: " Search by ad name",
  OriginalPrice: "Original price",
  ManageSupplierProfile: "Manage supplier profile",
  ManagePayments: "Manage payments",
  IndividualName: "Individual name",
  PaymentStatus: "Payment status",
  ChooseRole: "Choose role",
  Viewers: "viewers",
  CourseCategory: "Course category",
  CourseSubCategory: "Course sub-category",
  CourseSubCategoryForCourse: "Course sub-category",
  RecruitmentAds: "Recruitment ads",
  SearchByEvent: "Search by name",
  ChooseType: "Choose type",
  Title: "title",
  EnterLink: "Enter link ",
  VideoLink: "video link",
  VLink: "Video link",
  AboutOrganization: " About organization",
  CoursesOffer: "Courses offer",
  Highestlvl: "Highest level of education",
  HighestDegree: "Highest degree",
  EducationArea: "Education area",
  OtherRelevantEducation: "Other relevant education",
  DiplomaCertificates: "Diploma and certificates",
  CurrentProfessionalRole: "Current professional role",
  AdditionalRole: "Additional role (if any)",
  ExtraCurricular: "Extra curricular role competence you want",
  CoreResponsibilities: "Core responsibilities",
  EnterOriginalPrice: "Enter original price",
  LevelOfRole: "Level of role",
  FutureProfessionalRole: "Future professional role",
  AreaOfInterest: "Area of interest",
  UserType: "User type",
  On: "on",
  UploadCourseImages: "Upload course images",
  EnterCourseName: "Enter course name",
  CourseOverview: "Course overview",
  CourseLength: "Course length",
  EnterCourseLength: "Choose course length (in days/hour)",
  ChooseCourseLength: "Choose course length",
  OfferPrice: "Offer price",
  EnterOfferPrice: "Enter offer price",
  ChooseLevel: "Choose level",
  ChooseCourseLevel: "Choose course level",
  SelectCourseDate: "Select course date",
  ChooseCourseCategory: "Choose course category",
  Description: "Description",
  description: "description",
  EnterDescription: "Enter description",
  DescriptionCapital: "Description",
  ChooseSubCategory: "Choose sub-category",
  CourseType: "Course type",
  EnterOrganizationLoaction: "Enter organization location ",
  EnterMeetingLink: "Enter meeting link",
  ChooseFeeType: "Choose fee type",
  EnterCoursePrice: "Enter course price",
  SubArea: " Sub area (seprated by comma)",
  EnterCourseSubArea: "Enter course sub area",
  EnterCheckoutLink: "Enter checkout link",
  CourseLanguage: "Course language",
  CourseForOrganization: "Course for organization",
  DateofBirth: "Date of birth",
  optional: "optional",
  Passcode_optional: "Passcode (optional) ",
  EnterEventLocation: "Enter event location",
  ChooseEventType: "Choose event type",
  EnterEventPrice: "Enter event price ",
  ChooseEventFeeType: "Choose event fee type",
  EventStartTime: "Event start time",
  EventStartDate: "Event start date",
  all: "all",
  AdditionalInformation: "Additional information",
  OrganizationalInformation: "Organizational information",
  ResetyourPassword: "Reset your password ",
  Enteryourpasswordbelow: "Enter your password below",
  PASSWORD: "PASSWORD",
  DragDrop: "Drag and drop file or Choose from device",
  CourseName: "Course name",
  UniversityName: "University name",
  ManageSubCategory: "Manage Sub-category",
  UserType: "User Type",
  EventEndDate: "Event end date",
  PayByInvoice: "Pay by invoice",
  NoData: "No data found",
  MeetingPasscode: "Enter meeting Passcode",
  Online: "Digital",
  CourseLocation: "Course location",
  EnterLocation: "Enter course location(s) , separated by comma",
  EnterEmails: "Add email address(es) to specific users...",
  CheckoutLink: "Checkout link",
  EnterEmailBelow: "Enter your email and password below",
  AuthorName: "Course author name",
  EnterAuthorName: "Enter course author name",
  WriteAuthor: "Write something about author",
  CourseList: "Course list",
  BatchList: "Batch lisiting",
  searchbyBatchName: "Search by batch name",
  searchBySessionName: "Search by session name",
  AddBatch: "Add batch",
  BatchName: "Batch name",
  UpdateBatch: "Update batch",
  EnterBatchName: "Enter Batch Name",
  EnterSessionName: "Enter session name",
  chooseCourseName: "Choose course name",
  AddStudents: "Add participants",
  SearchSByName: "Search by name..",
  showAllStu: "Showing all participants (who have enrolled for this course) ",
  SessionLisiting: "Session lisiting",
  AddSession: "Add session",
  UpdateSession: "Update session",
  SessionName: "Session name",
  SessionURL: "Session URL",
  chooseBatch: "Choose batch",
  chooseBatchName: "Choose course batch",
  SessionStartDate: "Session start date",
  SessionEndDate: "Session end date",
  StartTime: "Start time",
  EndTime: "End time",
  TotalDuration: "Total duration(in hours)",
  ChooseDate: "Choose date",
  ChooseDays: "Choose days",
  EnterSessionURL: "Enter session URL",
  Batch: "Batch",
  Session: "Session",
  SelectDays: "Select days",
  TotalEarning: "Total earning",
  AmmountDue: "Total amount due for card payments (incl. VAT)",
  AmmountWithdrow: "Total amount withdrawn for card payments (incl. VAT)",
  PaymentList: "Payment list",
  SupplierPaymentList: "Supplier payment list",
  TotalEarning: "Total earning",
  Commission: "Commission",
  TotalPaid: "Total paid",
  TotalDue: "Total due",
  ViewPaymentDetails: "View payment details",
  AllPaymentDetails: "All payment details",
  PaymentDetailsText: "Here are the alll payment details of User.",
  SendPayment: "Send payment",
  SendPaymentRequest: "Send payment request",
  EnterAmmount: "Enter amount",
  Amount: "Amount",
  Ammount: "Withdrawal amount",
  Withdraw: "Withdraw",
  PayOut: "Payout",
  PaymentDetails: "Payment details",
  SupplierPayouts: "Supplier payouts",
  MyPayment: "My payment",
  MyPaymentLisiting: "My payment lisiting",
  PaymentMethod: "Payment method",
  WithdrawalRequest: "Withdrawal request",
  Transfer: "Transfer",
  Hold: "On hold",
  SureTransfer: "Are you sure to transfer amount",
  ProvideReason: "Please provide holding reason",
  EnterReason: "Enter reason",
  Reason: "Reason",
  WalletAmmount: "Total wallet amount",
  WithdrawalRequestAmount: "Requested amount",
  RequestedDate: "Requested date",
  ExclVATSEK: "(Excl. VAT, SEK)",
  View: "View",
  Adss: "Ads",
  SKIP: "SKIP",
  NEXT: "NEXT",
  YouAreAllSetNow: "You are all set now !",
  organization: "Organization",
  InDays: "(In days)",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  CourseLength: "Course length",
  //phase -2
  AgreeToReceiveNotificationInSwedish:
    "I wish to have emails and notification in English",
  ExpireOn: "Expire on",
  TotalValueSoldCourses: "Total value sold courses (on Eddi) (incl. VAT)",
  UploadBannerImages: "Upload banner images",
  Small: "Small",
  EnterVideolink: "Enter video link",
  StartDate: "start date",
  StartTime: "start time",
  FeeType: "Fee type",
  EventLocation: "Location",
  EventTitle: "Event title",
  LandingPage: "Landing page",
  UserDashboard: "User dashboard",
  Both: "Both",
  AreYouSure:
    "Are you sure to continue with google sign in? (google sign in is needed for calendar/sessions’ bookings)",
  Categories: "Categories",
  Event: "Event",
  CorporateCourse: "Organization courses",
  CateInSwe: "Category in Swedish",
  EnterCateInSWe: "Enter category name in Swedish",
  CatInEng: "Category in English",
  EnterCatName: "Enter category name",
  CatDescSwe: "Category description in Swedish",
  EnterCatDesSwe: "Enter category description in Swedish",
  EnterCatDesEng: "Enter category description",
  CatDesEng: "Category description in English",
  UpdateCat: "Update category",
  AddCat: "Add category",
  DeleteCat: "Delete category",
  TotalIndi: "Total individuals",
  ManageCourseContent: "Manage course content",
  ManangeModule: "Manage module",
  ModuleNo: "Module no.",
  ModuleName: "Module name",
  AddModule: "Add module",
  UpdateModule: "Update module",
  ManageMaterial: "Manage material",
  AddMNo: "Add module number",
  EnterMNo: "Enter module number",
  AddMNameSwe: "Add module name in Swedish",
  AddMNameEng: "Add module name in English",
  EnterMNameSwe: "Enter module name in Swedish",
  EnterMNameEng: "Enter module name",
  AddMDescSwe: "Add module description in Swedish",
  AddMDescEng: "Add module description in English",
  EnterMDesSwe: "Enter module description in Swedish",
  EnterMDesEng: "Enter module description ",
  AddVideo: "Add video",
  AddFile: "Add file",
  AdjustMaterial: "Adjust materials",
  Video: "Video",
  File: "File",
  NameInEn: " Name / Title  ( in English)",
  NameInSw: " Name / Title  ( in Swedish)",
  DescInEn: " description in English",
  DescInSw: " description in Swedish",
  BackgroundFile: "Choose background image/video",
  ChooseVideo: "Choose video file",
  ChooseFile: "Choose file",
  EnterVideoTitleSw: "Enter video title",
  EnterFileTitleSw: "Enter file title",
  EnterFileTitleEn: "Enter file title",
  EnterVideoTitleEn: "Enter video title",
  EnterDescFile: "Enter description",
  EnterValidTitle: "Please enter valid title",
  EnterValidDesc: "Please enter valid description",
  AtLeastOneFile: "Please select atleast 1 file",
  CorporateDashboard: "Organization dashboard",
  BackgroundImg: "Background image",
  WhatsOn: "What’s on",
  Logo: "Logo",
  AboutCompany: "Our course and training strategy",
  AddInfo: "Add information",
  UpdateInfo: "Update information",
  AboutCompanySw: "About the company in Swedish",
  AboutCompanyEn: "About the company in English",
  EnterContent: "Enter content",
  WhatOnEn: "What’s on in English",
  WhatOnSw: "What’s on in Swedish",
  CorporateUser: "Organization users",
  CorporateBatch: "Organization batch",
  CorporateSession: "Organization session",
  CourseInstructorname: "Course instructor name",
  CourseInstructoremail: "Course instructor e-mail",
  EnterCourseInstructoremail: "Enter course author e-mail",
  EnterCourseInstructorname: "Enter course author name",
  ManageModuleAndContent: "Manage modules and content",
  CourseContent: "Course content",
  RejectReason: "Please provide reject reason",
  InviteUser: "Invite users",
  EnterEmail: "Enter email addresses",
  PressEnter: "Type email and press enter..",
  CorporateCode: "Organization code",
  Published: "Published",
  PublishContent: "Publish content",
  UnpublishContent: "Un-publish content",
  CatName: "Category Name",
  MngCat: "Manage Category",
  Success: "Success",
  ComingSoon: "coming soon..",
  FileNaTiISW: "File Name / Title ( In Swedish)",
  NotificationFound: "Notification Found",
  Republish: "Re-publish course",
  OurOrganization: "Our organization",
  Physical: "Physical",
  EnterOrgEmail: "Enter Oraganization Email",
  OrgProfile: "Organization profile ",
  EnterCorporateCode: "Enter organization code",
  EnterOrgInformation: "Enter Information on Organization",
  ContactEddi: "Contact at Eddi",
  Learners: "Learners",
  UserInfo: "User information",
  SupplierName: "Supplier name",
  BannerPublistion: "Banner publication",
  success: "Success!",
  terms: "Terms and conditions",
  privacy: "Privacy policy",
  AgreeTermsUse: "Agree to terms of use ",
  ForgotPassword: "Forgot password?",
  TotalvalueSoldCourse: "Total value sold courses (on Eddi) (incl. VAT)",
  EnterLength: "Enter length of course",
  EnterYourEmail: "Enter your email address below",
  EnterYourEmail: "ENTER YOUR EMAIL",
  StartFrom: "Starts from",
  CourseDescription: "Course description",
  UserDashboard: "User Dashboard",
  AdvertisementTitle: "Enter Advertisement name",
  ReadyToPublish: "Are you sure you want to publish content?",
  ReadyToUnPublish: "Are you sure you want to un-publish content?",
  AdminFee: "Admin fee",
  IncludingVat: "Including VAT",
  news: "news",
  NewsTitleEn: "News title",
  EnterNewsTitleEn: "Enter news title",
  NewsDescTitleEn: "News description title",
  EnterNewsDescTitleEn: "Enter news description title",
  EnterShortDesc: "Enter short description",
  OngoingCourses: "Ongoing Courses",
  CreateStripeAccount: "Create Stripe Account",
  UpdateNews: "Update content",
  DateCreatedOn: "Date created on",
  Active: "Active",
  Inactive: "Inactive",
  Personal: "Personal",
  Information: "Information",
  Educational: "Educational Information",
  ProfessionalInfo: "Professional Information",
  CourseList: "Course List",
  Detail: "detail",
  CompanyInformation: "Company Information",
  CoursesOfferOrg: "Courses offer",
  SupplierProfile: "supplier profile",
  ChooseCourseLevelCorporate: "Choose course level",
  CourseLevel: "Course level",
  Order: "Order (drag/drop)",
  CoursesOfferedChart: "Courses offered",
  NotPurchased: "Not Purchased",
};
export default en;
