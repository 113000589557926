import React, { Fragment, useEffect, useState } from "react";
import "./batch.scss";
import placeholder from "../../../../assets/images/placeholder.svg";
import { getTranslatedText as t } from "../../../../translater/index";
import SidePopup from "../../../../components/sidePopup/sidePopup";
import API from "../../../../api";
import UploadImage from "../../../../assets/images/uploadImage.png";
import { useSelector } from "react-redux";
import NoData from "../../../../components/NoData/NoData";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../../assets/images/IcFilter.svg";
import IcSearch from "../../../../assets/images/icSearch.svg";
import FilterSelectMenu from "../../../../components/FilterSelectMenu/FilterSelectMenu";
import InputText from "../../../../components/inputText/inputText";

import { Link, useHistory } from "react-router-dom";
import {
  addBatch,
  deleteBatchById,
  editBatchById,
  editRecuritmentAdsApi,
  getBatch,
  getCourseDetailApi,
  getCourseNamesApi,
  getRecuritmentAdsApi,
  postRecuritmentAdsApi,
} from "../../../../services/supplierService";
import Popup from "../../../../components/popup/popup";
import Loader from "../../../../components/Loader/Loader";
import Footer from "../../../../components/footer/Footer";
import { useDispatch } from "react-redux";
import { getCourseNames } from "../../../../redux/actions";
import { formatDate } from "../../../../services/constant";

function Batch() {
  const initialAdsDetails = {
    batch_name: null,
    course_name: null,
    stu_emails: null,
  };

  let state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState([]);
  const [addAdsPopup, setAddBatchPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [loderBtn, setLoderBtn] = useState(false);
  const [editBatch, setEditBatch] = useState(null);
  const [filteredBatch, setFilteredBatch] = useState();
  const [filteredStudents, setFilteredStudents] = useState();
  const [batch, setBatch] = useState([]);
  const [paginationBatch, setPaginationBatch] = useState();
  const [courseNameList, setCourseNameList] = useState([]);
  const [enrolledUserList, setEnrolledUserList] = useState();
  const [checkedStudents, setCheckedStudents] = useState([]);
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [isAdmin, setIsAdmin] = useState(
    state?.UserDetail?.user_type == "Admin" ? true : false
  );
  const [addBatchDetail, setAddBatchDetail] = useState(initialAdsDetails);
  const [error, setError] = useState({
    imageError: "",
    nameError: "",
    courseNameError: "",
    dateError: "",
    stuEmailError: "",
  });

  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [isLoader, setIsLoader] = useState(false);

  const getAllBatches = () => {
    setIsLoader(true);
    getBatch()
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          setBatch(result?.data);
          setSearchData(result?.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };

  useEffect(async () => {
    getAllBatches();
  }, []);

  const deleteBatchApi = (uuid) => {
    deleteBatchById(uuid)
      .then((result) => {
        if (result.status == "success") {
          setPopupStatus(t("Success", lan));
          setPopupMessage(lan == "en" ? result.data : result?.data_sv);
        } else {
          setPopupStatus(t("Failed", lan));
          setPopupMessage(lan == "en" ? result.data : result?.data_sv);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //Pagination

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredBatch?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationBatch(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [paginationStartIndex, filteredBatch]);

  useEffect(() => {
    setPaginationStartIndex(1);
  }, [filteredBatch, searchData]);

  useEffect(() => {
    if (editBatch !== null && editBatch !== undefined) {
      // setSubCategoryName(editBatch.recruitmentAd_title);
      setAddBatchPopup(true);
    } else {
      // setSubCategoryName("");
    }
  }, [editBatch]);

  const generateColor = () => {
    return Math.random().toString(16).substr(-6);
  };

  function adsComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function adsComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date1 - date2;
  }
  const handleSearchData = (e) => {
    let searchData = [];
    if (e?.target?.value !== "") {
      batch?.map((item) => {
        if (
          item?.batch_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(item);
        }
      });
    } else {
      searchData = batch;
    }

    setSearchData(searchData);
  };

  const filterAdsFun = () => {
    let filteredbyTime = [];

    if (selectedTimeFilter === "Newest") {
      filteredbyTime = searchData.sort(adsComparisonbyNewestDate);
    } else {
      filteredbyTime = searchData?.sort(adsComparisonbyOldestDate);
    }

    setFilteredBatch(filteredbyTime);
  };

  useEffect(() => {
    filterAdsFun();
    pagination();
    setPaginationStartIndex(1);
  }, [selectedTimeFilter, searchData]);

  const handleClosePopup = () => {
    setAddBatchPopup(false);
    setAddBatchDetail(initialAdsDetails);
    setCheckedStudents([]);
    setFilteredStudents();
    setEditBatch(null);
    setError({
      nameError: "",
      imageError: "",
      courseNameError: "",
      dateError: "",
      stuEmailError: "",
    });
  };

  const onApproveReject = (e, id, type) => {
    let formData = new FormData();
    formData.append("status", e?.target?.value);
    setIsLoader(true);
    editBatchById(id, formData)
      .then((result) => {
        setIsLoader(false);
        if (result?.status == "success") {
          getAllBatches();
          setPopupStatus(t("Success", lan));
          setPopupMessage(lan == "en" ? result.data : result?.data_sv);
        } else {
          setPopupStatus(t("Failed", lan));
          setPopupMessage(lan == "en" ? result.data : result?.data_sv);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };
  const courseListApi = () => {
    getCourseNamesApi()
      .then((result) => {
        if (result?.status == "success") {
          setCourseNameList(result?.data);
          dispatch(getCourseNames(result.data));
        }
      })
      .catch((e) => console.log(e));
  };

  const onClickAddBatch = () => {
    setAddBatchPopup(true);
    courseListApi();
  };

  const onStudentSelect = (e, email) => {
    let newArray = checkedStudents || [];
    if (e?.target?.checked == true) {
      newArray.push(email);
    } else {
      newArray = newArray.filter((fil) => fil != email);
    }
    setCheckedStudents(newArray);
  };

  const onEditBatch = (batch) => {
    setEditBatch(batch);
    const studentArray = [];
    if (batch?.students?.length > 0) {
      batch?.students?.map((stu, ind) => {
        studentArray.push(stu?.email_id);
      });
    }
    setCheckedStudents(studentArray);
    setAddBatchDetail({
      batch_name: batch?.batch_name,
      course_name: batch?.course?.course_name,
      stu_emails: studentArray,
    });
    courseListApi();
    getCourseIndividuals(batch?.course?.uuid, true);
  };

  const onCourseSelected = (e, id) => {
    setAddBatchDetail({
      ...addBatchDetail,
      course_name: e?.target?.value,
    });
    setError({ ...error, courseNameError: "" });
    let filtered = state?.getCourseNames?.filter(
      (fill) => fill[fill.length - 1 || 2] == e?.target?.value
    );
    if (filtered[0]) return getCourseIndividuals(filtered[0][0]);
  };

  const getCourseIndividuals = (uuid, loder) => {
    setIsLoader(true);
    getCourseDetailApi(uuid)
      .then((result) => {
        setIsLoader(false);
        if (result.status == "success") {
          setEnrolledUserList(result?.Enrolled);
          setFilteredStudents(result?.Enrolled);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoader(false);
      });
  };

  const onSearchStudent = (e) => {
    let searchData = [];
    console.log("e?.target?.value", e?.target?.value);
    if (e?.target?.value?.trim() !== "") {
      enrolledUserList?.map((ser) => {
        if (
          ser?.first_name?.toLowerCase()?.includes(e.target.value) ||
          ser?.last_name?.toLowerCase()?.includes(e.target.value)
        ) {
          searchData.push(ser);
        }
      });
    } else {
      searchData = enrolledUserList;
    }
    setFilteredStudents(searchData);
  };

  const validate = () => {
    var urlRegex = new RegExp(
      "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    );

    if (!addBatchDetail?.batch_name && !addBatchDetail?.course_name) {
      setError({
        nameError: "*Name is Required",
        courseNameError: "*Course is Required",
      });
      return false;
    } else if (
      !addBatchDetail?.batch_name ||
      addBatchDetail?.batch_name?.trim() == ""
    ) {
      setError({ ...error, nameError: "*Name is Required" });
      return false;
    } else if (
      !addBatchDetail?.course_name ||
      addBatchDetail?.course_name?.trim() == ""
    ) {
      setError({ ...error, courseNameError: "*Course is Required" });
      return false;
    } else if (!checkedStudents || checkedStudents?.length == 0) {
      console.log("checkedStudents", checkedStudents);
      setError({
        ...error,
        stuEmailError: "*Atleast one student is required!",
      });
    } else {
      return true;
    }
  };

  const postBatches = async () => {
    const validation = await validate();
    if (validation == true) {
      setLoderBtn(true);
      var data = new FormData();
      data.append("batch_name", addBatchDetail?.batch_name);
      data.append("course ", addBatchDetail?.course_name);
      data.append("students ", checkedStudents);

      addBatch(data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            getAllBatches();
            setPopupStatus(t("Success", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            setPopupStatus(t("Failed", lan) + "!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const editBatchs = async () => {
    const validation = await validate();
    if (validation == true) {
      setLoderBtn(true);

      var data = new FormData();
      data.append("batch_name", addBatchDetail?.batch_name);
      data.append("course ", addBatchDetail?.course_name);
      data.append("students ", checkedStudents);

      editBatchById(editBatch?.uuid, data)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            getAllBatches();
            setPopupStatus(t("success", lan));
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          } else {
            setPopupStatus("Failed!");
            setPopupMessage(lan == "en" ? res?.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    getAllBatches();
  };

  return (
    <div className="batchDetail">
      <div className="fix_height">
        <div className="subCategoryHeader my-4 pb-3 pt-3">
          <p className="midText mb-0">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Dashboard", lan)} |{" "}
            </span>
            <span className="textPrimaryColor">{t("BatchList", lan)}</span>
          </p>
          <div className="fliter-block">
            <div className="pageSearch">
              <span className="iconSearch">
                <img src={IcSearch} />
              </span>
              <InputText
                placeholder={t("searchbyBatchName", lan)}
                onChange={handleSearchData}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="headerTab smallText position-relative">
                <span className="iconsSelect">
                  <img src={IcFilter} />
                </span>
                <FilterSelectMenu
                  placeholder=""
                  value={["Newest", "Oldest"]}
                  selected={selectedTimeFilter}
                  onChange={(e) => {
                    setSelectedTimeFilter(e.target.value);
                  }}
                  isWithicon
                />
              </div>

              {/* <div className="headerTab smallText position-relative">
                        <span className="iconsSelect">
                            <img src={IcFilter2} />
                        </span>
                        <FilterSelectMenu
                            placeholder="Filter By"
                            value={["Paid", "Free"]}
                            onChange={(e) => {
                            // setSelectedPriceFilter(e.target.value);
                            }}
                            isWithicon
                        />
                    </div> */}
              {
                <button
                  className="primaryButton mt-0"
                  onClick={() => {
                    onClickAddBatch();
                  }}
                >
                  {` ${t("AddBatch", lan)}`}
                </button>
              }
            </div>
          </div>
        </div>
        {paginationBatch?.length > 0 ? (
          <Fragment>
            <div className="subCategoryListDiv">
              <table className="subCategoryListTable rc-batch">
                <tr className="whitespaceNoWrap">
                  <th>{`${t("Id", lan)}`} </th>
                  <th> {` ${t("BatchName", lan)}`}</th>
                  <th> {` ${t("CourseName", lan)}`}</th>
                  <th> {` ${t("DateCreated", lan)}`}</th>
                  <th> {` ${t("Status", lan)} `}</th>
                  {!isAdmin && <th>{` ${t("Action", lan)} `}</th>}
                </tr>

                {paginationBatch?.length > 0 &&
                  paginationBatch?.map((batch, index) => {
                    return (
                      <tr key={index} className="subCategoryTableInfo">
                        <td>{batch.id}</td>
                        <td>{batch.batch_name}</td>
                        <td>{batch.course?.course_name}</td>
                        <td>{formatDate(batch?.created_date_time)}</td>
                        <td>
                          {batch?.status?.value && (
                            <select
                              value={batch?.status?.value}
                              onChange={(e) => {
                                onApproveReject(e, batch?.uuid, "Activation");
                              }}
                              className={
                                batch?.status?.value == "Active"
                                  ? "StatusActive"
                                  : "StatusInActive"
                              }
                            >
                              <option value={"Active"}>{` ${t(
                                "Active",
                                lan
                              )} `}</option>
                              <option value={"Inactive"}>{`${t(
                                "inActive",
                                lan
                              )} `}</option>
                            </select>
                          )}
                        </td>
                        <td className="whitespaceNoWrap">
                          {!isAdmin && (
                            <>
                              <span
                                className="cursorPointer textPrimaryColor"
                                onClick={(e) => {
                                  onEditBatch(batch);
                                }}
                              >
                                {` ${t("Edit", lan)} `}
                              </span>{" "}
                              |{" "}
                              <span
                                onClick={(e) => deleteBatchApi(batch?.uuid)}
                                className="cursorPointer textPrimaryColor"
                              >
                                {` ${t("Delete", lan)} `}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            <div className="mt-3 text-end">
              <div className="row">
                {filteredBatch?.length > 12 && (
                  <CustomPagination
                    startIndex={paginationStartIndex}
                    endIndex={
                      paginationStartIndex + 11 > filteredBatch?.length
                        ? filteredBatch?.length
                        : paginationStartIndex + 11
                    }
                    totalData={filteredBatch?.length}
                    onPrev={paginationPrev}
                    onNext={paginationNext}
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <div>
            <NoData />
          </div>
        )}
        <SidePopup
          header={
            editBatch !== null
              ? ` ${t("UpdateBatch", lan)}`
              : ` ${t("AddBatch", lan)}`
          }
          show={addAdsPopup}
          handleClose={handleClosePopup}
        >
          <div className="sidepopupContents">
            <label htmlFor="batchName">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("BatchName", lan)}`}
                <span className="text-danger">*</span>
              </p>
            </label>
            <input
              type="text"
              id="batchName"
              maxlength="100"
              placeholder={`${t("EnterBatchName", lan)}`}
              className="formInputs"
              value={addBatchDetail?.batch_name}
              onChange={(e) => {
                setError({ ...error, nameError: "" });
                setAddBatchDetail({
                  ...addBatchDetail,
                  batch_name: e.target.value,
                });
              }}
            />
            {error?.nameError && (
              <p className="errorText mb-0">{error?.nameError}</p>
            )}

            <label htmlFor="adsDescription ">
              <p className="smallText inputText boldText pWithMargin">
                {" "}
                {` ${t("chooseCourseName", lan)}`}
                <span className="text-danger">*</span>
              </p>
            </label>

            <select
              value={addBatchDetail?.course_name}
              onChange={(e) => onCourseSelected(e)}
              className={"formInputs"}
            >
              <option disabled selected>{` ${t(
                "Choose Course Name",
                lan
              )} `}</option>

              {state?.getCourseNames &&
                state?.getCourseNames.map((item, ind) => {
                  return (
                    <Fragment key={ind}>
                      <option id={item[0]} value={item[item?.length - 1 || 2]}>
                        {item[item?.length - 1 || 2]}
                      </option>
                    </Fragment>
                  );
                })}
            </select>

            {error?.courseNameError && (
              <p className="errorText mb-0">{error?.courseNameError}</p>
            )}

            <label htmlFor="stuname">
              <p className="smallText inputText boldText pWithMargin">
                {` ${t("AddStudents", lan)}`}
              </p>
            </label>
            <input
              type="text"
              id="stuname"
              placeholder={` ${t("SearchSByName", lan)}`}
              className="formInputs"
              onChange={(e) => {
                onSearchStudent(e);
              }}
            />

            <div>
              <label htmlFor="stuname mb-3">
                <p className="smallText inputText boldText pWithMargin">
                  {` ${t("showAllStu", lan)}`}
                </p>
              </label>

              {/* //student list  */}
              <div className="showing-all-student">
                {filteredStudents ? (
                  filteredStudents.map((user, index) => {
                    const isChecked = checkedStudents?.includes(user?.email_id);

                    return (
                      <Fragment key={index}>
                        <div className="showing-all-student-box">
                          <div className="showing-all-student-box-left">
                            <div className="sh-all-img-circle">
                              <span>
                                <p
                                  className="spplierImg"
                                  style={{ background: `#${generateColor()}` }}
                                >
                                  {user?.first_name.charAt(0).toUpperCase()}{" "}
                                </p>
                              </span>
                            </div>
                            <div className="sh-all-name">
                              <h5> {user?.first_name}</h5>
                              <span>{user?.email_id}</span>
                            </div>
                          </div>
                          <div className="sh-checkbox">
                            <div className="form-group">
                              <input
                                checked={isChecked}
                                onClick={(e) => {
                                  onStudentSelect(e, user?.email_id);
                                  setError({ ...error, stuEmailError: "" });
                                }}
                                name={`checkbox-${user?.email_id}`} // Update the name attribute
                                type="checkbox"
                                className="checkbox"
                                id={"check" + index}
                              />
                              <label for={"check" + index}></label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="checkboxsDiv mt-3">
                <label className="checkboxLabel">
                  <input
                    defaultChecked={checkedStudents?.filter((fil)=>fil == user?.email_id)[0] ? true : false} 
                    onClick={(e)=>{onStudentSelect(e,user?.email_id);
                      setError({ ...error, stuEmailError: "" });
                    }} 
                    name={user?.email_id}
                    type="checkbox"
                    className="checkbox"
                    id="AgreeTnC"
                  />
                  <span className="checkbox "></span>&nbsp;{" "}
                  {user?.first_name}
                </label>
              </div> */}
                      </Fragment>
                    );
                  })
                ) : (
                  <>
                    <p>{t("NoData", lan)}</p>
                  </>
                )}
              </div>
              {error?.stuEmailError && (
                <p className="errorText mb-0">{error?.stuEmailError}</p>
              )}
            </div>

            {loderBtn ? (
              <button className="primaryButton" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className="primaryButton"
                onClick={() => {
                  // postBatches();
                  editBatch !== null ? editBatchs() : postBatches();
                }}
              >
                {editBatch !== null
                  ? ` ${t("UpdateBatch", lan)}`
                  : ` ${t("AddBatch", lan)}`}
              </button>
            )}
          </div>
        </SidePopup>

        <Popup
          show={popupStatus !== "" ? true : false}
          header={popupStatus}
          handleClose={handleClosePopupStatus}
        >
          <div className="popupinfo">
            <p>{popupMessage}</p>
          </div>
          <div>
            <button
              onClick={handleClosePopupStatus}
              className="btn btn-green text-uppercase w-100 mt-2"
            >
              {t("Okbutton", lan)}
            </button>
          </div>
        </Popup>
      </div>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Batch;
