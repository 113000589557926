import React, { useEffect, useState } from "react";
import "./ManageCorporateUsers.scss";
import placeholder from "../../../../assets/images/placeholder.svg";
import { getTranslatedText as t } from "../../../../translater/index";

import API from "../../../../api";
import UploadImage from "../../../../assets/images/uploadImage.png";
import { useSelector } from "react-redux";
import NoData from "../../../../components/NoData/NoData";
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import IcFilter from "../../../../assets/images/IcFilter.svg";
import IcFilter2 from "../../../../assets/images/IcFilter2.svg";
import FilterSelectMenu from "../../../../components/FilterSelectMenu/FilterSelectMenu";
import { Link, useHistory } from "react-router-dom";
import IcSearch from "../../../../assets/images/icSearch.svg";
import Popup from "../../../../components/popup/popup";
import Loader from "../../../../components/Loader/Loader";
import { editDetailsUsers } from "../../../../services/adminService";
import InputText from "../../../../components/inputText/inputText";
import Footer from "../../../../components/footer/Footer";
import { changeCorporateUserStatusApi, getCorporateUserApi, inviteCorporateUserApi } from "../../../../services/supplierService";
import SidePopup from "../../../../components/sidePopup/sidePopup";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { toast } from "react-toastify";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function ManageCorporateUsers() {
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const history = useHistory()
  const [popupStatus, setPopupStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [userData, setUserData] = useState([]);
  const [filteredUser, setFilteredUser] = useState();
  const [paginationUser, setPaginationUser] = useState();
  const [paginationStartIndex, setPaginationStartIndex] = useState(1);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState("Newest");
  const [isLoader, setIsLoader] = useState(false);
  const [loaderbtn, setLoaderbtn] = useState(false);
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [inviteUserPopup, setInviteUserPopup] = useState({ value: false, users: [] })
  const [inviteError, setInviteError] = useState("")
  const [rejectPopup, setRejectPopup] = useState({
    value: false,
    data: {},
    reason: ""
  })

  const manageUsersApiCall = async () => {
    setIsLoader(true);
    getCorporateUserApi().then((res) => {
      if (res?.status == "success") {
        setUserData(res?.data);
        setSearchData(res?.data);
      }
    }).catch((e) => console.log(e))
      .finally(() => setIsLoader(false));
  };

  useEffect(async () => {
    await manageUsersApiCall();
  }, []);

  //Pagination

  const generateColor = () => {
    return Math.random().toString(16)?.substr(-6);
  };

  const paginationPrev = () => {
    setPaginationStartIndex(paginationStartIndex - 12);
  };
  const paginationNext = () => {
    setPaginationStartIndex(paginationStartIndex + 12);
  };

  const pagination = () => {
    const paginationData = [];
    const actualIndex = paginationStartIndex - 1;
    filteredUser?.map((data, i) => {
      if (i >= actualIndex && i <= actualIndex + 12) {
        paginationData.push(data);
      }
    });
    setPaginationUser(paginationData);
  };

  useEffect(() => {
    pagination();
  }, [
    paginationStartIndex,
    filteredUser,
    selectedRoleFilter,
    selectedTimeFilter,
  ]);

  function userComparisonbyNewestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date2 - date1;
  }
  function userComparisonbyOldestDate(a, b) {
    const date1 = new Date(a?.created_date_time);
    const date2 = new Date(b?.created_date_time);

    return date1 - date2;
  }

  const filterByRole = () => {
    let filterRole = [];
    let filteredbyTime = [];
    if (selectedRoleFilter !== "" && selectedRoleFilter !== "All") {
      searchData?.map((user) => {
        if (selectedRoleFilter === user.user_type?.user_type) {
          filterRole.push(user);
        }
      });
    } else if (selectedRoleFilter === "" || selectedRoleFilter === "All") {
      filterRole = searchData;
    }
    if (selectedTimeFilter === "Newest") {
      filteredbyTime = filterRole?.sort(userComparisonbyNewestDate);
    } else {
      filteredbyTime = filterRole?.sort(userComparisonbyOldestDate);
    }
    // console.log(filteredbyTime);

    setFilteredUser(filteredbyTime);
  };

  useEffect(() => {
    filterByRole();
    pagination();

    setPaginationStartIndex(1);
  }, [selectedTimeFilter, selectedRoleFilter, searchData]);

  const onApproveReject = (value, email, type) => {
    setIsLoader(true)
    let formData = new FormData();
    formData.append("user_email", email)
    if (type == "Approval") {
      formData.append("approval_status", value);
      formData.append("reject_reason", rejectPopup.reason);
    } else {
      formData.append("status", value);
    }

    changeCorporateUserStatusApi(formData)
      .then((result) => {
        if (result?.status == "success") {
          manageUsersApiCall();
          handleClosePopupStatus()
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoader(false));
  };

  const handleClosePopupStatus = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setPopupStatus("");
    setRejectPopup({
      value: false,
      data: "",
      reason: ""
    })
  };

  const handleSearchData = (e) => {
    let searchData = [];
    if (e?.target?.value != "") {
      userData?.map((user) => {
        if (
          user?.first_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) || user?.last_name
              ?.toLowerCase()
              .includes(e.target.value?.toLowerCase())
        ) {
          searchData.push(user);
        }
      });
    } else {
      searchData = userData;
    }

    setSearchData(searchData);
  };

  //chips user wise
  const addChip = (value) => {
    setInviteError("");
    setInviteUserPopup({
      ...inviteUserPopup,
      users: value,
    });

  };
  const onInvitationSend = () => {
    console.log(">>>>", inviteUserPopup.users.length)
    if (inviteUserPopup.users.length == 0) {
      setInviteError("Please enter atleast 1 email")
    } else if (inviteUserPopup.users.length > 15) {
      setInviteError("Max 15 email allowed at a time")
    } else {
      setLoaderbtn(true)

      const data = {
        user_invite_list: inviteUserPopup.users
      }
      inviteCorporateUserApi(data).then((res) => {
        if (res.status === 'success') {
          toast.success(lan == 'en' ? res.data : res.data_sv)
          setInviteUserPopup({ value: false, users: [] })

        } else {
          toast.error(lan == 'en' ? res.data : res.data_sv)
        }
      }).catch((e) => console.log(e))
        .finally(() => setLoaderbtn(false))
    }
  }

  return (
    <div className="manageUsers">
      <div className="subCategoryHeader my-4 pb-3 pt-3">
        <p className="midText mb-0">
          {" "}
          <span className="cursor-pointer" onClick={() => history.push('/supplier/dashboard')}>{t("Dashboard", lan)} |{" "} </span>
          <span className="textPrimaryColor"> {t("CorporateUser", lan)}</span>
        </p>
        <div className="fliter-block align-items-center">
          <div className="pageSearch">
            <span className="iconSearch">
              <img src={IcSearch} />
            </span>
            <InputText
              placeholder={t("searchbyName", lan)}
              onChange={handleSearchData}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">


            <div className="headerTab smallText position-relative">
              <span className="iconsSelect">
                <img src={IcFilter2} />
              </span>
              <FilterSelectMenu
                placeholder=""
                value={["Newest", "Oldest"]}
                selected={selectedTimeFilter}
                onChange={(e) => {
                  setSelectedTimeFilter(e.target.value);
                }}
                isWithicon
              />
            </div>
          </div>
          <button
            onClick={() => setInviteUserPopup({
              users: [], value: true
            })}
            className="primaryButton mt-0"
          >
            {t("InviteUser", lan)}
          </button>
        </div>

      </div>
      <div className="subCategoryListDiv">
        <table className="subCategoryListTable manageuser-tabel">
          <tr className="whitespaceNoWrap">
            <th> {t("UserName", lan)}</th>
            <th>
              {t("CorporateCode", lan)}
            </th>
            <th> {` ${t("DateCreated", lan)}  `}</th>
            <th> {` ${t("Status", lan)} `}</th>
            <th>   {` ${t("Approve", lan)} / ${t("Reject", lan)} `}</th>
            <th> {` ${t("Action", lan)}`}</th>
          </tr>

          {paginationUser?.length > 0 &&
            paginationUser?.map((users, index) => {
              return (
                <tr key={index} className="subCategoryTableInfo">
                  <td>
                    <div className="d-flex align-items-center">
                      <p
                        className="spplierImg"
                        style={{ background: `#${generateColor()}` }}
                      >
                        {users?.first_name.charAt(0).toUpperCase()}{" "}
                      </p>


                      <div>
                        <p className="mb-0">
                          {`${users?.first_name}  ${users?.last_name}` || "-"}
                        </p>
                        <p className="mb-1">{users?.email_id || "-"}</p>
                      </div>
                    </div>
                  </td>
                  <td>{users?.corporate_code || "-"}</td>
                  <td>
                    {new Date(users?.created_date_time).getDate()}{" "}
                    {monthNames[new Date(users?.created_date_time).getMonth()]}{" "}
                    {new Date(users?.created_date_time).getFullYear()}
                  </td>
                  <td>
                    <select
                      value={users?.status?.value}
                      onChange={(e) => {
                        onApproveReject(e.target.value, users?.email_id);
                      }}
                      className={
                        users?.status?.value == "Active"
                          ? "StatusActive"
                          : "StatusInActive"
                      }
                    >
                      <option value={"Active"}>
                        {" "}
                        {` ${t("Active", lan)} `}
                      </option>
                      <option value={"Inactive"}>
                        {" "}
                        {` ${t("inActive", lan)} `}
                      </option>
                    </select>
                  </td>

                  <td>
                    <select
                      value={users.is_approved?.value}
                      onChange={(e) => { e.target.value === "Rejected" ? setRejectPopup({ value: true, data: users, reason: "" }) : onApproveReject(e.target.value, users?.email_id, "Approval") }
                      }
                      className={
                        users.is_approved?.value == "Approved"
                          ? "StatusApproved"
                          : users?.is_approved?.value == "Rejected"
                            ? "StatusInActive"
                            : "StatusActive"
                      }
                    >
                      <option value={"Pending"}>{t("Pending", lan)}</option>
                      <option value={"Approved"}>
                        {t("Approved", lan)}
                      </option>
                      <option value={"Rejected"}>
                        {t("Rejected", lan)}
                      </option>
                    </select>
                  </td>

                  <td className="whitespaceNoWrap">
                    <Link
                      to={`/supplier/manage-corporate-users/users-detail/${users?.uuid}`}
                      className="cursorPointer textPrimaryColor linkDecoreRemove"

                    >
                      {t("View", lan)}
                    </Link>{" "}
                  </td>
                </tr>
              );
            })}
        </table>
        {paginationUser?.length <= 0 && (
          <div>
            <NoData />
          </div>
        )}
      </div>
      <div className="mt-3 text-end">
        <div className="col-12">
          {filteredUser?.length > 12 && (
            <CustomPagination
              startIndex={paginationStartIndex}
              endIndex={
                paginationStartIndex + 11 > filteredUser?.length
                  ? filteredUser?.length
                  : paginationStartIndex + 11
              }
              totalData={filteredUser?.length}
              onPrev={paginationPrev}
              onNext={paginationNext}
            />
          )}
        </div>
      </div>

      <SidePopup
        header={
          t("InviteUser", lan)
        }
        show={inviteUserPopup.value}
        handleClose={() =>
          setInviteUserPopup({ value: false, users: [] })
        }
      >
        <div className="sidepopupContents">

          <div className="my-3">
            <label htmlFor="ModuleDecEng">
              <p className="smallText inputText boldText pWithMargin my-2">
                {t("EnterEmail", lan)}
              </p>
            </label>

            <ReactMultiEmail
              className="multi-email"
              placeholder={t("PressEnter", lan)}
              emails={inviteUserPopup.users}
              onChange={(_emails) => addChip(_emails)}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            {inviteError && <p className="errorText mb-0">{inviteError}</p>}

          </div>

          <button onClick={onInvitationSend} className="primaryButton" >
            {t("InviteUser", lan)}
          </button>
        </div>
      </SidePopup>

      <div className="react-reason">
        <Popup
          show={rejectPopup?.value}
          header={
            t("RejectReason", lan)
          }
          handleClose={handleClosePopupStatus}
        >

          <div className="popupinfo">
            <div className="mt-3 form-group">

              <textarea
                rows={4}
                id="courseOverview"
                required
                placeholder={` ${t("EnterReason", lan)} `}
                className="form-control textArea mb-3"
                onChange={(e) => setRejectPopup({ ...rejectPopup, reason: e?.target?.value })}
              />
            </div>
            <button
              onClick={() => onApproveReject("Rejected", rejectPopup.data.email_id, "Approval")}
              className="btn btn-green text-uppercase w-100 mt-2  col-md-6 col-sm-6 col-xs-12"
            >
              {t("Okbutton", lan)}
            </button>
          </div>

        </Popup>
      </div>
      <Popup
        show={popupStatus !== "" ? true : false}
        header={popupStatus}
        handleClose={handleClosePopupStatus}
      >
        <div className="popupinfo">
          <p>{popupMessage}</p>
        </div>
        <div>
          <button
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2"
          >
            {t("Okbutton", lan)}
          </button>
        </div>
      </Popup>
      {isLoader ? <Loader /> : ""}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ManageCorporateUsers;
