import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import IcDashboard from "../../assets/images/IcDashboard.svg";
import Vector from "../../assets/images/Payment.svg";
import IcSpace from "../../assets/images/IcSpace.svg";
import IcMyCourse from "../../assets/images/IcMyCourse.svg";
import IcEddiProfile from "../../assets/images/IcEddiProfile.svg";
import IcOrg from "../../assets/images/ic_org.svg";
import IcSetting from "../../assets/images/IcSetting.svg";
import Logo from "../../assets/logo/logo-white.svg";
import ideas from "../../assets/images/ideas.svg";
import circle from "../../assets/images/info-circle.svg";
import { getTranslatedText as t } from "../../translater/index";
import "./Sidebar.scss";
import { useRouteMatch,useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function Sidebar(props) {
  let { url } = useRouteMatch();
  const history = useHistory()
  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;
  const role = state?.UserDetail?.user_type;
  const Approval = state.is_approved;
  const isCorporate = state?.UserDetail?.is_corporate
  const [courseOpen, setcourseOpen] = useState(false);
  const [organizationOpen, setOrganizationOpen] = useState(false);
  const [paymentMenu, setPaymentMenu] = useState(false);
  return (
    <>
      <div className="Sidebar-main">
        <div className="logo">
          <img className="cursor-pointer" onClick={()=>history.push('/supplier/dashboard')} src={Logo} />
        </div>
        <ul>
          <li>
            <Link
              to={
                role !== "Supplier"
                  ? `${url}/dashboard`
                  : Approval === true && `${url}/dashboard`
              }
              onClick={props.onClick}
              className={
                role !== "Supplier"
                  ? window.location.href.includes("/supplier/dashboard")
                    ? "active"
                    : null
                  : Approval === true
                  ? window.location.href.includes("/supplier/dashboard")
                    ? "active"
                    : null
                  : "disableLink"
              }
            >
              <span className="link-icon">
                <img src={IcDashboard} />
              </span>
              {t("Dashboard", lan)}
            </Link>
          </li>

          {/* organisation supplier flow */}


          { role === "Supplier" && isCorporate && (
            <li
              onClick={() => {
                setOrganizationOpen(!organizationOpen);
                setcourseOpen(false);
                setPaymentMenu(false);
              }}
            >
              <Link
                className={
                  role !== "Supplier"
                    ? window.location.href.includes("/organization")
                      ? " li-icon"
                      : "li-icon"
                    : Approval === true
                    ? window.location.href.includes("/organization")
                      ? " li-icon"
                      : "li-icon"
                    : "disableLink"
                }
              >
                <span className="link-icon">
                  <img src={IcOrg} alt="" />
                </span>
                {t("OurOrganization", lan)}
              </Link>
            </li>
          )}

          {organizationOpen && (
            <div className="side-sub-menu">
              {" "}
              {/* manage Categories  */}
              <li>
                <Link
                  to={
                    role !== "Supplier"
                      ? `${url}/organization/categories`
                      : Approval === true && `${url}/organization/categories`
                  }
                  onClick={props.onClick}
                  className={
                    role !== "Supplier"
                      ? window.location.href.includes(
                          "/organization/categories"
                        )
                        ? "active"
                        : null
                      : Approval === true
                      ? window.location.href.includes(
                          "/organization/categories"
                        )
                        ? "active"
                        : null
                      : "disableLink"
                  }
                >
                  {t("Categories", lan)}
                </Link>
              </li>
              <li>
                {/* manage org event  */}
                <Link
                  to={
                    role !== "Supplier"
                      ? `${url}/corporate/event`
                      : Approval === true && `${url}/corporate/event`
                  }
                  onClick={props.onClick}
                  className={
                    role !== "Supplier"
                      ? window.location.href.includes("/corporate/event")
                        ? "active"
                        : null
                      : Approval === true
                      ? window.location.href.includes("/corporate/event")
                        ? "active"
                        : null
                      : "disableLink"
                  }
                >
                  {t("EddiEvent", lan)}
                </Link>
              </li>
              {/* corporate dashboard  */}
              <li>
                <Link
                  to={
                    role !== "Supplier"
                      ? `${url}/corporate/dashboard`
                      : Approval === true && `${url}/corporate/dashboard`
                  }
                  onClick={props.onClick}
                  className={
                    role !== "Supplier"
                      ? window.location.href.endsWith("corporate/dashboard")
                        ? "active"
                        : ""
                      : Approval === true
                      ? window.location.href.endsWith("corporate/dashboard")
                        ? "active"
                        : ""
                      : "disableLink"
                  }
                >
                  {t("CorporateDashboard", lan)}
                </Link>
              </li>
              {/* org course  */}
              <li>
                <Link
                  to={
                    role !== "Supplier"
                      ? `${url}/corporate/course`
                      : Approval === true && `${url}/corporate/course`
                  }
                  onClick={props.onClick}
                  className={
                    role !== "Supplier"
                      ? window.location.href.includes("corporate/course") ||
                        window.location.href.includes("-course")
                        ? "active"
                        : ""
                      : Approval === true
                      ? window.location.href.includes("corporate/course") ||
                        window.location.href.includes("-course")
                        ? "active"
                        : ""
                      : "disableLink"
                  }
                >
                  {t("CorporateCourse", lan)}
                </Link>
              </li>
              {/* manage corporate user  */}
              <li>
                <Link
                  to={`${url}/manage-corporate-users`}
                  onClick={props.onClick}
                  className={
                    window.location.href.includes("/manage-corporate-users")
                      ? "active"
                      : null
                  }
                >
                  {t("CorporateUser", lan)}
                </Link>
              </li>
              {/* corporate batch  */}
              <li>
                <Link
                  to={`${url}/corporate/batch`}
                  onClick={props.onClick}
                  className={
                    window.location.href.includes("/corporate/batch")
                      ? "active"
                      : null
                  }
                >
                  {t("CorporateBatch", lan)}
                </Link>
              </li>
              {/* corporate sessions  */}
              <li>
                <Link
                  to={`${url}/corporate/session`}
                  onClick={props.onClick}
                  className={
                    window.location.href.includes("/corporate/session")
                      ? "active"
                      : null
                  }
                >
                  {t("CorporateSession", lan)}
                </Link>
              </li>
            </div>
          )}
    

          <li>
            <Link
              to={
                role !== "Supplier"
                  ? `${url}/subCategory`
                  : Approval === true && `${url}/subCategory`
              }
              onClick={props.onClick}
              className={
                role !== "Supplier"
                  ? window.location.href.includes("/subCategory")
                    ? "active"
                    : null
                  : Approval === true
                  ? window.location.href.includes("/subCategory")
                    ? "active"
                    : null
                  : "disableLink"
              }
            >
              <span className="link-icon">
                <img src={IcSpace} alt="" />
              </span>
              {t("CourseSubCategory", lan)}
            </Link>
          </li>

          <li
            onClick={() => {
              setcourseOpen(!courseOpen);
              setPaymentMenu(false);
              setOrganizationOpen(false);
            }}
          >
            <Link
              className={
                role !== "Supplier"
                  ? window.location.href.includes("/course")
                    ? " li-icon"
                    : "li-icon"
                  : Approval === true
                  ? window.location.href.includes("/course")
                    ? " li-icon"
                    : "li-icon"
                  : "disableLink"
              }
            >
              <span className="link-icon">
                <img src={IcMyCourse} alt="" />
              </span>
              {t("ManageCourses", lan)}
            </Link>
          </li>
          {/* manage course  */}
          {courseOpen && (
            <div className="side-sub-menu">
              {" "}
              {/* manage course  */}
              <li>
                <Link
                  to={
                    role !== "Supplier"
                      ? `${url}/course`
                      : Approval === true && `${url}/course`
                  }
                  onClick={props.onClick}
                  className={
                    role !== "Supplier"
                      ? 
                        window.location.href.includes(
                          "/supplier/course"
                        )
                        ? "active"
                        : ""
                      : Approval === true
                      ? 
                        window.location.href.includes(
                          "/supplier/course"
                        )
                        ? "active"
                        : ""
                      : "disableLink"
                  }
                >
                  {t("MyCourses", lan)}
                </Link>
              </li>
              <li>
                {/* manage batch  */}
                <Link
                  to={
                    role !== "Supplier"
                      ? `${url}/course/batch`
                      : Approval === true && `${url}/course/batch`
                  }
                  onClick={props.onClick}
                  className={
                    role !== "Supplier"
                      ? window.location.href.includes("/course/batch")
                        ? "active"
                        : null
                      : Approval === true
                      ? window.location.href.includes("/course/batch")
                        ? "active"
                        : null
                      : "disableLink"
                  }
                >
                  {t("Batch", lan)}
                </Link>
              </li>
              {/* session  */}
              <li>
                <Link
                  to={
                    role !== "Supplier"
                      ? `${url}/course/session`
                      : Approval === true && `${url}/course/session`
                  }
                  onClick={props.onClick}
                  className={
                    role !== "Supplier"
                      ? window.location.href.includes("/course/session")
                        ? "active"
                        : null
                      : Approval === true
                      ? window.location.href.includes("/course/session")
                        ? "active"
                        : null
                      : "disableLink"
                  }
                >
                  {t("Session", lan)}
                </Link>
              </li>
            </div>
          )}

  

          {/* {role && role !== "Supplier" && */}
          <li>
            <Link
              to={
                role !== "Supplier"
                  ? `${url}/recuritmentAds`
                  : Approval === true && `${url}/recuritmentAds`
              }
              onClick={props.onClick}
              className={
                role !== "Supplier"
                  ? window.location.href.includes("/recuritmentAds")
                    ? "active"
                    : null
                  : Approval === true
                  ? window.location.href.includes("/recuritmentAds")
                    ? "active"
                    : null
                  : "disableLink"
              }
            >
              <span className="link-icon">
                <img src={ideas} />
              </span>
              {t("RecruitmentAd", lan)}
            </Link>
          </li>
          {/* } */}
          {role && role === "Supplier" && (
            <li>
              <Link
                to={`${url}/create-profile`}
                onClick={props.onClick}
                className={
                  window.location.href.includes("/create-profile")
                    ? "active"
                    : null
                }
              >
                <span className="link-icon">
                  <img src={IcEddiProfile} />
                </span>
                {t("EddiProfile", lan)}
              </Link>
            </li>
          )}
          {role && role !== "Supplier" && (
            <>
              <li>
                <Link
                  to={`${url}/manage-users`}
                  onClick={props.onClick}
                  className={
                    window.location.href.includes("/manage-users")
                      ? "active"
                      : null
                  }
                >
                  <span className="link-icon">
                    <img src={IcEddiProfile} />
                  </span>
                  {t("ManageUsers", lan)}
                </Link>
              </li>
              <li>
                <Link
                  to={`${url}/manage-supplier-profile`}
                  onClick={props.onClick}
                  className={
                    window.location.href.includes("/manage-supplier-profile")
                      ? "active"
                      : null
                  }
                >
                  <span className="link-icon">
                    <img src={IcEddiProfile} />
                  </span>
                  {t("ManageSupplierProfile", lan)}
                </Link>
              </li>
            </>
          )}

          {/* payment section  */}
          {role === "Admin" && (
            <Fragment>
              <li
                onClick={() => {
                  setPaymentMenu(!paymentMenu);
                  setcourseOpen(false);
                }}
              >
                <Link
                  className={
                    role !== "Supplier"
                      ? window.location.href.includes("/payment")
                        ? " li-icon"
                        : "li-icon"
                      : Approval === true
                      ? window.location.href.includes("/payment")
                        ? " li-icon"
                        : "li-icon"
                      : "disableLink"
                  }
                >
                  <span className="link-icon">
                    <img src={Vector} alt="" />
                  </span>
                  {t("ManagePayments", lan)}
                </Link>
              </li>
              {/* manage payment  */}
              {paymentMenu && (
                <div className="side-sub-menu">
                  {/* my payments  */}
                  <li>
                    <Link
                      to={`${url}/my-payments`}
                      // to={`${url}/manage-payments`}
                      onClick={props.onClick}
                      className={
                        window.location.href.includes("/my-payments")
                          ? "active"
                          : ""
                      }
                    >
                      {t("MyPayment", lan)}
                    </Link>
                  </li>
                  {/* manage payment  */}
                  <li>
                    <Link
                      to={`${url}/manage-payments`}
                      // to={`${url}/manage-payments`}
                      onClick={props.onClick}
                      className={
                        window.location.href.includes("/manage-payments")
                          ? "active"
                          : ""
                      }
                    >
                      {t("PaymentDetails", lan)}
                    </Link>
                  </li>

                  {/* payment list  */}
                  <li>
                    <Link
                      to={`${url}/payment-list`}
                      // to={`${url}/manage-payments`}
                      onClick={props.onClick}
                      className={
                        role !== "Supplier"
                          ? window.location.href.includes("/payment-list")
                            ? "active"
                            : null
                          : Approval === true
                          ? window.location.href.includes("/payment-list")
                            ? "active"
                            : null
                          : ""
                      }
                    >
                      {t("SupplierPayouts", lan)}
                    </Link>
                  </li>

                  {/* withdrawal list (transaction list) */}
                  <li>
                    <Link
                      to={`${url}/withdrawal-list`}
                      // to={`${url}/manage-payments`}
                      onClick={props.onClick}
                      className={
                        role !== "Supplier"
                          ? window.location.href.includes("/withdrawal-list")
                            ? "active"
                            : null
                          : Approval === true
                          ? window.location.href.includes("/withdrawal-list")
                            ? "active"
                            : null
                          : ""
                      }
                    >
                      {t("WithdrawalRequest", lan)}
                    </Link>
                  </li>
                </div>
              )}
            </Fragment>
          )}

          {role === "Supplier" && (
            <li>
              <Link
                to={
                  role !== "Supplier"
                    ? `${url}/manage-payments`
                    : Approval === true && `${url}/manage-payments`
                }
                // to={`${url}/manage-payments`}
                onClick={props.onClick}
                className={
                  role !== "Supplier"
                    ? window.location.href.includes("/manage-payments")
                      ? "active"
                      : null
                    : Approval === true
                    ? window.location.href.includes("/manage-payments")
                      ? "active"
                      : null
                    : "disableLink"
                }
              >
                <span className="link-icon">
                  <img src={Vector} />
                </span>
                {t("ManagePayments", lan)}
              </Link>
            </li>
          )}

          {role && role !== "Supplier" && (
            <li>
              <Link
                to={`${url}/event`}
                onClick={props.onClick}
                className={
                  window.location.href.includes("/event") ? "active" : null
                }
              >
                <span className="link-icon">
                  <img src={circle} />
                </span>
                {t("EddiEvent", lan)}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
