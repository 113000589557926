
export const logout = (data) =>{
    return {
        type:"logout",
        payload:data
    }
}
export const courseCategories = (data) =>{
    return {
        type:"addCourseCategories",
        payload:data
    }
}
export const courseSubCategories = (data) =>{
    return {
        type:"addCourseSubCategories",
        payload:data
    }
}
export const courses = (data) =>{
    return {
        type:"addCourses",
        payload:data
    }
}

export const viewCoursesDetails = (data) =>{
    return {
        type:"viewCoursesDetails",
        payload:data
    }
}

export const getAllApprovedSubCategory = (data) =>{
    return {
        type:"getAllApprovedSubCategory",
        payload:data
    }
}

export const selectedUserEmail = (data) =>{
    return {
        type:"selectedUserEmail",
        payload:data
    }
}

export const tabPageNo = (data) =>{
    return {
        type:"tabNo",
        payload:data
    }
}

export const userDetail = (data) =>{
    return {
        type:"adduserDetail",
        payload:data
    }
}

export const searchCourseText = (data) =>{
    return{
        type:"searchCourseText",
        payload:data
    }
}

export const getAllCourses = (data) =>{
    return{
        type:"getAllCourses",
        payload:data
    }
}
export const getAllUser = (data) =>{
    return{
        type:"getAllUsers",
        payload:data
    }
}

export const courseGraphData = (data) =>{
    return{
        type:"courseGraphData",
        payload:data
    }
}

export const allEventDetails = (data) =>{
    return{
        type:"allEventDetails",
        payload:data
    }
}

export const selectedEventDetails = (data) =>{
    return{
        type:"selectedEventDetails",
        payload:data
    }
}

export const language = (data) =>{
    return{
        type:"language",
        payload:data
    }
}


export const getPersonalProfileData = (data) =>{
    return{
        type:"getPersonalProfileData",
        payload:data
    }
}

export const additionalInfoData=(data)=>{
    return{
        type:"additionalInfoData",
        payload:data
    }
}

export const organizationalInfoData=(data)=>{
    return{
        type:"organizationalInfoData",
        payload:data
    }
}

export const courseCategoryDetails=(data)=>{
    return{
        type:"courseCategoryDetails",
        payload:data
    }
}


export const isApproved=(data)=>{
    return{
        type:"isApproved",
        payload:data
    }
}

export const notificationCount=(data)=>{
    return{
        type:"notificationCount",
        payload:data
    }
}

export const allNotificationData=(data)=>{
    return{
        type:"allNotificationData",
        payload:data
    }
}

export const getCourseNames=(data)=>{
    return{
        type:"getCourseNames",
        payload:data
    }
}

export const isPublishedCourse=(data)=>{
    return{
        type:"isPublishedCourse",
        payload:data
    }
}