
import React, { useEffect, useRef, useState } from "react";


import aboutbanner from "../../assets/images/aboutbanner.jpg";

import "./PrivacyPolicy.scss";
import { Link } from "react-router-dom";
import API from "../../api";
import { getPrivacyPolicyApi } from "../../services/supplierService";

function PrivacyPolicy() {

    const [privacyPolicyData,setPrivacyPolicyData] = useState()

    const privacyCall = () =>{
        getPrivacyPolicyApi().then((res)=>{
          if(res.status === "success"){
            setPrivacyPolicyData(res.data);
          }
        })
      }
    
      useEffect(()=>{
        privacyCall()
      },[])
  return (
    <div className="privacypolicypage" >
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-12 px-0 banner-img"
            style={{
              backgroundImage: privacyPolicyData? `url(${privacyPolicyData?.section_1_image})`  : `url(${aboutbanner})`,
            }}
          >
            <div className="container">
              <div className="row ">
                <div className="pagename px-0">
                  <h1>{privacyPolicyData? privacyPolicyData?.section_1_heading : "Privacy Policy"}</h1>
                  <Link to="/">{privacyPolicyData?.section_1_button_text ? privacyPolicyData?.section_1_button_text : "Back to Home"}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tncContent">
        <div className="container">
            <h3>{privacyPolicyData? privacyPolicyData?.section_1_heading : "Privacy Policy"}</h3>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12 px-lg-0 ">
              <p>
                <div dangerouslySetInnerHTML= {{__html: privacyPolicyData?.section_2_description}}></div>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ultrices in tellus vitae proin. Adipiscing amet, iaculis
                tincidunt aliquam in. Nec nunc neque orci viverra proin tellus
                justo. Rhoncus, iaculis nisl, turpis quis.Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Ultrices in tellus vitae
                proin. Adipiscing amet, iaculis tincidunt aliquam in. Nec nunc
                neque orci viverra proin tellus justo. Rhoncus, iaculis nisl,
                turpis quis.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Ultrices in tellus vitae proin. Adipiscing amet, iaculis
                tincidunt aliquam in. Nec nunc neque orci viverra proin tellus
                justo. Rhoncus, iaculis nisl, turpis quis.Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Ultrices in tellus vitae
                proin. Adipiscing amet, iaculis tincidunt aliquam in. Nec nunc
                neque orci viverra proin tellus justo. Rhoncus, iaculis nisl,
                turpis quis.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Ultrices in tellus vitae proin. Adipiscing amet, iaculis
                tincidunt aliquam in. Nec nunc neque orci viverra proin tellus
                justo. Rhoncus, iaculis nisl, turpis quis. */}
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy