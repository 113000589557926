import React, { useEffect, useState } from "react";
import "./CorporateDashboard.scss";
import { Link, useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getTranslatedText as t } from "../../../../translater";
import { useSelector } from "react-redux";
import PlaceholderImg from "../../../../assets/images/dummy.png";
import Footer from "../../../../components/footer/Footer";
import SidePopup from "../../../../components/sidePopup/sidePopup";
import NoData from "../../../../components/NoData/NoData";
import { checkTrimOrUndefined } from "../../../../services/constant";
import {
  editCorporateDashboardData,
  getCorporateDashboard,
  postCorporateDashboardData,
} from "../../../../services/supplierService";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";

const CorporateDashboard = () => {
  const state = useSelector((state) => state.Eddi);
  let lan = state?.language;
  const history = useHistory();
  const [isLoader, setIsLoader] = useState(false);
  const [loderBtn, setLoderBtn] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [error, setError] = useState({
    aboutCompanySw: "",
    aboutCompanyEn: "",
    whatsOnSw: "",
    whatsOnEn: "",
  });
  const [addContentModule, setAddContentModule] = useState({
    type: "Add",
    value: false,
    aboutCompanySw: "",
    aboutCompanyEn: "",
    whatsOnSw: "",
    whatsOnEn: "",
    background_img: [],
    logo: [],
  });
  const getDashboardContent = () => {
    setIsLoader(true);
    getCorporateDashboard()
      .then((res) => {
        if (res.status == "success") {
          setDashboardData(res.data);
          setAddContentModule({
            ...addContentModule,
            aboutCompanySw: res.data?.about_company_sw || "",
            aboutCompanyEn: res.data?.about_company_en || "",
            whatsOnSw: res.data?.whats_on_sw || "",
            whatsOnEn: res.data?.whats_on_en || "",
            background_img: res.data?.corporate_bg_image || [],
            logo: res.data?.corporate_logo || [],
          });
        }
      })
      .catch((e) => console.log(e))
      .finally((e) => setIsLoader(false));
  };

  const handleChange = (value, type) => {
    setAddContentModule({ ...addContentModule, [type]: value });
    setError({ ...error, [type]: "" });
  };

  const handleClosePopup = () => {
    setAddContentModule({
      ...addContentModule,
      type: "Add",
      value: false,
    });
  };

  const validate = () => {
    if (checkTrimOrUndefined(addContentModule.aboutCompanySw)) {
      setError({ ...error, aboutCompanySw: "please enter about company" });
      return false;
    } else if (checkTrimOrUndefined(addContentModule.aboutCompanyEn)) {
      setError({ ...error, aboutCompanyEn: "please enter about company" });
      return false;
    } else if (checkTrimOrUndefined(addContentModule.whatsOnSw)) {
      setError({ ...error, whatsOnSw: "please enter text" });
      return false;
    } else if (checkTrimOrUndefined(addContentModule.whatsOnEn)) {
      setError({ ...error, whatsOnEn: "please enter text" });
      return false;
    } else {
      return true;
    }
  };

  const onChangeImg = (e, type) => {
    const img = e.target.files;
    setIsLoader(true);
    let formData = new FormData();
    formData.append("whats_on_en", addContentModule.whatsOnEn || "");
    formData.append("whats_on_sw", addContentModule.whatsOnSw || "");
    formData.append("about_company_en", addContentModule.aboutCompanyEn || "");
    formData.append("about_company_sw", addContentModule.aboutCompanySw || "");
    if (type === "logo") {
      formData.append("corporate_logo", img[0]);
      formData.append("corporate_bg_image", addContentModule.background_img);
    } else {
      formData.append("corporate_logo", addContentModule.logo);
      formData.append("corporate_bg_image", img[0]);
    }
    const uuid = dashboardData?.uuid;

    editCorporateDashboardData(formData, uuid)
      .then((res) => {
        setLoderBtn(false);
        if (res.status == "success") {
          handleClosePopup();
          toast.success(lan == "en" ? res.data : res?.data_sv);
          getDashboardContent();
        } else {
          toast.error(lan == "en" ? res.data : res?.data_sv);
        }
      })
      .catch((err) => {
        setLoderBtn(false);
      })
      .finally(() => setIsLoader(false));
  };

  const handleAddEdit = () => {
    if (validate()) {
      setLoderBtn(true);
      let formData = new FormData();
      formData.append("whats_on_en", addContentModule.whatsOnEn);
      formData.append("whats_on_sw", addContentModule.whatsOnSw);
      formData.append("about_company_en", addContentModule.aboutCompanyEn);
      formData.append("about_company_sw", addContentModule.aboutCompanySw);

      const uuid = dashboardData?.uuid;
      editCorporateDashboardData(formData, uuid)
        .then((res) => {
          setLoderBtn(false);
          if (res.status == "success") {
            handleClosePopup();
            toast.success(lan == "en" ? res.data : res?.data_sv);
            const updatedData = {
              whats_on_en: addContentModule.whatsOnEn,
              whats_on_sw: addContentModule.whatsOnSw,
              about_company_en: addContentModule.aboutCompanyEn,
              about_company_sw: addContentModule.aboutCompanySw,
            };
            setDashboardData({ ...dashboardData, ...updatedData });
          } else {
            toast.error(lan == "en" ? res.data : res?.data_sv);
          }
        })
        .catch((err) => {
          setLoderBtn(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getDashboardContent();
  }, []);

  if (isLoader) {
    return <Loader />;
  }

  return (
    <div className="main-dash">
      <div className="dashboard">
        <div className="courseHeader my-4 pb-3 pt-3">
          <p className="midText mb-0 ">
            <span
              className="cursor-pointer"
              onClick={() => history.push("/supplier/dashboard")}
            >
              {t("Welcome", lan)}{" "}
              {dashboardData?.corporate_user?.first_name || ""}
            </span>
          </p>
          <button
            className="btn-grn"
            onClick={() =>
              setAddContentModule({
                ...addContentModule,
                type: "Add",
                value: true,
              })
            }
          >
            {t("AddInfo", lan)}
          </button>
        </div>
        <div className="dashoabrd-list ">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-12 mb-4">
              <div className="main-bg-white p-lg-4 p-md-3 p-2 ">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="f-30">{t("BackgroundImg", lan)} </span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => onChangeImg(e, "background")}
                    id="actual-btn"
                    hidden
                  />

                  <label className="link-text cursor-pointer" for="actual-btn">
                    {t("Edit", lan)}
                  </label>
           </div>

                <div className="my-4 text-center">
                  {dashboardData?.corporate_bg_image ? (
                    <img
                      className="bg-img"
                      src={dashboardData?.corporate_bg_image}
                      alt="back"
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 col-12 mb-4">
              <div className="main-bg-white py-lg-4 py-md-3 px-lg-5 px-md-3 p-2  ">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="f-30">{t("WhatsOn", lan)} </span>
                  <span
                    className="link-text cursor-pointer"
                    onClick={(e) =>
                      setAddContentModule({
                        ...addContentModule,
                        value: true,
                        type: "Update",
                      })
                    }
                  >
                    {t("Edit", lan)}{" "}
                  </span>
                </div>
                <div className="my-4">
                  <p className="whatsOn">
                    {dashboardData?.[`whats_on_${lan}`] ? (
                      <p
                      dangerouslySetInnerHTML={{
                        __html: dashboardData?.[`whats_on_${lan}`] || ""
                      }}
                      ></p>
                    ) : (
                      <NoData />
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12 col-12 mb-4">
              <div className="main-bg-white p-lg-4 p-md-3 p-2 ">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="f-30">{t("Logo", lan)} </span>
                  {/* <span className='link-text cursor-pointer' onClick={(e) => setAddContentModule({ ...addContentModule, value: true, type: "Update" })}>{t("Edit", lan)} </span> */}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => onChangeImg(e, "logo")}
                    id="logo-btn"
                    hidden
                  />

                  <label className="link-text cursor-pointer" for="logo-btn">
                    {t("Edit", lan)}
                  </label>
                </div>

                <div className="my-4 text-center">
                  {dashboardData?.corporate_logo ? (
                    <img
                      className="logo-img"
                      src={dashboardData?.corporate_logo}
                      alt="back"
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 col-12 mb-4">
              <div className="main-bg-white py-lg-4 py-md-3 px-lg-5 px-md-3 p-2  ">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="f-30">{t("AboutCompany", lan)} </span>
                  <span
                    className="link-text cursor-pointer"
                    onClick={(e) =>
                      setAddContentModule({
                        ...addContentModule,
                        value: true,
                        type: "Update",
                      })
                    }
                  >
                    {t("Edit", lan)}{" "}
                  </span>
                </div>
                <div className="my-4">
                  <p className="whatsOn">
                    {dashboardData?.[`about_company_${lan}`] ? (
                      <p
                      dangerouslySetInnerHTML={{
                        __html: dashboardData?.[`about_company_${lan}`] || ""
                      }}
                      ></p>
                     
                    ) : (
                      <NoData />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SidePopup
        header={t(`${addContentModule.type}Info`, lan)}
        show={addContentModule.value}
        handleClose={handleClosePopup}
      >
        <div className="sidepopupContents">
          <div className="my-3">
            <label htmlFor="aboutSw">
              <p className="smallText inputText boldText pWithMargin">
                {t("AboutCompanySw", lan)}
              </p>
            </label>
            <div className="richtext">
              <CKEditor
                placeholder={t("EnterContent", lan)}
                className="formInputs textArea"
                id="aboutSw"
                data={addContentModule.aboutCompanySw || ""}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange(data, "aboutCompanySw");
                }}
              />
            </div>

            {error.aboutCompanySw && (
              <p className="errorText m-0">{error.aboutCompanySw}</p>
            )}
          </div>
          <div className="my-3">
            <label htmlFor="aboutEn">
              <p className="smallText inputText boldText pWithMargin">
                {t("AboutCompanyEn", lan)}
              </p>
            </label>
            <div className="richtext">
              <CKEditor
                className="formInputs"
                id="aboutEn"
                data={addContentModule.aboutCompanyEn || ""}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange(data, "aboutCompanyEn");
                }}
              />
            </div>
  
            {error.aboutCompanyEn && (
              <p className="errorText m-0">{error.aboutCompanyEn}</p>
            )}
          </div>
          <div className="my-3">
            <label htmlFor="whatsOnSw">
              <p className="smallText inputText boldText pWithMargin">
                {t("WhatOnSw", lan)}
              </p>
            </label>
            <div className="richtext">
              <CKEditor
                className="formInputs"
                id="whatsOnSw"
                data={addContentModule.whatsOnSw || ""}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange(data, "whatsOnSw");
                }}
              />
            </div>
 
            {error.whatsOnSw && (
              <p className="errorText m-0">{error.whatsOnSw}</p>
            )}
          </div>
          <div className="my-3">
            <label htmlFor="whatsOnEn">
              <p className="smallText inputText boldText pWithMargin">
                {t("WhatOnEn", lan)}
              </p>
            </label>
            <div className="richtext">
              <CKEditor
                className="formInputs"
                id="whatsOnEn"
                data={addContentModule.whatsOnEn || ""}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange(data, "whatsOnEn");
                }}
              />
            </div>
 
            {error.whatsOnEn && (
              <p className="errorText m-0">{error.whatsOnEn}</p>
            )}
          </div>

          <button className="btn-grn" onClick={handleAddEdit}>
            {t(`${addContentModule.type}Info`, lan)}
          </button>
        </div>
      </SidePopup>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CorporateDashboard;
