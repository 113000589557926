import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, Switch, Route } from "react-router-dom";
// import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import Boxarrow from "../../../assets/images/boxarrow.svg";
import IcSearch from "../../../assets/images/ic_serach.svg";
import ProfileImage from "../../../assets/images/profileImage.svg";
import IcLocation from "../../../assets/images/icLocation.svg";
import IcPhone from "../../../assets/images/IcPhone.svg";
import IcMail from "../../../assets/images/IcMail.svg";
import IcCamera from "../../../assets/images/IcCamera.svg";
import "../EditProfile/EditProfile.scss";
import { getTranslatedText as t } from "../../../translater/index";
import placeholder from "../../../assets/images/placeholder.svg";
import Footer from "../../../components/footer/Footer";
import InputText from "../../../components/inputText/inputText";
import ScrollContainer from "react-indiana-drag-scroll";

import ReactChipInput from "react-chip-input";
import api from "../../../api";
import {
  editPersonalProfileDetail,
  getPersonalProfileDetail,
} from "../../../services/supplierService";
import { getPersonalProfileData } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../components/popup/popup";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

function EditProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.Eddi);
  let lan = state?.language;
  const usertype = state.UserDetail?.user_type;
  const [phValue, setPhValue] = useState();
  const [editPopup, setEditPopup] = useState({ value: false, data: "" });
  const [nameErr, setnameErr] = useState(null);
  const [addErr, setaddErr] = useState(null);
  const [phNumErr, setphNumErr] = useState(null);
  const [abtErr, setabtErr] = useState(null);
  const [loderBtn, setLoderBtn] = useState(false);
  const [editData, setEditData] = useState({
    supplier_name: state?.personalData?.data?.supplier_name || "",
    address: state?.personalData?.data?.address || "",
    phone_number: state?.personalData?.data?.phone_number || "",
    supplier_image: state?.personalData?.data?.supplier_image || "",
    about_me: state?.personalData?.data?.about_me || "",
    is_swedishdefault:
      state?.personalData?.is_swedishdefault !== ""
        ? state?.personalData?.is_swedishdefault
        : true,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const personalProfileApi = () => {
    getPersonalProfileDetail()
      .then((result) => {
        if (result?.status == "success") {
          dispatch(getPersonalProfileData(result));
        }
      })
      .catch((e) => console.log(e));
  };

  function loadFile(event) {
    const src = URL.createObjectURL(event.target.files[0]);

    setEditData({
      ...editData,
      supplier_image: event?.target?.files[0],
      upload_image: src,
    });
  }

  const preventScroll = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  };

  const handleClosePopupStatus = () => {
    history.push("/supplier/eddi-profile");
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    setEditPopup({ value: false, data: "" });
  };

  const validate = () => {
    let supplier_name = editData?.supplier_name;
    let address = editData?.address;
    let phone = editData?.phone_number;
    let abtMe = editData?.about_me;
    if (
      !editData?.supplier_name &&
      !editData?.about_me &&
      !editData?.phone_number &&
      !editData?.address
    ) {
      setnameErr("* Please Enter  Name ");
      setaddErr("* Please Enter  Address ");
      setphNumErr("* Please  Enter  Mobile Number ");
      setabtErr("*  Please Tell us About Yourself");

      return false;
    } else if (supplier_name?.trim() === "" || !supplier_name) {
      setnameErr("* Please Enter  Name ");
      return false;
    } else if (address?.trim() === "" || !address) {
      setaddErr("* Please Enter  Address");
      return false;
    } else if (phone === "" || !phone) {
      setphNumErr("* Please Enter  Mobile Number ");
      return false;
    } else if (abtMe?.trim() === "" || !abtMe) {
      setabtErr("*  Please Tell us About Yourself");

      return false;
    } else {
      return true;
    }
  };
  const subMit = () => {
    // let stat = validate();
    // if (stat == true) {
    setLoderBtn(true);
    let formData = new FormData();
    formData.append("supplier_name", editData?.supplier_name);
    formData.append("address", editData?.address);
    formData.append("phone_number", editData?.phone_number);
    formData.append("supplier_image", editData?.supplier_image);
    formData.append("about_me", editData?.about_me);
    formData.append("is_swedishdefault", editData?.is_swedishdefault);

    editPersonalProfileDetail(formData)
      .then((result) => {
        setLoderBtn(false);
        if (result?.status == "success") {
          personalProfileApi();
          preventScroll();
          setEditPopup({
            value: true,
            data: lan == "en" ? result?.data : result?.data_sv,
          });
        } else {
          preventScroll();
          setEditPopup({
            value: true,
            data: lan == "en" ? result?.data : result?.data_sv,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setLoderBtn(false);
      });
    // }
  };

  return (
    <div className="mainpage">
      {/* <Sidebar /> */}
      <div className="EddiProfileMain">
        <div className="container-fluid">
          {/* <Header /> */}
          <div className="dashboard-inner">
            <h1>
              <Link to="/supplier/eddi-profile"> {t("MyProfile", lan)}</Link> |{" "}
              {t("EditProfile", lan)}
            </h1>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12 ">
                <div className="supplier-main">
                  <div className="supplier-photo-edit-profile">
                    <label for="file-input">
                      <img
                        id="output"
                        src={
                          editData?.upload_image
                            ? editData?.upload_image
                            : editData?.supplier_image
                            ? `${editData?.supplier_image}`
                            : placeholder
                        }
                      />
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={loadFile}
                    />
                  </div>
                  <div className="image-upload">
                    <label for="file-input">
                      <img src={IcCamera} />
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={loadFile}
                    />
                    {/* <input type="file" accept="image/*" onChange={loadFile} /> */}

                    {/* <input id="file-input" type="file" /> */}
                  </div>
                  <h4 className="text-center">Upload Profile Image</h4>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-12 profile-form-details">
                <div className="profile_input">
                  <InputText
                    placeholder={
                      usertype === "Supplier"
                        ? t("SupplierName", lan)
                        : t("YourName", lan)
                    }
                    labelName={t("YourName", lan)}
                    value={editData?.supplier_name}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        supplier_name: e?.target?.value,
                      });
                      setnameErr("");
                    }}
                    type="text"
                    pattern="[A-Za-z]"
                  />
                  {nameErr && <p className="errorText mb-0">{nameErr}</p>}
                </div>
                <div className="profile_input">
                  <InputText
                    placeholder={t("Address", lan)}
                    labelName={t("Address", lan)}
                    value={editData?.address}
                    onChange={(e) => {
                      setaddErr("");
                      setEditData({ ...editData, address: e?.target?.value });
                    }}
                  />
                  {addErr && <p className="errorText mb-0 ">{addErr}</p>}
                </div>
                <div className="profile_input">
                  <label>{t("phonnumber", lan)}</label>
                  {/* <InputText
                    placeholder={t("phonnumber", lan)}
                    labelName={t("phonnumber", lan)}
                    type={"number"}
                    className={"phone"}
                    value={editData?.phone_number}
                    onChange={(e) => {
                      setphNumErr("");
                      setEditData({
                        ...editData,
                        phone_number: e?.target?.value,
                      });
                    }}
                  /> */}
                  <PhoneInput
                    className="form-control phinput"
                    placeholder={t("phonnumber", lan)}
                    value={editData?.phone_number}
                    defaultCountry="SE"
                    onChange={(e) => {
                      setphNumErr("");
                      console.log(e);
                      setEditData({
                        ...editData,
                        phone_number: e,
                      });
                    }}
                    flags={false}
                    name="phoneVal"
                  />
                  {phNumErr && <p className="errorText mb-0">{phNumErr}</p>}
                </div>
                <div className="profile_input">
                  <InputText
                    placeholder={t("About Me", lan)}
                    labelName={t("About Me", lan)}
                    value={editData?.about_me}
                    onChange={(e) => {
                      setabtErr("");
                      setEditData({ ...editData, about_me: e?.target?.value });
                    }}
                  />
                  {abtErr && <p className="errorText mb-0">{abtErr}</p>}
                </div>
                <div className="form-check ms-1 my-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    onClick={(e) =>
                      setEditData({
                        ...editData,
                        is_swedishdefault: !e?.target?.checked,
                      })
                    }
                    checked={!editData?.is_swedishdefault}
                  />
                  <label className="form-check-label" for="exampleCheck1">
                    {t("AgreeToReceiveNotificationInSwedish", lan)}
                  </label>
                </div>
                {/* <div>
                  <label>{t("CoursesYouOffer", lan)}</label>
                  <ReactChipInput
                    classes="class1"
                    chips={chips}
                    onSubmit={(value) => addChip(value)}
                    onRemove={(index) => removeChip(index)}
                  />
                </div> */}
                <div className="mt-3">
                  <button
                    onClick={subMit}
                    disabled={loderBtn}
                    className="btn btn-green"
                  >
                    {loderBtn ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      t("SaveProfile", lan)
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <Footer />
          </div>
        </div>
      </div>

      <Popup
        show={editPopup?.value}
        header="Status"
        handleClose={handleClosePopupStatus}
        className="MB-3"
      >
        <div className="popupinfo">
          <p>{editPopup?.data}</p>

          <Link
            to={
              editPopup?.data === "Profile Data edited successfully"
                ? "/supplier/eddi-profile"
                : "#"
            }
            onClick={handleClosePopupStatus}
            className="btn btn-green text-uppercase w-100 mt-2  col-md-6 col-sm-6 col-xs-12"
          >
            {t("Okbutton", lan)}
          </Link>
        </div>
      </Popup>
    </div>
  );
}

export default EditProfile;
