import React, {useCallback, useEffect, useState} from 'react'
import {useDropzone, Dropzone} from 'react-dropzone'

import "./dropzone.scss"
import "../../common/styles/globalStyles.scss"
import {FaUpload} from "react-icons/fa"

function EddiDropzone(props) {
    
  const [validError,setvalidError]=useState("")
    const onDrop = useCallback(acceptedFiles => {
        
    }, []);

   
    const {acceptedFiles,getRootProps, getInputProps, isDragActive}  = useDropzone({

    })
    
    useEffect(()=>{
        if(acceptedFiles.length >0){
      let totalFile = props.selectedFiles;
        acceptedFiles.forEach((file) => {
          let fileType = file.type?.split('/')[0]
          console.log(">>>>>",(props?.accept).includes(fileType),fileType,props.accept);
          if(fileType && (props?.accept).includes(fileType) || props.accept ==='*'){
            totalFile.push(file);
            setvalidError("")
          }else{
            setvalidError("*Please Drop/Choose Valid File")
          }
            
        })
        return props.choosedFile(totalFile)}
  },[acceptedFiles])

  return (
    <div {...getRootProps()} className='customDropzone'>
      <input {...getInputProps()} />
      <FaUpload  className='textPrimaryColor uploadIcon' />
      {
        isDragActive ?
        <>
        <p>Drop the files here ...</p>

        </>
           :
          
          <p className='centerAlignText smallText dragText' >{props.dragText}</p>
      }
              {validError && ( <p className="errorText mb-0">{validError}</p>)}
    </div>
  )
}
export default EddiDropzone;